import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import chatWhatsappIcon from '../../../assets/icons/chatWhatsappIcon.svg';
import chatIcon from '../../../assets/icons/chatIcon.svg';
import chatCrossIcon from '../../../assets/icons/chatCrossIcon.svg';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        width: '293px',
        height: '263px',
        margin: '0 auto',
        boxShadow:
            '248px 269px 102px 0px rgba(171, 171, 171, 0.00), ' +
            '159px 172px 94px 0px rgba(171, 171, 171, 0.01), ' +
            '89px 97px 79px 0px rgba(171, 171, 171, 0.05), ' +
            '40px 43px 59px 0px rgba(171, 171, 171, 0.09), ' +
            '10px 11px 32px 0px rgba(171, 171, 171, 0.10)',
        borderRadius: '32px',
        position: 'fixed',
        bottom: '100px',
        right: '40px',
        backgroundColor: '#fff',
        zIndex: 1000,
        padding: '24px 32px',
        display: 'none',
    },
    visible: {
        display: 'block',
        animation: '$slideDown 0.5s ease forwards',
    },
    hidden: {
        animation: '$slideUp 0.5s ease forwards',
    },
    iconButton: {
        position: 'fixed',
        bottom: '20px',
        right: '40px',
        cursor: 'pointer',
        zIndex: 1001,
    },
    buttonContainer: {
        marginTop: '32px',
        textAlign: 'center',
    },
    text: {
        color: theme.palette.text.primary.primary950,
        lineHeight: '20px',

    },
    chatButton: {
        backgroundColor: theme.palette.background.primary.primary950,
        color: '#fff',
        border: 'none',
        borderRadius: '16px',
        padding: '12px 10px',
        fontWeight: '500',
        width: '100%',
        fontSize: '18px',
        cursor: 'pointer',
        textDecoration: 'none',
        display: 'inline-block',
    },
    '@keyframes slideDown': {
        from: { transform: 'translateY(20px)', opacity: 0 },
        to: { transform: 'translateY(0)', opacity: 1 },
    },
    '@keyframes slideUp': {
        from: { transform: 'translateY(0)', opacity: 1 },
        to: { transform: 'translateY(20px)', opacity: 0 },
    },
}));

const ChatBot: React.FC = () => {
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false);

    const toggleChatBox = () => {
        if (isOpen && !isAnimating) {
            setIsAnimating(true);
            setIsOpen(false);
            setTimeout(() => {
                setIsAnimating(false);
            }, 100);
        } else if (!isOpen && !isAnimating) {
            setIsAnimating(true);
            setIsOpen(true);
            setTimeout(() => {
                setIsAnimating(false);
            }, 500);
        }
    };

    return (
        <>
            <div
                className={`${classes.container} ${isOpen ? classes.visible : ''}`}
                style={{ display: isOpen || isAnimating ? 'block' : 'none' }}
            >
                <img src={chatIcon} alt="Chat Icon" />
                <p className={`${classes.text} text-base mt-6 mb-2 `}>Hi there!</p>
                <p className={`${classes.text} text-base mb-6`}>How Can I help you?</p>
                <div className={classes.buttonContainer}>
                    <a
                        href="https://api.whatsapp.com/send?phone=9654555531"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.chatButton}
                    >
                        Chat with us
                    </a>
                </div>
            </div>

            <div className={classes.iconButton} onClick={toggleChatBox}>
                {isOpen ? (
                    <img src={chatCrossIcon} alt="Close Chat" />
                ) : (
                    <img src={chatWhatsappIcon} alt="Open Chat" />
                )}
            </div>
        </>
    );
};

export default ChatBot;
