import React from 'react'
import { createUseStyles } from 'react-jss';
import onBoardingWelcomeSvg from "../../../assets/images/onBoardingWelcome.svg"
import { useNavigate } from 'react-router';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import { addToQueue } from '../../../utils/helper';
import { CUSTOMER_ROUTES } from '../../../utils/constant';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        display: "grid",
        padding: "70px 70px",
    },
    account: {
        width: "373px",
        borderRadius: "40px",
        background: theme.palette.background.success.success100,
        color: theme.palette.text.success.success600,
    },
    homepage: {
        width: "373px",
        borderRadius: "40px",
        color: theme.palette.text.success.success600,
        border: `1px solid ${theme.palette.border.success.success600}`
    },
    heading:{
        fontSize: "40px",
        lineHeight: "48px",
        color: theme.palette.text.neutral.neutral900,
        "& span":{
            color: theme.palette.text.success.success600,
        }
    },
    desc:{
        color: theme.palette.text.neutral.neutral800,
        lineHeight: "32px",
    }
}));

const OnBoardingWelcomePage = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { user, syncAuthDialogActive } = useAuthenticatedUser();
    const handleHomepage = () => {
        navigate('/');
    }

    const handleDashboard = () => {
        if (user) {
            addToQueue(JSON.stringify({ "key": "dashboard", "expandedMenu": { "Profile Overview": true, "My Profile": true } }));
            navigate(`${CUSTOMER_ROUTES.DASHBOARD}/my-profile`)
        }
    }

    return (
        <div className={`${classes.container} max-container mx-auto grid gap-y-[50px] justify-center items-center`}>
            <img className='mx-auto' src={onBoardingWelcomeSvg} alt={onBoardingWelcomeSvg} />
            <div className='grid gap-y-1 justify-center'>
                <div className={`${classes.heading} text-center`}>Onboarding Process <span>Complete!</span></div>
                <div className={`${classes.desc} grid justify-center text-center text-2xl`}>
                    <div>Thank you for completing the onboarding process. Our team will contact you soon.</div>
                   <div> We appreciate your commitment and look forward to working together!</div>
                   </div>
            </div>
            <div className='flex gap-x-[30px] justify-center'>
                <div className={`${classes.account} font-medium text-2xl text-center py-[26px] cursor-pointer`} onClick={handleDashboard}>Go to My Account</div>
                <div className={`${classes.homepage} font-medium text-2xl text-center py-[26px] cursor-pointer`} onClick={handleHomepage}>Go to Homepage</div>
            </div>
        </div>
    )
}

export default OnBoardingWelcomePage;