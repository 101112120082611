import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { AUTH_STATES, HTTP_STATUS } from "../../../utils/types";
import OtpInputs from "../../molecules/OtpInputGroup/OtpInputGroup";
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";
import { useSnackbar } from "../../../hooks/useSnackBar";
import CountDownTimer from "../../atoms/CountDownTimer/CountDownTimer";
import Button from "../../atoms/Button/Button";
import { createUseStyles } from "react-jss";
import { IncognitoUser } from "./Authentication.template";
import { useNavigate } from "react-router-dom";
import { CUSTOMER_ROUTES } from "../../../utils/constant";

interface OtpVerificationTemplateProps {
    setAuthState: (state: AUTH_STATES) => void;
    userDetails: IncognitoUser;
    otpVerifier: (verificationInfo: any) => Promise<any>
    section: AUTH_STATES
    setShowPopup?: Dispatch<SetStateAction<boolean>>;

}

const useStyles = createUseStyles((theme: any) => ({
    content: {
        color: theme.palette.text.neutral.neutral700,
        fontSize: "16px",
        lineHeight: "20px",
        "& span": {
            color: theme.palette.text.primary.primary500,
        }
    },
    headingColor: {
        color: theme.palette.text.neutral.neutral700,
        fontSize: "16px",
        lineHeight: "20px"
    },
    borderTop: {
        borderTop: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
}));

const OtpVerificationTemplate: React.FC<OtpVerificationTemplateProps> = ({ setAuthState, userDetails, otpVerifier, section, setShowPopup}) => {
    const classes = useStyles();
    const [otpErrorMessage, setOtpErrorMessage] = useState<string>("");
    const [otp, setOtp] = useState<string[]>(['', '', '', '']);
    const [isTimerActive, setIsTimerActive] = useState(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const { syncAuthDialogActive, setAuthenticatedUser } = useAuthenticatedUser();
    const navigate = useNavigate();
    const handleDialogClose = () => {
        syncAuthDialogActive(false);
        setAuthState(AUTH_STATES.LOGIN_WITH_MOBILE)
    };

    const isOtpValid = (otp: any, res?: any) => {
        if (otp.length !== 4) {
            setOtpErrorMessage("Enter valid OTP");
            return false;
        }

        if (res !== null && (res?.status !== HTTP_STATUS.OK || (section === AUTH_STATES.USER_SIGNUP && !res?.data?.data?.otpStatus))) {
            setOtpErrorMessage("Enter correct OTP");
            return false;
        }
        return true;
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        const otpCode = otp.join('');
        if (!isOtpValid(otpCode, null)) {
            return;
        }
        setIsLoading(true)
        otpVerifier({ fullName: userDetails.fullName, mobileNumber: userDetails.mobileNumber, otp: otpCode, gstin: userDetails.gstin })
            .then((res) => {
                if (!isOtpValid(otpCode, res)) {
                    return;
                }

                let { user, token, businessId , isVendor} = res?.data?.data ?? {};
                setAuthenticatedUser({ ...user, isVendor, token, businessId });

                if (section === AUTH_STATES.USER_SIGNUP)
                    setAuthState(AUTH_STATES.EMAIL_REGISTRATION)
                else {
                    handleDialogClose();
                    setShowPopup?.(false);
                    !businessId && navigate(CUSTOMER_ROUTES.ONBOARDING)
                }

            }).catch(error => {
                console.error("Otp Verification:", error)
            }).finally(() => setIsLoading(false))
    };

    const handleResendOtp = () => {
        setIsTimerActive(true);
        setOtp(['', '', '', '']);
        otpVerifier({ ...userDetails })
            .then(res => {
                if (res?.status === HTTP_STATUS.BAD_REQUEST) {
                    showSnackbar('error', 'OTP not sent');
                } else if (res?.status === HTTP_STATUS.OK) {
                    setIsTimerActive(true);
                    showSnackbar('success', 'OTP sent successfully');
                }
            }).catch(error => {
                console.error("Otp Verification - Resend Otp:", error);
            }).finally(() => setIsLoading(false))
    }

    const onTimerComplete = () => {
        setIsTimerActive(false);
    }

    const setAuthStateTo = (authState: AUTH_STATES) => () => {
        setAuthState(authState);
    }

    return (
        <div className="grid w-full" >
            {SnackBarComponent}
            <div className="grid gap-y-6 w-full">
                <form className="grid gap-y-6 items-center w-full text-lg" onSubmit={handleSubmit}>
                    <div className="grid gap-y-1">
                        <div className={`${classes.content} `}>Enter OTP sent to +91 {userDetails?.mobileNumber}</div>
                        <OtpInputs otp={otp} setOtp={setOtp} otpErrorMessage={otpErrorMessage} setOtpErrorMessage={setOtpErrorMessage} />
                    </div>

                    {isTimerActive ? (
                        <div className={`flex justify-center  font-normal pointer-events-none select-none ${classes.content}`}>Resend OTP in {isTimerActive && <span> &nbsp; <CountDownTimer timeInSeconds={60} onComplete={onTimerComplete} /></span>}</div>
                    ) : (
                        <Button label="Resend OTP" variant="secondaryText" onClick={handleResendOtp} size="small" className="" />
                    )}

                    <Button fullWidth variant="primaryContained" label={`${section === AUTH_STATES.USER_SIGNUP ? "Verify" : "Log In"}`} type="submit" isLoading={isLoading} />
                </form>
                <div className={`flex justify-center pt-6 ${classes.borderTop}`}>
                    <div className={`${classes.content} text-sm my-auto`}>Already Have an account?</div>
                    <Button label={"Log In"} variant="secondaryText" size="small" onClick={setAuthStateTo(AUTH_STATES.LOGIN_WITH_EMAIL)} />
                </div>
            </div>
        </div>
    );
}

export default OtpVerificationTemplate;