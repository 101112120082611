import React from 'react';
import { createUseStyles } from 'react-jss';
import manufacturing from '../../../../assets/images/manufacturing.svg';
import market from '../../../../assets/images/market.svg';
import procurment from '../../../../assets/images/procurment.svg';
import finance from '../../../../assets/images/finance.svg';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        padding: "100px 0px"
    },
    heading: {
        color: theme.palette.text.neutral.neutral800,
        lineHeight: "58px",
        "& span": {
            color: theme.palette.text.primary.primary500,
        },
    },
    card: {
        borderRadius: '20px',
        '&:nth-child(1)': {
            background: theme.palette.background.warning.warning200,
            color: theme.palette.text.warning.warning900,
        },
        '&:nth-child(2)': {
            background: theme.palette.background.primary.primary100,
            color: theme.palette.text.primary.primary900,
        },
        '&:nth-child(3)': {
            background: theme.palette.background.secondary.secondary100,
            text: theme.palette.text.secondary.secondary900,
        },
        '&:nth-child(4)': {
            background: theme.palette.background.success.success100,
            text: theme.palette.text.success.success900,
        },
    },
    cardTitle: {
        fontSize: '28px',
        fontWeight: '400',
        lineHeight: '34px',
    },
    cardDescription: {
        fontSize: '16px',
        lineHeight: '20px',
    },
}));

const cardDetails = [
    {
        imgSrc: market,
        title: "Global Sourcing Local Prices",
        description: "Multiple Network, Unlimited Trade."
    },
    {
        imgSrc: procurment,
        title: "Procurement Solutions",
        description: "Lowest Costs, Assured Qualities"
    },
    {
        imgSrc: manufacturing,
        title: "Manufacturing Services",
        description: "Competitive Buying and Selling"
    },
    {
        imgSrc: finance,
        title: "Leads Management Solutions",
        description: "Access Verified Lead to Drive Sales and Business Growth"
    }
];

const AipoweredTemplate: React.FC = () => {
    const classes = useStyles();

    return (
        <div className={`${classes.container} max-container grid gap-y-[72px]`}>
            <div className={`${classes.heading} text-5xl font-bold text-center`}> <span>AI Powered </span>B2B Digital Platform</div>
            <div className='grid grid-cols-4 gap-4'>
                {cardDetails.map((card, index) => (
                    <div className={`${classes.card} grid pt-6 pr-6 pl-6 pb-0 h-[410px]`} key={index}>
                        <div className=''>
                            <div className={classes.cardTitle}>{card.title}</div>
                            <div className={classes.cardDescription}>{card.description}</div>
                        </div>
                        <img className='self-end' src={card.imgSrc} alt="" />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AipoweredTemplate;