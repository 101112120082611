import React from 'react';
import { createUseStyles } from 'react-jss';

interface Tabs {
    label: string;
    description: string;
    isActive: Boolean;
    onClick: any,  
}

const useStyles = createUseStyles((theme: any) => ({
  text: {
    color: theme.palette.text.primary.primary800
  },
  description: {
    fontSize: "14px",
    color: theme.palette.text.neutral.neutral600
  },
  progressBar: {
    background: theme.palette.background.primary.primary600
  }
}))

 const ToggleTabs: React.FC<Tabs> = ({ label,description,isActive,onClick,}) => {
    const classes = useStyles();

    return (
    <div className="text-center cursor-pointer w-full" onClick={onClick}>
      <div>
        <div className={`${classes.text} text-2xl font-semibold`}>{label}</div>
        <p className={`${classes.description}`}>{description}</p>
      </div>
      <div className="w-full mt-2">
        <div
          className={`h-1 rounded-lg ${
            isActive ? `${classes.progressBar}` : 'bg-gray-300'
          }`}
        ></div>
      </div>
    </div>
  )};
  export default ToggleTabs;