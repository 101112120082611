import React, { useEffect, useState } from 'react'
import { IValueAddedServices } from '../../../pages/Cart/CartDetails.page';
import { createUseStyles } from 'react-jss';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import { TableBody, TableContainer, TableCell, TableHead, TableRow, Table } from '@mui/material';
import editIcon from "../../../../assets/images/editIcon.svg"
import deleteIcon from "../../../../assets/images/deleteOutlinedIcon.svg"
import { toInr } from '../../../../utils/helper';
import Button from '../../../atoms/Button/Button';
import { ICartItem } from '../CartItemDetail.template';
import { CUSTOMIZTION_TYPE, SHEARING_HEADING, SLITTING_HEADING, VAS_SECTIONS } from '../../../../utils/constant';
import ShearingCustomizationPopup, { IShearing } from '../../Customization/ShearingCustomizationPopup';
import SuccessFailurePopup from '../../../molecules/SuccessFailurePopup/SuccessFailurePopup';
import SlittingCustomizationPopup from '../../Customization/SlittingCustomizationPopup';
import { ICatalogueUpc } from '../../../pages/CatalogueDetail/CatalogueDetails.page';
import { ICustomization } from '../../Customization/SelectCustomization.template';

const useStyles = createUseStyles((theme: any) => ({
    dialogContainer: {
        "& .MuiDialog-paper": {
            width: "983px !important",
            maxWidth: "983px !important",
            borderRadius: "12px"
        },
    },
    container: {
        padding: "24px 0px",
        rowGap: "32px"
    },
    headingContainer: {
        padding: "0px 24px",
    },
    tableHead: {
        padding: "12px 16px",
        background: theme.palette.background.neutral.neutral50,
    },
    tableHeadCell: {
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "23.3px",
        padding: "12px 0px",
        color: theme.palette.text.neutral.neutral900,
        textAlign: "center",
        fontFamily: "IBM Plex Sans"
    },
    tableCell: {
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "23.3px",
        padding: "12px 16px",
        color: theme.palette.text.neutral.neutral700,
        textAlign: "center",
        fontFamily: "IBM Plex Sans"
    },
    mainHeading: {
        color: theme.palette.text.neutral.neutral900,
        fontSize: "24px",
        fontWeight: 600
    },
    closeIcon: {
        color: theme.palette.text.secondary.secondary500
    },
    selectedWeight: {
        color: theme.palette.text.neutral.neutral700
    },
    details: {
        color: theme.palette.text.neutral.neutral600,
    },
    detailBalancedWeight: {
        color: "#CA4F02"
    },
    deleteAllButton: {
        color: theme.palette.text.primary.primary100,
        '&:hover': {
            color: '#FD6202'
        }
    },
    addMoreButton: {
        color: theme.palette.text.primary.primary100,
        cursor: 'pointer',
        textDecoration: 'underline',
        '&:hover': {
            color: '#FD6202'
        }
    },
    tableContaier: {
    },
    webContainer: {
        display: "grid"
    },
    mobileContainer: {
        display: "none"
    },
    borderTop: {
        borderTop: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    borderBottom: {
        borderBottom: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    "@media (max-width: 480px)": {
        dialogContainer: {
            "& .MuiDialog-paper": {
                width: "448px !important",
                maxWidth: "448px !important",
                borderRadius: "12px"
            },
        },
        mainHeading: {
            fontSize: "18px",
            fontWeight: 500
        },
        container: {
            padding: "16px 0px",
            rowGap: "16px"
        },
        headingContainer: {
            padding: "0px 16px",
        },
        tableContaier: {
            width: "840px"
        },
        webContainer: {
            display: "none"
        },
        mobileContainer: {
            display: "grid"
        },
    },

}));

interface ValueAddedServicesDetailsProps {
    valueAddedServices: IValueAddedServices | null;
    setDialogOpen: (isDialog: boolean) => void;
    dialogOpen: boolean;
    shearingTotalWeight: number;
    slittingTotalWeight: number;
    shearingTotalCharges: number;
    slittingTotalCharges: number;
    cartData: ICartItem;
    setValueAddedServicesTo: (value: (IValueAddedServices | null)) => void;
    setCurrentSectionTo: (section: string) => void;
    selectedUpc: ICatalogueUpc | null;
    customization: ICustomization[] | null;
    setAnotherServiceDialogOpenTo: (isOpen: boolean) => void;
    availableCustomizations: ICustomization[];
    balanceWeight: number;
    setBalanceWeight: (weight: number) => void;
    setCartItemsTo?: (cartItems: ICartItem[] | []) => void;
    cartItems?: ICartItem[] | [];
}

const ValueAddedServicesDetailsTemplate: React.FC<ValueAddedServicesDetailsProps> = ({ valueAddedServices, setDialogOpen, dialogOpen, shearingTotalWeight, slittingTotalWeight, shearingTotalCharges, slittingTotalCharges, cartData, setValueAddedServicesTo, setCurrentSectionTo, selectedUpc, customization, setAnotherServiceDialogOpenTo, availableCustomizations, balanceWeight, setBalanceWeight, setCartItemsTo, cartItems}) => {
    const classes = useStyles();
    const quantity = cartData?.quantity;
    const isAllServices = !!(valueAddedServices?.shearing?.length && valueAddedServices?.slitting?.length);
    const [dialogStates, setDialogStatesTo] = useState({ shearingDialog: false, slittingDialog: false });
    const [itemToDelete, setItemToDelete] = useState<any>({ vasType: null, id: null, weight: null });
    const [editState, setEditState] = useState<boolean>(true);
    const [deleteState, setDeleteStateTo] = useState({ delete: false, deleteAll: false, slitting: false, shearing: false });

    const handleDialogClose: (event: any, reason: "backdropClick" | "escapeKeyDown" | "closeButtonClick") => void = (event, reason) => {
        if (reason !== "backdropClick") {
            setDialogOpen(false);
        }
    }
    const addMoreVasDetails = (vasName: string) => {
        if (vasName === CUSTOMIZTION_TYPE.SHEARING) {
            setDialogStatesTo(prevState => ({ ...prevState, shearingDialog: true }));
        }
        else if (vasName === CUSTOMIZTION_TYPE.SLITTING) {
            setDialogStatesTo(prevState => ({ ...prevState, slittingDialog: true }));
        }
    };

    const handleOnBackClick = () => {
        setDialogOpen(false);
    }

    const handleEditClick = (vasType: any) => {
        if (vasType == CUSTOMIZTION_TYPE.SHEARING) {
            setDialogStatesTo(prevState => ({ ...prevState, shearingDialog: true }));
        }
        else if (vasType == CUSTOMIZTION_TYPE.SLITTING) {
            setDialogStatesTo(prevState => ({ ...prevState, slittingDialog: true }));
        }
    }
    const handleDeleteAll = () => {
        setDeleteStateTo(prevState => ({ ...prevState, deleteAll: true }));
    };

    const handleDelete = (id: number, weight: number, vasType: string) => {
        setItemToDelete({ vasType: vasType, id: id, weight: weight });
        setDeleteStateTo(prevState => ({ ...prevState, delete: true }));
    }

    const handleDeleteConfirmed = () => {
        if (itemToDelete?.vasType === CUSTOMIZTION_TYPE.SHEARING) {
            setValueAddedServicesTo({ ...valueAddedServices, shearing: valueAddedServices?.shearing?.filter((item: any) => item.id !== itemToDelete?.id) });
            const updatedCartItems = clearShearingItemsInCart(cartItems, cartData.id);
            if (setCartItemsTo) {
                setCartItemsTo(updatedCartItems);
            }    
        }
        else if (itemToDelete?.vasType === CUSTOMIZTION_TYPE.SLITTING) {
            setValueAddedServicesTo({ ...valueAddedServices, slitting: valueAddedServices?.slitting?.filter((item: any) => item.id !== itemToDelete?.id) });
            const updatedCartItems = clearSlittingItemsInCart(cartItems, cartData.id);
            if (setCartItemsTo) {
                setCartItemsTo(updatedCartItems);
            } 
        }
    setBalanceWeight(parseFloat((balanceWeight + itemToDelete.weight).toFixed(3)))
    setDeleteStateTo(prevState => ({ ...prevState, delete: false }));
    };

    const clearShearingItemsInCart = (
        cartItems: ICartItem[] | undefined,
        itemId: number 
    ): ICartItem[] => {
        if (!cartItems) return [];
    
        const updatedCartItems = cartItems.map(item => {
            if (item.id === itemId && item.valueAddedServices?.shearing) {
                const updatedShearing = item.valueAddedServices?.shearing?.filter((item: any) => item.id !== itemToDelete?.id)
                return {
                    ...item,
                    valueAddedServices: {
                        ...item.valueAddedServices,
                        shearing: updatedShearing
                    }
                };
            }
            return item; 
        });
    
        return updatedCartItems;
    };

    const clearSlittingItemsInCart = (
        cartItems: ICartItem[] | undefined,
        itemId: number 
    ): ICartItem[] => {
        if (!cartItems) return [];
    
        const updatedCartItems = cartItems.map(item => {
            if (item.id === itemId && item.valueAddedServices?.slitting) {
                const updatedSlitting = item.valueAddedServices?.slitting?.filter((item: any) => item.id !== itemToDelete?.id)
                return {
                    ...item,
                    valueAddedServices: {
                        ...item.valueAddedServices,
                        slitting: updatedSlitting
                    }
                };
            }
            return item; 
        });
    
        return updatedCartItems;
    };

    const handleCancelDelete = () => {
        setDeleteStateTo(prevState => ({ ...prevState, delete: false }));
    };

    const handleDeleteAllConfirmed = () => {
        const updatedCartItems = clearAllVasItemsInCart(cartItems, cartData.id);
        if (setCartItemsTo) {
            setCartItemsTo(updatedCartItems);
        }  
        setValueAddedServicesTo({ ...valueAddedServices, shearing: [], slitting: [] });
        setBalanceWeight(parseFloat((balanceWeight + (shearingTotalWeight + slittingTotalWeight)).toFixed(3)));
        setDeleteStateTo(prevState => ({ ...prevState, deleteAll: false }));
        setDialogOpen(false);
    };

    const clearAllVasItemsInCart = (
        cartItems: ICartItem[] | undefined,
        itemId: number 
    ): ICartItem[] => {
        if (!cartItems) return [];
    
        const updatedCartItems = cartItems.map(item => {
            if (item.id === itemId && item.valueAddedServices) {
                return {
                    ...item,
                    valueAddedServices: {
                        ...item.valueAddedServices,
                        shearing: [],
                        slitting: []
                    }
                };
            }
            return item; 
        });
    
        return updatedCartItems;
    };    

    const handleCancelDeleteAll = () => {
        setDeleteStateTo(prevState => ({ ...prevState, deleteAll: false }));
    };

    const deleteShearingDetails = () => {
        setDeleteStateTo(prevState => ({ ...prevState, shearing: true }));
    }

    const shearingDeleteConfirmed = () => {
        setValueAddedServicesTo({ ...valueAddedServices, shearing: [] });
        setBalanceWeight(parseFloat((balanceWeight + (shearingTotalWeight)).toFixed(3)));
        setDeleteStateTo(prevState => ({ ...prevState, shearing: false }));
    }

    const handleCancelShearingDelete = () => {
        setDeleteStateTo(prevState => ({ ...prevState, shearing: false }));
    };

    const deleteSlittingDetails = () => {
        setDeleteStateTo(prevState => ({ ...prevState, slitting: true }));
    }

    const slittingDeleteConfirmed = () => {
        setBalanceWeight(parseFloat((balanceWeight + (slittingTotalWeight)).toFixed(3)));
        setValueAddedServicesTo({ ...valueAddedServices, slitting: [] });
        setDeleteStateTo(prevState => ({ ...prevState, slitting: false }));
    }

    const handleCancelSlittingDelete = () => {
        setDeleteStateTo(prevState => ({ ...prevState, slitting: false }));
    };

    const handleAddAnotherService = () => {
        setAnotherServiceDialogOpenTo(true);
        setCurrentSectionTo(VAS_SECTIONS.ADD_ANOTHER_SERVICE);
    }

    const [shearingCustomization, setShearingCustomization] = useState<ICustomization>(
        {
            uom: "MT",
            name: "Shearing (CTL)",
            price: 0,
            value: "SHEARING",
            discription: "Cutting large sheets of steel into smaller, manageable sizes.",
            minLength: 0,
            maxLength: Infinity,
            minThickness: 0,
            maxThickness: Infinity,
            minWidth: 0,
            maxWidth: Infinity,
        }
    );
    const [slittingCustomization, setSlittingCustomization] = useState<ICustomization>(

        {
            uom: "MT",
            name: "Slitting (CTW)",
            price: 0,
            value: "SLITTING",
            discription: "Longitudinally cutting into narrower strips with uniform width.",
            minLength: 0,
            maxLength: Infinity,
            minThickness: 0,
            maxThickness: Infinity,
            minWidth: 0,
            maxWidth: Infinity,
        }

    );

    useEffect(() => {
        if (customization != null) {
            const newShearingCustomization = customization?.find((attr: any) => attr.value === CUSTOMIZTION_TYPE.SHEARING);
            if (newShearingCustomization) {
                setShearingCustomization(prevState => ({
                    ...prevState,
                    ...newShearingCustomization
                }));
            }
            const newSlittingCustomization = customization?.find((attr: any) => attr.value === CUSTOMIZTION_TYPE.SLITTING);
            if (newShearingCustomization) {
                setSlittingCustomization(prevState => ({
                    ...prevState,
                    ...newSlittingCustomization
                }));
            }
        }
    }, [customization]);

    return (
        <Dialog fullWidth className={classes.dialogContainer} PaperProps={{ style: { height: 'auto' } }} open={dialogOpen} onClose={handleDialogClose}>
            <div className={`${classes.container} grid`}>

                <div className={`${classes.headingContainer} flex justify-between`}>
                    <div className={`${classes.mainHeading} `}>Value Added Services</div>
                    <CloseIcon className={`${classes.closeIcon} cursor-pointer`} onClick={(event) => handleDialogClose(event, "closeButtonClick")} />
                </div>
                <div className={`${classes.webContainer} gap-y-6 px-6`}>
                    {(valueAddedServices?.shearing?.length ?? 0) > 0 && (<TableContainer className="w-full">
                        <div className='grid gap-y-2'>
                            <div className='flex justify-between'>
                                <div className={`${classes.mainHeading} text-lg font-semibold my-auto`}>Shearing (CTL)</div>
                                <div className='flex gap-4'>
                                    <div className={`${classes.addMoreButton} px-1 py-2.5`} onClick={() => addMoreVasDetails(CUSTOMIZTION_TYPE.SHEARING)}>
                                        +Add Another CTL
                                    </div>
                                    <div className={`${classes.deleteAllButton} underline cursor-pointer px-1 py-2.5`} onClick={() => deleteShearingDetails()}>
                                        Remove All CTL
                                    </div>
                                </div>
                            </div>
                            {isAllServices && (
                                <div className='flex'>
                                    <div className={`${classes.selectedWeight} text-sm font-medium`}>
                                        Cumulative Weight:
                                    </div>
                                    <div className={`${classes.mainHeading} text-sm font-medium ml-2`}>
                                        {shearingTotalWeight} MT
                                    </div>
                                </div>
                            )}
                            <div className={`overflow-x-auto overflow-hidden w-full`}>
                                <div className={`${classes.tableContaier} border rounded-lg`}>
                                    <Table className="w-full" size="small">
                                        <TableHead className={classes.tableHead}>
                                            <TableRow>
                                                {SHEARING_HEADING.map((item, index) => (
                                                    <TableCell key={index} className={`${classes.tableHeadCell} first:pl-4 first:text-left last:pr-4 last:text-right`}>{item}</TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {valueAddedServices?.shearing?.map((data: any, index: number) => (
                                                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell className={classes.tableCell}>{index + 1}</TableCell>
                                                    <TableCell className={classes.tableCell}>{data.length}</TableCell>
                                                    <TableCell className={classes.tableCell}>{data.pieces}</TableCell>
                                                    <TableCell className={classes.tableCell}>{data.weightPerPiece}</TableCell>
                                                    <TableCell className={classes.tableCell}>{data.weight}</TableCell>
                                                    <TableCell className={classes.tableCell}>{data.chargesPerMT}</TableCell>
                                                    <TableCell className={classes.tableCell}>{data.totalCharges}</TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        <div className="flex justify-end">
                                                            <div className='m-1 cursor-pointer'>
                                                                <img src={editIcon} alt="editIcon" onClick={() => handleEditClick(CUSTOMIZTION_TYPE.SHEARING)} />
                                                            </div>
                                                            <div className='m-1 cursor-pointer'>
                                                                <img src={deleteIcon} alt="deleteIcon" onClick={() => handleDelete(data?.id, data?.weight, CUSTOMIZTION_TYPE.SHEARING)} />
                                                            </div>
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </TableContainer>)
                    }
                    {(valueAddedServices?.slitting?.length ?? 0) > 0 && (<TableContainer className="w-full">
                        <div className='grid gap-y-2'>
                            <div className='flex justify-between'>
                                <div className={`${classes.mainHeading} text-lg font-semibold my-auto`}>Slitting (CTW)
                                </div>
                                <div className='flex gap-4'>
                                    <div className={`${classes.addMoreButton} underline cursor cursor-pointer px-1 py-2.5`} onClick={() => addMoreVasDetails(CUSTOMIZTION_TYPE.SLITTING)}>
                                        +Add Another CTW
                                    </div>
                                    <div className={`${classes.deleteAllButton} underline cursor cursor-pointer px-1 py-2.5`} onClick={() => deleteSlittingDetails()}>
                                        Remove All CTW
                                    </div>
                                </div>
                            </div>
                            {isAllServices && (
                                <div className='flex'>
                                    <div className={`${classes.selectedWeight} text-sm font-medium`}>
                                        Cumulative Weight:
                                    </div>
                                    <div className={`${classes.mainHeading} text-sm font-medium ml-2`}>
                                        {slittingTotalWeight} MT
                                    </div>
                                </div>
                            )
                            }
                            <div className={`overflow-x-auto overflow-hidden w-full`}>
                                <div className={`${classes.tableContaier} border rounded-lg`}>
                                    <Table className="w-full" size="small">
                                        <TableHead className={classes.tableHead}>
                                            <TableRow>
                                                {SLITTING_HEADING.map((item, index) => (
                                                    <TableCell key={index} className={`${classes.tableHeadCell} first:pl-4 first:text-left last:pr-4 last:text-right`}>{item}</TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {valueAddedServices?.slitting?.map((data: any, index: number) => (
                                                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell className={`${classes.tableHeadCell} first:pl-8 first:text-left`}>{index + 1}</TableCell>
                                                    <TableCell className={classes.tableCell}>{data.width}</TableCell>
                                                    <TableCell className={classes.tableCell}>{data.weightOfCoil}</TableCell>
                                                    <TableCell className={classes.tableCell}>{data.chargesPerMT}</TableCell>
                                                    <TableCell className={`${classes.tableHeadCell} last:pr-8 last:text-right`}>{data.totalCharges}</TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        <div className="flex justify-end">
                                                            <div className='m-1 cursor-pointer'>
                                                                <img src={editIcon} alt="editIcon" onClick={() => handleEditClick(CUSTOMIZTION_TYPE.SLITTING)} />
                                                            </div>
                                                            <div className='m-1 cursor-pointer'>
                                                                <img src={deleteIcon} alt="deleteIcon" onClick={() => handleDelete(data?.id, data?.weightOfCoil, CUSTOMIZTION_TYPE.SLITTING)} />
                                                            </div>
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </TableContainer>
                    )}
                    <div className="flex justify-between">
                        <div className="grid gap-y-2 w-1/3">
                            <div className={`${classes.details} text-sm font-medium`}>Weight Details</div>
                            <div className={`${classes.selectedWeight} flex justify-between text-lg font-medium`}>
                                <div >Balance Weight</div>
                                <div className={`${classes.detailBalancedWeight}`}>{balanceWeight?.toFixed(3)} MT</div>
                            </div>
                            <div className={`${classes.selectedWeight} flex justify-between text-sm font-medium`}>
                                <div>Current Cumulative Wt.</div>
                                <div>{(shearingTotalWeight + slittingTotalWeight).toFixed(3)} MT</div>
                            </div>
                        </div>

                        <div className="grid gap-y-2 h-fit w-1/3">
                            <div className={`${classes.details} text-sm font-medium`}>Price Details</div>
                            <div className={`${classes.selectedWeight} flex justify-between text-lg font-medium`}>
                                <div>Grand Total</div>
                                <div>{toInr(slittingTotalCharges + shearingTotalCharges)}</div>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-between">
                        <div>
                            <Button className="" label="Delete All Service" variant="primaryText" size="medium" onClick={handleDeleteAll} />
                        </div>
                        <div className="flex gap-x-4 items-center">
                            <Button label="Go Back" variant="tertiaryContained" size="medium" onClick={handleOnBackClick} />
                            {availableCustomizations.length > 0 &&
                                (<Button label="Add Another Service" variant="primaryContained" size="large" onClick={handleAddAnotherService} />
                                )}
                        </div>
                    </div>

                </div>

                <div className={`${classes.mobileContainer} ${classes.borderTop} pt-6 `}>
                    {(valueAddedServices?.shearing?.length ?? 0) > 0 && (<TableContainer className="w-full px-4">
                        <div className='grid gap-y-2'>
                            <div className='flex justify-between'>
                                <div className={`${classes.mainHeading} text-lg font-semibold my-auto`}>Shearing (CTL)</div>
                                <div className='flex gap-4'>
                                    <div className={`${classes.addMoreButton} px-1 py-2.5`} onClick={() => addMoreVasDetails(CUSTOMIZTION_TYPE.SHEARING)}>
                                        +Add Another CTL
                                    </div>

                                </div>
                            </div>
                            {isAllServices && (
                                <div className='flex'>
                                    <div className={`${classes.selectedWeight} text-sm font-medium`}>
                                        Cumulative Weight:
                                    </div>
                                    <div className={`${classes.mainHeading} text-sm font-medium ml-2`}>
                                        {shearingTotalWeight} MT
                                    </div>
                                </div>
                            )}
                            <div className={`overflow-x-auto overflow-hidden w-full`}>
                                <div className={`${classes.tableContaier} border rounded-lg`}>
                                    <Table className="w-full" size="small">
                                        <TableHead className={classes.tableHead}>
                                            <TableRow>
                                                {SHEARING_HEADING.map((item, index) => (
                                                    <TableCell key={index} className={`${classes.tableHeadCell} first:pl-4 first:text-left last:pr-4 last:text-right`}>{item}</TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {valueAddedServices?.shearing?.map((data: any, index: number) => (
                                                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell className={classes.tableCell}>{index + 1}</TableCell>
                                                    <TableCell className={classes.tableCell}>{data.length}</TableCell>
                                                    <TableCell className={classes.tableCell}>{data.pieces}</TableCell>
                                                    <TableCell className={classes.tableCell}>{data.weightPerPiece}</TableCell>
                                                    <TableCell className={classes.tableCell}>{data.weight}</TableCell>
                                                    <TableCell className={classes.tableCell}>{data.chargesPerMT}</TableCell>
                                                    <TableCell className={classes.tableCell}>{data.totalCharges}</TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        <div className="flex justify-end">
                                                            <div className='m-1 cursor-pointer'>
                                                                <img src={editIcon} alt="editIcon" onClick={() => handleEditClick(CUSTOMIZTION_TYPE.SHEARING)} />
                                                            </div>
                                                            <div className='m-1 cursor-pointer'>
                                                                <img src={deleteIcon} alt="deleteIcon" onClick={() => handleDelete(data?.id, data?.weight, CUSTOMIZTION_TYPE.SHEARING)} />
                                                            </div>
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </div>
                            </div>

                            <div className='flex justify-end'>
                                <div className={`${classes.deleteAllButton} underline cursor-pointer px-1 py-2.5`} onClick={() => deleteShearingDetails()}>
                                    Remove All CTL
                                </div>
                            </div>
                        </div>
                    </TableContainer>)
                    }
                    {isAllServices && (
                        <div className={`${classes.borderTop} my-4`}></div>
                    )}
                    {(valueAddedServices?.slitting?.length ?? 0) > 0 && (<TableContainer className="w-full px-4 mb-6">
                        <div className='grid gap-y-2'>
                            <div className='flex justify-between'>
                                <div className={`${classes.mainHeading} text-lg font-semibold my-auto`}>Slitting (CTW)
                                </div>
                                <div className='flex gap-4'>
                                    <div className={`${classes.addMoreButton} underline cursor cursor-pointer px-1 py-2.5`} onClick={() => addMoreVasDetails(CUSTOMIZTION_TYPE.SLITTING)}>
                                        +Add Another CTW
                                    </div>

                                </div>
                            </div>
                            {isAllServices && (
                                <div className='flex'>
                                    <div className={`${classes.selectedWeight} text-sm font-medium`}>
                                        Cumulative Weight:
                                    </div>
                                    <div className={`${classes.mainHeading} text-sm font-medium ml-2`}>
                                        {slittingTotalWeight} MT
                                    </div>
                                </div>
                            )
                            }
                            <div className={`overflow-x-auto overflow-hidden w-full`}>
                                <div className={`${classes.tableContaier} border rounded-lg`}>
                                    <Table className="w-full" size="small">
                                        <TableHead className={classes.tableHead}>
                                            <TableRow>
                                                {SLITTING_HEADING.map((item, index) => (
                                                    <TableCell key={index} className={`${classes.tableHeadCell} first:pl-4 first:text-left last:pr-4 last:text-right`}>{item}</TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {valueAddedServices?.slitting?.map((data: any, index: number) => (
                                                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell className={`${classes.tableHeadCell} first:pl-8 first:text-left`}>{index + 1}</TableCell>
                                                    <TableCell className={classes.tableCell}>{data.width}</TableCell>
                                                    <TableCell className={classes.tableCell}>{data.weightOfCoil}</TableCell>
                                                    <TableCell className={classes.tableCell}>{data.chargesPerMT}</TableCell>
                                                    <TableCell className={`${classes.tableHeadCell} last:pr-8 last:text-right`}>{data.totalCharges}</TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        <div className="flex justify-end">
                                                            <div className='m-1 cursor-pointer'>
                                                                <img src={editIcon} alt="editIcon" onClick={() => handleEditClick(CUSTOMIZTION_TYPE.SLITTING)} />
                                                            </div>
                                                            <div className='m-1 cursor-pointer'>
                                                                <img src={deleteIcon} alt="deleteIcon" onClick={() => handleDelete(data?.id, data?.weightOfCoil, CUSTOMIZTION_TYPE.SLITTING)} />
                                                            </div>
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </div>
                            </div>

                            <div className='flex justify-end'>
                                <div className={`${classes.deleteAllButton} underline cursor cursor-pointer px-1 py-2.5`} onClick={() => deleteSlittingDetails()}>
                                    Remove All CTW
                                </div>
                            </div>
                        </div>
                    </TableContainer>
                    )}


                    <div className={`${classes.borderTop} ${classes.borderBottom} grid gap-y-6 py-6 mb-6`}>
                        <div className="grid gap-y-1 w-full px-4">
                            <div className={`${classes.details} text-sm font-medium`}>Weight Details</div>
                            <div className={`${classes.selectedWeight} flex justify-between text-lg font-medium`}>
                                <div >Balance Weight</div>
                                <div className={`${classes.detailBalancedWeight}`}>{balanceWeight?.toFixed(3)} MT</div>
                            </div>
                            <div className={`${classes.selectedWeight} flex justify-between text-sm font-medium`}>
                                <div>Current Cumulative Wt.</div>
                                <div>{(shearingTotalWeight + slittingTotalWeight).toFixed(3)} MT</div>
                            </div>
                        </div>

                        <div className="grid gap-y-1 h-fit w-full px-4">
                            <div className={`${classes.details} text-sm font-medium`}>Price Details</div>
                            <div className={`${classes.selectedWeight} flex justify-between text-lg font-medium`}>
                                <div>Grand Total</div>
                                <div>{toInr(slittingTotalCharges + shearingTotalCharges)}</div>
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-between px-4">
                        {isAllServices && (
                            <div>
                                <Button className="" label="Delete All Service" variant="primaryText" size="small" onClick={handleDeleteAll} />
                            </div>
                        )}
                        <Button label="Go Back" variant="tertiaryContained" size="small" onClick={handleOnBackClick} />
                        {availableCustomizations.length > 0 &&
                            (<Button label="Add Another Service" variant="primaryContained" size="small" onClick={handleAddAnotherService} />
                            )}

                    </div>
                </div>


                {dialogStates.shearingDialog && <ShearingCustomizationPopup
                    dialogOpen={dialogStates.shearingDialog}
                    setDialogOpen={(value) => setDialogStatesTo(prevState => ({ ...prevState, shearingDialog: false }))}
                    selectedUpc={selectedUpc}
                    selectedWeight={quantity}
                    customization={shearingCustomization}
                    valueAddedService={valueAddedServices}
                    setValueAddedServiceTo={setValueAddedServicesTo}
                    balanceWeight={balanceWeight}
                    setBalanceWeight={setBalanceWeight}
                    setCartItemsTo = {setCartItemsTo}
                    cartData = {cartData}
                    cartItems = {cartItems}

                />
                }
                {dialogStates.slittingDialog && <SlittingCustomizationPopup
                    dialogOpen={dialogStates.slittingDialog}
                    setDialogOpen={(value) => setDialogStatesTo(prevState => ({ ...prevState, slittingDialog: false }))}
                    selectedUpc={selectedUpc}
                    selectedWeight={quantity}
                    customization={slittingCustomization}
                    valueAddedService={valueAddedServices}
                    setValueAddedServiceTo={setValueAddedServicesTo}
                    balanceWeight={balanceWeight}
                    setBalanceWeight={setBalanceWeight}
                    editState={editState}
                    setEditState={setEditState}


                />
                }
                {deleteState.delete &&
                    <SuccessFailurePopup
                        setShowPopup={(value) => setDeleteStateTo(prevState => ({ ...prevState, delete: false }))}
                        variant="Delete"
                        description="Are you sure you want to delete this?"
                        heading="Delete Service?"
                        button1={{
                            text: "Yes",
                            variant: "tertiaryContained",
                            size: "large",
                            onClick: handleDeleteConfirmed,
                        }}
                        button2={{
                            text: "No",
                            variant: "secondaryContained",
                            size: "large",
                            onClick: handleCancelDelete,
                        }}
                    />
                }

                {deleteState?.deleteAll &&
                    <SuccessFailurePopup
                        setShowPopup={(value) => setDeleteStateTo(prevState => ({ ...prevState, deleteAll: false }))}
                        variant="Delete"
                        heading="Delete All Service?"
                        description="Are you sure you want to delete this?"
                        button1={{
                            text: "Yes",
                            variant: "tertiaryContained",
                            size: "large",
                            onClick: handleDeleteAllConfirmed,
                        }}
                        button2={{
                            text: "No",
                            variant: "secondaryContained",
                            size: "large",
                            onClick: handleCancelDeleteAll,
                        }}
                    />
                }
                {deleteState.shearing &&
                    <SuccessFailurePopup
                        setShowPopup={(value) => setDeleteStateTo(prevState => ({ ...prevState, shearing: false }))}
                        variant="Delete"
                        heading="Delete Service?"
                        description="Are you sure you want to delete this?"
                        button1={{
                            text: "Yes",
                            variant: "tertiaryContained",
                            size: "large",
                            onClick: shearingDeleteConfirmed,
                        }}
                        button2={{
                            text: "No",
                            variant: "secondaryContained",
                            size: "large",
                            onClick: handleCancelShearingDelete,
                        }}
                    />
                }
                {deleteState.slitting &&
                    <SuccessFailurePopup
                        setShowPopup={(value) => setDeleteStateTo(prevState => ({ ...prevState, slitting: false }))}
                        variant="Delete"
                        heading="Delete Service?"
                        description="Are you sure you want to delete this?"
                        button1={{
                            text: "Yes",
                            variant: "tertiaryContained",
                            size: "large",
                            onClick: slittingDeleteConfirmed,
                        }}
                        button2={{
                            text: "No",
                            variant: "secondaryContained",
                            size: "large",
                            onClick: handleCancelSlittingDelete,
                        }}
                    />
                }
            </div>
        </Dialog>
    )
}

export default ValueAddedServicesDetailsTemplate;