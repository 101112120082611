import React, { useEffect } from 'react'
import { createUseStyles } from 'react-jss';
import noImage from "../../../../assets/images/requestForDemo.svg"
import Button from '../../../atoms/Button/Button';
import Dialog from '@mui/material/Dialog';
import { useNavigate } from 'react-router-dom';


const useStyles = createUseStyles((theme: any) => ({
    container: {
        "& .MuiDialog-paper": {
            borderRadius: "16px",
            padding: "68px 70px",
            minWidth: "800px !important",
        }      
    },
    description: {
        color: theme.palette.text.neutral.neutral600
    }

}));

interface DialogProps {
    dialogOpen: boolean;
    setDialogOpen?: (open: boolean) => void;
}

const ResponsePopupTemplate: React.FC<DialogProps> = ({dialogOpen, setDialogOpen = () => {}}) => {
    
    const classes = useStyles();
    const navigate = useNavigate();

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleNavigateToHome = () => {
        handleDialogClose();
        navigate("/");
    };
    
    return (
        <Dialog className={`${classes.container}`}
            open={dialogOpen} onClose={handleDialogClose}>
            <div className='grid gap-6 w-full'>
                <img className={`m-auto`} src={noImage} alt="CatalogueImage" />
                <div className='w-full text-center text-3xl font-semibold'>Thank you for requesting a demo!</div>
                <span className={`${classes.description} text-2xl w-full text-center`}>We’ve received your information and will get back to you shortly. <br />
                    One of our experts will reach out to walk you through how<br />
                    SteelBazaar can help transform your business.</span>
                <div className='flex items-center justify-center'>
                    <Button variant="primaryContained" label={"Visit Homepage"} onClick={handleNavigateToHome} />
                </div>
            </div>
        </Dialog>
    )
}
export default ResponsePopupTemplate;