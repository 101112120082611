import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import Button from '../../../atoms/Button/Button';
import { Link } from 'react-router-dom';
import { STATIC_PAGES_ROUTES } from '../../../../utils/constant';
import navbarArrowIcon from '../../../../assets/icons/navbarArrowIcon.svg';
import Navbarplatform1 from '../../../../assets/images/Navbarplatform1.jpg';
import Navbarplatform2 from '../../../../assets/images/Navbarplatform2.jpg';
import Navbarplatform3 from '../../../../assets/images/Navbarplatform3.jpg';
import Navbarplatform4 from '../../../../assets/images/Navbarplatform4.jpg';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        background: "white",
        position: 'absolute',
        width: '100%',
        top: '125px',
        left: '0',
        zIndex: '99',
    },
    primaryContainer: {
        width: '1297px',
        margin: '0 auto',
    },
    dropdownContent: {
        "& h3": {
            color: '#000',
            fontSize: '22px',
            lineHeight: '26px',
        },
        "& h4": {
            lineHeight: '22px',
            color: '#000',
            borderBottom: `1px solid ${theme.palette.border.neutral.neutral200}`
        },
        "& p": {
            color: '#000',
            position: 'relative', 
            paddingLeft: '24px', 
            "&::before": {
                content: '""',
                display: 'inline-block',
                position: 'absolute',
                left: '12px',
                top: '50%',
                transform: 'translateY(-50%)',
                width: '8px', 
                height: '12px',
                backgroundImage: `url(${navbarArrowIcon})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
            },
        },
    },
    dropdownImgContainer: {
        '& img': {
            width: '100%',
            height: 'auto',
        }, 
    },
}));

const ContactUsDropdown: React.FC = () => {
    const classes = useStyles();

    const [hoveredImage, setHoveredImage] = useState<string>(Navbarplatform1);

    const imagesMap: Record<string, string> = {
        'Navbarplatform1': Navbarplatform1,
        'Navbarplatform2': Navbarplatform2,
        'Navbarplatform3': Navbarplatform3,
        'Navbarplatform4': Navbarplatform4,
    };

    return (
        <div className={`${classes.container} py-10`}>
            <div className={`${classes.primaryContainer} flex justify-between items-center`}>
                <div className='flex w-full gap-[15px]'>
                    <div className='w-1/4 pr-10'>
                        <div className={`${classes.dropdownContent} flex flex-col `}>
                            <div className='p-[10px]'>
                                <h4 className='font-semibold text-lg py-[5px] px-[10px] mb-1'>Customer Support</h4>
                                <p
                                    className="text-base leading-5 py-[5px] px-[10px]"
                                    onMouseEnter={() => setHoveredImage(imagesMap['Navbarplatform1'])}
                                >
                                    <Link to={`${STATIC_PAGES_ROUTES.CONTACT_US}`}>Phone & Email Chat Support</Link>
                                </p>
                            </div>
                        </div>
                        <div className={`${classes.dropdownContent} flex flex-col `}>
                            <div className='p-[10px]'>
                                <h4 className='font-semibold text-lg py-[5px] px-[10px] mb-1'>Office Location</h4>
                                <p
                                    className="text-base leading-5 py-[5px] px-[10px]"
                                    onMouseEnter={() => setHoveredImage(imagesMap['Navbarplatform2'])}
                                >
                                    <Link to={`${STATIC_PAGES_ROUTES.CONTACT_US}`}>Principal Office</Link>
                                </p>
                                <p
                                    className="text-base leading-5 py-[5px] px-[10px]"
                                    onMouseEnter={() => setHoveredImage(imagesMap['Navbarplatform3'])}
                                >
                                    <Link to={`${STATIC_PAGES_ROUTES.CONTACT_US}`}>Regional Offices</Link>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='w-1/4 pr-10'>
                        <div className={`${classes.dropdownContent} flex flex-col `}>
                            <div className='p-[10px]'>
                                <h4 className='font-semibold text-lg py-[5px] px-[10px] mb-1'>Partnerships</h4>
                                <p className='text-base leading-5 py-[5px] px-[10px]'>Channel Trade Partners</p>
                                <p className='text-base leading-5 py-[5px] px-[10px]'>Finance Partners</p>
                                <p className='text-base leading-5 py-[5px] px-[10px]'>Logistic Partners</p>
                            </div>
                        </div>
                    </div>
                    <div className='w-1/4 pr-10'>
                        <div className={`${classes.dropdownContent} flex flex-col `}>
                            <div className='p-[10px]'>
                                <h4 className='font-semibold text-lg py-[5px] px-[10px] mb-1'>Careers</h4>
                                <p className='text-base leading-5 py-[5px] px-[10px]'>Job Openings</p>
                                <p className='text-base leading-5 py-[5px] px-[10px]'>Life At SteelBazaar</p>
                            </div>
                        </div>
                    </div>
                    <div className={`${classes.dropdownImgContainer} rounded-lg w-1/4 `}>
                        {hoveredImage && <img src={hoveredImage} alt="Hovered Image" />}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactUsDropdown;
