import { request } from ".";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { API_METHOD } from "../utils/constant";
import { replaceUrlParams } from "../utils/helper";

export const CATALOGUE_BUNDLE_URLS = {
  GET_CATALOGUE_BUNDLE_BY_ID: "/catalog-bundles/:id",
  GET_CATALOGUE_BUNDLE_PRODUCT: "/catalog-bundles/:id/product-details",
  GET_ALL_CATALOGUE_BUNDLE: "/catalog-bundles"
};

interface ICatalogueBundleParams {
  page: number;
  size: number;
  sort?: string;
}

export interface ICatalogueBundleRequestBody {
  categoryId?: number | string | null;
  brandId?: number[] | null;
  classType?: string | null;
}


export const useCatalogueBundleService = () => {
  const { user: authenticatedUser } = useAuthenticatedUser();

  const getCatalogueBundleById = (id: number) => {
    return request(API_METHOD.GET, replaceUrlParams(CATALOGUE_BUNDLE_URLS.GET_CATALOGUE_BUNDLE_BY_ID, { id } ), authenticatedUser );
  };

  const getCatalogueBundleProductById = (id: number) => {
    return request(API_METHOD.GET, replaceUrlParams(CATALOGUE_BUNDLE_URLS.GET_CATALOGUE_BUNDLE_PRODUCT, { id } ), authenticatedUser );
  };

  const getAllCataloguesBundle = (params: ICatalogueBundleParams, data: ICatalogueBundleRequestBody) => {
    return request(API_METHOD.POST, CATALOGUE_BUNDLE_URLS.GET_ALL_CATALOGUE_BUNDLE, authenticatedUser, data, { params } );
  };

  return {
    getCatalogueBundleById,
    getCatalogueBundleProductById,
    getAllCataloguesBundle
  };
};
