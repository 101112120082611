import React, { useEffect, useState, useContext, useRef } from 'react'
import { IWarehouse } from '../../template/CatalogueDetail/WarehouseLocation.template';
import { CATALOGUE_TYPE_STATES, CLASS_STATES, HTTP_STATUS } from '../../../utils/types';
import { createUseStyles } from 'react-jss';
import CatalogueImageTemplate, { IImage } from '../../template/CatalogueDetail/CatalogueImage.template';
import { makeRoute, snakeCaseToTitleCase } from '../../../utils/helper';
import { useLocation, useNavigate } from 'react-router-dom';
import { ICartRequestBody, useCartService } from '../../../services/useCartService';
import Breadcrumbs from '../../atoms/BreadCrumbs/BreadCrumbs';
import { CartContext } from '../../../contexts/CartContext';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import { CART_LIFE_CYCLE, CUSTOMER_ROUTES, PAYMENT_METHODS, REGEX, STATUS, UNIT } from '../../../utils/constant';
import TermsAndConditionsCarousel from '../../template/CatalogueDetail/TermsAndConditionsCarousel.template';
import Disclaimer from '../../template/CatalogueDetail/Disclaimer.template';
import AddToFavouriteTemplate from '../../template/CatalogueDetail/AddToFavourite.template';
import PaymentDetailsTemplate from '../../template/CatalogueDetail/PaymentDetails.template';
import DeliveryDetailsTemplate from '../../template/CatalogueDetail/DeliveryDetails.template';
import ContactUs from '../../molecules/ContactUs/ContactUs.page';
import { IValueAddedService } from '../../template/Customization/SelectCustomization.template';
import TermsConditionsTemplate from '../../template/CatalogueDetail/TermsConditions.template';
import TextFieldV2 from '../../atoms/Input/TextFieldV2';
import SuccessFailurePopup from '../../molecules/SuccessFailurePopup/SuccessFailurePopup';
import CatalogueBundleListTemplate from '../../template/CatalogueDetail/CatalogueBundleList.template';
import shape from "../../../assets/icons/shape.svg";
import productCategory from "../../../assets/icons/productCategory.svg";
import BundlePriceDetailsTemplate from '../../template/CatalogueDetail/BundlePriceDetails.template';
import { useCatalogueBundleService } from '../../../services/useCatalogueBundleService';
import CatalogueBundleProductAttributesTemplate from '../../template/CatalogueDetail/CatalogueBundleProductAttributes.template';
import { useSnackbar } from '../../../hooks/useSnackBar';

export interface IAttribute {
    id: number;
    name: string;
    uom?: string;
    fieldType?: string;
    attributeOptions: number | string | string[];
}

export interface IUpcProperty {
    name: string;
    minimum?: number;
    maximum?: number;
    roomTemperature?: string;
    otherTemperature?: string;
    uom?: string;
}

export interface ICatalogueUpc {
    id: number;
    upcPrice: number;
    superCategory: string;
    mainCategory: string;
    productCategory: string;
    attributes: {
        SPECIFICATION: IAttribute[];
        CLASSIFICATION: IAttribute[];
        GENERALIZATION: IAttribute[];
    };
    hsnCodes: number[];
    tensileProperties: IUpcProperty[];
    chemicalComposition: IUpcProperty[];
    mechanicalProperties: IUpcProperty[];
    warehouseDetails: IWarehouse[];
    minimumOrderQuantity?: number;
}

export interface ICurrentCartItem {
    attributes: Record<string, any>;
    upc: ICatalogueUpc | null;
    warehouse: IWarehouse | null;
    packaging: string;
    quantity: string;
    secondarySkuId: number | null;
    paymentType: string | null;
}

interface Attribute {
    id: number;
    name: string;
    minValue: string;
    maxValue: string;
    uom: string | null;
};

interface Product {
    id: number;
    productCode: string;
    moq: number;
    catalogId: number;
    categoryId: number;
    categoryName: string;
    shape: string;
    attributes: Attribute[];
    classType: string;
};

interface TransformedAttribute {
    name: string;
    minValue: string;
    maxValue: string;
    uom: string | null;
};

const useStyles = createUseStyles((theme: any) => ({
    webContainer: {
        display: "grid",
        padding: "0px 70px 108px 70px"
    },
    leftContainer: {
        width: "66.54%",
    },
    middleChild: {
        width: "60%"
    },
    rightContainer: {
        width: "31.85%",
    },
    catalogueName: {
        color: theme.palette.text.primary.primary900,
        lineHeight: "28px"
    },
    value: {
        color: theme.palette.text.neutral.neutral700,
        fontSize: "16px",
        lineHeight: "20px"
    },
    inStock: {
        background: theme.palette.background.success.success50,
        border: `1px solid ${theme.palette.border.success.success100}`,
        color: theme.palette.text.success.success600,
    },
    location: {
        border: `1px solid ${theme.palette.border.neutral.neutral200}`,
        background: "none !important",
    },
    borderBottom: {
        borderBottom: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    border: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    property: {
        color: theme.palette.text.primary.primary900,
        fontSize: "14px"
    },
}));

const CatalogueBundleDetails: React.FC = () => {

    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const { showSnackbar, SnackBarComponent } = useSnackbar();

    const { user, syncAuthDialogActive } = useAuthenticatedUser();

    const cartContextData = useContext(CartContext);

    const catalogueBundleService = useCatalogueBundleService();
    const cartService = useCartService();

    const [catalogueId, setCatalogueIdTo] = useState<number | null>(null);
    const [catalogueBundle, setCatalogueBundleTo] = useState<any | null>(null);
    const [catalogueBundleProductDetail, setCatalogueBundleProductDetailTo] = useState<any | null>(null);

    const [currentCartItem, setCurrentCartItemTo] = useState<ICurrentCartItem>({
        attributes: {},
        upc: null,
        warehouse: null,
        packaging: "WITHOUT_PACKAGING",
        quantity: "",
        secondarySkuId: null,
        paymentType: PAYMENT_METHODS.CASH_PAYMENT
    });

    const [paymentType, setPaymentType] = useState<string | null>(PAYMENT_METHODS.CASH_PAYMENT);

    const scrollToLabel = () => {
        const label = document.getElementById('bundleTable');
        if (label) {
            label.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    const [showPopUp, setShowPopUp] = useState(false);

    const handleAddToCart = async () => {
        if (!user) {
            syncAuthDialogActive()
            return;
        }
        const requiredQuantity = parseFloat(currentCartItem.quantity);
        if (!REGEX.FLOAT.test(currentCartItem.quantity?.toString())) {
            return;
        }
        if (catalogueBundle && currentCartItem && currentCartItem.upc && currentCartItem.warehouse && currentCartItem.paymentType) {
            let cartRequestBody: ICartRequestBody = {
                catalogueName: catalogueBundle.name,
                upcId: currentCartItem.upc.id,
                warehouseId: currentCartItem.warehouse?.id,
                quantity: requiredQuantity,
                uom: currentCartItem.warehouse?.uom || "MT",
                packagingType: currentCartItem.packaging,
                secondarySkuId: null,
                valueAddedServices: {},
                paymentType: currentCartItem.paymentType
            }
            await cartService.saveToCart(cartRequestBody);
            await cartContextData.syncCart(CART_LIFE_CYCLE.ADD_PRODUCT);
            setShowPopUp(true);
        }
    }

    const handleGoToCart = () => {
        setShowPopUp(false);
        navigate(CUSTOMER_ROUTES.CART)
    }

    const handleContinueShopping = () => {
        setShowPopUp(false);
    }

    const handlePaymentTypeChange = (paymentTypeMethod: string) => {
        setCurrentCartItemTo({
            ...currentCartItem,
            paymentType: paymentTypeMethod
        });
        setPaymentType(paymentTypeMethod);
    };

    useEffect(() => {
        const catalogueIdString = queryParams.get('catalogueId');
        if (catalogueIdString !== null) {
            const parsedCatalogueId = parseInt(catalogueIdString, 10);
            if (!isNaN(parsedCatalogueId)) {
                setCatalogueIdTo(parsedCatalogueId);
            }
        }
    }, [location.search])

    useEffect(() => {
        if (catalogueId && catalogueBundleProductDetail) {
            catalogueBundleService.getCatalogueBundleById(catalogueId)
                .then((response) => {
                    if (response.status === HTTP_STATUS.OK) {
                        if(response?.data?.data?.status === STATUS.ARCHIVED){
                            navigate(makeRoute(CUSTOMER_ROUTES.CATALOGUE_LISTING, { query: { "classes": CLASS_STATES.BUNDLE, "catalogueTypes": CATALOGUE_TYPE_STATES.MAKE_TO_ORDER, "categoryId": catalogueBundleProductDetail[0]?.categoryId } }));
                            showSnackbar('error', "This Bundle has beeen removed")
                        }

                        setCatalogueBundleTo(response.data.data ? response?.data?.data : null);
                        setPaymentType(response?.data?.data?.paymentTerms)
                        setCurrentCartItemTo(prevCartItem => ({ ...prevCartItem, packaging: response.data.data?.packagingType || "WITHOUT_PACKAGING", quantity: response?.data?.data.moq }));
                    }
                })
                .catch((error) => {
                    console.error('Error', error);
                    setCatalogueBundleTo(null);
                });
        }
    }, [catalogueId, catalogueBundleProductDetail]);

    useEffect(() => {
        if (catalogueId) {
            catalogueBundleService.getCatalogueBundleProductById(catalogueId)
                .then((response) => {
                    if (response?.status === HTTP_STATUS.OK) {
                        setCatalogueBundleProductDetailTo(response?.data?.data)                       
                    }
                })
                .catch((error) => {
                    console.error('Error', error);
                    setCatalogueBundleProductDetailTo([])
                });
        }
    }, [catalogueId]);

    const catalogueBundleProduct = catalogueBundleProductDetail?.reduce((bundles: any, item: any) => {
        const { categoryName } = item;

        const existingBundle = bundles.find((bundle: any) => bundle.categoryName === categoryName);

        if (existingBundle) {
            existingBundle.products.push(item);
        } else {
            bundles.push({ categoryName, products: [item] });
        }
        return bundles;
    }, []);

    const bundledData = catalogueBundleProductDetail?.reduce((bundles: any, item: any) => {
        const { categoryName, shape } = item;

        const existingCategory = bundles.categories.find((c: any) => c.name === categoryName);
        if (existingCategory) {
            existingCategory.count += 1;
        } else {
            bundles.categories.push({ name: categoryName, count: 1 });
        }

        const existingShape = bundles?.shapes?.find((s: any) => s.name === shape);
        if (existingShape) {
            existingShape.count += 1;
        } else {
            bundles.shapes.push({ name: shape, count: 1 });
        }

        return bundles;
    }, { categories: [], shapes: [] });

    const categorySummary = bundledData?.categories?.slice(0, 2).map((c: any) => `${c.name} (${c.count})`).join(", ");
    const extraCategories = bundledData?.categories?.length - 2;
    const categoryString = extraCategories > 0 ? `${categorySummary} +${extraCategories}` : categorySummary;

    const shapeSummary = bundledData?.shapes?.slice(0, 1).map((s: any) => `${snakeCaseToTitleCase(s.name)}`).join(", ");
    const extraShapes = bundledData?.shapes?.length - 1;
    const shapeString = extraShapes > 0 ? `${shapeSummary} +${extraShapes}` : shapeSummary;

    const CATALOGUE_DETAILS_SCHEMA = [{ value: categoryString, icon: productCategory, label: "Product Category" }, { value: shapeString, icon: shape, label: "Shape" }];

    const extractFirstImages = (images: Record<string, any[]>) => {
        if (images) {
            const entries = Object.entries(images);
            const filteredEntries = entries.filter(([_, arr]) => Array.isArray(arr) && arr.length > 0);
            const result = filteredEntries.map(([_, arr]) => arr[0]);
            return result;
        }
    };

    const firstImagesArray = extractFirstImages(catalogueBundle?.images);

    const transformAttributeData = (data: Product[]): TransformedAttribute[] => {
        const allAttributes = data?.flatMap((product) => product.attributes);

        const groupedAttributes = allAttributes?.reduce<Record<string, Attribute[]>>((acc, attr) => {
            if (!acc[attr.name]) {
                acc[attr.name] = [];
            }
            acc[attr.name].push(attr);
            return acc;
        }, {});

        return Object.entries(groupedAttributes)?.map(([name, attributes]) => {
            const isNumeric = attributes?.every((attr) => !isNaN(parseFloat(attr.minValue)) && !isNaN(parseFloat(attr.maxValue)));

            let minValue: string, maxValue: string;

            if (isNumeric) {
                minValue = Math.min(...attributes?.map((attr) => parseFloat(attr.minValue))).toString();
                maxValue = Math.max(...attributes.map((attr) => parseFloat(attr.maxValue))).toString();
            } else {

                const sortedMinValues = attributes?.map((attr) => attr.minValue).sort();
                const sortedMaxValues = attributes?.map((attr) => attr.maxValue).sort();
                minValue = sortedMinValues[0];
                maxValue = sortedMaxValues[sortedMaxValues.length - 1];
            }

            const uom = attributes?.find((attr) => attr.uom)?.uom || null;

            return { name, minValue, maxValue, uom };
        });
    };

    const attributes: any = transformAttributeData(catalogueBundleProductDetail || []);

    return (
        
            <div className={`${classes.webContainer} max-container m-auto`}>
                <div className="flex justify-between">
                    <Breadcrumbs crumbs={[
                        {
                            // link: makeRoute(CUSTOMER_ROUTES.CATALOGUE_LISTING, { query: { classes: catalogue?.classType ?? CLASS_STATES.STANDARD, catalogueTypes: CATALOGUE_TYPE_STATES.CURRENT_INVENTORY } }),
                            link: makeRoute(CUSTOMER_ROUTES.CATALOGUE_LISTING, { query: { classes: CLASS_STATES.STANDARD, catalogueTypes: CATALOGUE_TYPE_STATES.CURRENT_INVENTORY } }),
                            label: "Product Listing Page"

                        },
                        {
                            link: "",
                            label: "Product Description Page"
                        }
                    ]} />
                    <div className="flex justify-end items-end">
                        <ContactUs />
                    </div>
                </div>
                <div className='flex gap-x-6'>
                    <div className={`grid mt-6 ${classes.leftContainer}`}>
                        <div className="w-full flex gap-x-6 ">
                            <div className="grid gap-y-4 h-fit">
                                <CatalogueImageTemplate catalogueImages={firstImagesArray} isRepresentationImage={false} />
                                <TermsAndConditionsCarousel />
                                <AddToFavouriteTemplate id={catalogueBundle?.id} catalogueType={CLASS_STATES.STANDARD} />
                            </div>
                            <div className={`${classes.middleChild} grid gap-y-6 h-fit mt-0 m-auto`}>
                                <div className={`${classes.catalogueName} text-2xl font-semibold`}>{catalogueBundle?.name}</div>
                                <div className={`grid grid-cols-2 p-4 ${classes.border} rounded-2xl`}>
                                    {CATALOGUE_DETAILS_SCHEMA.map((key: any, index: number) => (
                                        <div className="flex gap-x-2 cursor-pointer" onClick={scrollToLabel}>
                                            <div className="my-auto w-5"><img src={key.icon} className="m-auto" alt="" /></div>
                                            <div className="grid ">
                                                <div className={`${classes.property} font-medium`}>{(key.value || "")}</div>
                                                <div className={`${classes.value} font-normal text-start`}>{key.label}</div>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                <CatalogueBundleProductAttributesTemplate attributes={attributes || []} />

                                <div className="flex gap-3">
                                    <div className="grid gap-y-1" style={{ minWidth: "165px" }}>
                                        <TextFieldV2
                                            label="Packaging"
                                            value={snakeCaseToTitleCase(currentCartItem.packaging)}
                                            onChange={(event) => {
                                                setCurrentCartItemTo({
                                                    ...currentCartItem,
                                                    packaging: event.target.value,
                                                });
                                            }}
                                            fullWidth
                                            InputProps={{ readOnly: true }}
                                            disabled
                                        />
                                    </div>

                                    <div className="grid gap-y-1">
                                        <TextFieldV2
                                            label="Location"
                                            value={catalogueBundle?.warehouse?.name}
                                            fullWidth
                                            InputProps={{ readOnly: true }}
                                            disabled
                                        />
                                    </div>

                                    <div className="grid gap-y-1" style={{ minWidth: "130px" }}>
                                        <TextFieldV2
                                            label="Delivery Terms"
                                            value={snakeCaseToTitleCase(catalogueBundle?.deliveryTerm) || "Within 3 Days"}
                                            InputProps={{
                                                disableUnderline: true,
                                                readOnly: true,
                                            }}
                                            disabled
                                        />
                                    </div>
                                    <div className={`${classes.inStock} flex justify-end self-end py-1 px-2 text-xs font-medium h-fit whitespace-nowrap rounded`}>In Stock</div>
                                </div>
                            </div>
                        </div>

                        <div id="bundleTable" className={`pt-[50px] pb-10 ${classes.borderBottom}`}><CatalogueBundleListTemplate catalogueBundleProduct={catalogueBundleProduct} /></div>
                        <div className={`py-10 ${classes.borderBottom}`}><PaymentDetailsTemplate paymentType={paymentType} handlePaymentTypeChange={handlePaymentTypeChange} /></div>
                        <div className={`py-10 ${classes.borderBottom}`}><DeliveryDetailsTemplate /></div>
                        <div className={`py-10 ${classes.borderBottom}`}><TermsConditionsTemplate /></div>
                        <div className={`pt-10`} id="scrollToDisclaimer"><Disclaimer /></div>
                    </div>
                    <div className={classes.rightContainer}>
                        <div className={`pt-6 sticky top-[150px] grid`}>
                            {catalogueBundle && <BundlePriceDetailsTemplate
                                handleAddToCart={handleAddToCart}
                                paymentType={currentCartItem.paymentType}
                                minimumPrice={catalogueBundle?.price}
                                maximumPrice={catalogueBundle?.price}
                                quantity={catalogueBundle?.moq?.toString() ?? ''}
                                warehousename={catalogueBundle?.warehouse}
                                gst={18}
                                uom={currentCartItem?.warehouse?.uom?.toString() || ""}
                                moq={catalogueBundle?.moq}
                                catalogueBundle={catalogueBundle}
                            />}
                        </div>
                        {showPopUp &&
                            <SuccessFailurePopup
                                setShowPopup={setShowPopUp}
                                variant={"Add"}
                                heading="Your Product has Been Added!"
                                description="Your product has been added to the cart!"
                                button1={{
                                    text: "Go to Cart",
                                    variant: "tertiaryContained",
                                    size: "large",
                                    onClick: handleGoToCart,
                                }}
                                button2={{
                                    text: "Continue Shopping",
                                    variant: "secondaryContained",
                                    size: "large",
                                    onClick: handleContinueShopping,
                                }}
                            />
                        }
                    </div>
                </div>
            </div>
    );
}

export default CatalogueBundleDetails;