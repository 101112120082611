import React, { useCallback, useContext, useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import { useCategoryService } from "../../../services/useCategoryService";
import { createSearchParams, useSearchParams } from "react-router-dom";
import Breadcrumbs from "../../atoms/BreadCrumbs/BreadCrumbs";
import { createUseStyles } from "react-jss";
import Dialog from '@mui/material/Dialog';
import { PRODUCT_TYPE, STORE_FRONT_CATALOGUE_LIST_QUERY, CATALOGUE_TYPE_STATES, CLASS_STATES, IPagination, VENDOR_CATALOGUE_TYPE_STATES } from "../../../utils/types";
import { ICatalogueAttributes, ICatalogueRequestBody, useCatalogueService } from "../../../services/useCatalogueService";
import * as _ from "lodash";
import { MetaDataContext } from "../../../contexts/MetaDataContext";
import useMetaDataService from "../../../hooks/useMetaDataService";
import { ATTRIBUTES_WITH_RANGE, CATALOGUES_TYPE, DEBOUNCE_TIME } from "../../../utils/constant";
import { toSnakeCase, toAllCapsCase, useDebounce, Enum } from "../../../utils/helper";
import Tune from '../../../assets/icons/tune.svg'
import { Badge } from "@mui/material";
import CatalogueFilterMobile from "../../template/CatalogueList/CatalogueFilterMobile.template";
import CatalogueListTemplate, { Catalogue } from "../../template/CatalogueList/CatalogueList.template";
import CatalogueFilter, { ICatalogueFilterExtended } from "../../template/CatalogueList/CatalogueFilter.template";
import StoreFrontTemplate from "../../template/StoreFront/StoreFront.template";
import { StoreFrontGstDetailsTemplate } from "../../template/StoreFront/StoreFrontGstDetails.template";
import { ITabsSchema } from "../../molecules/TabsV2/TabsV2";
import TabsV4 from "../../molecules/TabsV2/TabsV4";
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";
import StoreFrontCatalogueFilter from "../../template/CatalogueList/StoreFrontCatalogueFilter.template";

export interface ICategory {
    path: string;
    id: number | string;
    label: string;
}

const useStyles = createUseStyles((theme: any) => ({
    tabContainer: {
        display: "grid",
        // padding: "0px 70px 104px 70px",
    },
    heading: {
        color: theme.palette.text.primary.primary900,
        lineHeight: "28px"
    },
    filterContainer: {
        width: "19%",
    },
    rightContainer: {
        width: "79.85%",
    },
    category: {
        border: `1px solid ${theme.palette.border.primary.primary200}`,
        color: theme.palette.text.primary.primary800,
    },
    activeTabTitle: {
        background: theme.palette.background.primary.primary50,
    },
    vendorDetails:{
        color: theme.palette.text.neutral.neutral600,
    },
    borderBottom:{
        borderBottom:`1px solid ${theme.palette.border.neutral.neutral100}`
    },
    filterMobileContainer: {
        display: "none"
    },
    dialog: {
        '& .MuiDialog-paper ': {
            alignSelf: "end",
            borderTopLeftRadius: "24px",
            borderTopRightRadius: "24px",
            width: '100%',
            margin: '0px'
        },
    },
    tabMobileContainer: {
        display: "none"
    },
    customBadge: {},
    filters: {},
    "@media (max-width: 480px)": {
        tabContainer: {
            display: "none"
        },
        tabMobileContainer: {
            display: "grid",
            width: '100%',
        },
        filters: {
            padding: '8px 16px',
            borderRadius: '55px',
            border: `1px solid ${theme.palette.text.neutral.neutral100}`,
            color: theme.palette.text.neutral.neutral800
        },
        customBadge: {
            "& .MuiBadge-badge": {
                color: "white",
                backgroundColor: theme.palette.background.secondary.secondary600
            }
        },
        filterMobileContainer: {
            display: "flex",
            width: "100%"
        },
    },
}));

const initialCatalogueFilters: ICatalogueFilterExtended = {
    standards: [],
    brands: [],
    grades: [],
    shapes: [],
    manufacturers: [],
    classes: [CLASS_STATES.STANDARD],
    catalogueTypes: []
};

const StoreFrontProduct: React.FC = () => {
    const { metaData: filterData } = useMetaDataService();
    const [openFilter, setOpenFilterTo] = useState<boolean>(false);
    const [catalogueFilter, setCatalogueFilter] = useState<ICatalogueFilterExtended>(initialCatalogueFilters);

    const filterContextData = useContext(MetaDataContext);

    const catagoryService = useCategoryService();
    const classes = useStyles();
    const [categories, setCategoriesTo] = useState<ICategory[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<ICategory | null>(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const catalogueService = useCatalogueService();
    const [catalogues, setCataloguesTo] = useState<Catalogue[]>([]);
    const {user} = useAuthenticatedUser();
    const [pagination, setPaginationTo] = useState<IPagination>({
        size: 12,
        totalRecords: 0,
        page: 0,
    });
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const categoryId: string = searchParams.get(STORE_FRONT_CATALOGUE_LIST_QUERY.CATEGORY_ID) ?? "";
    const [carousel, setcarouselTo] = useState<Carousel | null>(null);
    const catalogueClass = searchParams.get(STORE_FRONT_CATALOGUE_LIST_QUERY.CLASSES);
    const catalogueType = searchParams.get(STORE_FRONT_CATALOGUE_LIST_QUERY.CATALOGUE_TYPES);
    const [activeTab, setActiveTab] = useState<string>("1");
    const [vendorDetails, setVendorDetails]=useState<any>({})

    useEffect(() => {
        const params: { [key: string]: any } = {};
        for (const [key, values] of Object.entries(filterData.attributeFilters)) {
            if (ATTRIBUTES_WITH_RANGE.includes(toAllCapsCase(key))) {
                params[key] = [searchParams.get(toSnakeCase(key + " MIN")) ?? "", searchParams.get(toSnakeCase(key + " MAX")) ?? ""]
            } else {
                params[key] = searchParams.get(toSnakeCase(key))?.split(",") ?? [];
            }
        }
        setCatalogueFilter({ ...catalogueFilter, ...params });
    }, [filterData]);

    const CATEGORY_LEVEL = {
        PRODUCT_CATEGORY: 2,
    };

    const loadCategories = async () => {
        try {
            if(!user?.businessId) return 
            const params = {
                level: CATEGORY_LEVEL.PRODUCT_CATEGORY,
                limit: 100,
            };
            const { data: categoriesResponse } = await catagoryService.getAllVendorStoreFrontCategories(user.businessId, params);
            if (categoriesResponse?.data?.length) {
                setCategoriesTo(categoriesResponse.data);
                setSelectedCategory(categoriesResponse.data.find((category: Catalogue) => category.id.toString() === categoryId) ?? categoriesResponse.data[0]);
            } else {
                throw new Error("Categories fetch failed");
            }
        } catch (error) {
            setCategoriesTo([]);
            setSelectedCategory(null);
        }
    };

    useEffect(() => {
        loadCategories();
    }, [categoryId]);

    useEffect(() => {
        fetchData();
    }, [searchParams]);

    useEffect(() => {
        const productCategoryId = urlParams?.categoryId ? urlParams?.categoryId : categories.length && categories[0]?.id;
        const params = {
            classType: catalogueClass ? catalogueClass : CLASS_STATES.STANDARD,
            catalogueType: catalogueType ? catalogueType : VENDOR_CATALOGUE_TYPE_STATES.SINGLE_PRODUCT,
            categoryId: parseInt(productCategoryId.toString(), 10),
            vendorId: user?.businessId!!,
        };
        categories.length && filterContextData.loadVendorFilters(params);
    }, [catalogueClass, catalogueType, categoryId, categories]);

    const fetchData = async () => {
        const catalogueFilter: ICatalogueFilterExtended = {
            standards: extractParameterIdFromSearchParams(STORE_FRONT_CATALOGUE_LIST_QUERY.STANDARDS),
            brands: extractParameterIdFromSearchParams(STORE_FRONT_CATALOGUE_LIST_QUERY.BRANDS),
            grades: extractParameterIdFromSearchParams(STORE_FRONT_CATALOGUE_LIST_QUERY.GRADES),
            manufacturers: extractParameterIdFromSearchParams(STORE_FRONT_CATALOGUE_LIST_QUERY.MANUFACTURERS),
            shapes: searchParams.get(STORE_FRONT_CATALOGUE_LIST_QUERY.SHAPES)?.split(",") ?? [],
            classes: catalogueClass ? [catalogueClass] : [CLASS_STATES.STANDARD],
            catalogueTypes: catalogueType ? [catalogueType] : [VENDOR_CATALOGUE_TYPE_STATES.SINGLE_PRODUCT]
        };

        const specificationParams: { [key: string]: any } = {};
        for (const [key, values] of Object.entries(filterData.attributeFilters)) {
            if (ATTRIBUTES_WITH_RANGE.includes(toAllCapsCase(key))) {
                specificationParams[key] = [searchParams.get(toSnakeCase(key + " MIN")) ?? "", searchParams.get(toSnakeCase(key + " MAX")) ?? ""]
            } else {
                specificationParams[key] = searchParams.get(toSnakeCase(key))?.split(",") ?? []
            }
        }
        setCatalogueFilter({ ...catalogueFilter, ...specificationParams });
    };

    const extractParameterIdFromSearchParams: (parameterName: string) => number[] = (parameterName: string) =>
        searchParams.get(parameterName)?.split(",")?.map(parameterId => parseInt(parameterId.trim() || '0')) ?? [];

    const handleCategoryClick = (item: any) => () => {
        setSelectedCategory(item);
        setPaginationTo({
            size: 12,
            totalRecords: 0,
            page: 0,
        });
        setSearchParams((prevSearchParams) => ({
            ...prevSearchParams,
            categoryId: item.id.toString(),
            classes: selectedClass,
            catalogueTypes: selectedCatalogueType
        }));
    }

    const debouncedHandleCategoryClick = useDebounce((item) => {
        handleCategoryClick(item)();
    }, DEBOUNCE_TIME.PLP_CATEGORIES);

    const refreshCatalogues = useCallback(useDebounce(async () => {
        try {
            if(catalogueFilter?.catalogueTypes[0] === CATALOGUE_TYPE_STATES.MAKE_TO_ORDER){
               setCataloguesTo([]);
               return; 
            }
            // *********************************TODO- fix api and remove the interation************************************ */
            setIsLoading(true);
            let payload: ICatalogueRequestBody = {
                standardIds: catalogueFilter.standards.length > 0 ? catalogueFilter.standards : null,
                brandIds: catalogueFilter.brands.length > 0 ? catalogueFilter.brands : null,
                gradeIds: catalogueFilter.grades.length > 0 ? catalogueFilter.grades : null,
                shapes: catalogueFilter.shapes.length > 0 ? catalogueFilter.shapes : null,
                manufacturerIds: catalogueFilter.manufacturers.length > 0 ? catalogueFilter.manufacturers : null,
                // classes: catalogueFilter.classes,
                // catalogueTypes: catalogueFilter.catalogueTypes,
                categoryId: selectedCategory?.id ?? null
            };
            const specificationsPayload: { [key: string]: ICatalogueAttributes } = {};
            for (const [key, values] of Object.entries(filterData.attributeFilters)) {
                let attributeDetails: ICatalogueAttributes = {
                    minValue: null,
                    maxValue: null,
                    values: null
                }
                if (ATTRIBUTES_WITH_RANGE.includes(toAllCapsCase(key))) {
                    const attributeMinValue = catalogueFilter[key][0];
                    const attributeMaxValue = catalogueFilter[key][1];
                    attributeDetails.minValue = attributeMinValue !== null && attributeMinValue !== '' ? String(attributeMinValue) : null;
                    attributeDetails.maxValue = attributeMaxValue !== null && attributeMaxValue !== '' ? String(attributeMaxValue) : null;
                } else {
                    attributeDetails.values = catalogueFilter[key].length > 0 ? catalogueFilter[key] : null;
                }
                if (attributeDetails.minValue == null && attributeDetails.maxValue == null && attributeDetails.values == null) continue;
                specificationsPayload[key] = attributeDetails;
            }
            payload = { ...payload, attributes: { ...specificationsPayload } };
            // *********************************TODO- fix api and remove the interation************************************ */
            const cataloguesResponse = await catalogueService.getVendorCatalogues(
                user?.businessId,
                {
                    productType:selectedClass,
                    page: pagination.page,
                    size: pagination.size,
                },
                payload
            );
            setVendorDetails(cataloguesResponse.data.data.vendorVerificationDetails)
            if (cataloguesResponse?.data?.data?.catalogueDetailsResponseDTOS?.content?.length) {
                const { content, totalElements } = cataloguesResponse.data.data.catalogueDetailsResponseDTOS;
                setCataloguesTo(content);
                setPaginationTo((prevPagination: IPagination) => ({
                    ...prevPagination,
                    totalRecords: totalElements,
                }));
            } else {
                throw new Error("Catalogue fetch failed");
            }
        } catch (error) {
            setCataloguesTo([]);
        } finally {
            setIsLoading(false);
        }
    }, DEBOUNCE_TIME.CATALOGUE_FILTERS), [
        pagination.page,
        pagination.size,
        catalogueFilter,
        selectedCategory,
        catagoryService
    ]);

    useEffect(() => {
        if (selectedCategory?.id) {
            setCataloguesTo([])
            refreshCatalogues();
        }
    }, [catalogueFilter, selectedCategory, pagination.page, pagination.size]);

    useEffect(() => {
        document.getElementById("main")?.scroll(0, 0); // TODO don't scroll entire page. scroll only the right side content
    }, [selectedCategory, pagination.page, pagination.size])

    const onPaginationChange = (_: any, page: number) => {
        setPaginationTo({
            ...pagination,
            page: page
        })
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setPaginationTo({
            ...pagination,
            size: parseInt(event.target.value, 10)
        })
    };

    const urlParams = Object.fromEntries(searchParams.entries());

    let selectedStandards: number[] = urlParams.standards ? urlParams.standards.split(",").map((item: string) => parseInt(item, 10)) : [];
    let selectedBrands: number[] = urlParams.brands ? urlParams.brands.split(",").map((item: string) => parseInt(item, 10)) : [];
    let selectedGrades: number[] = urlParams.grades ? urlParams.grades.split(",").map((item: string) => parseInt(item, 10)) : [];
    let selectedShapes: string[] = urlParams.shapes ? urlParams.shapes.split(",") : [];
    let selectedManufacturers: number[] = urlParams.manufacturers ? urlParams.manufacturers.split(",").map((item: string) => parseInt(item, 10)) : [];
    let selectedClass: string = urlParams.classes || CLASS_STATES.STANDARD;
    let selectedCatalogueType: string = urlParams.catalogueTypes || VENDOR_CATALOGUE_TYPE_STATES.SINGLE_PRODUCT;
    let selectedAttrbites: { [key: string]: string[] } = {}

    for (const [key, values] of Object.entries(filterData.attributeFilters)) {
        if (key == null) continue;
        if (ATTRIBUTES_WITH_RANGE.includes(toAllCapsCase(key))) {
            selectedAttrbites[key] = [urlParams[toSnakeCase(key + " MIN")] ?? "", urlParams[toSnakeCase(key + " MAX")] ?? ""];
        } else {
            selectedAttrbites[key] = urlParams[toSnakeCase(key)] ? urlParams[toSnakeCase(key)]?.split(",") : []
        }
    }

    const updateUrl = () => {
        const params = {
            ...(selectedStandards.length > 0 && { standards: selectedStandards.join(",") }),
            ...(selectedBrands.length > 0 && { brands: selectedBrands.join(",") }),
            ...(selectedGrades.length > 0 && { grades: selectedGrades.join(",") }),
            ...(selectedShapes.length > 0 && { shapes: selectedShapes.join(",") }),
            ...(selectedManufacturers.length > 0 && { manufacturers: selectedManufacturers.join(",") }),
            classes: selectedClass,
            catalogueTypes: selectedClass === CLASS_STATES.NONSTANDARD ? VENDOR_CATALOGUE_TYPE_STATES.SINGLE_PRODUCT : selectedCatalogueType,
            categoryId: categoryId.toString(),
        };
        const selectedSpecifiationsParams: { [key: string]: any } = {}
        for (const [key, values] of Object.entries(selectedAttrbites)) {
            if (ATTRIBUTES_WITH_RANGE.includes(toAllCapsCase(key))) {
                if (selectedAttrbites[key][0]) selectedSpecifiationsParams[toSnakeCase(key + " MIN")] = selectedAttrbites[key][0];
                if (selectedAttrbites[key][1]) selectedSpecifiationsParams[toSnakeCase(key + " MAX")] = selectedAttrbites[key][1];
            } else {
                if (selectedAttrbites[key].length > 0) selectedSpecifiationsParams[toSnakeCase(key)] = selectedAttrbites[key].join(",");
            }
        }
        setSearchParams({ ...params, ...selectedSpecifiationsParams });
    };

    const onFilterChange = (filterName: STORE_FRONT_CATALOGUE_LIST_QUERY | string, filterId: number | string, isAdd: boolean, value?: string) => {
        switch (filterName) {
            case STORE_FRONT_CATALOGUE_LIST_QUERY.CLASSES:
                selectedClass = filterId.toString();
                break;
            case STORE_FRONT_CATALOGUE_LIST_QUERY.CATALOGUE_TYPES:
                selectedCatalogueType = filterId.toString();
                break;
            case STORE_FRONT_CATALOGUE_LIST_QUERY.STANDARDS:
                isAdd ? selectedStandards.push(filterId as number) : selectedStandards = selectedStandards.filter(id => id !== filterId);
                break;
            case STORE_FRONT_CATALOGUE_LIST_QUERY.BRANDS:
                isAdd ? selectedBrands.push(filterId as number) : selectedBrands = selectedBrands.filter(id => id !== filterId);
                break;
            case STORE_FRONT_CATALOGUE_LIST_QUERY.GRADES:
                isAdd ? selectedGrades.push(filterId as number) : selectedGrades = selectedGrades.filter(id => id !== filterId);
                break;
            case STORE_FRONT_CATALOGUE_LIST_QUERY.SHAPES:
                isAdd ? selectedShapes.push(filterId.toString()) : selectedShapes = selectedShapes.filter(shape => shape !== filterId);
                break;
            case STORE_FRONT_CATALOGUE_LIST_QUERY.MANUFACTURERS:
                isAdd ? selectedManufacturers.push(filterId as number) : selectedManufacturers = selectedManufacturers.filter(id => id !== filterId);
                break;
            default:
                if (ATTRIBUTES_WITH_RANGE.includes(toAllCapsCase(filterName))) {
                    selectedAttrbites[filterName][filterId as number] = value ?? "";
                } else {
                    isAdd ? selectedAttrbites[filterName].push(filterId.toString()) : selectedAttrbites[filterName] = selectedAttrbites[filterName].filter(id => id !== filterId);
                }
                break;
        }
        updateUrl();
        setCatalogueFilter(catalogueFilter);
    };

    const onDialogClose = () => {
        setOpenFilterTo(false);
    };

    const onFilterClear = () => {
        setSearchParams(createSearchParams({ categoryId }).toString())
    }

    const slidetoChange = (categoryId: any) => {
        const categoryIndex = categories.findIndex(category => category.id == categoryId)
        if (carousel && categoryIndex >= 0) {
            const targetSlide = (categoryIndex - (categoryIndex % 6));
            carousel.goToSlide(Math.min((categories.length - 6) < 0 ? 0 : (categories.length - 6), targetSlide));
        }
    }

    const schema: ITabsSchema[] = [
        { label: 'Raw Materials', component: <div></div> },
        { label: 'Finished Products', component: <div></div>,disabled:true },
        { label: 'Commercial Scraps', component: <div></div>,disabled:true },
        { label: 'Melting Scraps', component: <div></div>,disabled:true }
    ]

    const handleTabChange = (tab: string | number) => {
        setActiveTab(tab.toString());
    };

    useEffect(() => {
        if (carousel && categoryId && categories?.length) {
            slidetoChange(categoryId)
        }
    }, [carousel?.state?.domLoaded, categories])

    const getResourceOptionsAppliedFilterCount = (catalogueFilter: ICatalogueFilterExtended) => {
        let resourceOptionsFilter = 0;
        try {
            for (const [key, values] of Object.entries(catalogueFilter)) {
                if (ATTRIBUTES_WITH_RANGE.includes(toAllCapsCase(key))) {
                    resourceOptionsFilter += ((typeof values[0] === 'string' && values[0].length > 0) || (typeof values[1] === 'string' && values[1].length > 0)) ? 1 : 0;
                } else {
                    resourceOptionsFilter += values.length > 0 ? 1 : 0;
                }
            }
        } catch (error) {
            console.error(error);
        }
        return resourceOptionsFilter;
    }

    return (
        <>
            <div className={` ${classes.tabContainer} gap-y-4 max-container m-auto`}>
                    {/* <Breadcrumbs crumbs={[{link: "", label: selectedCategory?.label ? selectedCategory.label : "",},]} /> */}
                    <StoreFrontGstDetailsTemplate />
                    <div className={`text-2xl mt-2 font-semibold ${classes.heading}`}>View Products</div>
                    <TabsV4 schema={schema} value={activeTab.toString()} setValue={handleTabChange} />
                    <div className="flex justify-between gap-x-4">
                        <div className={`mt-0 my-auto ${classes.filterContainer}`}>
                            <StoreFrontCatalogueFilter
                                catalogueFilter={catalogueFilter}
                                onFilterChange={onFilterChange}
                            />
                        </div>
                        <div className={`flex flex-col gap-y-4 mt-0 ${classes.rightContainer}`}>
                            {categories.length > 0 && (
                                <div className={`mt-0 flex flex-wrap gap-3`}>
                                    {categories.map((item: any, index) => (
                                        <div key={item} onClick={() => debouncedHandleCategoryClick(item)} className={`cursor-pointer w-max`}>
                                            <div className={`py-2 px-3 text-sm font-medium rounded w-max ${classes.category} ${selectedCategory?.id === item.id ? classes.activeTabTitle : ''} `}>{item.label}</div>
                                        </div>
                                    ))}
                                </div>
                            )}
                            <StoreFrontTemplate
                                category={selectedCategory}
                                pagination={pagination}
                                catalogues={catalogues}
                                onPaginationChange={onPaginationChange}
                                isLoading={isLoading}
                                handleChangeRowsPerPage={handleChangeRowsPerPage}
                                vendorDetails={vendorDetails}
                            />
                        </div>
                    </div>
            </div>

            <div className={`px-4 ${classes.tabMobileContainer}`}>

                <Breadcrumbs crumbs={[
                    {
                        link: "",
                        label: selectedCategory?.label ? selectedCategory.label : "",
                    },
                ]} />

                <div className={`flex justify-between`}>
                    <div className={`text-2xl font-medium ${classes.heading}`}>
                        All Categories
                    </div>
                    <div className={`flex gap-x-4 items-center font-medium rounded-3xl ${classes.filters}`} onClick={() => setOpenFilterTo(true)}>
                        <span>
                            <Badge badgeContent={getResourceOptionsAppliedFilterCount(catalogueFilter)}
                                className={classes.customBadge}>
                                <img src={Tune} />
                            </Badge>
                        </span>
                        Filters
                    </div>
                </div>

                {categories.length > 0 && (
                    <div className={`overflow-x-auto overflow-hidden w-full mt-3`}>
                        <div className={`flex gap-4 w-max`}>
                            {categories.map((item: any, index) => (
                                <div
                                    key={item}
                                    onClick={() => debouncedHandleCategoryClick(item)}
                                    className={`cursor-pointer  ${selectedCategory?.id === item.id ? classes.activeTabTitle : ''}`}
                                >
                                    <div className={`p-2 text-base font-medium rounded-lg  w-max ${selectedCategory?.id === item.id ? classes.activeTabTitle : classes.category}`}>{item.label}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}


                <div className={` w-full mt-6`}>
                    <CatalogueListTemplate
                        category={selectedCategory}
                        pagination={pagination}
                        catalogues={catalogues}
                        onPaginationChange={onPaginationChange}
                        isLoading={isLoading}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </div>

                <Dialog className={classes.dialog} open={openFilter} onClose={onDialogClose}>
                    <div className={`  ${classes.filterMobileContainer}`}>
                        <CatalogueFilterMobile
                            totalRecords={pagination.totalRecords}
                            onDialogClose={onDialogClose}
                            catalogueFilter={catalogueFilter}
                            onFilterChange={onFilterChange}
                            onFilterClear={onFilterClear}
                        />
                    </div>
                </Dialog>
            </div>
        </>
    );
};

export default StoreFrontProduct;
