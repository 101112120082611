import { LOGIN_TYPES } from './../utils/types';
import { request } from "."
import { API_METHOD } from "../utils/constant";

export const AUTH_URLS = {
    LOGIN: "/auth/sign-in",
    SINGUP: "/auth/sign-up",
}

export interface ILoginTypeParam {
  loginType: LOGIN_TYPES | null;  
}

export const useAuthService = () => {

    const login = (data: { email: string; password: string } | { mobileNumber: string; otp?: string }) => {
        return request(API_METHOD.POST, AUTH_URLS.LOGIN, null, data);
    }

    const signUp = (data: { mobileNumber: string, otp?: string, fullName: string, gstin: string }, params: ILoginTypeParam) => {
        return request(API_METHOD.POST, AUTH_URLS.SINGUP, null, data, {params});
    }

    return {
        login,
        signUp
    }
};