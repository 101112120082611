import React from "react";
import { createUseStyles } from "react-jss";
import { TableBody, TableCell, TableHead, TableRow, Table } from '@mui/material';
import { makeRoute, snakeCaseToTitleCase } from "../../../utils/helper";
import { useNavigate } from "react-router";
import { CUSTOMER_ROUTES } from "../../../utils/constant";

const useStyles = createUseStyles((theme: any) => ({
    title: {
        color: "#1A1A1A",
        lineHeight: '24.3px'
    },
    productCategory: {
        color: "#1A1A1A",
        lineHeight: '22.4px',
        "& span": {
            color: "#2743A0",
            lineHeight: '22.4px',
        }
    },
    tableHead: {
        padding: "12px 16px",
        background: theme.palette.background.neutral.neutral50,
    },
    tableHeadCell: {
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "23.2px",
        padding: "12px 0px",
        color: "4D4D4D",
        textAlign: "center",
        '&:first-child': {
            borderTopLeftRadius: '12px',
        },
        '&:last-child': {
            borderTopRightRadius: '12px',
        },
        "&.MuiTableCell-root": {
            borderBottom: "none"
        }
    },
    tableCell: {
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "16px",
        padding: "12px",
        color: theme.palette.text.neutral.neutral700,
        textAlign: "center",
        "&.MuiTableCell-root": {
            borderBottom: "none"
        }
    },
    border: {
        border: `1px solid ${theme.palette.border.neutral.neutral200}`,
    },
    tableBodyCell: {
        "&.MuiTableCell-root": {
            borderBottom: "none"
        }
    },
    button: {
        color: "#333",
        fontSize: "14px",
        fontweight: 500,
        lineHeight: "16.1px",
        letterSpacing: "0.014px",
        textDecorationLine: "underline",
        textDecorationStyle: "solid",
    }
}));

interface ICatalogueBundleListTemplateProps {
    catalogueBundleProduct: any
}

const CatalogueBundleListTemplate: React.FC<ICatalogueBundleListTemplateProps> = ({ catalogueBundleProduct }) => {

    const classes = useStyles();
    const navigate = useNavigate();

    const HEADING = [
        "Sno.",
        "SKU ID",
        "Class",
        "Shape",
        "Thickness\n(mm)",
        "Width\n(mm)",
        "Net Weight\n(MT)",
        "Action",
    ]


    const handleView = (id: number) => () => {
        navigate(makeRoute(CUSTOMER_ROUTES.CATALOGUE_DESCRIPTION, { query: { "catalogueId": id } }))
    }

    return (
        <div className="grid gap-y-3">
            <div className={`${classes.title} text-lg font-bold`}>Information About Each Product in the Bundle</div>
            <div className="grid gap-y-6">
                <div className="grid gap-y-6">
                    {catalogueBundleProduct?.map((category: any, categoryIndex: any) => (
                        <div key={categoryIndex} className="grid gap-y-2">
                            <div className={`${classes.productCategory} text-base font-medium`}>Product Category: <span>{category.categoryName}</span></div>

                            <div className={`overflow-x-auto overflow-hidden w-full`}>
                                <div className={`${classes.border} rounded-xl`}>
                                    <Table className="w-full" size="small">
                                        <TableHead className={classes.tableHead}>
                                            <TableRow>
                                                {HEADING.map((item: any, index: any) => (
                                                    <TableCell key={index} className={`${classes.tableHeadCell} !py-4 first:pl-3 first:text-left last:pr-3 last:text-right`}>
                                                        {item.split("\n").map((line: any, lineIndex: any) => (<div key={lineIndex}>{line}</div>))}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody className={classes.tableBodyCell}>
                                            {category.products.map((product: any, productIndex: any) => (
                                                <TableRow key={productIndex} sx={{ borderTop: "1px solid #E6E6E6" }}>
                                                    <TableCell className={`${classes.tableCell} first:pl-8 first:text-left`}>{productIndex + 1}</TableCell>
                                                    <TableCell className={classes.tableCell}>{product.productCode}</TableCell>
                                                    <TableCell className={classes.tableCell}>{snakeCaseToTitleCase(product.classType)}</TableCell>
                                                    <TableCell className={classes.tableCell}>{snakeCaseToTitleCase(product.shape)}</TableCell>
                                                    <TableCell className={classes.tableCell}> {product.attributes.find((attr: any) => attr.name === "Thickness")?.minValue ?? "-"}</TableCell>
                                                    <TableCell className={classes.tableCell}>{product.attributes.find((attr: any) => attr.name === "Width")?.minValue ?? "-"}</TableCell>
                                                    <TableCell className={classes.tableCell}>{product.moq}</TableCell>
                                                    <TableCell className={`${classes.tableCell} last:pr-3 last:text-right last:items-end`}>
                                                        {/* <IconButton variant="primaryText" label={"View"} size="small" className="!px-1" onClick={handleView(product.catalogId)} /> */}
                                                        <div className={`${classes.button} cursor-pointer`} onClick={handleView(product.catalogId)}>View</div>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CatalogueBundleListTemplate;