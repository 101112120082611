import { request } from ".";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { API_METHOD } from "../utils/constant";
import { replaceUrlParams } from "../utils/helper";

export const ENQUIRY_URLS = {
        CONTACT_US: "/vendor/:vendorId/enquiry/contact-us",
        HOME_PAGE: "/enquiry"
};

export interface IEnquiryBody {
    name: string,
    email: string,
    phoneNumber: string,
    description: string,
    category?: string,
    quantity?: number
  }

  export interface IEnquiryRequestBody {
     name: string,
     email: string,
    phoneNumber: number | string,
     category: string,
  description: string,
    quantity: number,
    type: string,
    vendorId: number | null,
    companyName: string,
    section: string,
    status: string,
  }

export const useEnquiryService = () => {
  const { user: authenticatedUser } = useAuthenticatedUser();

  const createVendorStoreFrontContactUs = (data: IEnquiryBody, vendorId: number | undefined) => {
    return request(API_METHOD.POST, replaceUrlParams(ENQUIRY_URLS.CONTACT_US, {vendorId}), authenticatedUser, data);
  };

  const createEnquiry = (data: IEnquiryRequestBody) => {
    return request(API_METHOD.POST, ENQUIRY_URLS.HOME_PAGE, authenticatedUser, data);
  };


  return {
    createVendorStoreFrontContactUs,
    createEnquiry
  };
};
