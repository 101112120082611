import React, { Dispatch, SetStateAction, useMemo, useState } from 'react'
import { ADD_VENDOR_INVENTORY_STATES, HTTP_STATUS } from '../../../../utils/types';
import { FormikProps, useFormik } from 'formik';
import SelectProductTypeTemplate from './SelectProductType.template';
import SelectProductTemplate from './SelectProduct.template';
import AddUsnDetailTemplate from './AddUsnDetail.template';
import VendorInventoryReviewTemplate from './VendorInventoryReview.template';
import { createUseStyles } from 'react-jss';
import * as Yup from "yup";
import { useAuthenticatedUser } from '../../../../hooks/useAuthenticatedUser';
import { useSnackbar } from '../../../../hooks/useSnackBar';
import { useNavigate } from 'react-router-dom';
import { IVendorInventoryMillTest, useVendorInventoryService } from '../../../../services/useVendorInventoryService';
import { IModalFormikForm } from './AddInventorysection.template';

export interface IAddNewInventoryDetail {
    id?: number;
    ageingDays?: number;
    warehouseId: number;
    usnCode?: string;
    vendorUsnCode: string;
    grossWeight: number;
    netWeight: number;
    packagingType: string;
    isMillTest: boolean;
    millTestCertificate: File | null;
    entryDate?:string;
    status?:string;
}
export interface IInventorySampleInformation {
    sampleAvailable: boolean;
    sizeOfSample: string;
    PriceOfSample: string;
}
export interface IMillTestFileDetails {
    fileName: string;
    section: string;
    status: string;
    path: string;
    mimeType: string;
}
export interface IAddVendorInventoryForm {
    productType: string;
    productId: number | null;
    warehouseDetails: IAddNewInventoryDetail[];
    sampleInformation: IInventorySampleInformation; 
}


export interface AddVendorInventorySectionProp {
    setCurrentSectionTo: (section: ADD_VENDOR_INVENTORY_STATES) => void;
    formik: FormikProps<IAddVendorInventoryForm>;
    setWareHouseId?: Dispatch<SetStateAction<number>>;
    mode?:string;
    modalFormikEdit?: FormikProps<IModalFormikForm>;
    setExistingWarehouseDetails?: (warehouseDetails: IAddNewInventoryDetail[]) => void;
    existingWarehouseDetails?: IAddNewInventoryDetail[];
}

const initialValues: IAddVendorInventoryForm = {
    productType: 'STANDARD',
    productId: null,
    warehouseDetails: [],
    sampleInformation: {
        sampleAvailable: false,
        sizeOfSample: '',
        PriceOfSample: '',
    },
};

const useStyles = createUseStyles((theme: any) => ({
    titleText: {
        color: theme.palette.text.primary.primary900
    }
}));

const AddNewVendorInventoryTemplate = () => {
    const classes = useStyles();
    const [currentSection, setCurrentSection] = useState<ADD_VENDOR_INVENTORY_STATES>(ADD_VENDOR_INVENTORY_STATES.PRODUCT_TYPE);
    const { user } = useAuthenticatedUser();
    const vendorInventoryService = useVendorInventoryService();
    const navigate = useNavigate();
    const { showSnackbar, SnackBarComponent } = useSnackbar();

   

    const validationSchema = Yup.object().shape({
        productId: Yup.number()
            .required('Product ID is required')
            .positive('Product ID must be a positive number')
            .integer('Product ID must be an integer'),
        warehouseDetails: Yup.array().of(
            Yup.object().shape({
                netWeight: Yup.number()
                    .required('Net Weight is required')
                    .positive('Net Weight must be a positive number'),
                grossWeight: Yup.number()
                    .required('Gross Weight is required')
                    .positive('Gross Weight must be a positive number'),
                packagingType: Yup.string()
                    .required('Packaging Type is required'),
                isMillTest: Yup.boolean()
                    .required('Mill Test is required'),
            })
        )
    });
    const formik = useFormik<IAddVendorInventoryForm>({
        initialValues,
        validationSchema,
        onSubmit: async () => {
            const { warehouseDetails } = formik.values;
            const formData = new FormData();
            const inventoryArray = warehouseDetails.map((detail) => ({
                vendorId: user?.businessId!,
                productId: formik.values.productId!,
                warehouseId: detail.warehouseId,
                vendorUsnCode: detail.vendorUsnCode,
                netWeight: detail.netWeight,
                grossWeight: detail.grossWeight,
                packagingType: detail.packagingType,
                isMillTest: detail.isMillTest,
                status: "ACTIVE",
                uom: "MT",
                warehousePlacement: "Aisle 3, Shelf B",
            }));

            const jsonArrayBlob = new Blob([JSON.stringify(inventoryArray)], { type: 'application/json' });
            formData.append('inventoryRequestBodyDTOV2s', jsonArrayBlob);

            warehouseDetails.forEach((detail, index) => {
                if (detail.isMillTest && detail.millTestCertificate) {
                    formData.append(`files`, detail.millTestCertificate, detail.millTestCertificate.name);
                }else{
                    const emptyBlob = new Blob([], { type: 'application/octet-stream' });
                    formData.append(`files`, emptyBlob, `NULL`);
                }
            });
        try {
            const vendorCreateResponse = await vendorInventoryService.createInventories(formData);
            if (vendorCreateResponse.status === HTTP_STATUS.OK) {
                navigate('/dashboard/inventory-management');
                showSnackbar("success", `Vendor Inventory Created successfully`);
            }
        } catch (error) {
            showSnackbar("error", `Vendor Inventory Creation failed: ${error}`);
        }
        },
    });

    const addVendorInventorySectionProps: AddVendorInventorySectionProp = {
        setCurrentSectionTo: setCurrentSection,
        formik: formik,
    };

    const VendorInventorySectionView = useMemo(() => {
        switch (currentSection) {
            case ADD_VENDOR_INVENTORY_STATES.PRODUCT_TYPE:
                return <SelectProductTypeTemplate {...addVendorInventorySectionProps} />;
            case ADD_VENDOR_INVENTORY_STATES.PRODUCT:
                return <SelectProductTemplate {...addVendorInventorySectionProps} />
            case ADD_VENDOR_INVENTORY_STATES.ADD_USN:
                return <AddUsnDetailTemplate {...addVendorInventorySectionProps} />;
            case ADD_VENDOR_INVENTORY_STATES.REVIEW:
                return <VendorInventoryReviewTemplate {...addVendorInventorySectionProps} />;
            default:
                return <SelectProductTypeTemplate {...addVendorInventorySectionProps} />;
        }
    }, [currentSection, formik]);
    return (
        <div className='grid gap-6 justify-items-stretch '>
            <div className={`${classes.titleText} font-medium text-lg`}>Add USN</div>
            {VendorInventorySectionView}
        </div>
    )
}

export default AddNewVendorInventoryTemplate