import React, { useContext, useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { VendorCatalogueSectionProp } from './VendorCatalogueForm.template';
import { CATALOGUE_TYPE_STATES, HTTP_STATUS, VENDOR_CATALOGUE_FORM_STATES, VENDOR_PRODUCT_DETAILS_TYPE } from '../../../utils/types';
import Button from '../../atoms/Button/Button';
import { CART_LIFE_CYCLE, DELIVERY_INFORMATION, MODE, PACKAGING_TYPES, PAYMENT_METHODS, REGEX, SHAPE_TYPES, VENDOR_CATALOGUE_ROUTES, VENDOR_PRODUCT_CLASS_TYPE } from '../../../utils/constant';
import { ICartRequestBody, useCartService } from '../../../services/useCartService';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import { CartContext } from '../../../contexts/CartContext';
import { useCatalogueService } from '../../../services/useCatalogueService';
import SelectCustomizationReviewFormTemplate from '../Catalogue/Customization/SelectCustomizationReviewForm.template';
import TextFieldV2 from '../../atoms/Input/TextFieldV2';
import Select from '../../atoms/Select/Select';
import contactIcon from "../../../assets/images/contactImage.svg"
import callReviewIcon from "../../../assets/images/callReviewIcon.svg"
import whatsappReviewIcon from "../../../assets/images/whatsappReviewIcon.svg"
import { useBusinessProfileService } from '../../../services/useBusinessProfileService';
import { useSnackbar } from '../../../hooks/useSnackBar';
import ProductCategoryIcon from "../../../assets/icons/productCategory.svg";
import ProductShapeIcon from "../../../assets/icons/shape.svg";
import ProductClassIcon from "../../../assets/icons/classType.svg";
import ViewDetailsModalTemplate from './ViewDetailsModal.template';
import { useVendorInventoryService } from '../../../services/useVendorInventoryService';
import TermsConditionsReviewTemplate from './TermsConditionsReview.template';
import { usePostProductService } from '../../../services/usePostProductService';
import SingleUpcAttributeReviewForm from '../Catalogue/SingleUpcAttributeReviewForm.Template';
import { IValueAddedService } from '../Customization/SelectCustomization.template';
import { ICatalogue, ICatalogueUpc, ICurrentCartItem } from '../../pages/CatalogueDetail/CatalogueDetails.page';
import DeliveryDetailsTemplate from '../CatalogueDetail/DeliveryDetails.template';
import DisclaimerTemplate from '../CatalogueDetail/Disclaimer.template';
import TermsAndConditionsCarouselReview from './TermsAndConditionCarouselReview.template';
import { IproductDetails } from '../VendorManagement/ProductCatalogueView.template';
import PaymentDetailsTemplate from '../CatalogueDetail/PaymentDetails.template';
import PriceDetailsTemplate from '../CatalogueDetail/PriceDetails.template';
import ImagePreviewerv2 from '../../organisms/ImagePreviewer/ImagePreviewerV2';
import PriceDetailsReviewFormTemplate from '../Catalogue/PriceDetailsReviewForm.template';


export interface WarehouseProductDetail {
    deliveryTimeFrame: string;
    minimumOrderQuantity: number;
    warehouseId: number;
    warehouseName: string;
  }

const useStyles = createUseStyles((theme: any) => ({
    webContainer: {
        display: "grid"
    },
    middleContainer:{
        width:"449px",
        maxWidth:"449px"
    },
    rightContainer:{
        minWidth:"383px"
    },
    iconContainer:{
        borderRadius:"16px",
        border: "1px solid rgba(29, 54, 121, 0.15)",
    },
    locationContainer:{
        padding:"24px",
        border:"1px solid #E6E6E6",
        borderRadius:"12px",        
    },
    inventory:{
        color:"#070D1E",
        fontWeight:"500",
        fontSize:"18px"
    },
    packaging:{
        color:"#0A1229",
        fontWeight:"500",
        fontSize:"16px"
    },
    customerSupport:{
        borderRadius:"16px",
        boxShadow:"0px 7px 18px 0px rgba(29, 54, 121, 0.12)",
        backgroundColor:"white"
    },
    customerSupportName:{
        color:theme.palette.text.primary.primary900
    },
    customnerSupportDetails:{
        color:theme.palette.text.neutral.neutral600
    },
    activatedProgressBar: {
        backgroundColor: theme.palette.background.primary.primary500
    },
    activeProgressBar: {
        backgroundColor: theme.palette.background.primary.primary400
    },
    UnTouchProgressBar: {
        backgroundColor: theme.palette.background.neutral.neutral200
    },
    inputContainer: {
        display: "flex",
    },
    vendorDetails:{
        backgroundColor:"rgba(235, 239, 250, 0.55)"
    },
    barHeading: {
        color: theme.palette.text.neutral.neutral700
    },
    textHeading: {
        color: "red"
    },
    textHeading2: {
        color: "red"
    },
    selectBox: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    radioColor: {
        color: theme.palette.background.primary.primary400,
        width: '23%'
    },
    pageHeading:{
        color: theme.palette.text.primary.primary900,
        fontWeight:"500"
    },
    pageSubHeading:{
        color: theme.palette.text.neutral.neutral700,
        fontSize:"12px",
        fontWeight:"400"
    },
    label: {
        color: theme.palette.background.neutral.neutral700,
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "20px"
    },
    inventoryValue:{
        fontSize:"14px",
        fontWeight:"400",
        color:theme.palette.background.primary.primary800,
        padding:"8px",
        border:`1px solid ${theme.palette.background.secondary.secondary200}`,
        height:"36px",
        textAlign:"center"
    },
    inventoryHeading:{
        color:theme.palette.text.neutral.neutral900,
        fontSize:"14px",
        fontWeight:"600"
    },
    inStock: {
        height: "40px",
        borderRadius: "24px",
        background: theme.palette.background.success.success50,
        padding: "8px 16px",
        justifyContent: "center",
        alignItems: "center",
    },
    inStockValue: {
        color: theme.palette.text.success.success600,
    },
    input: {
        border: "1px solid",
        borderColor: theme.palette.background.neutral.neutral200,
        fontSize: "16px",
        fontWeight: 500,
        borderRadius: 8,
        color: theme.palette.text.neutral.neutral400,
        "&:hover": {
            borderColor: theme.palette.border.neutral.neutral200,
            borderWidth: 2,
            outline: "none",
        },
        "& .MuiOutlinedInput-notchedOutline": {
            outline: "none",
            borderWidth: 0,
            borderColor: theme.palette.border.neutral.neutral200,
        },
        "&:focus-within": {
            outline: "none",
            borderColor: `${theme.palette.border.neutral.neutral200} !important`,
            borderWidth: "2px !important",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            outline: "none",
            borderWidth: 0,
            borderColor: `${theme.palette.border.neutral.neutral200} !important`,
        },
        "& .MuiInputBase-input": {
            padding: "16px !important"
        },
    },
    "@media (max-width: 480px)": {
        inputContainer: {
            display: "grid",
            rowGap: "12px"
        },
        label: {
            color: theme.palette.text.neutral.neutral700,
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "16.1px"
        },
        selectionWrapper: {
            width: "fit-content"
        },
        input: {
            border: "1px solid",
            borderColor: theme.palette.background.neutral.neutral200,
            fontSize: "16px",
            fontWeight: 500,
            borderRadius: 8,
            color: theme.palette.text.neutral.neutral400,
            "&:hover": {
                borderColor: theme.palette.border.neutral.neutral200,
                borderWidth: 2,
                outline: "none",
            },
            "& .MuiOutlinedInput-notchedOutline": {
                outline: "none",
                borderWidth: 0,
                borderColor: theme.palette.border.neutral.neutral200,
            },
            "&:focus-within": {
                outline: "none",
                borderColor: `${theme.palette.border.neutral.neutral200} !important`,
                borderWidth: "2px !important",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                outline: "none",
                borderWidth: 0,
                borderColor: `${theme.palette.border.neutral.neutral200} !important`,
            },
            "& .MuiInputBase-input": {
                padding: "16px !important"
            },
        },
    }
}));
const AddCatalogueReviewFormTemplate: React.FC<VendorCatalogueSectionProp> = ({ formik, setCurrentSectionTo, mode }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { user, syncAuthDialogActive } = useAuthenticatedUser();
    const [catalogue, setCatalogueTo] = useState<ICatalogue | null>(null);
    const [currentCartItem, setCurrentCartItemTo] = useState<ICurrentCartItem>({
        attributes: {},
        upc: null,
        warehouse: null,
        packaging: "WITHOUT_PACKAGING",
        quantity: "",
        secondarySkuId: null,
        paymentType: PAYMENT_METHODS.CASH_PAYMENT
    });
    const [catalogueId, setCatalogueIdTo] = useState<number | null>(null);
    const [businessProfile, setBusinessProfileTo] = useState<any>();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const [catalogueUpc, setCatalogueUpcTo] = useState<ICatalogueUpc[] | null>(null);
    const [moq, setMOQ] = useState<string>('');
    const bussinessProfileService = useBusinessProfileService();
    const cartService = useCartService();
    const cartContextData = useContext(CartContext);
    const [packagingTypes, setPackagingTypesTo] = useState<Record<string, string>>({});
    const [productCombination, setProductCombination] = useState<boolean | null>(null);
    const [valueAddedService, setValueAddedServiceTo] = useState<IValueAddedService>({
        shearing: [],
        slitting: [],
    })
    const [paymentType, setPaymentType] = useState<string | null>();
    const catalogueService = useCatalogueService();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [dialogOpen, setDialogOpen] = useState<boolean>(false)
    const vendorInventoryService = useVendorInventoryService();
    const [vendorData, setVendorData]=useState<IproductDetails |  null>(null);
    const [vendorId, setVendorId]=useState<number>();
    const [productId, setProductId]=useState<number>();
    const [productIdForDetails, setProductIdForDetails]=useState<number>()
    const [warehouseDetails, setWarehouseDetails]=useState<any>([]);
    const [selectedWarehouse, setSelectedWarehouse]=useState<any>(warehouseDetails.length > 0 ? warehouseDetails[0] : {});
    const [usnDetailStats, setUsnDetailStats]=useState<any>()
    const [productDetails,setProductDetails]=useState<any>({})
    const [catalogImageData, setCatalogImageData]=useState<any>({})
    const [catalogueImages, setCatalogueImages]=useState([]);
    const postProductService = usePostProductService();
    const params = useParams();

    const getProductDetails = async () => {
        if(productIdForDetails)
            try{
                const response=await postProductService.getProductDetailsById(productIdForDetails)
                if(response.status===HTTP_STATUS.OK){
                    setProductDetails(response.data.data)
                }
            }
            catch(error){
                setProductDetails({});
            }
    }

    const handleQuantityChange = (eventOrValue: any) => {
        const quantity = typeof eventOrValue === 'string' ? eventOrValue : eventOrValue.target.value;
        if (REGEX.QUANTITY.test(quantity) || quantity === '') {
            setCurrentCartItemTo({
                ...currentCartItem,
                quantity
            });
        }
    };

    const handleWarehouseChange = (e: any) => {
        const selected = warehouseDetails.filter(({warehouseId} : any) => warehouseId===e.target.value)
        setSelectedWarehouse({...selected[0]});
    };

    const scrollToLabel = () => {
        const label = document.getElementById('scrollToLabel');
        if (label) {
            label.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }

        const label2 = document.getElementById('scroll-mobile');
        if (label2) {
            label2.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    const handleAddToCart = async () => {
        if (!user) {
            syncAuthDialogActive()
            return;
        }
        const requiredQuantity = parseFloat(currentCartItem.quantity);
        if (!REGEX.FLOAT.test(currentCartItem.quantity?.toString())) {
            return;
        }
        if (catalogue && currentCartItem && currentCartItem.upc && currentCartItem.warehouse && currentCartItem.paymentType) {
            let cartRequestBody: ICartRequestBody = {
                catalogueName: catalogue.name,
                upcId: currentCartItem.upc.id,
                warehouseId: currentCartItem.warehouse?.id,
                quantity: requiredQuantity,
                uom: currentCartItem.warehouse?.uom || "MT",
                packagingType: currentCartItem.packaging,
                secondarySkuId: null,
                valueAddedServices: valueAddedService,
                paymentType: currentCartItem.paymentType
            }
            await cartService.saveToCart(cartRequestBody);
            await cartContextData.syncCart(CART_LIFE_CYCLE.ADD_PRODUCT);
        }
    }

    const handleCancel = () => {
        navigate(VENDOR_CATALOGUE_ROUTES.MAKE_CATALOGUE);
    };
    const handleBack = () => {
        setCurrentSectionTo(VENDOR_CATALOGUE_FORM_STATES.CUSTOMIZATION);
    }
    const onNext = (event: React.FormEvent) => {
        formik.handleSubmit()
    }

    const handlePaymentTypeChange = (paymentTypeMethod: string) => {
        setCurrentCartItemTo({
            ...currentCartItem,
            paymentType: paymentTypeMethod
        });
        setPaymentType(paymentTypeMethod);
    };

    const onViewDetails = () => {
        setDialogOpen(true);
    }

    const convertWarehousesToOptions = (warehouses: any): any => {
        return warehouses.map((warehouse: any) => ({
            value: warehouse.warehouseId,
            label: warehouse.name.toLowerCase(),
        }));
    };

    const getBusinessProfile = async () => {
        try {
          const businessProfileResponse = await bussinessProfileService.getBusinessProfile();
          if (businessProfileResponse.status === HTTP_STATUS.OK) {
            const profileData = businessProfileResponse?.data?.data;
            setBusinessProfileTo(profileData);
          }
        } catch (error) {
          showSnackbar('error', 'Business Profile fetch failed');
        }
    };

    const getVedorProductbyId = async () => {
        if (user?.businessId && formik?.values?.productId) {
            try {
                const productId = formik?.values?.productId;
                const ProductsResponse = await vendorInventoryService.getDetailedProduct(user?.businessId, productId, { vendorProductDetailsType: VENDOR_PRODUCT_DETAILS_TYPE.VENDOR_PRODUCT_USN_DETAILS });
                if (ProductsResponse.status === HTTP_STATUS.OK) {
                    const productData = ProductsResponse?.data?.data;
                    setVendorData(productData)
                    setCatalogImageData({
                        catalogueType:"CURRENT_INVENTORY",
                        brandName:productData.standardProductDetails.brand,
                        classType:productData.standardProductDetails.classType,
                        shape:productData.standardProductDetails.shape,
                        productCategoryId:productData.standardProductDetails.productCategoryId
                    })
                    setProductIdForDetails(productData?.productId)
                    setVendorId(productData?.vendorId)
                    setProductId(productData?.id)
                    setWarehouseDetails(productData?.warehouseWiseProductDetails.map(({deliveryTimeFrame,minimumOrderQuantity,warehouseId,warehouseName}:WarehouseProductDetail) => ({warehouseId,deliveryTimeFrame,name:warehouseName,minimumOrderQuantity})))
                }
            } catch (error) {
                showSnackbar('error', 'Detailed Product Price fetch failed');
            }
        }
    };

    const getWarehouseDetails = async () => {
        if(vendorId && productId && selectedWarehouse?.warehouseId){
            try{
                const id=vendorId
                const warehouseId=selectedWarehouse.warehouseId
                const usnDetails = await vendorInventoryService.getWarehouseDetails(id,warehouseId, productId);
                if(usnDetails.status===HTTP_STATUS.OK){
                    setUsnDetailStats(usnDetails.data.data);
                }
            } catch(error){
                showSnackbar('error', "USN Details fetch failed")
            }
        }
    }

    const handleOnSaveDraft = () => {
        formik.setFieldValue("status","DRAFT");
        formik.handleSubmit();
    };

    const getProductImages = async () => {
        try{
            const catalogImages = await vendorInventoryService.getCatalogueImages(catalogImageData);
            setCatalogueImages(catalogImages.data.data)
        }
        catch(error){
            showSnackbar('error', "Images fetch failed")
        }
    }

    useEffect(() => {
        if(Object.values(catalogImageData).length > 1){
           getProductImages()
        }
    },[catalogImageData])

    useEffect(() => {
        getProductDetails()
    },[productIdForDetails])

    useEffect(() => {
        getVedorProductbyId();
    }, [formik?.values?.productId]);

    useEffect(() => {
        getWarehouseDetails()
    },[vendorId,selectedWarehouse?.warehouseId,productId])

    useEffect(() => {
        const catalogueIdString = formik.values.productId;
        if (catalogueIdString !== null) {
            // const parsedCatalogueId = parseInt(catalogueIdString, 10);
            const parsedCatalogueId = Number(catalogueIdString);
            if (!isNaN(parsedCatalogueId)) {
                setCatalogueIdTo(parsedCatalogueId);
            }
        }
    }, [formik?.values?.productId])

    // useEffect(() => {
    //     if (catalogueId) {
    //         catalogueService.getCatalogueById(catalogueId)
    //             .then((response) => {
    //                 if (response.status === HTTP_STATUS.OK) {
    //                     setCatalogueTo(response.data.data ? response.data.data : null);
    //                     setPackagingTypesTo(Object.fromEntries(response.data.data?.packagingTypes?.map((key: string) => [key, key])) ?? {});
    //                     setCurrentCartItemTo(prevCartItem => ({ ...prevCartItem, packaging: response.data.data?.packagingTypes[0] || null }));
    //                 }
    //             })
    //             .catch((error) => {
    //                 console.error('Error', error);
    //                 setCatalogueTo(null);
    //             });
    //     }
    // }, [catalogueId]);

    useEffect(() => {
        if (catalogueId) {
            catalogueService.getAllUpcsByCatalogueId(catalogueId)
                .then((response) => {
                    setCatalogueUpcTo(response.status === HTTP_STATUS.OK && response.data.data ? response.data.data : null);
                    setMOQ(response?.data?.data[0].minimumOrderQuantity)
                    setCurrentCartItemTo(prevCartItem => ({
                        ...prevCartItem,
                        warehouse: response?.data?.data?.[0]?.warehouseDetails?.[0],
                        upc: response?.data?.data?.[0]
                    }));
                },)
                .catch((error) => {
                    console.error('Error', error);
                    setCatalogueUpcTo(null);
                })
        }
    }, [catalogueId]);

    useEffect(() => {
        if (warehouseDetails.length > 0) {
            setSelectedWarehouse({
                warehouseId: warehouseDetails[0].warehouseId,
                deliveryTimeFrame: warehouseDetails[0].deliveryTimeFrame,
                name: warehouseDetails[0].name,
                minimumOrderQuantity: warehouseDetails[0].minimumOrderQuantity,
            });
        }
    }, [warehouseDetails]); 

    useEffect(() => {
        getBusinessProfile();
    }, []);
    
    useEffect(() => {
        document.getElementById("main")?.scroll(0, 0); 
    }, []);

    return (
        <div className={`${classes.webContainer} grid gap-y-8 `}>
            { !(mode === MODE.VIEW) &&
              (
              <div>
                <div className={`w-full m-auto flex justify-between gap-x-2 text-var(--black, #4D4D4D) text-center text-sm `}>
                <div className="grid gap-y-3 w-3/5 ">
                    <div className='flex gap-x-2 mx-auto '>
                        <div className={`${classes.activatedProgressBar} w-3 h-3 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto font-semibold`}>Select Catalog Type </div>
                    </div>
                    <div className={`${classes.activatedProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-3/5">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.activatedProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto`}>Select Product Type </div>
                    </div>
                    <div className={`${classes.activatedProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/2">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.activatedProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto`}>Select Product </div>
                    </div>
                    <div className={`${classes.activatedProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-3/5">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.activatedProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto`}>Enter Pricing Details</div>
                    </div>
                    <div className={`${classes.activatedProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/5">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.activeProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto`}>Review</div>
                    </div>
                    <div className={`${classes.activeProgressBar} h-2.5 rounded `}></div>
                </div>
            </div>

            <div className='flex flex-col mt-8 gap-0.5'>
                <div className={classes.pageHeading}>
                    Review
                </div>
                <div className={classes.pageSubHeading}>Please review the data you have entered</div>
            </div>
            </div>)
            }

            <div className='flex gap-4 -mt-4'>
                <div style={{width:"665px"}}>
                    <div className='flex gap-4'>
                        <div className='flex flex-col'>
                            <ImagePreviewerv2 width="200px" height="160px" defaultActiveIndex={0} images={catalogueImages}/>
                            <div><TermsAndConditionsCarouselReview width={"200px"} height="74px"/></div>
                        </div>
                        <div className={`${classes.middleContainer} flex flex-col gap-6`}>
                            <div className='text-2xl font-semibold'>{`${formik?.values?.name}`}</div>
                            <div className={`${classes.iconContainer} flex justify-between p-4`}>
                                <div className='flex gap-2'>
                                    <img src={ProductCategoryIcon} alt="icon" width={"20px"} height={"20px"}/>
                                    <div className='flex flex-col'>
                                        <div className={`${classes.pageHeading} text-sm`}>{productDetails?.productCategory}</div>
                                        <div className={`${classes.pageSubHeading} -mt-0.5`}>{"Product Category"}</div>
                                    </div>
                                </div>
                                <div className='flex gap-2'>
                                    <img src={ProductClassIcon} alt="icon" width={"20px"} height={"20px"}/>
                                    <div className='flex flex-col'>
                                        <div className={`${classes.pageHeading} text-sm`}>{productDetails?.classType && VENDOR_PRODUCT_CLASS_TYPE.filter(({value}) => value===productDetails?.classType)[0].label}</div>
                                        <div className={`${classes.pageSubHeading} -mt-0.5`}>{"Class"}</div>
                                    </div>
                                </div>
                                <div className='flex gap-2'>
                                    <img src={ProductShapeIcon} alt="icon" width={"20px"} height={"20px"}/>
                                    <div className='flex flex-col'>
                                        <div className={`${classes.pageHeading} text-sm`}>{productDetails?.shape && SHAPE_TYPES.filter(({id}) => id===productDetails?.shape)[0].name}</div>
                                        <div className={`${classes.pageSubHeading} -mt-0.5`}>{"Shape"}</div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <SingleUpcAttributeReviewForm attributes={productDetails?.attributesMap?.SPECIFICATION || {}} />
                            </div>
                                <div>
                                    <div className={`${classes.inventory} mb-3`}>Inventory</div>
                                    {usnDetailStats && <div className={`${classes.locationContainer} flex flex-col gap-4`}>
                                        <div className={classes.label}>
                                            Location
                                        </div>
                                        {selectedWarehouse?.warehouseId && 
                                            <Select
                                                label=""
                                                value={selectedWarehouse?.warehouseId}
                                                fullWidth
                                                onChange={(e: any) => handleWarehouseChange(e)}
                                                name="warehouse"
                                                id="warehouse"
                                                options={convertWarehousesToOptions(warehouseDetails)}
                                                className={`${classes.input} capitalize`}
                                            />
                                        }
                                        <div className='flex justify-between gap-2'>
                                            <div className='flex flex-col gap-2'>
                                                <div className={` whitespace-nowrap ${classes.inventoryHeading}`}>{"No. of USNs"}</div>
                                                <div className={`${classes.inventoryValue} flex justify-center items-center rounded-lg`}>{`${usnDetailStats?.noOfUsnCodes} USNs`}</div>
                                            </div>
                                            <div className='flex flex-col gap-2 overflow-hidden'>
                                                <div className={` whitespace-nowrap ${classes.inventoryHeading}`}>{"Net Weight"}</div>
                                                <div className={`${classes.inventoryValue} flex justify-center items-center rounded-lg`}>{usnDetailStats?.grossWeight && `${usnDetailStats?.grossWeight.toFixed(2)} MT`}</div>
                                            </div>
                                            <div className='flex flex-col gap-2 overflow-hidden'>
                                                <div className={` whitespace-nowrap ${classes.inventoryHeading}`}>{"Gross Weight"}</div> 
                                                <div className={`${classes.inventoryValue} flex justify-center items-center rounded-lg`}>{usnDetailStats?.netWeight && `${usnDetailStats?.netWeight.toFixed(2)} MT`}</div>
                                            </div>
                                            <div className='mt-auto whitespace-nowrap'>
                                                <Button
                                                    variant="secondaryText"
                                                    label="View Details"
                                                    onClick={onViewDetails}
                                                    size='small'
                                                    className='cursor-pointer'
                                                />
                                            </div>
                                            <div className='hidden'>
                                                {dialogOpen && <ViewDetailsModalTemplate vendorId={vendorId} productId={productId} productCategory={productDetails?.productCategory} selectedWarehouse={selectedWarehouse} dialogOpen={dialogOpen} setDialogOpen={setDialogOpen}/>}
                                            </div>
                                        </div>
                                    </div>}
                                    <div className={`${classes.inputContainer} flex justify-between gap-2 mt-6`}>
                                        <div className='flex flex-col gap-3 w-[60%]'>
                                            <TextFieldV2
                                                label="Packaging"
                                                value={
                                                    PACKAGING_TYPES.filter((item) => item.value === formik.values.catalogueProductDetails.find(
                                                        (product) => product.warehouseId === selectedWarehouse.warehouseId
                                                    )?.packagingType)[0]?.label || ''
                                                }
                                                fullWidth
                                                disabled
                                            />
                                        </div>
                                        <div className='flex flex-col gap-3'>
                                            <TextFieldV2
                                                fullWidth
                                                label="Delivery Terms"
                                                value={DELIVERY_INFORMATION.filter(({value}) => value===selectedWarehouse?.deliveryTimeFrame)[0]?.label}
                                                disabled
                                            />
                                        </div>

                                        <div className={`${classes.inStock} whitespace-nowrap w-fit mt-auto`}>
                                            <span className={`${classes.inStockValue} font-medium text-sm`}>In Stock</span>
                                        </div>
                                    </div>
                                </div>                        
                        </div>
                    </div>
                    <hr className='mt-6 mb-10'/>
                   { 
                   (formik?.values?.catalogueProductDetails?.filter((item) => item?.warehouseId===selectedWarehouse?.warehouseId)[0]?.productServicesDetails.length>0 && 
                   formik?.values?.catalogueProductDetails?.filter((item) => item?.warehouseId===selectedWarehouse?.warehouseId)[0]?.productServicesDetails[0]?.locationId) &&
                    <>
                        <div>
                            <SelectCustomizationReviewFormTemplate
                                catalogue={catalogue}
                                secondaryCatalogue={null}
                                quantity={parseFloat(currentCartItem.quantity)}
                                selectedUpc={currentCartItem?.upc}
                                valueAddedService={valueAddedService}
                                setValueAddedServiceTo={setValueAddedServiceTo}
                                customizationArray={formik?.values?.catalogueProductDetails?.filter((item) => item?.warehouseId===selectedWarehouse?.warehouseId)[0]?.productServicesDetails}
                                />
                        </div>
                        <hr className='mt-10 mb-10'/>
                    </>
                    }
                    <div className={``}><PaymentDetailsTemplate paymentType={formik.values.paymentTerms} handlePaymentTypeChange={handlePaymentTypeChange} /></div>
                    <hr className='mt-6 mb-10'/>
                    <div className={``}><DeliveryDetailsTemplate /></div>
                    <hr className='mt-10 mb-10'/>
                    <div className={``}><TermsConditionsReviewTemplate description={formik?.values?.termsConditions} /></div>
                    <div className='mt-10'></div>
                    <div className={``} id="scrollToDisclaimer"><DisclaimerTemplate /></div>
                </div>
                <div className={`flex flex-col gap-4`}>
                    <div className={`${classes.rightContainer}`}>
                        {<PriceDetailsReviewFormTemplate handleAddToCart={handleAddToCart}
                            paymentType={currentCartItem.paymentType}
                            moq={String(selectedWarehouse?.minimumOrderQuantity)}
                            handleQuantityChange={handleQuantityChange}
                            minimumPrice={formik.values.catalogueProductDetails.find(
                                (product) => product.warehouseId === selectedWarehouse.warehouseId
                            )?.offerSalePrice ?? 0}
                            maximumPrice={formik.values.catalogueProductDetails.find(
                                (product) => product.warehouseId === selectedWarehouse.warehouseId
                            )?.maximumRetailPrice ?? 0}
                            quantity={currentCartItem.quantity || ""}
                            gst={18}
                            warehouse={currentCartItem.warehouse}
                            uom={(currentCartItem?.warehouse?.uom)?.toString() || ""}
                            catalogueType={CATALOGUE_TYPE_STATES.CURRENT_INVENTORY}
                            productCombination={productCombination} />}
                    </div>
                    <div className={`${classes.customerSupport} flex flex-col gap-3 p-4`}>
                        <div className='flex justify-between'>
                            <div className='flex gap-3.5'>
                                <img src={contactIcon} width={"38px"} height="38px" alt="contact icon" />
                                <div>
                                    <div className={`${classes.customerSupportName} text-sm font-normal`}>{businessProfile?.pocs[0]?.name}</div>
                                    <div className={`${classes.customnerSupportDetails} text-xs font-normal`}>{`${businessProfile?.pocs[0]?.designation ? businessProfile?.pocs[0]?.designation : ""}${`${businessProfile?.pocs[0]?.designation ? " | " : ""}`}${businessProfile?.pocs[0]?.countryCode ? businessProfile?.pocs[0]?.countryCode : ""} ${businessProfile?.pocs[0]?.mobile ? businessProfile?.pocs[0]?.mobile : ""}`}</div>
                                </div>
                            </div>
                            <div className='flex gap-2'>
                                <img className='cursor-pointer' src={callReviewIcon} alt="call" />
                                <img className='cursor-pointer' src={whatsappReviewIcon} alt="whatsapp" />
                            </div>
                        </div>
                        <div className={`${classes.vendorDetails} flex flex-col gap-0.5 p-2 rounded-lg`}>
                            <div className='flex justify-between'>
                                <div className='text-sm font-medium underline'>{businessProfile?.name}</div>
                                <div className='text-sm font-normal'>Vendor ID: {businessProfile?.id}</div>
                            </div>
                            <div className='text-sm font-light'>Manufacturer/Factory, Trading Company, Group Corporation</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='flex justify-between items-center'>
                {!(mode === MODE.VIEW) && (
                    <Button
                        variant="tertiaryContained"
                        label="Cancel"
                        onClick={handleCancel}
                    />
                )}
                <div className={`flex gap-6 ${mode === MODE.VIEW ? 'ml-auto' : ''}`}>
                    <Button
                        variant="tertiaryContained"
                        label="Back"
                        onClick={mode === MODE.VIEW ? handleCancel : handleBack}
                    />
                    {mode === MODE.ADD && (
                        <Button
                            variant="secondaryContained"
                            label="Save as Draft"
                            onClick={handleOnSaveDraft}
                        />
                    )}
                    {mode === MODE.VIEW && vendorData?.status !== "INACTIVE"? (
                        <Button
                            variant="primaryContained"
                            label="Edit"
                            onClick={() => {
                                navigate(`${VENDOR_CATALOGUE_ROUTES.EDIT_CATALOGUE}/${params.catalogueId}`);
                            }}
                        />
                    ) : (
                        <Button
                            variant="primaryContained"
                            label="Add"
                            onClick={onNext}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

export default AddCatalogueReviewFormTemplate