import React, { useContext, useEffect, useMemo, useState } from 'react'
import { createUseStyles } from "react-jss";
import {Dialog, DialogContent } from '@mui/material';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import LoginWithMobileTemplate from './LoginWithMobile.template';
import { AUTH_STATES, AUTHENTICATION_TYPE, HTTP_STATUS, LOGIN_TYPES } from "../../../utils/types";
import { IncognitoUser } from './Authentication.template';
import SignUpWithMobileTemplate from './SignUpWithMobile.template';
import OnBoardingCarouselTemplate from './OnBoardingCarousel.template';
import ToggleTabs from '../../molecules/TabsV2/ToggleTabs';
import OtpVerificationTemplate from './OtpVerification.template';
import EmailConfirmationTemplate from './EmailConfirmation.template';
import RegisterEmailTemplate from './RegisterEmail.template';
import PasswordTemplate from './Password.template';
import LoginWithEmailTemplate from './LoginWithEmail.template';
import { useAuthService } from '../../../services/useAuthService';
import { useLocation } from 'react-router-dom';
import closeIcon from "../../../assets/icons/closeRoundIcon.svg";
import Button from '../../atoms/Button/Button';
import { useConfigurationService } from '../../../services/useConfigurationService';
import { AuthPopupContext } from '../../../contexts/AuthenticationLoginTypeContext';


const useStyles = createUseStyles((theme: any) => ({
    dialogContainer: {
        "& .MuiDialog-paper": {
            borderRadius: "16px",
            maxWidth: "819px !important",
        },
    },
    header: {
        borderBottom: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },

    closeButton: {
        cursor: 'pointer',
    },
    sectionText: {
        color: "#1C3F9F"
    },
    errorMessage: {
        color: "#EF0000"
    },
    separator: {
        color: "#5D5D5D",
        '& > span:nth-child(odd)': {
            flex: 1,
            border: `1px solid #5D5D5D`,
            height: '1px',
        },
    }
}))

const AuthenticationCustomerTemplate: React.FC = () => {
    const classes = useStyles();
    const { user, isAuthDialogActive, syncAuthDialogActive } = useAuthenticatedUser();
    const [showPopup, setShowPopup] = useState<boolean>(false); 
    const [loginPopUpTime, setLoginPopUpTimeTo] = useState<number>();
    const configurationService = useConfigurationService();
    const { isAuthTemplateActive } = React.useContext(AuthPopupContext);

    const handleDialogClose = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>, reason: "backdropClick" | "escapeKeyDown" | null) => {
        if (reason === "backdropClick" || reason === "escapeKeyDown")
            return;
        syncAuthDialogActive(false);
        setShowPopup(false);
        // setAuthState(AUTH_STATES.LOGIN_WITH_MOBILE);
    };
    const location = useLocation();
    const [activeTab, setActiveTab] = useState(AUTHENTICATION_TYPE.LOGIN);
    const [authState, setAuthState] = useState<AUTH_STATES>(() => {
        return new URLSearchParams(location.search).get('token')?.length ?
            AUTH_STATES.SET_PASSWORD : AUTH_STATES.LOGIN_WITH_MOBILE;
    });
    const authService = useAuthService();
    const [sentEmailType, setSentEmailType] = useState<AUTH_STATES>(AUTH_STATES.FORGOT_PASSWORD);

    const [otpVerificationProps, setOtpVerificationProps] = useState<({ otpVerifier: (verificationInfo: any, params?:any) => Promise<any>, section: AUTH_STATES })>({
        otpVerifier: authService.login, section: AUTH_STATES.LOGIN_WITH_MOBILE
    });
    const [userDetails, setUserDetail] = useState<IncognitoUser>({ mobileNumber: user?.mobileNumber ?? "", email: user?.email, fullName: user?.fullName, gstin: user?.gstin, isGstinVerified: false });

    const updateUserDetails = (name: string, value: string | boolean) => {
        setUserDetail((prev: any) => ({ ...prev, [name]: value, }));
    }

        useEffect(() => {
        if (authState === AUTH_STATES.LOGIN_WITH_MOBILE || authState === AUTH_STATES.USER_SIGNUP) {
            setOtpVerificationProps(authState === AUTH_STATES.LOGIN_WITH_MOBILE ? {
                otpVerifier: authService.login,
                section: AUTH_STATES.LOGIN_WITH_MOBILE
            } : {
                otpVerifier: (data) => {
                    const params = { loginType: LOGIN_TYPES.FORCED};
                    return authService.signUp(data, params);
                },
                section: AUTH_STATES.USER_SIGNUP
            });
        }
    }, [authState]);

    useEffect(() => {
        if (!user && loginPopUpTime && !sessionStorage.getItem('loginPopupState') && !isAuthDialogActive) {
            const timer = setTimeout(() => {
                setShowPopup(true);
                syncAuthDialogActive(false);
                sessionStorage.setItem('loginPopupState', 'true');
            }, loginPopUpTime);
            return () => clearTimeout(timer);
        }
    }, [user, loginPopUpTime, isAuthTemplateActive]);

    const getPopUpTime = () => {
        configurationService.getConfiguration1({ contexts: "POPUP_TIME" })
            .then((res: { status: HTTP_STATUS; data: { data: { data: any[] }[] } }) => {
                if (res?.status === HTTP_STATUS.OK) {
                    const loginPopupResponse = res?.data?.data?.[0]?.data?.[0];
                    const loginPopUpTime = loginPopupResponse?.popUpTime;
                    if (loginPopUpTime) {
                        setLoginPopUpTimeTo(loginPopUpTime);
                    } else {
                        console.error("Pop-up time not found in response.");
                        setLoginPopUpTimeTo(5000);
                    }
                }
            })
            .catch((error: any) => {
                console.error('Error in AuthenticationCustomerTemplate , Something went wrong while getting popup time', error);
                //keeping forced popup to appear after 5 secs 
                setLoginPopUpTimeTo(5000);
            });
    };
    

    useEffect(() =>{
         getPopUpTime();    
    },[]);

    const closePopup = () => {
        setShowPopup(false);
    };

    const tabs = [
        { id: 1, label: 'Login', description: 'Already have an account? LogIn Fast' },
        { id: 2, label: 'Register Now', description: 'Join Now in Simple Steps' },
    ];

    useEffect(() => {
        if (activeTab === AUTHENTICATION_TYPE.LOGIN) {
            setAuthState(AUTH_STATES.LOGIN_WITH_MOBILE);
        }
        else if (activeTab === AUTHENTICATION_TYPE.REGISTER_NOW) {
            setAuthState(AUTH_STATES.USER_SIGNUP);
        }
    }, [activeTab]);

    const authStateView = useMemo(() => {

        switch (authState) {
            case AUTH_STATES.LOGIN_WITH_MOBILE: return <LoginWithMobileTemplate setAuthState={setAuthState} mobileNumber={userDetails?.mobileNumber} updateUserDetails={updateUserDetails} styles={classes} setShowPopup = {setShowPopup} showFooter = {false}/>;
            case AUTH_STATES.USER_SIGNUP: return <SignUpWithMobileTemplate setAuthState={setAuthState} updateUserDetails={updateUserDetails} userDetails={userDetails} setActiveTab = {setActiveTab}/>;
            case AUTH_STATES.OTP_VERIFICATION: return <OtpVerificationTemplate setAuthState={setAuthState} userDetails={userDetails} {...otpVerificationProps} setShowPopup = {setShowPopup}/>;
            case AUTH_STATES.EMAIL_CONFIRMAITON: return <EmailConfirmationTemplate setAuthState={setAuthState} sentEmailType={sentEmailType} userDetails={userDetails} authState={authState} />;
            case AUTH_STATES.EMAIL_REGISTRATION: return <RegisterEmailTemplate setAuthState={setAuthState} updateUserDetails={updateUserDetails} setSentEmailType={setSentEmailType} authState={authState} />;
            case AUTH_STATES.FORGOT_PASSWORD: return <RegisterEmailTemplate setAuthState={setAuthState} updateUserDetails={updateUserDetails} setSentEmailType={setSentEmailType} authState={authState} />;
            case AUTH_STATES.SET_PASSWORD: return <PasswordTemplate setAuthState={setAuthState} />;
            default: return <LoginWithEmailTemplate setAuthState={setAuthState} styles={classes} setShowPopup = {setShowPopup} />;
        }
    }, [authState, activeTab, userDetails]);

    const handleSkip = () => {
        setShowPopup(false);
    }

    return (
        <Dialog fullWidth className={`${classes.dialogContainer}`} PaperProps={{ style: { height: '600x' } }} open={showPopup} onClose={handleDialogClose}>
            <div className=" p-6 grid gap-y-3">
                    <div className='flex gap-14'>
                        <div><OnBoardingCarouselTemplate /></div>
                        <div className={`flex flex-col  relative w-full`}>
                        <div className={`cursor-pointer flex justify-end`} onClick={(event) => handleDialogClose(event, null)} >
                        <img src={closeIcon} alt="closeIcon" />
                    </div>
                        <div className='!mr-14'>
                            <div className="flex gap-3 mb-6">
                                {tabs.map((tab) => (
                                    <ToggleTabs
                                        key={tab.id}
                                        label={tab.label}
                                        description={tab.description}
                                        isActive={activeTab === tab.id}
                                        onClick={() => setActiveTab(tab.id)}
                                    />
                                ))}
                            </div>
                            <div className='mt-8 px-6'>
                                {authStateView}
                            </div>
                            </div>
                    <div className={`cursor-pointer flex justify-end bottom-0 right-0 absolute pt-14`}>
                <Button label={"Skip"} variant="secondaryText" size="small" onClick={() => handleSkip()}/>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}
export default AuthenticationCustomerTemplate