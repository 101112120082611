import React, { useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import procurmentBanner from '../../../assets/images/procurmentBanner.jpg';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import customerBuyerBanner from '../../../assets/images/customerBuyerBanner.jpg';
import financing from '../../../assets/icons/financing.jpg';
import bulk from '../../../assets/icons/bulk.svg';
import visibililty from '../../../assets/icons/visibililty.svg';
import programs from '../../../assets/icons/programs.svg';
import longPartnership from '../../../assets/icons/longPartnership.svg';
import stategy from '../../../assets/icons/stategy.svg';
import logistics from '../../../assets/icons/logistics.svg';
import Vendorinventory from '../../../assets/icons/Vendorinventory.svg';
import reach from '../../../assets/icons/reach.svg';
import catalogStatic from '../../../assets/images/catalogStatic.png';
import roudTickIcon from '../../../assets/icons/roudTickIcon.jpg';
import vendorSentral1 from '../../../assets/images/vendorCentral1.jpg';
import iProcure from '../../../assets/images/iProcure.jpg';
import sbMaxCard from '../../../assets/images/sbMaxCard.jpg';
import procurePlus from '../../../assets/images/procurePlus.jpg';
import primaryBlueTick from '../../../assets/icons/primaryBlueTick.jpg';
import orangeTick from '../../../assets/icons/orangeTick.jpg';
import blueTick from '../../../assets/icons/blueTick.jpg';
import { Button } from '@mui/joy';
import RequestDemoTemplate from '../../template/Home/RequestDemo/RequestDemo.template';
import register from '../../../assets/images/register.jpg';
import dashboard from '../../../assets/images/dashboard.png';
import filterOptions from '../../../assets/images/filterOptions.png';
import brandChoice from '../../../assets/images/brandChoice.png';
import pricing from '../../../assets/images/pricing.png';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        width: '1297px',
        margin: '0 auto',
    },
    bannerContainer: {
        width: '100%',
        height: '650px',
        backgroundImage: `url(${customerBuyerBanner})`,
        backgroundSize: 'cover',
        position: 'relative',
    },
    bannerSellContainer: {
        padding: '10% 0px',
    },
    heading: {
        fontSize: '56px',
        lineHeight: '66px',
        color: theme.palette.text.primary.primary50,
        "& span": {
            color: theme.palette.text.warning.warning400,
        },
    },
    bannerDescription: {
        color: theme.palette.text.primary.primary50,
        lineHeight: '28px',
        "& span": {
            color: theme.palette.text.warning.warning400,
        },
    },
    bannerBtn: {
        background: theme.palette.background.primary.primary500,
        padding: '10px 20px',
        width: '228px',
        color: '#fff',
    },
    btnArrow: {
        border: '1px solid #fff',
        borderRadius: '100%',
    },
    cardsContainer: {
        background: '#fff',
        "& p": {
            fontSize: '28px',
        },
    },
    card: {
        border: `1px solid ${theme.palette.border.neutral.neutral200}`,
        background: '#F8F8F8',
        padding: '20px 15px',
        width: '23%',
        "& h4": {
            color: '#000',
            fontSize: '22px',
            lineHeight: '26px',
        },
        "& p": {
            color: theme.palette.text.neutral.neutral800,
            fontSize: '16px',
            lineHeight: '20px',
        },
    },
    cardIconContainer: {
        border: `1px solid ${theme.palette.border.neutral.neutral200}`,
        background: '#F8F8F8',
    },
    sectionHeading: {
        color: "#000",
        fontSize: '54px',
        lineHeight: '66px',
        "& span": {
            color: theme.palette.text.primary.primary500,
        },
        "& p": {
            color: theme.palette.text.success.success500,
        },
    },
    primaryContainer: {
        background: '#F8F8F8',
    },
    featuresContainer: {
        "& > p": {
            color: theme.palette.text.neutral.neutral600,
        },
        "& span": {
            fontSize: '40px',
            lineHeight: '48px',
            color: theme.palette.text.primary.primary700,
        },
        "& h4": {
            "& span": {
                color: theme.palette.text.primary.primary500,
                fontWeight: '600',
            },
        },
        "& h6": {
            color: theme.palette.text.neutral.neutral900,
            lineHeight: '28px'
        },
    },
    featureInfo: {
        "& h4": {
            color: theme.palette.text.neutral.neutral900,
        },
        "& p": {
            color: theme.palette.text.neutral.neutral800,
            lineHeight: '20px',
        },
    },
    headingText: {
        fontSize: '48px !important',
    },
    subHeading: {
        color: theme.palette.text.neutral.neutral800,
        lineHeight: '28px',
    },
    profileCard1: {
        background: theme.palette.background.complementary.complementary50,
        border: `1px solid ${theme.palette.border.complementary.complementary400}`,
        overflow: 'hidden',
        "& p": {
            color: theme.palette.text.complementary.complementary800,
            lineHeight: '22px',
        },
    },
    profileCardHeader1: {
        background: theme.palette.background.complementary.complementary400,
        fontSize: '22px',
        color: '#fff',


    },
    profileCard2: {
        background: theme.palette.background.warning.warning50,
        border: `1px solid ${theme.palette.border.warning.warning400}`,
        overflow: 'hidden',
        "& p": {
            color: theme.palette.text.warning.warning800,
            lineHeight: '22px',
        },
    },
    profileCardHeader2: {
        background: theme.palette.background.warning.warning400,
        fontSize: '22px',
        color: '#fff',
    },
    profileCard3: {
        background: '#FFEFE6',
        overflow: 'hidden',
        border: '1px solid #FD8235',
        "& p": {
            color: '#652701',
            lineHeight: '22px',
        },
    },
    profileCardHeader3: {
        background: '#FD8235',
        fontSize: '22px',
        color: '#fff',

    },
    profileCard4: {
        background: theme.palette.background.primary.primary50,
        border: `1px solid ${theme.palette.border.primary.primary400}`,
        overflow: 'hidden',
        "& p": {
            color: theme.palette.text.primary.primary800,
            lineHeight: '22px',
        },
    },
    profileCardHeader4: {
        background: theme.palette.background.primary.primary400,
        fontSize: '22px',
        color: '#fff',
    },
    profileCard5: {
        background: theme.palette.background.success.success50,
        border: `1px solid ${theme.palette.border.success.success400}`,
        overflow: 'hidden',
        "& p": {
            color: theme.palette.text.success.success800,
            lineHeight: '22px',
        },
    },
    profileCardHeader5: {
        background: theme.palette.background.success.success400,
        fontSize: '22px',
        color: '#fff',
    },

    catalogsContainer: {
        border: `1px solid ${theme.palette.border.neutral.neutral200}`,
        background: '#fff',
        width: '48%',
        "& h5": {
            fontSize: '22px',
            color: '#000',
            lineHeight: '26px',
        },
        "& p": {
            color: '#000',
            lineHeight: '20px',
        },
    },
    discover1: {
        border: `1px solid ${theme.palette.border.secondary.secondary200}`,
        background: theme.palette.background.secondary.secondary50,
        width: '580px',
        height: '493px',
    },
    discover2: {
        background: theme.palette.background.primary.primary50,
        border: `1px solid ${theme.palette.border.primary.primary200}`,
        width: '580px',
        marginTop: '130px',
        height: '493px',
    },
    discover3: {
        background: theme.palette.background.warning.warning50,
        border: `1px solid ${theme.palette.border.warning.warning200}`,
        width: '580px',
        marginTop: '-70px',
        height: '493px',
    },
    discover4: {
        background: theme.palette.background.neutral.neutral50,
        border: `1px solid ${theme.palette.border.neutral.neutral200}`,
        width: '580px',
        marginTop: '70px',
        height: '493px',
    },
    discover5: {
        background: theme.palette.background.success.success50,
        border: `1px solid ${theme.palette.border.success.success200}`,
        width: '580px',
        marginTop: '-70px',
        height: '493px',
    },
    discover6: {
        backgroundColor: '#FFEFE6',
        border: '1px solid #FEC09A',
        width: '580px',
        marginTop: '70px',
        height: '493px',
    },
    discoverCard01: {
        border: `1px solid ${theme.palette.border.secondary.secondary200}`,
    },
    discoverCard02: {
        border: `1px solid ${theme.palette.border.primary.primary200}`,
    },
    discoverCard03: {
        border: `1px solid ${theme.palette.border.warning.warning400}`,
    },
    discoverCard04: {
        border: `1px solid ${theme.palette.border.neutral.neutral200}`,
    },
    discoverCard05: {
        border: `1px solid ${theme.palette.border.success.success400}`,
    },
    discoverCard06: {
        border: '1px solid #FEC09A',
    },
    discoverCard1: {
        width: '367px',
        minHeight: '139px',
        background: '#fff',
        position: 'relative',
        right: '-40px',
        "& h4": {
            color: '#000',
            fontSize: '22px',
        },
        "& p": {
            color: '#000',
            lineHeight: '26px',
        },
    },
    discoverText: {
        fontSize: '32px',
        lineHeight: '38px',
    },
    discoverContent: {
        position: 'relative',
        left: '-40px',
    },
    discoverCard2: {
        marginRight: '0px',
        background: '#fff',
        "& h4": {
            color: '#000',
            fontSize: '22px',
        },
        "& p": {
            color: '#000',
            lineHeight: '26px',
        },
    },
    outlineCommon: {
        color: 'transparent',
        WebkitTextStroke: '2px black',
        fontSize: '150px',
        fontWeight: '800',
        lineHeight: '120px',
    },
    outline: {
        textStroke: `4px ${theme.palette.text.secondary.secondary400}`,
    },
    outline2: {
        textStroke: `1px ${theme.palette.text.primary.primary400}`,
    },
    outline3: {
        textStroke: `1px ${theme.palette.text.warning.warning400}`,
    },
    outline4: {
        textStroke: `1px ${theme.palette.text.neutral.neutral400}`,
    },
    outline5: {
        textStroke: `1px ${theme.palette.text.success.success400}`,
    },
    outline6: {
        textStroke: '1px #FD8235'
    },
    intoContainer: {
        backgroundColor: theme.palette.background.neutral.neutral50,
    },
    primaryText: {
        fontSize: '54px',
        color: '#000',
        lineHeight: '66px',
    },
    decContainer: {
        width: '750px',
        "& p": {
            color: '#000',
            lineHeight: '26px',
            fontWeight: '400',
        },
    },
    featuresText: {
        width: '651px',
        "& h5": {
            color: '#000',
            fontSize: '22px',
            lineHeight: '26px',
        },
        "& p": {
            color: theme.palette.text.neutral.neutral800,
            lineHeight: '26px',
        },
    },
    cardTop1: {
        background: '#FD8235',
        color: '#fff',
        borderRadius: '15px 15px 0px 0px',
        margin: '0 auto',
    },
    cardTop2: {
        background: theme.palette.background.complementary.complementary400,
        color: '#fff',
        borderRadius: '15px 15px 0px 0px',
        margin: '0 auto',
    },
    cardTop3: {
        background: theme.palette.background.primary.primary500,
        color: '#fff',
        borderRadius: '15px 15px 0px 0px',
        margin: '0 auto',
    },
    bussinessCard: {
        boxShadow: '2px 2px 15px 0px rgba(0, 0, 0, 0.12)',
        overflow: 'hidden',
        "& h5": {
            fontSize: '40px',
        },
        "& p": {

        }
    },
    cardBottom1: {
        borderRadius: '0px !important',
    },
    procureCardHeaidng1: {
        "& span": {
            color: theme.palette.text.complementary.complementary400,
        },
    },
    procureCardHeaidng2: {
        "& span": {
            color: theme.palette.text.primary.primary500,
        },
    },
    procureCardHeaidng3: {
        "& span": {
            color: '#FD8235',
        },
    },
    ProcurementSectionHeading: {
        fontSize: '54px',
        lineHeight: '66px',
        color: '#000',
        "& span": {
            color: theme.palette.text.primary.primary500,
        },
    },
    procurementSubHeading: {
        color: theme.palette.text.neutral.neutral600,
        lineHeight: '28px',

    },
    buyersContainer: {
        background: theme.palette.background.primary.primary500,
    },
    btn3: {
        background: '#fff',
        color: '#fff',
        fontSize: '18px',
        fontWeight: '500',
        padding: '10px 20px',
        lineHeight: '22px',
        width: '292px !important',
    },
    btnArrow2: {
        border: '1px solid transparent',
        background: theme.palette.background.primary.primary500,
        borderRadius: '50%',
        height: '32px',
        width: '32px',
    },
    btnText: {
        color: theme.palette.text.primary.primary500,
    },
    buyerContainer: {
        "& h2": {
            color: theme.palette.text.warning.warning400,
            lineHeight: '58px',
        },
        "& p": {
            color: theme.palette.text.primary.primary50,
            fontSize: '32px',
            lineHeight: '38px',
        },
    },
}));

const cardData = [
    {
        img: reach,
        title: "Wide Range of Products",
        title2: "Products",
        description:
            "Access a comprehensive catalogue of steel products from reputed manufacturers, tailored to diverse industry needs.",
    },
    {
        img: financing,
        title: "Competitive and",
        title2: "Real-time Pricing",
        description:
            "Benefit from transparent real-time pricing & auction options to secure the best deals in the market..",
    },
    {
        img: Vendorinventory,
        title: "Verified Quality ",
        title2: "Assurance",
        description:
            "Purchase from trusted suppliers with certifications and quality guarantees, ensuring project reliability.",
    },
    {
        img: logistics,
        title: "Global Sourcing ",
        title2: "Opportunities",
        description:
            "Expand your options by sourcing steel from international markets, ensuring access to the best products worldwide.",
    },
    {
        img: bulk,
        title: "Customization ",
        title2: "Options",
        description:
            "Get steel products tailored to your specifications with customization services for grades, sizes, & finishes.",
    },
    {
        img: stategy,
        title: "End-to-End Digital",
        title2: "Features",
        description:
            "Experience a seamless buying process with features like digital storefronts, real-time tracking, instant quotations, and integrated order management.",
    },
    {
        img: longPartnership,
        title: "Integrated Financing ",
        title2: "Solutions",
        description:
            "Unlock flexible financing options like trade credit and working capital support to simplify procurement, even with tight budgets.",
    },
    {
        img: programs,
        title: "Streamlined Logistics &",
        title2: "Timely Deliveries",
        description:
            "Enjoy hassle-free logistics solutions with reliable delivery timelines to keep your projects on schedule.",
    },
    {
        img: visibililty,
        title: "Market Insights with SB ",
        title2: "SteelIntel",
        description:
            "Leverage real-time analytics and market trends to make informed purchasing decisions and optimise your costs.",
    },
    {
        img: visibililty,
        title: "Access to Exclusive Mill",
        title2: "Offers & Discounts",
        description:
            "Save more with direct access to mill promotions, bulk discounts, and seasonal offers available only on Steelbazaar",
    },
    {
        img: visibililty,
        title: "Simplified Bulk Order ",
        title2: "Management",
        description:
            "Manage bulk steel requirements effortlessly with our centralized procurement system, designed to handle bulk orders for infrastructure, construction, and manufacturing projects.",
    },
    {
        img: visibililty,
        title: "Direct Buyer-Seller",
        title2: "Connections",
        description:
            "Eliminate intermediaries and build long-term relationships with verified suppliers, ensuring better pricing, transparency, and trust in every transaction",
    },
];

const CustomerBuyer: React.FC = () => {
    const classes = useStyles();
    const location = useLocation();
    
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const component = params.get('component');

        if (component) {
            const element = document.getElementById(component);
            if (element) {
                element.scrollIntoView();
            }
        }
    }, [location.search]);

    return (
        <>
            <div className={`${classes.bannerContainer}`}>
                <div className={`${classes.container} ${classes.bannerSellContainer}`}>
                    <h1 className={`${classes.heading} font-semibold`}>Transforming Your <br /><span>Steel Sourcing</span><br />Experience.</h1>
                    <p className={`${classes.bannerDescription} text-2xl font-medium mt-4 mb-4`}>Explore our digital platform for customized<br />steel solutions, flexible finance options,<br />and real-time tracking.</p>
                    <a href="#" className={`${classes.bannerBtn} flex gap-[10px] rounded-lg font-bold items-center justify-center text-base`}>Start Buying Now<div className={classes.btnArrow}><ArrowForwardIcon /></div></a>
                </div>
            </div>
            <div className={`${classes.intoContainer}`}>
                <div className={`${classes.container} flex items-center gap-[60px] py-14`}>
                    <div className=''>
                        <p className={`${classes.primaryText} font-medium`}>Smart buying, <br /> Maximum Savings</p>
                    </div>
                    <div className={classes.decContainer}>
                        <p className='text-lg mb-[15px]'>Steelbazaar is India's premier digital platform designed to simplify and streamline steel procurement for buyers of all scales. Whether you need raw materials, semi-finished or finished steel products, Steelbazaar connects you with verified suppliers, delivers competitive pricing, and provides unmatched convenience through our digital solutions.
                        </p>

                    </div>
                </div>
            </div>
            <div className={classes.cardsContainer} id='features'>
                <div className={`${classes.container} pt-[110px] pb-[110px]`}>
                    <div>
                        <h2
                            className={`${classes.sectionHeading} font-semibold text-center`}
                        >
                            Why Buy from SteelBazaar?
                        </h2>
                        <p className='text-center font-medium'>"One-Stop Solution for All Your Steel Needs"</p>
                    </div>
                    <div className="flex gap-x-[30px] gap-y-[36px] flex-wrap mt-[36px]">
                        {cardData.map((card, index) => (
                            <div key={index} className={`${classes.card} rounded-lg`}>
                                <div
                                    className={`${classes.cardIconContainer} flex items-center justify-center p-[10px] w-[82px] h-[82px] rounded-2xl`}
                                >
                                    <img src={card.img} alt={card.title} />
                                </div>
                                <h4 className="mt-[10px] font-semibold ">{card.title}</h4>
                                <h4 className="font-semibold mb-[10px]">{card.title2}</h4>

                                <p className="text-base leading-5">{card.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className={`${classes.container} pb-[110px]`} id='Registration'>
                <h2 className={`${classes.sectionHeading} font-semibold text-center`}>How to Buy on SteelBazaar?</h2>
                <p className={`${classes.subHeading} text-center text-2xl font-medium mt-[10px]`}>"Step-by-Step Buying Process"</p>
                <div className='flex gap-[60px] items-center'>
                    <div className='w-[700px]'>
                        <div className={`${classes.featuresContainer} `}>
                            <p className='text-2xl font-medium'>Step 01:</p>
                            <h4 className='mt-[6px] mb-[15px] text-5xl font-bold'>Easy Registration <br /><span className={`${classes.headingText} text-5xl font-bold`}>Create Your Account</span></h4>
                        </div>
                        <div className={`${classes.featureInfo}`}>
                            <p className='text-base'>Set up your account to begin your journey with SteelBazaar. Provide basic information about your business and create a secure profile.</p>
                            <div>
                                <div className='flex gap-[15px] mt-[15px] items-center'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Visit Steelbazaar.com and click on Sign Up.</h5>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Fill out basic details such as Name, Company, GST No. , Mobile No. , Email ID and Create a password.</h5>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Once registered, you’ll have access to Steelbazaar’s dashboard.</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div><img src={register} alt="" /></div>
                </div>
            </div>
            <div className={`${classes.primaryContainer} pt-[110px] pb-[50px]`}>
                <div className={`${classes.container}`}>
                    <div className={`${classes.featuresContainer} ${classes.container}`}>
                        <p className='text-2xl font-medium'>Step 02:</p>
                        <h4 className='mt-[6px] text-5xl font-bold'>Complete your Customer <span className={`${classes.headingText} text-5xl font-bold`}>Profile</span></h4>
                        <div className={`${classes.featureInfo}`}>
                            <h4 className='text-2xl font-medium'>Add your business details to optimise purchasing and Specify sourcing preferences for tailored offers.</h4>
                        </div>
                        <div className='flex gap-5 mt-[50px]'>
                            <div className={`${classes.profileCard1} w-1/5 pb-5 rounded-lg`}>
                                <div className={`${classes.profileCardHeader1} mb-5 px-[15px] py-2 font-semibold`}>Section 1</div>
                                <p className='px-[15px] font-medium text-lg'>Company Details,<br />Business Type</p>
                            </div>
                            <div className={`${classes.profileCard2} w-1/5 pb-5 rounded-lg`}>
                                <div className={`${classes.profileCardHeader2} mb-5 px-[15px] py-2 font-semibold`}>Section 2</div>
                                <p className='px-[15px] font-medium text-lg'>Key Person Details & Upload Relevant Documents.</p>
                            </div>
                            <div className={`${classes.profileCard3} w-1/5 pb-5 rounded-lg`}>
                                <div className={`${classes.profileCardHeader3} mb-5 px-[15px] py-2 font-semibold`}>Section 3</div>
                                <p className='px-[15px] font-medium text-lg'>Statutory Details of the Company</p>
                            </div>
                            <div className={`${classes.profileCard4} w-1/5 pb-5 rounded-lg`}>
                                <div className={`${classes.profileCardHeader4} mb-5 px-[15px] py-2 font-semibold`}>Section 4</div>
                                <p className='px-[15px] font-medium text-lg'>Provide your Steel Product Requirements</p>
                            </div>
                            <div className={`${classes.profileCard5} w-1/5 pb-5 rounded-lg`}>
                                <div className={`${classes.profileCardHeader5} mb-5 px-[15px] py-2 font-semibold`}>Section 5</div>
                                <p className='px-[15px] font-medium text-lg'>Set your Detailed Preferences.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${classes.container} flex gap-[60px] items-center pt-[48px]`}>
                <div className='w-[700px]'>
                    <div className={`${classes.featuresContainer} `}>
                        <p className='text-2xl font-medium'>Step 03:</p>
                        <h4 className='mt-[6px] mb-[15px] text-5xl font-bold'>Access the <br /><span className={`${classes.headingText} text-5xl font-bold`}>Comprehensive Dashboard</span></h4>
                    </div>
                    <div className={`${classes.featureInfo}`}>
                        <h4 className='text-2xl font-semibold'>Your Control Center for Efficient Purchasing</h4>
                        <p className='text-base'>The SteelBazaar dashboard offers a centralised hub for managing your profile, tracking quotations and orders, viewing catalogues, communicating with sellers via the message centre, analysing data, participating in auctions, and accessing support.</p>
                        <div>
                            <div className='flex gap-[15px] mt-[15px] items-center'>
                                <img src={roudTickIcon} alt="" />
                                <div className={classes.featuresText}>
                                    <h5 className='font-medium mb-1'>Order Tracking</h5>
                                    <p className='text-lg'>Monitor orders from placement to delivery.</p>
                                </div>
                            </div>
                            <div className='flex gap-[15px] mt-[15px] items-center'>
                                <img src={roudTickIcon} alt="" />
                                <div className={classes.featuresText}>
                                    <h5 className='font-medium mb-1'>Catalog Access</h5>
                                    <p className='text-lg'>Browse available products and suppliers.</p>
                                </div>
                            </div>
                            <div className='flex gap-[15px] mt-[15px] items-center'>
                                <img src={roudTickIcon} alt="" />
                                <div className={classes.featuresText}>
                                    <h5 className='font-medium mb-1'>Real-Time Notifications</h5>
                                    <p className='text-lg'>Stay updated with alerts and status updates.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div><img src={dashboard} alt="" /></div>
            </div>
            <div className={`${classes.container} flex gap-[60px] items-center pt-[48px] pb-[110px]`}>
                <div><img src={filterOptions} alt="" /></div>
                <div className='w-[700px]'>
                    <div className={`${classes.featuresContainer} `}>
                        <p className='text-2xl font-medium'>Step 04:</p>
                        <h4 className='mt-[6px] mb-[15px] text-5xl font-bold'>Advanced Search &<br /><span className={`${classes.headingText} text-5xl font-bold`}>Filter Options</span></h4>
                    </div>
                    <div className={`${classes.featureInfo}`}>
                        <h4 className='text-2xl font-semibold'>Find Exactly What You Need Quickly</h4>
                        <p className='text-base'>Use SteelBazaar’s advanced search and filter tools to find products based on specific criteria, such as size, grade, price, and location.</p>
                        <div>
                            <div className='flex gap-[15px] mt-[15px] items-center'>
                                <img src={roudTickIcon} alt="" />
                                <div className={classes.featuresText}>
                                    <h5 className='font-medium mb-1'>Detailed Filters</h5>
                                    <p className='text-lg'>Narrow results by specifications, grade, and more.</p>
                                </div>
                            </div>
                            <div className='flex gap-[15px] mt-[15px] items-center'>
                                <img src={roudTickIcon} alt="" />
                                <div className={classes.featuresText}>
                                    <h5 className='font-medium mb-1'>Quick Results</h5>
                                    <p className='text-lg'>Get accurate results with minimal effort.</p>
                                </div>
                            </div>
                            <div className='flex gap-[15px] mt-[15px] items-center'>
                                <img src={roudTickIcon} alt="" />
                                <div className={classes.featuresText}>
                                    <h5 className='font-medium mb-1'>Save Time</h5>
                                    <p className='text-lg'>Efficiently find the exact products you need.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${classes.primaryContainer} pt-[110px] pb-[50px]`} id='catalogue'>
                <div className={`${classes.container}`}>
                    <div className={`${classes.featuresContainer} ${classes.container}`}>
                        <p className='text-2xl font-medium'>Step 05:</p>
                        <h4 className='mt-[6px] text-5xl font-bold mb-[54px]'>Explore Digital Catalogs for Smart Steel Buying</h4>
                    </div>
                    <div className='flex gap-[60px] items-center'>
                        <div>
                            <img src={catalogStatic} alt="" />
                        </div>
                        <div className='flex flex-wrap gap-5 w-2/3'>
                            <div className={`${classes.catalogsContainer} px-[15px] py-5 rounded-lg`}>
                                <h5 className='font-semibold mb-3'>Comprehensive Product Listings</h5>
                                <p className='text-base'>Showcase a wide range of steel products with detailed specifications.</p>
                            </div>
                            <div className={`${classes.catalogsContainer} px-[15px] py-5 rounded-lg`}>
                                <h5 className='font-semibold mb-3'>Customization Services</h5>
                                <p className='text-base'>Offer customizable steel products (size, grade, specifications) from trusted manufacturers.</p>
                            </div>
                            <div className={`${classes.catalogsContainer} px-[15px] py-5 rounded-lg`}>
                                <h5 className='font-semibold mb-3'>Real-Time Pricing Updates</h5>
                                <p className='text-base'>Display Current prices as per market trends, and helping buyers make informed decisions.</p>
                            </div>
                            <div className={`${classes.catalogsContainer} px-[15px] py-5 rounded-lg`}>
                                <h5 className='font-semibold mb-3'>Latest Mill Offers and Discounts</h5>
                                <p className='text-base'>Latest Mill Offers and Discounts</p>
                            </div>
                            <div className={`${classes.catalogsContainer} px-[15px] py-5 rounded-lg`}>
                                <h5 className='font-semibold mb-3'>Customizable Filters</h5>
                                <p className='text-base'>Users can search and filter products by grade, size, type, supplier, and location for quick access.</p>
                            </div>
                            <div className={`${classes.catalogsContainer} px-[15px] py-5 rounded-lg`}>
                                <h5 className='font-semibold mb-3'>Integrated Finance Services</h5>
                                <p className='text-base'>Finance options like trade credit and SB Instafin for easy purchasing with limited cash flow.</p>
                            </div>
                            <div className={`${classes.catalogsContainer} px-[15px] py-5 rounded-lg`}>
                                <h5 className='font-semibold mb-3'>Verified Supplier Information</h5>
                                <p className='text-base'>Ensure transparency with supplier profiles, including certifications and ratings.</p>
                            </div>
                            <div className={`${classes.catalogsContainer} px-[15px] py-5 rounded-lg`}>
                                <h5 className='font-semibold mb-3'>Logistics Services</h5>
                                <p className='text-base'>Allow buyers to select logistics solutions directly for easy procurement and delivery.</p>
                            </div>
                            <div className={`${classes.catalogsContainer} px-[15px] py-5 rounded-lg`}>
                                <h5 className='font-semibold mb-3'>High-Quality Visuals</h5>
                                <p className='text-base'>Include product images, datasheets, and certificates for buyer assurance.</p>
                            </div>
                            <div className={`${classes.catalogsContainer} px-[15px] py-5 rounded-lg`}>
                                <h5 className='font-semibold mb-3'>Comparison with Other Brands</h5>
                                <p className='text-base'>Comparisons of products, prices, & features from multiple suppliers to help buyers find the best fit.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`${classes.container} flex gap-[60px] items-center pt-[48px] pb-[48px]`}>
                <div><img src={brandChoice} alt="" /></div>
                <div className='w-[700px]'>
                    <div className={`${classes.featuresContainer} `}>
                        <p className='text-2xl font-medium'>Step 06:</p>
                        <h4 className='mt-[6px] mb-[15px] text-5xl font-semibold'>Compare Top Brands for<br /><span className={`${classes.headingText} text-5xl`}>The Best Choice</span></h4>
                    </div>
                    <div className={`${classes.featureInfo}`}>
                        <h4 className='text-2xl font-semibold'>Make Informed Decisions with Product Comparisons</h4>
                        <p className='text-base'>Description: SteelBazaar’s comparison tool helps you evaluate brands and products side by side, ensuring you choose the best options for your needs.</p>
                        <div>
                            <div className='flex gap-[15px] mt-[15px] items-center'>
                                <img src={roudTickIcon} alt="" />
                                <div className={classes.featuresText}>
                                    <h5 className='font-medium mb-1'>Side-by-Side Comparison</h5>
                                    <p className='text-lg'>See product specs and pricing in one view.</p>
                                </div>
                            </div>
                            <div className='flex gap-[15px] mt-[15px] items-center'>
                                <img src={roudTickIcon} alt="" />
                                <div className={classes.featuresText}>
                                    <h5 className='font-medium mb-1'>Quality and Value</h5>
                                    <p className='text-lg'>Compare brands to find the best quality and price.</p>
                                </div>
                            </div>
                            <div className='flex gap-[15px] mt-[15px] items-center'>
                                <img src={roudTickIcon} alt="" />
                                <div className={classes.featuresText}>
                                    <h5 className='font-medium mb-1'>Quick Decision-Making</h5>
                                    <p className='text-lg'>Save time by assessing options at a glance.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`${classes.container} flex gap-[60px] items-center pb-[48px]`}>
                <div className='w-[700px]'>
                    <div className={`${classes.featuresContainer} `}>
                        <p className='text-2xl font-medium'>Step 07:</p>
                        <h4 className='mt-[6px] mb-[15px] text-5xl font-bold'>Get Instant Quotations
                            for<br /><span className={`${classes.headingText} text-5xl`}>Quick Pricing</span></h4>
                    </div>
                    <div className={`${classes.featureInfo}`}>
                        <p className='text-base'>Request and receive instant product quotations to make timely purchasing decisions based on current market rates.</p>
                        <div>
                            <div className='flex gap-[15px] mt-[15px] items-center'>
                                <img src={roudTickIcon} alt="" />
                                <div className={classes.featuresText}>
                                    <h5 className='font-medium mb-1'>Fast Quotes</h5>
                                    <p className='text-lg'>Get real-time pricing with just a click.</p>
                                </div>
                            </div>
                            <div className='flex gap-[15px] mt-[15px] items-center'>
                                <img src={roudTickIcon} alt="" />
                                <div className={classes.featuresText}>
                                    <h5 className='font-medium mb-1'>Accurate Market Rates</h5>
                                    <p className='text-lg'>Always know the current market pricing.</p>
                                </div>
                            </div>
                            <div className='flex gap-[15px] mt-[15px] items-center'>
                                <img src={roudTickIcon} alt="" />
                                <div className={classes.featuresText}>
                                    <h5 className='font-medium mb-1'>Convenient Access</h5>
                                    <p className='text-lg'>View quotations directly on your dashboard.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div><img src={pricing} alt="" /></div>
            </div>


            <div className={`${classes.container} flex gap-[60px] items-center pb-[48px]`}>
                <div><img src={filterOptions} alt="" /></div>
                <div className='w-[700px]'>
                    <div className={`${classes.featuresContainer} `}>
                        <p className='text-2xl font-medium'>Step 08:</p>
                        <h4 className='mt-[6px] mb-[15px] text-5xl font-bold'>Place an Order <br />
                            with <span className={`${classes.headingText} text-5xl font-bold`}>Ease</span></h4>
                    </div>
                    <div className={`${classes.featureInfo}`}>

                        <div>
                            <div className='flex gap-[15px] mt-[15px] items-center'>
                                <img src={roudTickIcon} alt="" />
                                <div className={classes.featuresText}>
                                    <h5 className='font-medium mb-1'>Once you receive quotes, review them and choose the best option.</h5>
                                </div>
                            </div>
                            <div className='flex gap-[15px] mt-[15px] items-center'>
                                <img src={roudTickIcon} alt="" />
                                <div className={classes.featuresText}>
                                    <h5 className='font-medium mb-1'>Add selected products to your cart and proceed to Checkout.</h5>
                                </div>
                            </div>
                            <div className='flex gap-[15px] mt-[15px] items-center'>
                                <img src={roudTickIcon} alt="" />
                                <div className={classes.featuresText}>
                                    <h5 className='font-medium mb-1'>Review the shipping info for the order details and confirm your purchase.</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className={`${classes.container} flex gap-[60px] items-center `}>
                <div className='w-[700px]'>
                    <div className={`${classes.featuresContainer} `}>
                        <p className='text-2xl font-medium'>Step 09:</p>
                        <h4 className='mt-[6px] mb-[15px] text-5xl font-semibold'>Digital Order
                            <br /><span className={`${classes.headingText} text-5xl`}>Tracking for Transparency</span></h4>
                    </div>
                    <div className={`${classes.featureInfo}`}>
                        <h4 className='text-2xl font-semibold'>Track Your Orders from Placement to Delivery</h4>
                        <p className='text-base'>Use SteelBazaar’s digital order tracking to monitor each purchase step, from processing to shipping and delivery.</p>
                        <div>
                            <div className='flex gap-[15px] mt-[15px] items-center'>
                                <img src={roudTickIcon} alt="" />
                                <div className={classes.featuresText}>
                                    <h5 className='font-medium mb-1'>After placing the order, go to your Dashboard to track your orders.</h5>
                                </div>
                            </div>
                            <div className='flex gap-[15px] mt-[15px] items-center'>
                                <img src={roudTickIcon} alt="" />
                                <div className={classes.featuresText}>
                                    <h5 className='font-medium mb-1'>Receive real-time updates on order status: processing, shipping, and delivery.</h5>
                                </div>
                            </div>
                            <div className='flex gap-[15px] mt-[15px] items-center'>
                                <img src={roudTickIcon} alt="" />
                                <div className={classes.featuresText}>
                                    <h5 className='font-medium mb-1'>Access invoices, delivery schedules, and tracking numbers in one place.</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div><img src={dashboard} alt="" /></div>
            </div>

            <div className={`${classes.container} pt-[110px]`} id='features'>
                <h2
                    className={`${classes.sectionHeading} font-semibold text-center mb-[60px]`}
                >
                    Discover SteelBazaar’s Exclusive Features
                </h2>
                <div className='flex gap-[86px]'>
                    <div className={`${classes.discover1} p-5 flex gap-5 rounded-lg`}>
                        <div className='w-max flex flex-col justify-between'>
                            <h4 className={`${classes.discoverText} font-semibold`}>Participate in Auctions & Get Best Deals</h4>
                            <h1 className={`${classes.outline} ${classes.outlineCommon}`}>01</h1>
                        </div>
                        <div className={`${classes.discoverContent} flex flex-col gap-4`}>
                            <div className={`${classes.discoverCard1} ${classes.discoverCard01} rounded-lg p-[15px]`}>
                                <h4 className='font-semibold mb-1'>Save on Procurement Costs</h4>
                                <p className='text-lg'>Benefit from reduced procurement costs by winning auctions for your specific steel requirements.</p>
                            </div>
                            <div className={`${classes.discoverCard2} ${classes.discoverCard01} rounded-lg p-[15px]`}>
                                <h4 className='font-semibold mb-1'>Exclusive Bulk Deals</h4>
                                <p className='text-lg'>Access inventory from vendors looking to sell large volumes at competitive rates.</p>
                            </div>
                            <div className={`${classes.discoverCard1} ${classes.discoverCard01} rounded-lg p-[15px]`}>
                                <h4 className='font-semibold mb-1'>Real-Time Bidding</h4>
                                <p className='text-lg'>Participate in live auctions Place competitive bids to get high-quality materials at bargain prices.</p>
                            </div>
                        </div>
                    </div>
                    <div className={`${classes.discover2} p-5 flex gap-5 rounded-lg`}>
                        <div className='w-max flex flex-col justify-between'>
                            <h4 className={`${classes.discoverText} font-semibold`}>Flexible Finance Terms for Purchases</h4>
                            <h1 className={`${classes.outline2} ${classes.outlineCommon}`}>02</h1>
                        </div>
                        <div className={`${classes.discoverContent} flex flex-col gap-4`}>
                            <div className={`${classes.discoverCard1} ${classes.discoverCard02} rounded-lg p-[15px]`}>
                                <h4 className='font-semibold mb-1'>Financing Solutions</h4>
                                <p className='text-lg'>Defer payments while maintaining smooth operations and production schedules.</p>
                            </div>
                            <div className={`${classes.discoverCard2} ${classes.discoverCard02} rounded-lg p-[15px]`}>
                                <h4 className='font-semibold mb-1'>Pay Later Options</h4>
                                <p className='text-lg'>Defer payments while maintaining smooth operations and production schedules.</p>
                            </div>
                            <div className={`${classes.discoverCard1} ${classes.discoverCard02} rounded-lg p-[15px]`}>
                                <h4 className='font-semibold mb-1'>Credit Facilities</h4>
                                <p className='text-lg'>Secure credit terms to buy steel products without upfront payments, helping you optimise working capital</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex gap-[86px]'>
                    <div className={`${classes.discover3} p-5 flex gap-5 rounded-lg`}>
                        <div className='w-[229px] flex flex-col justify-between'>
                            <h4 className={`${classes.discoverText} font-semibold`}>Connect Directly <br />
                                with Top Manufacturers</h4>
                            <h1 className={`${classes.outline3} ${classes.outlineCommon}`}>03</h1>
                        </div>
                        <div className={`${classes.discoverContent} flex flex-col gap-4`}>
                            <div className={`${classes.discoverCard1} ${classes.discoverCard03} rounded-lg p-[15px]`}>
                                <h4 className='font-semibold mb-1'>Browse verified manufacturers for raw materials and finished products</h4>
                            </div>
                            <div className={`${classes.discoverCard2} ${classes.discoverCard03} rounded-lg p-[15px]`}>
                                <h4 className='font-semibold mb-1'>Secure competitive rates through direct negotiation</h4>
                            </div>
                            <div className={`${classes.discoverCard1} ${classes.discoverCard03} rounded-lg p-[15px]`}>
                                <h4 className='font-semibold mb-1'>Gain access to exclusive, high-quality steel products from around the world</h4>
                            </div>
                        </div>
                    </div>
                    <div className={`${classes.discover4} p-5 flex gap-5 rounded-lg`}>
                        <div className='w-max flex flex-col justify-between'>
                            <h4 className={`${classes.discoverText} font-semibold`}>Stay Competitive with Targeted Buy Leads</h4>
                            <h1 className={`${classes.outline4} ${classes.outlineCommon}`}>04</h1>
                        </div>
                        <div className={`${classes.discoverContent} flex flex-col gap-4`}>
                            <div className={`${classes.discoverCard1} ${classes.discoverCard04} rounded-lg p-[15px]`}>
                                <h4 className='font-semibold mb-1'>Personalised Lead Matching</h4>
                                <p className='text-lg'>Leads tailored to your sourcing needs.</p>
                            </div>
                            <div className={`${classes.discoverCard2} ${classes.discoverCard04} rounded-lg p-[15px]`}>
                                <h4 className='font-semibold mb-1'>Direct Access to Suppliers</h4>
                                <p className='text-lg'>Connect with verified suppliers directly.</p>
                            </div>
                            <div className={`${classes.discoverCard1} ${classes.discoverCard04} rounded-lg p-[15px]`}>
                                <h4 className='font-semibold mb-1'>Increased Purchase Opportunities</h4>
                                <p className='text-lg'>Access new buying options regularly.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex gap-[86px]'>
                    <div className={`${classes.discover5} p-5 flex gap-5 rounded-lg`}>
                        <div className='w-[229px] flex flex-col justify-between'>
                            <h4 className={`${classes.discoverText} font-semibold`}>Make Smart Choices with Up-to-Date Market Information</h4>
                            <h1 className={`${classes.outline5} ${classes.outlineCommon}`}>05</h1>
                        </div>
                        <div className={`${classes.discoverContent} flex flex-col gap-4`}>
                            <div className={`${classes.discoverCard1} ${classes.discoverCard05} rounded-lg p-[15px]`}>
                                <h4 className='font-semibold mb-1'>Real-Time Market News</h4>
                                <p className='text-lg'>Stay on top of industry changes.</p>
                            </div>
                            <div className={`${classes.discoverCard2} ${classes.discoverCard05} rounded-lg p-[15px]`}>
                                <h4 className='font-semibold mb-1'>Comprehensive Reports</h4>
                                <p className='text-lg'>Access in-depth reports for better planning.</p>
                            </div>
                            <div className={`${classes.discoverCard1} ${classes.discoverCard05} rounded-lg p-[15px]`}>
                                <h4 className='font-semibold mb-1'>Actionable Insights</h4>
                                <p className='text-lg'>Gain insights into trends for strategic decisions</p>
                            </div>
                        </div>
                    </div>
                    <div className={`${classes.discover6} p-5 flex gap-5 rounded-lg`}>
                        <div className='w-max flex flex-col justify-between'>
                            <h4 className={`${classes.discoverText} font-semibold`}>Dedicated Customer Support & After-Sales assistance</h4>
                            <h1 className={`${classes.outline6} ${classes.outlineCommon}`}>06</h1>
                        </div>
                        <div className={`${classes.discoverContent} flex flex-col gap-4`}>
                            <div className={`${classes.discoverCard1} ${classes.discoverCard06} rounded-lg p-[15px]`}>
                                <h4 className='font-semibold mb-1'>24/7 customer support</h4>

                            </div>
                            <div className={`${classes.discoverCard2} ${classes.discoverCard06} rounded-lg p-[15px]`}>
                                <h4 className='font-semibold mb-1'>Detailed FAQs and video tutorials for self-service</h4>

                            </div>
                            <div className={`${classes.discoverCard1} ${classes.discoverCard06} rounded-lg p-[15px]`}>
                                <h4 className='font-semibold mb-1'>Personalised assistance to ensure you have a seamless experience</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${classes.container} py-[110px]`}>
                <h2 className={`${classes.ProcurementSectionHeading} font-semibold text-center`}>
                    Tailored Procurement Solutions <br /><span>for Every Business</span></h2>
                <p className={`${classes.procurementSubHeading} text-2xl font-semibold text-center mt-1`}>Smarter, Faster, Seamless</p>
                <div className='mt-[50px] flex gap-[30px]'>
                    <div>
                        <div className={`${classes.cardTop1} py-[10px] w-[325px] text-center font-semibold text-2xl `}>SMEs & Micro</div>
                        <div className={`${classes.bussinessCard} h-[682px] rounded-2xl relative`}>
                            <img src={iProcure} alt="" />
                            <div className='px-[15px] '>
                                <h5 className={`${classes.procureCardHeaidng3} my-3 font-semibold`}><span>i</span>Procure</h5>
                                <p className='flex items-center gap-3 mb-5'><img src={orangeTick} alt="" /> RM Sourcings: eBidding Based</p>
                                <p className='flex items-center gap-3'><img src={orangeTick} alt="" /> Inventory Management</p>
                            </div>
                            <div className={`${classes.cardTop1} ${classes.cardBottom1} py-[10px] w-full text-center font-semibold text-lg absolute bottom-0 flex gap-[10px] items-center justify-center`}>Explore More <div className={classes.btnArrow}><ArrowForwardIcon /></div></div>

                        </div>
                    </div>
                    <div>
                        <div className={`${classes.cardTop2} py-[10px] w-[325px] text-center font-semibold text-2x `}>Mid Corporate & MSMEs</div>
                        <div className={`${classes.bussinessCard} h-[682px] rounded-2xl relative`}>
                            <img src={procurePlus} alt="" />
                            <div className='px-[15px] '>
                                <h5 className={`${classes.procureCardHeaidng1} my-3 font-semibold`}>iProcure <span>Plus</span></h5>
                                <div className='flex flex-col gap-5'>
                                    <p className='flex items-center gap-3'><img src={blueTick} alt="" />RM Sourcings: eBidding Based</p>
                                    <p className='flex items-center gap-3'><img src={blueTick} alt="" /> Inventory Management</p>
                                    <p className='flex items-center gap-3'><img src={blueTick} alt="" /> Supply Chain Finance</p>
                                    <p className='flex items-center gap-3'><img src={blueTick} alt="" /> Logistics</p>
                                    <p className='flex items-center gap-3'><img src={blueTick} alt="" /> Performance Metrics based Dashboard</p>
                                </div>
                            </div>
                            <div className={`${classes.cardTop2} ${classes.cardBottom1} py-[10px] w-full text-center font-semibold text-lg absolute bottom-0 flex gap-[10px] items-center justify-center`}>Explore More <div className={classes.btnArrow}><ArrowForwardIcon /></div></div>
                        </div>
                    </div>
                    <div>
                        <div className={`${classes.cardTop3} py-[10px] w-[325px] text-center font-semibold text-2xl `}>Enterprises</div>
                        <div className={`${classes.bussinessCard} h-[682px] rounded-2xl relative`}>
                            <img src={sbMaxCard} alt="" />
                            <div className='px-[15px] '>
                                <h5 className={`${classes.procureCardHeaidng2} my-3 font-semibold`}>SB<span> Maxx</span> </h5>
                                <div className='flex flex-col gap-5'>
                                    <p className='flex items-center gap-3'><img src={primaryBlueTick} alt="" /> Supply Chain Planning</p>
                                    <p className='flex items-center gap-3'><img src={primaryBlueTick} alt="" /> Demand and Forecasting</p>
                                    <p className='flex items-center gap-3'><img src={primaryBlueTick} alt="" /> RM Sourcings: eBidding Based</p>
                                    <p className='flex items-center gap-3'><img src={primaryBlueTick} alt="" /> Supply Chain Finance</p>
                                    <p className='flex items-center gap-3'><img src={primaryBlueTick} alt="" /> Inventory Management</p>
                                    <p className='flex items-center gap-3'><img src={primaryBlueTick} alt="" /> Logistics</p>
                                    <p className='flex items-center gap-3'><img src={primaryBlueTick} alt="" /> Performance Metrics based Dashboard</p>
                                </div>

                            </div>
                            <div className={`${classes.cardTop3} ${classes.cardBottom1} py-[10px] w-full text-center font-semibold text-lg absolute bottom-0 flex gap-[10px] items-center justify-center`}>Explore More <div className={classes.btnArrow}><ArrowForwardIcon /></div></div>

                        </div>
                    </div>
                </div>
            </div>  
            <div className={`${classes.buyersContainer} py-[50px]`}>
                <div className={classes.container}>
                    <div className='flex items-center justify-between'>
                        <div className={classes.buyerContainer}>
                            <h2 className='text-5xl font-semibold mb-5'>Join Thousands of Satisfied Buyers on <br /> Steelbazaar</h2>
                            <p className='font-semibold'>Ready to transform your steel procurement? Sign up now and <br /> experience the benefits of digital trade.</p>
                        </div>
                        <Button className={`${classes.btn3} font-bold text-base flex items-center gap-[10px] px-5 py-[10px]`}> <span className={classes.btnText}>Register as a Buyer Now</span><div className={`${classes.btnArrow2} flex items-center justify-center`}><ArrowForwardIcon /></div></Button>

                    </div>
                </div>
            </div>
            <RequestDemoTemplate />
        </>
    );
};

export default CustomerBuyer;