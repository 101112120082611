import React, { useRef, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { useLocation } from 'react-router-dom';
import sellOnSteel from '../../../assets/images/sellOnSteel.jpg';
import Button from '../../atoms/Button/Button';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import CarouselRef from 'react-multi-carousel';
import source_card_icon1 from '../../../assets/icons/source_card_icon1.jpg';
import source_card_icon2 from '../../../assets/icons/source_card_icon2.jpg';
import source_card_icon3 from '../../../assets/icons/source_card_icon3.jpg';
import source_card_icon4 from '../../../assets/icons/source_card_icon4.jpg';
import transport from '../../../assets/images/transport.jpg';
import hotRolled from '../../../assets/images/hotRolled.jpg';
import coldRolled from '../../../assets/images/coldRolled.png';
import galvanised from '../../../assets/images/galvanised.svg';
import ppgi from '../../../assets/images/ppgi.jpg';
import angle from '../../../assets/images/angle.jpg';
import channel from '../../../assets/images/channel.jpg';
import beam from '../../../assets/images/beam.jpg';
import tmt from '../../../assets/images/tmt.jpg';
import sheets from '../../../assets/images/sheets.jpg';
import parts from '../../../assets/images/parts.jpg';
import box from '../../../assets/images/box.png';
import containers from '../../../assets/images/containers.jpg';
import smart from '../../../assets/images/smart.jpg';
import Seamless from '../../../assets/images/seamless.jpg';
import Streamlined from '../../../assets/images/streamlined.jpg';
import Strategic from '../../../assets/images/strategic.jpg';
import Secure from '../../../assets/images/Secure.svg';
import Standardized from '../../../assets/images/Standardized.svg';
import Scalable from '../../../assets/images/Scalable.svg';
import Sustainability from '../../../assets/images/Sustainability.jpg';
import RequestDemoTemplate from '../../template/Home/RequestDemo/RequestDemo.template';
import rightArrow from "../../../assets/icons/rightArrow.svg";
import leftArrow from "../../../assets/icons/leftArrow.svg"
import automotiveSvg from '../../../assets/images/Automotive.jpg';
import constructionSvg from '../../../assets/images/Construction.jpg';
import transportationSvg from '../../../assets/images/Transportation.jpg';
import industrialEquipmentSvg from '../../../assets/images/IndustrialEquipment.jpg';
import energySvg from '../../../assets/images/Energy.jpg';
import agricultureSvg from '../../../assets/images/Agriculture.jpg';
import coldStorageSvg from '../../../assets/images/ColdStorage.jpg';
import foodPackagingSvg from '../../../assets/images/FoodPackaging.jpg';
import b2bBanner from '../../../assets/images/b2bBanner.jpg';
import seller from '../../../assets/images/seller.png';
import vendor from '../../../assets/images/vender.png';
import roudTickIcon from '../../../assets/icons/roudTickIcon.jpg';
import sbInstant from '../../../assets/images/sbInstant.jpg';
import sbLead from '../../../assets/images/sbLead.jpg';
import sbDigiLog from '../../../assets/images/sbDigiLog.jpg';
import sbDigitStore from '../../../assets/images/sbDigitStore.jpg';
import sbTailor from '../../../assets/images/sbTailor.jpg';
import sbCompare from '../../../assets/images/sbCompare.jpg';
import sbFinance from '../../../assets/images/sbFinance.jpg';
import sbBid from '../../../assets/images/sbBid.jpg';
import shearing2 from '../../../assets/images/shearing2.jpg';
import slitting2 from '../../../assets/images/slitting2.jpg';
import colorCoated from '../../../assets/images/colorCoated.jpg';
import profiling from '../../../assets/images/profiling.jpg';
import cnc2 from '../../../assets/images/cnc2.jpg';
import blanking from '../../../assets/images/blanking.jpg';
import dieCasting2 from '../../../assets/images/dieCasting2.jpg';
import forging2 from '../../../assets/images/forging2.jpg';
import pipesTools from '../../../assets/images/pipesTools.jpg';
import btnRightArrow from '../../../assets/icons/btnRightArrow.jpg';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const useStyles = createUseStyles((theme: any) => ({
    bannerContainer: {
        width: '100%',
        height: '650px',
        backgroundImage: `url(${b2bBanner})`,
        backgroundSize: 'cover',
        position: 'relative',
    },
    bannerBtn: {
        background: theme.palette.background.primary.primary500,
        padding: '10px 20px',
        width: '228px',
        color: '#fff',

    },
    btnArrow: {
        border: '1px solid #fff',
        borderRadius: '100%',
    },
    container: {
        width: '1297px',
        margin: '0 auto',
    },
    bannerSellContainer: {
        padding: '10% 0px',
    },
    heading: {
        fontSize: '56px',
        lineHeight: '66px',
        color: theme.palette.text.primary.primary50,
        "& span": {
            color: theme.palette.text.warning.warning400,
        },
    },
    bannerDescription: {
        color: theme.palette.text.primary.primary50,
        lineHeight: '28px',
    },
    intoContainer: {
        backgroundColor: theme.palette.background.neutral.neutral50,
    },
    primaryText: {
        fontSize: '40px',
        color: theme.palette.text.neutral.neutral400,
    },
    text: {
        fontSize: '54px',
        color: "#000",
        lineHeight: '66px',
    },
    decContainer: {
        width: '715px',
        "& p": {
            color: "#000",
            lineHeight: '26px',
        },
    },
    sectionHeading: {
        color: "#000",
        fontSize: '54px',
        lineHeight: '66px',
        "& span": {
            color: theme.palette.text.primary.primary500,
        },
        "& p": {
            color: theme.palette.text.success.success500,
        },
    },
    subHeading: {
        color: theme.palette.text.neutral.neutral600,
        lineHeight: '28px',
    },
    card: {
        border: `1px solid ${theme.palette.border.neutral.neutral200}`,
        background: '#F8F8F8',
        padding: '20px 15px',
        width: '302px',
        "& h4": {
            color: '#000',
            fontSize: '22px',
            lineHeight: '26px',
        },
        "& p": {
            lineHeight: '26px',
        },
    },
    productsCard: {
        border: `1px solid #E6E6E6`,
        width: '248px',
        height: '190px',
        "&:nth-child(1)": {
            width: '307px !important',
            background: '#FFE59A',
            borderRadius: '16px 0px 0px 16px',
        },
        "& p": {
            color: '#000',
        },
        "& h4": {
            fontSize: '28px',
            color: '#000',
            fontWeight: '500',
            lineHeight: '34px',
        },
    },
    product2: {
        background: "#D7E2FF !important",
    },
    product3: {
        background: `${theme.palette.background.success.success100} !important`,
    },
    btn: {
        minWidth: '74px',
        fontSize: '16px',
        padding: '10px',
        marginTop: '25px',
    },
    serialNumber: {
        width: '83px',
        height: '132px',

        borderRadius: '8px 0px 0px 8px',
        "& span": {
            color: '#FFF',
        }
    },
    productCardText: {
        "& h5": {
            color: '#000',
            fontSize: '32px',
            fontWeight: '600',
        },
        "& p": {
            lineHeight: '26px',
            fontSize: '18px',
        },
    },
    serialNumber1: {
        background: theme.palette.background.secondary.secondary400,
    },
    serialNumber2: {
        background: theme.palette.background.success.success500,
    },
    serialNumber3: {
        background: theme.palette.background.complementary.complementary300,
    },
    serialNumber4: {
        background: "#FD8235",
    },
    serialNumber5: {
        background: theme.palette.background.success.success400,
    },
    serialNumber6: {
        background: theme.palette.background.secondary.secondary700,
    },
    serialNumber7: {
        background: theme.palette.background.warning.warning400,
    },
    serialNumber8: {
        background: theme.palette.background.primary.primary400,
    },
    salesContainer: {
        background: '#F8F8F8',
    },
    sellDescription: {
        "& h5": {
            fontSize: '32px',
        },
        "& p": {
            lineHeight: '26px',
            color: '#000',
        },
    },
    sellText: {
        border: `1px solid ${theme.palette.border.neutral.neutral300}`,
        background: '#fff',
        borderRadius: '40px',
        width: '362px',
        "& p": {
            lineHeight: '22px',
            color: '#000',

        },
    },
  
    featuresContainer: {
        "& span": {
            fontSize: '40px',
            lineHeight: '48px',
            color: theme.palette.text.primary.primary700,
        },
        "& h4": {
            "& span": {
                color: theme.palette.text.primary.primary500,
                fontWeight: '600',
            },
        },
    },
    featureInfo: {
        "& p": {
            color: theme.palette.text.neutral.neutral900,
        },
    },
    featuresText: {
        width: '651px',
        "& h5": {
            color: '#000',
            fontSize: '22px',
            lineHeight: '26px',
        },
        "& p": {
            color: theme.palette.text.neutral.neutral800,
            lineHeight: '26px',
        },
    },
    // heading: {
    //     fontSize: '48px',
    //     lineHeight: "58px",
    //     color: theme.palette.text.primary.primary500,
    //     "& span": {
    //         color: theme.palette.text.neutral.neutral900,
    //     },
    // },
    slide: {
        width: '246px',
        borderRadius: "12px",
        "&:hover": {
            boxShadow: "0px 5px 16px 0px rgba(8, 15, 52, 0.08)"
        },
    },
    tab: {
        marginRight: "16px"
    },
    image: {
        borderTopLeftRadius: "12px",
        borderTopRightRadius: "12px"
    },
    imagePrimary: {
        borderRadius: '12px',
    },
    title: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
        borderTop: "none",
        color: theme.palette.text.primary.primary900,
        lineHeight: "22px",
        borderBottomLeftRadius: "12px",
        borderBottomRightRadius: "12px"
    },
    titlePrimary: {
        borderTop: "none",
        color: theme.palette.text.primary.primary50,
        lineHeight: "22px",
        borderBottomLeftRadius: "12px",
        borderBottomRightRadius: "12px"
    },
    customButtonContainer: {
        marginTop: "-12%",
    },
    carouselContainer: {
        "& .react-multiple-carousel__arrow--right": {
            right: "0 !important"
        },
        "& .react-multiple-carousel__arrow--left": {
            left: "0 !important"
        },
        "& .react-multi-carousel-item": {
        },
        "& .react-multi-carousel-list": {
            width: "1184px",
            margin: "0 auto",
        },
        "& .react-multi-carousel-list li": {
            width: "fit-content !important",
        },
    },
    customArrow: {
        borderRadius: "50%",
        width: "48px",
        height: "48px",
        cursor: "pointer",
        transition: "box-shadow 0.3s ease-in-out",
        boxShadow: `0px 6px 12px 2px rgba(10, 18, 41, 0.16), 0px 2px 4px 1px rgba(10, 18, 41, 0.04)`,
    },
    cardIconContainer: {
        border: `1px solid ${theme.palette.border.neutral.neutral200}`,
        background: '#fff',
    },
    headingText: {
        fontSize: '48px !important',
    },

}));

const B2BMarketplace: React.FC = () => {

    const classes = useStyles();
    const carouselRef = useRef<CarouselRef>(null);
    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const component = params.get('component');

        if (component) {
            const element = document.getElementById(component);
            if (element) {
                element.scrollIntoView();
            }
        }
    }, [location.search]);

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 5,
            partialVisibilityGutter: 0
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 5,
            partialVisibilityGutter: -23
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3,
            partialVisibilityGutter: 10
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 2,
            partialVisibilityGutter: 0
        },
    };

    const data = [
        {
            imgSrc: automotiveSvg,
            title: "Automotive",
        },
        {
            imgSrc: constructionSvg,
            title: "Construction",
        },
        {
            imgSrc: transportationSvg,
            title: "Transportation",
        },
        {
            imgSrc: industrialEquipmentSvg,
            title: "Industrial Equipment",
        },
        {
            imgSrc: energySvg,
            title: "Energy",
        },
        {
            imgSrc: agricultureSvg,
            title: "Agriculture",
        },
        {
            imgSrc: coldStorageSvg,
            title: "Cold Storage",
        },
        {
            imgSrc: foodPackagingSvg,
            title: "Food Packaging",
        },

    ];
    const dataSlider = [
        {
            imgSrc: shearing2,
            title: "Shearing",
        },
        {
            imgSrc: slitting2,
            title: "Slitting",
        },
        {
            imgSrc: colorCoated,
            title: "Color Coated",
        },
        {
            imgSrc: profiling,
            title: "Profiling",
        },
        {
            imgSrc: cnc2,
            title: "CNC",
        },
        {
            imgSrc: blanking,
            title: "Blanking",
        },
        {
            imgSrc: dieCasting2,
            title: "Die Casting",
        },
        {
            imgSrc: forging2,
            title: "Forging",
        },
        {
            imgSrc: pipesTools,
            title: "Pipes and Tubes",
        },
    ];

    const ButtonGroup = ({ next, previous, carouselState }: any) => {
        const { currentSlide, totalItems, slidesToShow } = carouselState;
        const isFirstSlide = currentSlide === 0;
        const isLastSlide = currentSlide === totalItems - slidesToShow;

        return (
            <div className={`${classes.customButtonContainer} flex justify-between items-center w-full`}>
                {!isFirstSlide && (
                    <div className={`${classes.customArrow} bg-white flex justify-center items-center`} onClick={previous}>
                        <img src={leftArrow} alt="" />
                    </div>
                )}
                {!isLastSlide && (
                    <div className={`${classes.customArrow} bg-white flex justify-center items-center ml-auto`} onClick={next}>
                        <img src={rightArrow} alt="" />
                    </div>
                )}
            </div>
        );
    };

    return (
        <div>
            <div className={`${classes.bannerContainer}`}>
                <div className={`${classes.container} ${classes.bannerSellContainer}`}>
                    <h1 className={`${classes.heading} font-semibold`}><span>Connect,</span> <br />Trade, and Grow</h1>
                    <p className={`${classes.bannerDescription} text-2xl font-medium mt-4 mb-4`}>A Global B2B Marketplace for Steel </p>
                    <a href="#" className={`${classes.bannerBtn} flex gap-[10px] rounded-lg font-bold items-center justify-center text-base`}>Get Started Today <div className={classes.btnArrow}><ArrowForwardIcon /></div></a>
                </div>
            </div>
            <div className={`${classes.intoContainer} mb-20`}>
                <div className={`${classes.container} flex items-center py-14 justify-between`}>
                    <div className=''>
                        <p className={`${classes.text} font-semibold mb-2`}>Your Real-Time<br />Steel Trading Hub</p>
                    </div>
                    <div className={classes.decContainer}>
                        <p className='text-lg mb-4'>Steelbazaar is a one-stop digital marketplace tailored for the steel industry, connecting buyers and sellers in an efficient, transparent, & scalable way. We bring innovative tools, including Seller Central & Vendor Central models, along with supply chain financing & lead management services, to ensure businesses of all sizes can thrive in the steel trade.
                        </p>
                        <p className='text-lg'>From procurement to sales & financial solutions, Steelbazaar offers an end-to-end solution for the steel market. With its user-friendly interface, robust features, and commitment to customer success, Steelbazaar.com is poised to revolutionize the way steel businesses operate and thrive in the digital age.</p>
                    </div>
                </div>
            </div>
            <section id='global'>
                <div className={`${classes.container}`} >
                    <div >
                        <h2 className={`${classes.sectionHeading} font-semibold text-center`}>Global Sourcing Simplified</h2>
                        <p className={`${classes.subHeading} text-center text-2xl font-medium`}>Connect with trusted suppliers across the globe</p>
                    </div>
                    <div className='flex gap-[30px] mt-[50px] mb-[50px] pt-[40px] pb-[40px]'>
                        <div className={`${classes.card} rounded-lg`}>
                            <div className={`${classes.cardIconContainer} flex items-center justify-center p-[10px] w-[82px] h-[82px] rounded-2xl`}><img src={source_card_icon1} alt="" /></div>
                            <h4 className='mt-[10px] mb-[10px] font-medium'>Enhancing Import Export Opportunities</h4>
                            <p className='text-base'>Empower Indian vendors with access to a wider international market for steel products.</p>
                        </div>
                        <div className={`${classes.card} rounded-lg`}>
                            <div className={`${classes.cardIconContainer} flex items-center justify-center p-[10px] w-[82px] h-[82px] rounded-2xl`}><img src={source_card_icon2} alt="" /></div>
                            <h4 className='mt-[10px] mb-[10px] font-medium'>Streamlined Cross-Border Trade</h4>
                            <p className='text-base'>Provide essential services like export <strong>documentation</strong> and compliance to simplify processes.</p>
                        </div>
                        <div className={`${classes.card} rounded-lg`}>
                            <div className={`${classes.cardIconContainer} flex items-center justify-center p-[10px] w-[82px] h-[82px] rounded-2xl`}><img src={source_card_icon3} alt="" /></div>
                            <h4 className='mt-[10px] mb-[10px] font-medium'>Robust Logistics <br />Solutions</h4>
                            <p className='text-base'>Ensure efficient logistics support to handle the complexities of international shipping.</p>
                        </div>
                        <div className={`${classes.card} rounded-lg`}>
                            <div className={`${classes.cardIconContainer} flex items-center justify-center p-[10px] w-[82px] h-[82px] rounded-2xl`}><img src={source_card_icon4} alt="" /></div>
                            <h4 className='mt-[10px] mb-[10px] font-medium'>Global Buyer-Seller Matching</h4>
                            <p className='text-base'>Implement a system that connects Indian vendors with international buyers effectively.</p>
                        </div>
                    </div>
                </div>
            </section>
       
            <div className='w-full'>
                <img src={transport} alt="" className='w-full' />
            </div>
            <div className={`${classes.container} pb-[110px] pt-[110px]`}>
                <h2 className={`${classes.sectionHeading} font-semibold text-center`}>A Diverse Range of Steel Products for Every Need</h2>
                <p className={`${classes.subHeading} text-center text-2xl font-medium`}>Choose Seller Central or Vendor Central</p>
                <div className='mt-10 flex flex-col gap-10'>
                    <div className='flex'>
                        <div className={`${classes.productsCard} px-[15px] py-[30px] `}>
                            <h4 className='font-[28px]'>Carbon and Stainless Flat Products</h4>
                            <Button variant={'tertiaryContained'} label="View All" className={`${classes.btn}`}></Button>
                        </div>
                        <div className={`${classes.productsCard} pt-[15px] pl-[15px] relative`}>
                            <p className='text-lg font-medium'>Hot Rolled Coils </p>
                            <img src={hotRolled} alt="" className='absolute right-0 bottom-0' />
                        </div>
                        <div className={`${classes.productsCard} pt-[15px] pl-[15px] relative`}>
                            <p className='text-lg font-medium'>Cold Rolled</p>
                            <img src={coldRolled} alt="" className='absolute right-0 bottom-0' />
                        </div>
                        <div className={`${classes.productsCard} pt-[15px] pl-[15px] relative`}>
                            <p className='text-lg font-medium'>Galvanized</p>
                            <img src={galvanised} alt="" className='absolute right-0 bottom-0' />
                        </div>
                        <div className={`${classes.productsCard} pt-[15px] pl-[15px] relative`}>
                            <p className='text-lg font-medium'>PPGI</p>
                            <img src={ppgi} alt="" className='absolute right-0 bottom-0' />
                        </div>
                    </div>
                    <div className='flex'>
                        <div className={`${classes.productsCard} ${classes.product2} px-[15px] py-[30px]`}>
                            <h4 className='font-[28px]'>Carbon and Stainless Long Products</h4>
                            <Button variant={'tertiaryContained'} label="View All" className={`${classes.btn}`}></Button>
                        </div>
                        <div className={`${classes.productsCard} pt-[15px] pl-[15px] relative`}>
                            <p className='text-lg font-medium'>Hot Rolled Coils </p>
                            <img src={angle} alt="" className='absolute right-0 bottom-0' />
                        </div>
                        <div className={`${classes.productsCard} pt-[15px] pl-[15px] relative`}>
                            <p className='text-lg font-medium'>Cold Rolled</p>
                            <img src={channel} alt="" className='absolute right-0 bottom-0' />
                        </div>
                        <div className={`${classes.productsCard} pt-[15px] pl-[15px] relative`}>
                            <p className='text-lg font-medium'>Galvanized</p>
                            <img src={beam} alt="" className='absolute right-0 bottom-0' />
                        </div>
                        <div className={`${classes.productsCard} pt-[15px] pl-[15px] relative`}>
                            <p className='text-lg font-medium'>PPGI</p>
                            <img src={tmt} alt="" className='absolute right-0 bottom-0' />
                        </div>
                    </div>
                    <div className='flex'>
                        <div className={`${classes.productsCard} ${classes.product3} px-[15px] py-[30px]`}>
                            <h4 className='font-[28px]'>Finished Products at Bulk Prices</h4>
                            <Button variant={'tertiaryContained'} label="View All" className={`${classes.btn}`}></Button>
                        </div>
                        <div className={`${classes.productsCard} pt-[15px] pl-[15px] relative`}>
                            <p className='text-lg font-medium'>Hot Rolled Coils </p>
                            <img src={sheets} alt="" className='absolute right-0 bottom-0' />
                        </div>
                        <div className={`${classes.productsCard} pt-[15px] pl-[15px] relative`}>
                            <p className='text-lg font-medium'>Cold Rolled</p>
                            <img src={parts} alt="" className='absolute right-0 bottom-0' />
                        </div>
                        <div className={`${classes.productsCard} pt-[15px] pl-[15px] relative`}>
                            <p className='text-lg font-medium'>Galvanized</p>
                            <img src={box} alt="" className='absolute right-0 bottom-0' />
                        </div>
                        <div className={`${classes.productsCard} pt-[15px] pl-[15px] relative`}>
                            <p className='text-lg font-medium'>PPGI</p>
                            <img src={containers} alt="" className='absolute right-0 bottom-0' />
                        </div>
                    </div>
                </div>
            </div>
            <div className={`w-[1300px] mx-auto grid gap-y-8 mb-[110px]`}>
                <div className={`${classes.sectionHeading} font-semibold text-center`}>Customization Services for Tailored Products</div>
                <div className={`${classes.carouselContainer} relative`} style={{ height: "262px" }}>
                    <Carousel
                        responsive={responsive}
                        showDots={false}
                        infinite={false}
                        partialVisible={true}
                        ref={carouselRef}
                        arrows={false}
                        removeArrowOnDeviceType={["sm-mobile"]}
                        renderButtonGroupOutside={true}
                        customButtonGroup={<ButtonGroup />}
                    >
                        {dataSlider.map((item, index) => (
                            <div
                                className={`${classes.slide} grid relative ${index !== dataSlider.length - 1 ? classes.tab : ''}`}
                                key={item.title} // Ensure the key is unique; here we use title (or item.id if you have it)
                            >
                                <img
                                    className={classes.imagePrimary}
                                    src={item.imgSrc}
                                    alt={`${item.title} image`} // Use title for better alt text
                                />
                                <div className={`${classes.titlePrimary} absolute bottom-3 left-3 text-lg font-bold`}>
                                    {item.title}
                                </div>
                            </div>
                        ))}
                    </Carousel>
                </div>

            </div>
            <div className={`${classes.salesContainer} pt-[110px] pb-[110px]`}>
                <div className={classes.container}>
                    <h2 className={`${classes.sectionHeading} font-semibold text-center`}>Flexible Sales Solutions for All</h2>
                    <p className={`${classes.subHeading} text-center text-2xl font-medium`}>From Raw Materials to Finished Products</p>
                    <div className=''>
                        <div className='flex items-center gap-10'>
                            <div><img src={seller} alt="" /></div>
                            <div className={`${classes.sellDescription} w-2/3 mt-5`}>
                                <h4 className='text-5xl font-semibold mb-[10px]'>Seller Central (3P) Model</h4>
                                <h5 className='font-medium mb-[10px]'>Sell Directly to Customers</h5>
                                <p className='text-lg'>Steelbazaar's Seller Central allows independent sellers to list products, manage inventory, and connect with buyers. It offers tools for creating a digital storefront, generating leads, and managing orders, making it ideal for businesses looking to reach a broad customer base while managing logistics.</p>
                                <div className='flex flex-wrap gap-x-4 gap-y-[30px] mt-5'>
                                    <div className={`${classes.sellText} px-5 py-2`}>
                                        <p className='text-lg'>Customizable storefronts for product listings and branding.</p>
                                    </div>
                                    <div className={`${classes.sellText} px-5 py-2`}>
                                        <p className='text-lg'>Tools for lead generation to connect with potential buyers.</p>
                                    </div>
                                    <div className={`${classes.sellText} px-5 py-2`}>
                                        <p className='text-lg'>Increased visibility within Steelbazaar's buyer network.</p>
                                    </div>
                                    <div className={`${classes.sellText} px-5 py-2`}>
                                        <p className='text-lg'>Integrated inventory management in real-time.</p>
                                    </div>
                                    <div className={`${classes.sellText} px-5 py-2`}>
                                        <p className='text-lg'>Control over pricing, promotions, and special offers.</p>
                                    </div>
                                    <div className={`${classes.sellText} px-5 py-2`}>
                                        <p className='text-lg'>Direct access to manage listings and sales.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='flex items-center gap-10'>
                            <div className={`${classes.sellDescription} w-2/3 mt-5`}>
                                <h4 className='text-5xl font-semibold mb-[10px]'>Vendor Central (1P) Model</h4>
                                <h5 className='font-medium mb-[10px]'>Bulk Sales Directly to Us</h5>
                                <p className='text-lg'>The Vendor Central model allows businesses to operate as first-party (1P) suppliers, selling products directly to Steelbazaar. Steelbazaar purchases inventory and manages sales and fulfilment, making this model ideal for businesses seeking stable demand and consistent orders.</p>
                                <div className='flex flex-wrap gap-x-4 gap-y-[30px] mt-5'>
                                    <div className={`${classes.sellText} px-5 py-2`}>
                                        <p className='text-lg'>Guaranteed Bulk Purchases and Large Contract Opportunities</p>
                                    </div>
                                    <div className={`${classes.sellText} px-5 py-2`}>
                                        <p className='text-lg'>Steelbazaar manages sales, fulfilment, & customer interactions.</p>
                                    </div>
                                    <div className={`${classes.sellText} px-5 py-2`}>
                                        <p className='text-lg'>Steelbazaar handles enhanced visibility and marketing.</p>
                                    </div>
                                    <div className={`${classes.sellText} px-5 py-2`}>
                                        <p className='text-lg'>We offer payment integration with secure financial transactions.</p>
                                    </div>
                                    <div className={`${classes.sellText} px-5 py-2`}>
                                        <p className='text-lg'>Gain access to large buyers and enterprise-level sales opportunities.</p>
                                    </div>
                                    <div className={`${classes.sellText} px-5 py-2`}>
                                        <p className='text-lg'>Enjoy consistent, long-term sales through bulk purchasing options.</p>
                                    </div>
                                </div>
                            </div>
                            <div><img src={vendor} alt="" /></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`${classes.container} pt-[110px] pb-[110px]`}>
                <h2 className={`${classes.sectionHeading} font-semibold text-center`}>Innovative Features Revolutionize the Steel Trade</h2>
                <div className='flex gap-10 items-center mt-12'>
                    <img src={sbInstant} alt="" />
                    <div className={classes.featuresContainer}>
                        <span className='font-semibold'>SB Instant</span>
                        <h4 className='mt-6 mb-[30px] text-5xl font-semibold'>Real-Time Pricing<br />and <span className={`${classes.headingText} text-5xl`} >Market Updates</span></h4>
                        <div className={`${classes.featureInfo}`}>
                            <p className='font-medium text-2xl'>Stay Informed with Accurate & Updated Insights</p>
                            <div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Instant Price Tracking</h5>
                                        <p className='text-lg'>Access real-time pricing data to make timely, informed decisions.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Market Trend Analysis</h5>
                                        <p className='text-lg'>Stay ahead with insights into market fluctuations and trends.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Custom Alerts and Notifications</h5>
                                        <p className='text-lg'>Get personalized updates on price changes and market shifts.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex gap-10 items-center mt-12'>
                    <div className={classes.featuresContainer}>
                        <span className='font-semibold'>SB Compare</span>
                        <h4 className='mt-6 mb-[30px] text-5xl font-semibold'>Brand Comparison
                            Choose the <span className={`${classes.headingText} text-5xl`}>Best for You</span></h4>
                        <div className={`${classes.featureInfo}`}>
                            <p className='font-medium text-2xl'>Analyse Top Brands to Make Informed Decisions</p>
                            <div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Feature-by-Feature Comparisons</h5>
                                        <p className='text-lg'>Examine product specifications, functionalities & benefits across leading brands.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Pricing Transparency</h5>
                                        <p className='text-lg'>detailed pricing structures with discounts/offers to identify the best sourcing</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Expert Advice</h5>
                                        <p className='text-lg'>Access professional recommendations to assist in your decision-making process.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img src={sbCompare} alt="" />
                </div>
                <div className='flex gap-10 items-center mt-12'>
                    <img src={sbTailor} alt="" />
                    <div className={classes.featuresContainer}>
                        <span className='font-semibold'>SB Tailor</span>
                        <h4 className='mt-6 mb-[30px] text-5xl font-semibold'>Customized Material
                            Solutions for <span className={`${classes.headingText} text-5xl`}>Every Need</span></h4>
                        <div className={`${classes.featureInfo}`}>
                            <p className='font-medium text-2xl'>Tailored Steel Products to Meet Your Specific Requirements</p>
                            <div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Bespoke Material Specifications</h5>
                                        <p className='text-lg'>Access steel products customized to your precise dimensions, grades, and finishes, ensuring optimal project performance.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Flexible Order Quantities</h5>
                                        <p className='text-lg'>Whether you need small batches or large volumes, our platform accommodates orders of all sizes to suit your production demands.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Expert Consultation Services</h5>
                                        <p className='text-lg'>Collaborate with our experienced professionals to select materials that align with your technical & budgetary needs, ensuring the best fit for your applications</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex gap-10 items-center mt-12'>
                    <div className={classes.featuresContainer}>
                        <span className='font-semibold'>SB DigiStore</span>
                        <h4 className='mt-6 mb-[30px] text-5xl font-semibold'>Digital Storefronts &<br /><span className={`${classes.headingText} text-5xl`}>Inventory Management</span></h4>
                        <div className={`${classes.featureInfo}`}>
                            <p className='font-medium text-2xl'>Manage Your Products Digitally with Ease</p>
                            <div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Dynamic Digital Storefronts</h5>
                                        <p className='text-lg'>Set up a personalized, professional storefront with company branding, product displays, and promotional banners, ensuring maximum visibility to potential buyers.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Real-Time Inventory Tracking</h5>
                                        <p className='text-lg'>Manage your inventory efficiently with real-time tracking and Get instant notifications for  inventory movements and stock levels</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Integrated Order and Inventory Management</h5>
                                        <p className='text-lg'>Track orders and manage stock from a unified dashboard.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img src={sbDigitStore} alt="" />
                </div>
                <div className='flex gap-10 items-center mt-12'>
                    <img src={sbDigiLog} alt="" />
                    <div className={classes.featuresContainer}>
                        <span className='font-semibold'>SB DigiLog</span>
                        <h4 className='mt-6 mb-[30px] text-5xl font-semibold'>Explore Our<br />Digital <span className={`${classes.headingText} text-5xl`}>Product Catalogs</span></h4>
                        <div className={`${classes.featureInfo}`}>
                            <p className='font-medium text-2xl'>Access Comprehensive Steel Listings Anytime, Anywhere</p>
                            <div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Interactive Product Exploration</h5>
                                        <p className='text-lg'>Engage with detailed product descriptions, high-resolution images & specifications to make informed decisions.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Real-Time Inventory Updates</h5>
                                        <p className='text-lg'>Stay informed with the latest stock availability and pricing, ensuring timely procurement.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Seamless Navigation</h5>
                                        <p className='text-lg'>Utilize intuitive search and filter options to locate the steel products that meet your specific requirements quickly.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex gap-10 items-center mt-12'>
                    <div className={classes.featuresContainer}>
                        <span className='font-semibold'>SB LeadX</span>
                        <h4 className='mt-6 mb-[30px] text-5xl font-semibold'>Boost Sales Performance<br />with <span className={`${classes.headingText} text-5xl`}>Lead Management</span></h4>
                        <div className={`${classes.featureInfo}`}>
                            <p className='font-medium text-2xl'>Connect Sellers with Quality Buy Leads & Close Deals Faster</p>
                            <div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Verified Buyer and Seller Leads</h5>
                                        <p className='text-lg'>Verified and qualified leads to ensure serious buyers and Increase sales opportunities</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Real-Time Lead Updates</h5>
                                        <p className='text-lg'>Monitor & manage leads efficiently with real-time updates and insights.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Tailored Lead Recommendations</h5>
                                        <p className='text-lg'>With advanced algorithms, SB Connect recommends leads based on your business profile and product range, ensuring focussed sales efforts</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img src={sbLead} alt="" />
                </div>
                <div className='flex gap-10 items-center mt-12'>
                    <img src={sbBid} alt="" />
                    <div className={classes.featuresContainer}>
                        <span className='font-semibold'>SB BidHub</span>
                        <h4 className='mt-6 mb-[30px] text-5xl font-semibold'>Forward and Reverse <br /> <span className={`${classes.headingText} text-5xl`}>Auction Services</span></h4>
                        <div className={`${classes.featureInfo}`}>
                            <p className='font-medium text-2xl'>Achieve Optimal Pricing Through Competitive Auctioning</p>
                            <div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Dynamic Forward Auctions</h5>
                                        <p className='text-lg'>Sell products to the highest bidder, maximizing returns on your inventory.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Efficient Reverse Auctions</h5>
                                        <p className='text-lg'>Procure materials at the best prices by inviting competitive bids from suppliers.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Transparent Bidding Platform</h5>
                                        <p className='text-lg'>Experience secure, real-time bidding with full transparency, ensuring fair & competitive pricing</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex gap-10 items-center mt-12'>
                    <div className={classes.featuresContainer}>
                        <span className='font-semibold'>SB Instafin</span>
                        <h4 className='mt-6 mb-[30px] text-5xl font-semibold'>Supply Chain
                            <br /><span className={`${classes.headingText} text-5xl`}>Financing Solutions</span></h4>
                        <div className={`${classes.featureInfo}`}>
                            <p className='font-medium text-2xl'>Flexible Financing to Keep Your Business Moving</p>
                            <div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Seamless Working Capital Solutions</h5>
                                        <p className='text-lg'>Customized working capital loans to ensure uninterrupted operations and steady cash flow.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Empower Trade with Extended Payment Terms</h5>
                                        <p className='text-lg'>Enable smoother transactions with flexible credit terms for both buyers and sellers.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Instant Approvals and Digital Processing</h5>
                                        <p className='text-lg'>With fully digital workflows, We ensure fast approvals and minimal paperwork, giving you the financial support you need without delays or complexities.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img src={sbFinance} alt="" />
                </div>
            </div>

            <div className={`${classes.container} pb-[110px]`}>
                <h2 className={`${classes.sectionHeading} font-semibold text-center`}>Unlocking the 8S Power of Marketplace<br />Smart <span>Sourcing </span>to <p className='inline-block'>Sustainability</p></h2>
                <div className='mt-10 flex flex-wrap gap-[37px]'>
                    <div className='flex gap-[10px] items-center w-[630px]'>
                        <img src={smart} alt="" />
                        <div className={classes.productCardText}>
                            <h5>Smart</h5>
                            <p>Trade with AI-driven tools and insights for optimized trading and decision-making </p>
                        </div>
                        <div className={`${classes.serialNumber} ${classes.serialNumber1} flex items-center justify-center p-[15px]`}>
                            <span className='text-5xl font-semi-bold'>01</span>
                        </div>
                    </div>
                    <div className='flex gap-[10px] items-center w-[630px]'>
                        <img src={Seamless} alt="" />
                        <div className={classes.productCardText}>
                            <h5>Seamless</h5>
                            <p>Sourcing from Multiple Global & Domestic Manufacturers with Quality Assurance</p>
                        </div>
                        <div className={`${classes.serialNumber} ${classes.serialNumber2} flex items-center justify-center p-[15px]`}>
                            <span className='text-5xl font-semi-bold'>02</span>
                        </div>
                    </div>
                    <div className='flex gap-[10px] items-center w-[630px]'>
                        <img src={Streamlined} alt="" />
                        <div className={classes.productCardText}>
                            <h5>Streamlined</h5>
                            <p>Digital, Automated & Transparent Workflows for inventory & order management</p>
                        </div>
                        <div className={`${classes.serialNumber} ${classes.serialNumber3} flex items-center justify-center p-[15px]`}>
                            <span className='text-5xl font-semi-bold'>03</span>
                        </div>
                    </div>
                    <div className='flex gap-[10px] items-center w-[630px]'>
                        <img src={Strategic} alt="" />
                        <div className={classes.productCardText}>
                            <h5>Strategic</h5>
                            <p>Savings through Bulk pricing, cost-effective sourcing, and financing options </p>
                        </div>
                        <div className={`${classes.serialNumber} ${classes.serialNumber4} flex items-center justify-center p-[15px]`}>
                            <span className='text-5xl font-semi-bold'>04</span>
                        </div>
                    </div>
                    <div className='flex gap-[10px] items-center w-[630px]'>
                        <img src={Secure} alt="" />
                        <div className={classes.productCardText}>
                            <h5>Secure</h5>
                            <p>Fast and Easy Transactions : Trade with Confidence</p>
                        </div>
                        <div className={`${classes.serialNumber} ${classes.serialNumber5} flex items-center justify-center p-[15px]`}>
                            <span className='text-5xl font-semi-bold'>05</span>
                        </div>
                    </div>
                    <div className='flex gap-[10px] items-center w-[630px]'>
                        <img src={Standardized} alt="" />
                        <div className={classes.productCardText}>
                            <h5>Standardized</h5>
                            <p>and Industry Compliant processes and materials for assured, regulated quality</p>
                        </div>
                        <div className={`${classes.serialNumber} ${classes.serialNumber6} flex items-center justify-center p-[15px]`}>
                            <span className='text-5xl font-semi-bold'>06</span>
                        </div>
                    </div>
                    <div className='flex gap-[10px] items-center w-[630px]'>
                        <img src={Scalable} alt="" />
                        <div className={classes.productCardText}>
                            <h5>Scalable</h5>
                            <p>Growth through discovering new global markets and connections</p>
                        </div>
                        <div className={`${classes.serialNumber} ${classes.serialNumber7} flex items-center justify-center p-[15px]`}>
                            <span className='text-5xl font-semi-bold'>07</span>
                        </div>
                    </div>
                    <div className='flex gap-[10px] items-center w-[630px]'>
                        <img src={Sustainability} alt="" />
                        <div className={classes.productCardText}>
                            <h5>Sustainability</h5>
                            <p>Commitment to eco-friendly practices, including recycling, promotes environmental responsibility.</p>
                        </div>
                        <div className={`${classes.serialNumber} ${classes.serialNumber8} flex items-center justify-center p-[15px]`}>
                            <span className='text-5xl font-semi-bold'>08</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`w-[1300px] mx-auto grid mb-10`}>
                <div className={`${classes.sectionHeading} font-semibold text-center`}>Optimized Supply Chains <br /> Maximum Realizations</div>
                <p className={`${classes.subHeading} text-center mb-12 text-2xl font-medium`}>Transforming diverse Industrial Supply Chains for Optimal Performance </p>

                <div className=''></div>
                <div className={`${classes.carouselContainer} relative`} style={{ height: "262px" }}>
                    <Carousel
                        responsive={responsive}
                        showDots={false}
                        infinite={false}
                        partialVisible={true}
                        ref={carouselRef}
                        arrows={false}
                        removeArrowOnDeviceType={["sm-mobile"]}
                        renderButtonGroupOutside={true}
                        customButtonGroup={<ButtonGroup />}
                    >
                        {data.map((item: any, index: any) => (
                            <div className={` ${classes.slide} grid ${index !== data.length - 1 ? classes.tab : ''}`} key={item.id} >
                                <img className={classes.image} src={item.imgSrc} alt="industryImage" />
                                <div className={`${classes.title} pl-4 pt-4 pb-6 text-lg font-bold`}>{item.title}</div>
                            </div>
                        ))}
                    </Carousel>
                </div>
            </div>
            <RequestDemoTemplate />
        </div>
    );
};
export default B2BMarketplace;
