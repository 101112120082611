import { createUseStyles } from 'react-jss';
import { InputAdornment } from '@mui/material';
import Button from '../../atoms/Button/Button';
import TextFieldV2 from '../../atoms/Input/TextFieldV2';
import banner from "../../../assets/images/rfqFormBanner.png"
import { IPersonalDetails, changeSectionTo } from './RequestForQuotationForm.template';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import { RFQ_SECTIONS } from '../../../utils/types';

interface CustomerDetailsFormTemplateProps {
    setCurrentSectionTo: (section: RFQ_SECTIONS) => void;
    formik: any;
    gstDetails?: any;
}

const useStyles = createUseStyles((theme: any) => ({
    webContainer: {
        display: "grid"
    },
    heading: {
        color: theme.palette.text.primary.primary500
    },
    subHeading: {
        color: theme.palette.text.primary.primary500
    },
    form: {
        color: theme.palette.text.primary.primary500
    },
    activeProgressBar: {
        backgroundColor: theme.palette.background.primary.primary500
    },
    inActiveProgressBar: {
        backgroundColor: theme.palette.background.primary.primary50
    },
    errorMessage: {
        color: theme.palette.text.secondary.secondary500,
    },
    input: {
        "& .MuiInputBase-root": {
            borderRadius: "8px !important",
        },
    },
    mobile: {
        "& .MuiTypography-root": {
            color: theme.palette.text.neutral.neutral400
        }
    },
    mobileContainer: {
        display: "none"
    },
    productBar: {

    },
    customerBar: {

    },
    image: {
        width: "262px",
        height: "399px",
        display: "grid"
    },
    borderTop:{

    },
    "@media (max-width: 767px)": {
        webContainer: {
            display: "none"
        },
        mobileContainer: {
            display: "grid"
        },
        productBar: {
            width: "12.89%"
        },
        customerBar: {
            width: "83.98%"
        },
        form: {
            backgroundColor: theme.palette.text.primary.primary500,
            color: "white",
            fontSize: "16px",
            lineHeight: "20px"
        },
        borderTop:{
            borderTop: `1px solid ${theme.palette.border.neutral.neutral100}`,

        },
    }
}));

const CustomerDetailsFormTemplate: React.FC<CustomerDetailsFormTemplateProps> = ({ setCurrentSectionTo, formik, gstDetails }) => {
    const classes = useStyles();
    const { user } = useAuthenticatedUser();
    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        const requiredFields: (keyof IPersonalDetails)[] = ["name", "companyName", "addressLine1", "addressLine2", "pinCode", "city", "state", "gst", "email", "mobileNumber"];
        let fieldError = false;
        for (const key of requiredFields) {
            if (key in formik.errors) {
                formik.setFieldTouched(key, true);
                fieldError = true;
            }
        }
        if (fieldError === true) return;
        formik.setValues((prevValues: any) => ({
            ...prevValues,
            name: formik?.values?.name,
            companyName: formik?.values?.companyName,
            addressLine1: formik?.values?.addressLine1,
            addressLine2: formik?.values?.addressLine2,
            pinCode: formik?.values?.pinCode,
            city: formik?.values?.city,
            state: formik?.values?.state,
            gst: formik?.values?.gst.toUpperCase(),
            email: formik?.values?.email,
            mobileNumber: formik?.values?.mobileNumber,
        }));
        formik.handleSubmit();
    };

    return (
        <>
            <div className={`${classes.webContainer} grid gap-y-6`}>
                <div className={`w-full grid grid-cols-2 gap-x-2  `}>
                    <div className={`${classes.activeProgressBar} h-2.5 rounded `}></div>
                    <div className={`${classes.inActiveProgressBar} h-2.5 rounded `}></div>
                </div>

                <form className='' onSubmit={handleSubmit}>
                    <div className='grid gap-y-6'>
                        <div className='flex gap-x-6'>
                            <img className={classes.image} src={banner} alt="" />
                            <div className='grid gap-y-6'>
                                <div className='flex justify-between text-base font-semibold'>
                                    <div className={classes.subHeading} >Please tell us about yourself</div>
                                    <div className={classes.form}>2/2</div>
                                </div>

                                <div className="grid gap-y-3" >
                                    <div className="grid w-full">
                                        <TextFieldV2
                                            placeholder="Name*"
                                            id="name"
                                            name="name"
                                            label="Name*"
                                            variant="outlined"
                                            {...formik.getFieldProps("name")}
                                            disabled={user?.fullName}
                                        />
                                        {formik.touched &&
                                            formik.touched.name &&
                                            formik.errors &&
                                            formik.errors.name && (
                                                <div className={classes.errorMessage}>
                                                    <small>{formik.errors.name}</small>
                                                </div>
                                            )}
                                    </div>

                                    <div className="flex gap-x-4 w-full">

                                        <div className="grid w-full">
                                            <TextFieldV2
                                                id="companyName"
                                                name="companyName"
                                                placeholder="Company Name*"
                                                label="Company Name*"
                                                variant="outlined"
                                                {...formik.getFieldProps("companyName")}
                                                disabled={gstDetails?.legalNameOfBusiness}
                                            />
                                            {formik.touched &&
                                                formik.touched.companyName &&
                                                formik.errors &&
                                                formik.errors.companyName && (
                                                    <div className={classes.errorMessage}>
                                                        <small>{formik.errors.companyName}</small>
                                                    </div>
                                                )}
                                        </div>
                                        <div className="grid w-full">
                                            <TextFieldV2
                                                id="gst"
                                                name="gst"
                                                label="GST (Optional)"
                                                placeholder="Gst"
                                                variant="outlined"
                                                {...formik.getFieldProps("gst")}
                                                value={formik.values.gst.toUpperCase()}
                                                disabled={user?.gstin}
                                            />
                                            {formik.touched &&
                                                formik.touched.gst &&
                                                formik.errors &&
                                                formik.errors.gst && (
                                                    <div className={classes.errorMessage}>
                                                        <small>{formik.errors.gst}</small>
                                                    </div>
                                                )}
                                        </div>
                                    </div>

                                    <div className="flex gap-x-4 w-full">
                                        <div className="grid w-full">
                                            <TextFieldV2
                                                id="addressLine1"
                                                name="addressLine1"
                                                placeholder="Address"
                                                label="Address Line 1*"
                                                variant="outlined"
                                                {...formik.getFieldProps("addressLine1")}
                                                disabled={gstDetails?.addressLine}
                                            />
                                            {formik.touched &&
                                                formik.touched.addressLine1 &&
                                                formik.errors &&
                                                formik.errors.addressLine1 && (
                                                    <div className={classes.errorMessage}>
                                                        <small>{formik.errors.addressLine1}</small>
                                                    </div>
                                                )}
                                        </div>
                                        <div className="grid w-full">
                                            <TextFieldV2
                                                id="addressLine2"
                                                name="addressLine2"
                                                placeholder="Address"
                                                label="Address Line 2 (Optional)"
                                                variant="outlined"
                                                {...formik.getFieldProps("addressLine2")}
                                            />
                                        </div>
                                    </div>

                                    <div className="flex gap-x-4 w-full">
                                        <div className="grid w-full">
                                            <TextFieldV2
                                                id="pinCode"
                                                name="pinCode"
                                                placeholder="PinCode"
                                                label="Pincode*"
                                                type='number'
                                                variant="outlined"
                                                {...formik.getFieldProps("pinCode")}
                                                disabled={gstDetails?.city[0]}
                                            />
                                            {formik.touched &&
                                                formik.touched.pinCode &&
                                                formik.errors &&
                                                formik.errors.pinCode && (
                                                    <div className={classes.errorMessage}>
                                                        <small>{formik.errors.pinCode}</small>
                                                    </div>
                                                )}
                                        </div>
                                        <div className="grid w-full">
                                            <TextFieldV2
                                                id="city"
                                                name="city"
                                                label="City*"
                                                placeholder="City"
                                                variant="outlined"
                                                {...formik.getFieldProps("city")}
                                                disabled={gstDetails?.city[0]}
                                            />
                                            {formik.touched &&
                                                formik.touched.city &&
                                                formik.errors &&
                                                formik.errors.city && (
                                                    <div className={classes.errorMessage}>
                                                        <small>{formik.errors.city}</small>
                                                    </div>
                                                )}
                                        </div>
                                        <div className="grid w-full">
                                            <TextFieldV2
                                                id="state"
                                                name="state"
                                                label="State*"
                                                placeholder="State"
                                                variant="outlined"
                                                {...formik.getFieldProps("state")}
                                                disabled={gstDetails?.state[0][0]}
                                            />
                                            {formik.touched &&
                                                formik.touched.state &&
                                                formik.errors &&
                                                formik.errors.state && (
                                                    <div className={classes.errorMessage}>
                                                        <small>{formik.errors.state}</small>
                                                    </div>
                                                )}
                                        </div>
                                    </div>

                                    <div className="grid w-full">
                                        <TextFieldV2
                                            id="email"
                                            name="email"
                                            label="Email (Optional)"
                                            placeholder="Email"
                                            variant="outlined"
                                            {...formik.getFieldProps("email")}
                                            disabled={user?.email}
                                        />
                                        {formik.touched &&
                                            formik.touched.email &&
                                            formik.errors &&
                                            formik.errors.email && (
                                                <div className={classes.errorMessage}>
                                                    <small>{formik.errors.email}</small>
                                                </div>
                                            )}
                                    </div>

                                    <div className="grid w-full">
                                        <TextFieldV2
                                            id="mobileNumber"
                                            name="mobileNumber"
                                            label="Phone Number*"
                                            placeholder="Enter Phone Number*"
                                            variant="outlined"
                                            {...formik.getFieldProps("mobileNumber")}
                                            disabled={user?.mobileNumber}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start" className={`${classes.mobile} text-base font-normal`}>IND (+91)</InputAdornment>,
                                            }}
                                        />
                                        {formik.touched &&
                                            formik.touched.mobileNumber &&
                                            formik.errors &&
                                            formik.errors.mobileNumber && (
                                                <div className={classes.errorMessage}>
                                                    <small>{formik.errors.mobileNumber}</small>
                                                </div>
                                            )}
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className='flex justify-end gap-x-3'>
                            <Button variant="tertiaryContained" label="Back" onClick={changeSectionTo(setCurrentSectionTo)(RFQ_SECTIONS.PRODUCT_REQUIREMENT)} />
                            <Button variant='primaryContained' label='Submit' type="submit" />
                        </div>
                    </div>
                </form>
            </div>

            <div className={`${classes.mobileContainer} grid gap-y-5`}>

                <div className={`w-full m-auto flex justify-between gap-x-2.5`}>
                    <div className={`${classes.inActiveProgressBar} w-1/2 h-1 rounded `}></div>
                    <div className={`${classes.activeProgressBar} w-1/2 h-1 rounded `}></div>
                </div>

                <form className='' onSubmit={handleSubmit}>
                    <div className='grid gap-y-5'>
                        <div className='grid gap-y-2.5'>
                            <div className='flex justify-between'>
                                <div className={`${classes.subHeading} text-sm font-semibold my-auto`} >Please tell us about yourself</div>
                            <div className={`${classes.form} p-auto flex justify-center h-[34px] w-[34px] items-center`}>2/2</div>
                            </div>

                            <div className="grid gap-y-3" >
                                <div className="grid w-full">
                                    <TextFieldV2
                                        placeholder="Name*"
                                        id="name"
                                        name="name"
                                        label="Name*"
                                        variant="outlined"
                                        {...formik.getFieldProps("name")}
                                        disabled={user?.fullName}
                                    />
                                    {formik.touched &&
                                        formik.touched.name &&
                                        formik.errors &&
                                        formik.errors.name && (
                                            <div className={classes.errorMessage}>
                                                <small>{formik.errors.name}</small>
                                            </div>
                                        )}
                                </div>

                                <div className="grid gap-y-3 w-full">
                                    <div className="grid w-full">
                                        <TextFieldV2
                                            placeholder="Company Name*"
                                            id="companyName"
                                            name="companyName"
                                            label="Company Name*"
                                            variant="outlined"
                                            {...formik.getFieldProps("companyName")}
                                            disabled={gstDetails?.legalNameOfBusiness}
                                        />
                                        {formik.touched &&
                                            formik.touched.companyName &&
                                            formik.errors &&
                                            formik.errors.companyName && (
                                                <div className={classes.errorMessage}>
                                                    <small>{formik.errors.companyName}</small>
                                                </div>
                                            )}
                                    </div>

                                    <div className="grid w-full">
                                        <TextFieldV2
                                            id="gst"
                                            name="gst"
                                            label="GST (Optional)"
                                            placeholder="Gst"
                                            variant="outlined"
                                            {...formik.getFieldProps("gst")}
                                            value={formik.values.gst.toUpperCase()}
                                            disabled={user?.gstin}
                                        />
                                        {formik.touched &&
                                            formik.touched.gst &&
                                            formik.errors &&
                                            formik.errors.gst && (
                                                <div className={classes.errorMessage}>
                                                    <small>{formik.errors.gst}</small>
                                                </div>
                                            )}
                                    </div>
                                </div>

                                <div className="grid gap-y-3 w-full">
                                    <div className="grid w-full">
                                        <TextFieldV2
                                            laceholder="Address"
                                            id="addressLine1"
                                            name="addressLine1"
                                            label="Address Line 1*"
                                            variant="outlined"
                                            placeholder='Address'
                                            {...formik.getFieldProps("addressLine1")}
                                            disabled={gstDetails?.addressLine}
                                        />
                                        {formik.touched &&
                                            formik.touched.addressLine1 &&
                                            formik.errors &&
                                            formik.errors.addressLine1 && (
                                                <div className={classes.errorMessage}>
                                                    <small>{formik.errors.addressLine1}</small>
                                                </div>
                                            )}
                                    </div>
                                    <div className="grid w-full">
                                        <TextFieldV2
                                            id="addressLine2"
                                            name="addressLine2"
                                            label="Address Line 2 (Optional)"
                                            placeholder="Address"
                                            variant="outlined"
                                            {...formik.getFieldProps("addressLine2")}
                                        />
                                    </div>
                                </div>

                                <div className="grid gap-y-3 w-full">
                                    <div className="grid w-full">
                                        <TextFieldV2
                                            id="pinCode"
                                            name="pinCode"
                                            label="Pincode*"
                                            placeholder="PinCode"
                                            type='number'
                                            variant="outlined"
                                            {...formik.getFieldProps("pinCode")}
                                            disabled={gstDetails?.city[0]}
                                        />
                                        {formik.touched &&
                                            formik.touched.pinCode &&
                                            formik.errors &&
                                            formik.errors.pinCode && (
                                                <div className={classes.errorMessage}>
                                                    <small>{formik.errors.pinCode}</small>
                                                </div>
                                            )}
                                    </div>
                                    <div className='grid grid-cols-2 gap-x-2.5'>
                                    <div className="grid w-full">
                                        <TextFieldV2
                                            id="city"
                                            name="city"
                                            label="City*"
                                            placeholder="City"
                                            variant="outlined"
                                            {...formik.getFieldProps("city")}
                                            disabled={gstDetails?.city[0]}
                                        />
                                        {formik.touched &&
                                            formik.touched.city &&
                                            formik.errors &&
                                            formik.errors.city && (
                                                <div className={classes.errorMessage}>
                                                    <small>{formik.errors.city}</small>
                                                </div>
                                            )}
                                    </div>
                                    <div className="grid w-full">
                                        <TextFieldV2
                                            id="state"
                                            name="state"
                                            label="State*"
                                            placeholder="State"
                                            variant="outlined"
                                            {...formik.getFieldProps("state")}
                                            disabled={gstDetails?.state[0][0]}
                                        />
                                        {formik.touched &&
                                            formik.touched.state &&
                                            formik.errors &&
                                            formik.errors.state && (
                                                <div className={classes.errorMessage}>
                                                    <small>{formik.errors.state}</small>
                                                </div>
                                            )}
                                    </div>
                                    </div>
                                </div>

                                <div className="grid w-full">
                                    <TextFieldV2
                                        id="email"
                                        name="email"
                                        label="Email (Optional)"
                                        placeholder="Email"
                                        variant="outlined"
                                        {...formik.getFieldProps("email")}
                                        disabled={user?.email}
                                    />
                                    {formik.touched &&
                                        formik.touched.email &&
                                        formik.errors &&
                                        formik.errors.email && (
                                            <div className={classes.errorMessage}>
                                                <small>{formik.errors.email}</small>
                                            </div>
                                        )}
                                </div>

                                <div className="grid w-full">
                                    <TextFieldV2
                                        id="mobileNumber"
                                        name="mobileNumber"
                                        label="Phone Number"
                                        placeholder="Enter Here"
                                        variant="outlined"
                                        {...formik.getFieldProps("mobileNumber")}
                                        disabled={user?.mobileNumber}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start" className={`${classes.mobile} text-base font-normal`}>IND (+91)</InputAdornment>,
                                        }}
                                    />
                                    {formik.touched && formik.touched.mobileNumber && formik.errors && formik.errors.mobileNumber && (
                                        <div className={classes.errorMessage}>
                                            <small>{formik.errors.mobileNumber}</small>
                                        </div>
                                    )}
                                </div>

                            </div>
                        </div>
                        <div className={`${classes.borderTop} flex justify-end gap-x-2.5 pt-2.5`}>
                            <Button variant="tertiaryContained" size='small' label="Back" onClick={changeSectionTo(setCurrentSectionTo)(RFQ_SECTIONS.PRODUCT_REQUIREMENT)} />
                            <Button variant='primaryContained' size='small' label='Submit' type="submit" />
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default CustomerDetailsFormTemplate