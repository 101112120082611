import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import Button from '../../../atoms/Button/Button';
import { Link } from 'react-router-dom';
import { STATIC_PAGES_ROUTES } from '../../../../utils/constant';
import navbarArrowIcon from '../../../../assets/icons/navbarArrowIcon.svg';
import NavbarServices1 from '../../../../assets/images/NavbarServices1.jpg';
import NavbarServices5 from '../../../../assets/images/NavbarServices5.jpg';
import NavbarServices4 from '../../../../assets/images/NavbarServices4.jpg';
import Navbarplatform1 from '../../../../assets/images/Navbarplatform1.jpg';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        background: "white",
        position: 'absolute',
        width: '100%',
        top: '125px',
        left: '0',
        zIndex: '99',
    },
    primaryContainer: {
        width: '1297px',
        margin: '0 auto',
    },
    dropdownContent: {
        "& h3": {
            color: '#000',
            fontSize: '22px',
            lineHeight: '26px',
        },
        "& h4": {
            lineHeight: '22px',
            color: '#000',
            borderBottom: `1px solid ${theme.palette.border.neutral.neutral200}`
        },
        "& p": {
            color: '#000',
            position: 'relative', 
            paddingLeft: '24px', 
            "&::before": {
                content: '""',
                display: 'inline-block',
                position: 'absolute',
                left: '12px',
                top: '50%',
                transform: 'translateY(-50%)',
                width: '8px', 
                height: '12px',
                backgroundImage: `url(${navbarArrowIcon})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
            },
        },
    },
    dropdownImgContainer: {
        '& img': {
            width: '100%',
            height: 'auto',
        },
    },
}));

const SupplierDropdown: React.FC = () => {
    const classes = useStyles();

    const [hoveredImage, setHoveredImage] = useState<string>(NavbarServices1);

    const imagesMap: Record<string, string> = {
        'NavbarServices1': NavbarServices1,
        'NavbarServices4': NavbarServices4,
        'NavbarServices5': NavbarServices5,
        'Navbarplatform1': Navbarplatform1,
    };

    return (
        <div className={`${classes.container} py-10`}>
            <div className={`${classes.primaryContainer} flex justify-between items-center`}>
                <div className='flex w-full gap-[15px]'>
                    <div className='w-1/4 pr-10'>
                        <div className={`${classes.dropdownContent} flex flex-col `}>
                            <div className='p-[10px]'>
                                <h4 className='font-semibold text-lg py-[5px] px-[10px] mb-1'>Why Sell on SteelBazaar?</h4>
                                <p
                                    className="text-base leading-5 py-[5px] px-[10px]"
                                    onMouseEnter={() => setHoveredImage(imagesMap['NavbarServices4'])}
                                >
                                    <Link to={`${STATIC_PAGES_ROUTES.SELLER_CENTRAL}`}>Benefits & Features</Link>
                                </p>
                            </div>
                        </div>
                        <div className={`${classes.dropdownContent} flex flex-col `}>
                            <div className='p-[10px]'>
                                <h4 className='font-semibold text-lg py-[5px] px-[10px] mb-1'>How to Get Started</h4>
                                <p
                                    className="text-base leading-5 py-[5px] px-[10px]"
                                    onMouseEnter={() => setHoveredImage(imagesMap['NavbarServices5'])}
                                >
                                    <Link to={`${STATIC_PAGES_ROUTES.SELLER_CENTRAL}/?component=registration`}>Registration Process</Link>
                                </p>
                                <p
                                    className="text-base leading-5 py-[5px] px-[10px]"
                                    onMouseEnter={() => setHoveredImage(imagesMap['NavbarServices1'])}
                                >
                                    <Link to={`${STATIC_PAGES_ROUTES.SELLER_CENTRAL}/?component=inventory`}>Digitize your Inventory</Link>
                                </p>
                                <p
                                    className="text-base leading-5 py-[5px] px-[10px]"
                                   
                                >
                                <Link to={`${STATIC_PAGES_ROUTES.SELLER_CENTRAL}/?component=storeFront`}>Store Front Creation Guide</Link>
                                </p>
                                <p
                                    className="text-base leading-5 py-[5px] px-[10px]"
                                    onMouseEnter={() => setHoveredImage(imagesMap['Navbarplatform1'])}
                                >
                                    <Link to={`${STATIC_PAGES_ROUTES.SELLER_CENTRAL}/?component=publish`}>List Your Products</Link>
                                </p>

                            </div>
                        </div>
                    </div>
                    <div className='w-1/4 pr-10'>
                        <div className={`${classes.dropdownContent} flex flex-col `}>
                            <div className='p-[10px]'>
                                <h4 className='font-semibold text-lg py-[5px] px-[10px] mb-1'>Advanced Selling Tools</h4>
                                <p
                                    className="text-base leading-5 py-[5px] px-[10px]"
                                    onMouseEnter={() => setHoveredImage(imagesMap['NavbarServices5'])}
                                >
                                    <Link to={`${STATIC_PAGES_ROUTES.SELLER_CENTRAL}/?component=sellSmart`}>Sell Smart & Fast</Link>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='w-1/4 pr-10'>
                        {/* <div className={`${classes.dropdownContent} flex flex-col `}>
                            <div className='p-[10px]'>
                                <h4 className='font-semibold text-lg py-[5px] px-[10px] mb-1'>Case Studies</h4>
                                <p className='text-base leading-5 py-[5px] px-[10px]'>Successful Seller Stories</p>
                            </div>
                        </div> */}
                    </div>
                    <div className={`${classes.dropdownImgContainer} rounded-lg w-1/4 `}>
                        {hoveredImage && <img src={hoveredImage} alt="Hovered Image" />}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SupplierDropdown;
