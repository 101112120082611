import React from 'react';
import { createUseStyles } from 'react-jss';
import footerLogo from '../../../../src/assets/images/footerLogo.svg';
import TextField from '../../atoms/Input/TextField';
import rightArrow from '../../../assets/icons/rightArrowButton.svg';
import TextFieldV2 from '../../atoms/Input/TextFieldV2';
import { CUSTOMER_ROUTES, REGEX, STATIC_PAGES_ROUTES } from '../../../utils/constant';
import { useEmailSubscriptionService } from '../../../services/useEmailService';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { EMAIL_SUBSCRIPTION_CONTEXT } from '../../../utils/types';
import { Link, useNavigate } from 'react-router-dom';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/joy';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ClassNames } from '@emotion/react';
import location_icon from '../../../assets/icons/location_icon.svg';
import mail_icon from '../../../assets/icons/mail_icon.svg';
import facebook_Icon_1 from '../../../assets/icons/facebook_Icon_1.svg';
import linkedin_Icon_1 from '../../../assets/icons/linkedin_Icon_1.svg';
import x_Icon_1 from '../../../assets/icons/x_Icon_1.svg';
import youtube_Icon_1 from '../../../assets/icons/youtube_Icon_1.svg';
import whatsapp_Icon_1 from '../../../assets/icons/whatsapp_Icon_1.svg';


const useStyles = createUseStyles((theme: any) => ({
    container: {
        background: "#fff",
        padding: "50px 70px 40px 70px",
    },
    topContainer: {
        // borderBottom: `1px solid ${theme.palette.border.primary.primary500}`,
    },
    footerHeading: {
        color: "#000",
        lineHeight: '20px',
        marginBottom: '10px',
    },
    btn: {
        background: theme.palette.background.primary.primary500,
        color: "white",
        borderRadius: '50px',
        height: '48px',
        width: '152px',
    },
    inputSearch: {
        border: `0.8px solid ${theme.palette.border.primary.primary500}`,
        borderRadius: '45px',
        overflow: 'hidden',
        background: 'white',
        width: '512px',
        "& .MuiInputBase-root": {
            border: 'none',
            background: '#fff',
            width: '350px',
        },
    },
    middleContainer: {
        padding: "25px 70px",
        background: '#F9F9F9',
        borderTop: `1px solid ${theme.palette.border.neutral.neutral300}`,
    },
    tagLine: {
        color: theme.palette.text.neutral.neutral800,
        lineHeight: '16px',
    },
    companyName: {
        color: theme.palette.text.neutral.neutral700,
        lineHeight: '22px',
    },
    address: {
        color: theme.palette.text.neutral.neutral700,
        lineHeight: '16px',
        "& div": {
            color: theme.palette.text.neutral.neutral700,
            lineHeight: '16px',
        },
    },
    footerContent: {
        transition: 'all 0.5s ease',
        "& ul": {
            "& li": {
                position: 'relative',
                color: theme.palette.text.neutral.neutral700,
                fontSize: '14px',
                fontWeight: '400',
                marginBottom: '8px',
                textDecoration: 'none',
                transition: 'padding-left 0.3s ease',
                "&::before": {
                    content: `''`,
                    position: 'absolute',
                    left: '-5px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    width: '20px',
                    height: '20px',
                    background: `url(${rightArrow}) no-repeat center center`,
                    backgroundSize: 'contain',
                    opacity: 0,
                    transition: 'opacity 0.3s ease, left 0.3s ease',
                },
                "&:hover": {
                    paddingLeft: '25px',
                    color: theme.palette.text.primary.primary700,
                },
                "&:hover::before": {
                    left: '0',
                    opacity: 1,
                },
                "& a": {
                    textDecoration: 'none',
                    color: 'inherit',
                },
            },
        },
    },
    sectionTitle: {
        color: theme.palette.text.primary.primary500,
        lineHeight: "20px",
    },
    bottomContainer: {
        // borderTop: `1px solid ${theme.palette.border.primary.primary500}`,
        background: theme.palette.background.neutral.neutral900,
        padding: '10px 70px',
    },
    copyRight: {
        color: theme.palette.text.neutral.neutral50,
        lineHeight: '16px',
    },
    text: {
        color: `${theme.palette.text.neutral.neutral50} !important `,
        lineHeight: '16px',
        '&:hover': {
            color: theme.palette.text.primary.primary900,
        },
    },
    footerChildContainer: {
        width: '190px',
    },
    mobileFooterContainer: {
        padding: "16px",
        display: 'none',
    },
    primaryContainer: {

    },
    footerBottomLinks: {
        marginBottom: "-8px",
    },

    "@media (max-width: 480px)": {
        container: {
        },
        footerHeading: {
            fontSize: '28px',
        },
        inputSearch: {
            width: '100%',
            "& .MuiInputBase-root": {
                width: '100%',
            },
        },
    },
}));

const footerSections = [
    {
        title: 'Sell on SteelBazaar',
        items: [
            { name: 'Sell on SteelBazaar', route: '/sell-on-steelbazaar' },
            { name: 'Participate in RFQs', route: '/sell/rfqs' },
            { name: 'Create Sale Auction', route: '/sell/sale-auction' },
            { name: 'Post Your Lead', route: '/sell/post-lead' },
            { name: 'Become Verified Seller', route: '/sell/verified-seller' },
            { name: 'Channel Partners', route: '/sell/channel-partners' }
        ]
    },
    {
        title: 'Buy on SteelBazaar',
        items: [
            { name: 'Search and Find', route: '/buy-on-steelbazaar' },
            { name: 'Compare the Brands', route: '/buy-on-steelbazaar' },
            { name: 'Pool and Save', route: '/buy-on-steelbazaar' },
            { name: 'Post Your Lead', route: '/buy-on-steelbazaar' }
        ]
    },
    {
        title: 'Other Services',
        items: [
            { name: 'Manufacturing', route: '/services' },
            { name: 'Project Management', route: '/services' },
            { name: 'Customization', route: '/services' },
            { name: 'Fabrication', route: '/services' },
            { name: 'Dismantling', route: '/services' }
        ]
    },
    {
        title: 'Support and Care',
        items: [
            { name: 'Help Centre', route: '/support-and-care' },
            { name: 'Live Chat', route: '/support-and-care' },
            { name: 'Whatsapp Us', route: '/support-and-care' },
            { name: 'Order Status', route: '/support-and-care' },
            { name: 'Refunds', route: '/support-and-care' },
            { name: 'Complaints', route: '/support-and-care' }
        ]
    },
    {   
        title: 'Useful Link',
        items: [
            { name: 'About Us', route: '/about-us' },
            { name: 'Careers with Us', route: '/contact-us' },
            { name: 'News Centre', route: '/contact-us' },
            { name: 'Lending Partners', route: '/contact-us' }
        ]
    }
];

const validationSchema = Yup.object().shape({
    email: Yup.string().required('Please enter your email address').matches(REGEX.EMAIL, 'Invalid email address'),
});

const initialValues = {
    email: ''
};

const FooterTemplate: React.FC = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const emailSubscriptionService = useEmailSubscriptionService();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnMount: true,
        onSubmit: (values, { setSubmitting, setFieldError, resetForm }) => {
            setSubmitting(true);
            emailSubscriptionService.createEmailSubscription({ email: values.email, context: EMAIL_SUBSCRIPTION_CONTEXT.EMAIL_SUBSCRIPTION })
                .then(res => {
                    showSnackbar('success', 'Successfully subscribed to newsletter');
                }).catch(err => {
                    setFieldError('email', 'Failed to subscribe this email');
                    showSnackbar('error', 'Failed to subscribe to newsletter');
                }).finally(() => {
                    setSubmitting(false);
                });
            resetForm();
        },
    });

    const handleClick = (route: string) => {
        navigate(route);
    };

    return (
        <>
            <div className={`${classes.container} `}>
                <div className='py-10 flex gap-x-2 justify-between'>
                    <div className='grid gap-y-8 h-fit'>
                        <div className='grid gap-y-3'>
                            <img className='w-[204px]' src={footerLogo} alt="footerLogo" />
                            <div className={`${classes.tagLine} text-sm font-semibold`}>Smart Trade With Technology</div>
                        </div>

                        <div className='grid gap-y-2 h-fit'>
                            <div className={`text-lg font-bold ${classes.companyName}`}>FutureCom Technologies Pvt. Ltd.</div>
                            <div className={`${classes.address} `}>
                                <div className={`text-sm font-medium mb-3`}>C-196, 2nd Floor, Sec-63, Noida, <br /> Uttar Pradesh (India)</div>
                                <div className={`text-sm flex gap-2`}><img src={location_icon} alt="" /> <span>Get Direction on Map</span></div>
                                <div className={`text-sm flex gap-2 mt-[10px]`}><img src={mail_icon} alt="" /> <span>info@steelbazaar.com</span></div>
                            </div>
                        </div>
                    </div>

                    {footerSections.map((section, index) => (
                        <div key={index} className={classes.footerChildContainer}>
                            <div className={`${classes.sectionTitle} font-semibold text-base mb-5`}>{section.title}</div>
                            <div className={`${classes.footerContent} grid gap-y-2`}>
                                <ul>
                                    {section.items.map((item, idx) => (
                                        <li key={idx} onClick={() => handleClick(item.route)}>
                                            <Link to={item.route}>{item.name}</Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className={`${classes.middleContainer} flex justify-between pt-2.5 items-center`}>
                <div className='flex justify-between items-center w-full'>
                    <div className='flex flex-col'>
                        <div className={`${classes.footerHeading} font-medium text-base`}>Subscribe to our Newsletter</div>
                        <form onSubmit={formik.handleSubmit}>
                            <div className={`${classes.inputSearch} flex justify-between items-center h-fit w-80`}>
                                <TextField
                                    fullWidth
                                    className='!py-0 !px-0 !w-80'
                                    placeholder='Enter your Email'
                                    {...formik.getFieldProps("email")}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    helperText={formik.touched.email && Boolean(formik.errors.email) && formik.errors.email}
                                />
                                <button className={`${classes.btn} !text-base !mr-1 flex justify-center items-center`} type='submit'>Submit</button>
                            </div>
                        </form>
                    </div>

                    <div>
                        <p className='font-semibold text-base mb-3'>Follow Us:</p>
                        <div className='flex gap-7'>
                            <img src={facebook_Icon_1} alt="" />
                            <img src={x_Icon_1} alt="" />
                            <a href="https://www.linkedin.com/company/steelbazaar" target='blank'><img src={linkedin_Icon_1} alt="" /></a>
                            <img src={youtube_Icon_1} alt="" />
                            <img src={whatsapp_Icon_1} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${classes.bottomContainer} flex justify-between items-center`}>
                <div className={`${classes.copyRight} text-sm font-medium`}>© 2024 FutureCom Technologies Pvt. Ltd., All Rights Reserved</div>
                <div className={`${classes.footerContent} flex gap-x-6`}>
                    <ul className={`${classes.footerBottomLinks} flex gap-3`}>
                        <li><Link to={STATIC_PAGES_ROUTES.TERMS_AND_CONDITIONS} className={`${classes.text} mb-0 cursor-pointer px-1 text-sm font-medium`}>Terms & Conditions</Link></li>
                        <li><Link to={STATIC_PAGES_ROUTES.PRIVACY_POLICY} className={`${classes.text} cursor-pointer mb-0 px-1 text-sm font-medium`}>Privacy Policy</Link></li>
                    </ul>
                </div>
            </div>
            <div>
                <div className={classes.mobileFooterContainer}>
                    <div className={`${classes.topContainer} flex justify-between pb-10`}>
                        <div className={`${classes.footerHeading} font-medium text-base`}>Subscribe to our Newsletter</div>
                        <form onSubmit={formik.handleSubmit}>
                            <div className={`${classes.inputSearch} flex justify-between items-center h-fit w-80`}>
                                <TextField
                                    fullWidth
                                    className='!py-0 !px-0 !w-80'
                                    placeholder='Enter your Email'
                                    {...formik.getFieldProps("email")}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    helperText={formik.touched.email && Boolean(formik.errors.email) && formik.errors.email}
                                />
                                <button className={`${classes.btn} !text-base !mr-1 flex justify-center items-center`} type='submit'>Submit</button>
                            </div>
                        </form>
                    </div>
                    {footerSections.map((section, index) => (
                        <Accordion key={index}>
                            <AccordionSummary className={classes.primaryContainer}
                            >
                                <div className={`${classes.sectionTitle} font-bold text-sm px-2 py-3`}>{section.title}</div>
                            </AccordionSummary>
                            <AccordionDetails className={`${classes.footerContent} grid gap-y-2`}>
                                <ul className='px-2 py-3'>
                                    {section.items.map((item, idx) => (
                                        <li key={idx} >
                                            <Link to={item.route}>{item.name}</Link>
                                        </li>
                                    ))}
                                </ul>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </div>
            </div>
        </>
    );
};

export default FooterTemplate;
