import React from "react";
import { default as MuiTextField, TextFieldProps } from "@mui/material/TextField";
import { styled } from "@mui/system";
import { createUseStyles } from "react-jss";
import ErrorMessage from "../../atoms/ErrorMessage/ErrorMessage";

const useStyles = createUseStyles((theme: any) => ({
    label: {
        color: theme.palette.text.neutral.neutral700,
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "16px"
    },
    textField: {
        "& .MuiInputBase-root": {
            border: "1px solid",
            borderColor: theme.palette.border.neutral.neutral200,
            fontSize: "16px",
            fontWeight: 500,
            borderRadius: "12px",
            color: theme.palette.text.primary.primary900,
            "&:hover": {
                borderColor: theme.palette.border.primary.primary300,
                background: theme.palette.text.primary.primary50,
                borderWidth: "1px",
                outline: "none",
            },
            "& .MuiInputBase-input": {
                padding: "4px 3px",
                "&::placeholder": {
                    color: `${theme.palette.text.neutral.neutral400} !important`,
                    fontWeight: 400
                }
            },
            "& .MuiOutlinedInput-notchedOutline": {
                outline: "none",
                borderWidth: 0,
                borderColor: theme.palette.border.primary.primary300,
            },
            "&:focus-within": {
                outline: "none",
                borderColor: theme.palette.border.primary.primary300,
                borderWidth: 2,
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                outline: "none",
                borderWidth: 0,
                borderColor: theme.palette.border.primary.primary300,
            },
            "& .Mui-disabled": {
                fontWeight: 400,
                borderColor: theme.palette.text.neutral.neutral200,
                borderRadius: "12px",
                WebkitTextFillColor: theme.palette.text.neutral.neutral400,
                fontSize: "16px"
            },
        },
        "& .Mui-error": {
            border: "1px solid",
            borderColor: theme.palette.border.secondary.secondary200,
            fontSize: "16px",
            fontWeight: 400,
            borderRadius: 8,
            backgroundColor: theme.palette.background.secondary.secondary50,
        }
    },

    "@media (max-width: 767px)": {
        textField: {
            "& .MuiInputBase-root": {
                border: "1px solid",
                borderColor: theme.palette.border.neutral.neutral200,
                fontSize: "14px",
                fontWeight: 400,
                borderRadius: "6px",
                color: theme.palette.text.primary.primary900,
                "&:hover": {
                    borderColor: theme.palette.border.primary.primary300,
                    borderWidth: "1px",
                    outline: "none",
                },
                "& .MuiInputBase-input": {
                    "&::placeholder": {
                        color: `${theme.palette.text.neutral.neutral400} !important`,
                        fontWeight: 400,
                    }
                },
                "& .MuiOutlinedInput-notchedOutline": {
                    outline: "none",
                    borderWidth: 0,
                    borderColor: theme.palette.border.primary.primary300,
                },
                "&:focus-within": {
                    outline: "none",
                    borderColor: theme.palette.border.primary.primary300,
                    borderWidth: 2,
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    outline: "none",
                    borderWidth: 0,
                    borderColor: theme.palette.border.primary.primary300,
                },
                "& .Mui-disabled": {
                    fontWeight: 400,
                    borderColor: theme.palette.text.neutral.neutral200,
                    borderRadius: "6px",
                    WebkitTextFillColor: theme.palette.text.neutral.neutral400,
                    fontSize: "14px",
            
                },
            },
            "& .Mui-error": {
                border: "1px solid",
                borderColor: theme.palette.border.secondary.secondary200,
                fontSize: "14px",
                fontWeight: 400,
                borderRadius: "6px",
                backgroundColor: theme.palette.background.secondary.secondary50,
                color:theme.palette.text.secondary.secondary400 
            }
        },
        label: {
            color: theme.palette.text.neutral.neutral700,
            fontSize: "14px",
            lineHeight: "16.1px"
        },
    },
}));

const AutoCompleteTextField = styled((props: TextFieldProps) => {
    const classes = useStyles();
    return (
        <div className="flex flex-col gap-1 relative">
            <MuiTextField {...props} label="" className={classes.textField} helperText={null} />
            {props.error && !!props.helperText && <ErrorMessage message={props.helperText as string} size={props.size as string} />}
        </div>
    );
})();
export default AutoCompleteTextField;