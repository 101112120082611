import { Button } from '@mui/joy';
import React from 'react';
import { createUseStyles } from 'react-jss';
import procurmentBanner from '../../../assets/images/procurmentBanner.jpg';
import RequestDemoTemplate from '../../template/Home/RequestDemo/RequestDemo.template';
import roudTickIcon from '../../../assets/icons/roudTickIcon.jpg';
import vendorSentral1 from '../../../assets/images/vendorCentral1.jpg';
import vendorSentral2 from '../../../assets/images/vendorCentral2.jpg';
import platform1 from '../../../assets/images/platform1.jpg';
import platform2 from '../../../assets/images/platform2.jpg';
import platform3 from '../../../assets/images/platform3.jpg';
import platform4 from '../../../assets/images/platform4.jpg';
import platform5 from '../../../assets/images/platform5.jpg';
import financing from '../../../assets/icons/financing.jpg';
import bulk from '../../../assets/icons/bulk.svg';
import visibililty from '../../../assets/icons/visibililty.svg';
import programs from '../../../assets/icons/programs.svg';
import longPartnership from '../../../assets/icons/longPartnership.svg';
import stategy from '../../../assets/icons/stategy.svg';
import logistics from '../../../assets/icons/logistics.svg';
import Vendorinventory from '../../../assets/icons/Vendorinventory.svg';
import reach from '../../../assets/icons/reach.svg';
import vender from '../../../assets/images/vendorCentralBanner.jpg';
import seller from '../../../assets/images/SellerSecondaryBanner.jpg';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import vendorSeller from '../../../assets/images/vendorSeller.svg';
import vendorOrders from '../../../assets/images/vendorOrders.svg';
import vendorWearhouse from '../../../assets/images/vendorWearhouse.svg';
import vendorQuality from '../../../assets/images/vendorQuality.svg';
import vendorPayment from '../../../assets/images/vendorPayment.svg';
import vendorRetailing from '../../../assets/images/vendorRetailing.svg';
import vendorRelationship from '../../../assets/images/vendorRelationship.svg';

interface CardData {
    img: string;
    title: string;
    description: string;
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        width: '1297px',
        margin: '0 auto',
    },
    bannerContainer: {
        width: '100%',
        height: '650px',
        backgroundImage: `url(${vender})`,
        backgroundSize: 'cover',
        position: 'relative',
    },

    bannerSellContainer: {
        padding: '10% 0px',
    },
    heading: {
        fontSize: '56px',
        lineHeight: '66px',
        color: theme.palette.text.primary.primary50,
        "& span": {
            color: theme.palette.text.warning.warning400,
        },
    },
    intoContainer: {
        backgroundColor: theme.palette.background.neutral.neutral50,
    },
    text: {
        fontSize: '32px',
        color: theme.palette.text.primary.primary700,
        lineHeight: "38px",
    },
    primaryText: {
        fontSize: '54px',
        color: '#000',
        lineHeight: '66px',
    },
    decContainer: {
        width: '750px',
        "& p": {
            color: '#000',
            lineHeight: '26px',
            fontWeight: '400',
        },
    },
    sectionHeading: {
        color: "#000",
        fontSize: '54px',
        lineHeight: '66px',
        "& span": {
            color: theme.palette.text.primary.primary500,
        },
        "& p": {
            color: theme.palette.text.success.success500,
        },
    },
    featureInfo: {
        "& h4": {
            color: theme.palette.text.neutral.neutral900,
        },
        "& p": {
            color: theme.palette.text.neutral.neutral800,
            lineHeight: '20px',
        },
    },
    featuresText: {
        width: '651px',
        "& h5": {
            color: '#000',
            fontSize: '22px',
            lineHeight: '26px',
        },
        "& p": {
            color: theme.palette.text.neutral.neutral800,
            lineHeight: '26px',
        },
    },
    headingText: {
        fontSize: '48px !important',
    },
    featuresContainer: {
        "& p": {
            color: theme.palette.text.neutral.neutral600,
        },
        "& span": {
            fontSize: '40px',
            lineHeight: '48px',
            color: theme.palette.text.primary.primary700,
        },
        "& h4": {
            "& span": {
                color: theme.palette.text.primary.primary500,
                fontWeight: '600',
            },
        },
        "& h6": {
            color: theme.palette.text.neutral.neutral900,
            lineHeight: '28px'
        },
    },
    platformHeading: {
        fontSize: '32px',
        color: theme.palette.text.neutral.neutral900,
        lineHeight: '38px',
    },
    platformSubHeading: {
        color: theme.palette.text.neutral.neutral600,
    },
    platformImg: {
        position: "relative"
    },
    platformText: {
        position: 'absolute',
        top: "50%",
        left: '73%',
        width: '100%',
        transform: 'translate(-50%, -50%)',
        color: '#fff',
        lineHeight: '28px',
    },
    borderDiv: {
        background: '#000',
        width: '2px',
        height: '25px',
    },
    platformSecText: {
        color: '#000',
    },
    secondaryPlatform: {
        position: 'relative',
        top: '-87px',
    },
    cardsContainer: {
        background: '#F8F8F8',
    },
    card: {
        border: `1px solid ${theme.palette.border.neutral.neutral200}`,
        background: '#FFF',
        padding: '20px 15px',
        width: '31%',
        "& h4": {
            color: '#000',
            fontSize: '22px',
            lineHeight: '26px',
        },
        "& p": {
            lineHeight: '26px',
        },
    },
    cardIconContainer: {
        border: `1px solid ${theme.palette.border.neutral.neutral200}`,
        background: '#F8F8F8',
    },
    subHeading: {
        color: theme.palette.text.neutral.neutral600,
        lineHeight: '28px',
    },
    tradingContainer: {
        width: '100%',
        height: '480px',
        backgroundImage: `url(${seller})`,
        backgroundSize: 'cover',
        position: 'relative',
    },
    tradingHeading: {
        color: theme.palette.text.primary.primary50,
        fontSize: '32px',
        lineHeight: '38px',
    },
    tradingDes: {
        color: '#fff',
        lineHeight: '26px',
    },
    btn: {
        background: theme.palette.background.primary.primary500,
        color: '#fff',
        fontSize: '16px',
        fontWeight: '700',
        padding: '10px 20px',
    },
    btnArrow: {
        border: '1px solid #fff',
        borderRadius: '50%',
    },
    btn2: {
        background: '#0053A9',
        color: '#fff',
        fontSize: '18px',
        fontWeight: '500',
        padding: '10px 20px',
        lineHeight: '22px',
        width: 'max-content',
    },
    btnArrow2: {
        border: '1px solid transparent',
        background: theme.palette.background.primary.primary500,
        borderRadius: '50%',
        height: '32px',
        width: '32px',
    },
    btn3: {
        background: '#fff',
        color: '#fff',
        fontSize: '18px',
        fontWeight: '500',
        padding: '10px 20px',
        lineHeight: '22px',
        width: 'max-content',
    },
    btnText: {
        color: theme.palette.text.primary.primary500,
    },
    vendorCount: {
        height: '120px',
        width: '120px',
        border: `10px solid ${theme.palette.border.complementary.complementary800}`,
        background: theme.palette.background.complementary.complementary400,
        borderRadius: '60px',
        fontSize: '54px',
        color: '#fff',

    },
    vendorfeatures: {
        position: 'relative',
        right: '-118px',
    },
    vendorfeatures2: {
        position: 'relative',
        left: '-100px',
    },
    venderFeatureText: {
        "& span": {
            color: theme.palette.text.neutral.neutral900,
        },
        "& p": {
            color: theme.palette.text.neutral.neutral900,
            lineHeight: '24px',
        },
    },
    mehDiagonal: {
        '&:after': {
            content: '""',
            borderBottom: `16px solid ${theme.palette.border.complementary.complementary800}`,
            width: '100px',
            position: 'absolute',
            top: '123px',
            left: '34%',
            transform: 'rotate(48deg)',
            transformOrigin: 'top left',
        },
    },
    mehDiagonal2: {
        '&:after': {
            content: '""',
            borderBottom: `16px solid ${theme.palette.border.complementary.complementary800}`,
            width: '101px',
            position: 'absolute',
            top: '120px',
            left: '54%',
            transform: 'rotate(-48deg)',
            transformOrigin: 'top right',
        },
    },
}));

const cardData = [
    {
        img: reach,
        title: "Explore New Markets & Customers",
        description:
            "Connect with a vast network of buyers across domestic & international markets.",
    },
    {
        img: financing,
        title: "Maximize Sales with Lead Generation",
        description:
            "Get regular customer inquiries through our integrated lead management.",
    },
    {
        img: Vendorinventory,
        title: "Cost-Effective Purchases",
        description:
            "Negotiate best deals and explore long-term contracts and partnerships.",
    },
    {
        img: logistics,
        title: "Enhanced Visibility",
        description:
            "Showcase your products to a targeted audience with marketing support.",
    },
    {
        img: bulk,
        title: "Ease of Transactions",
        description:
            "Enjoy smooth order management, secure payments, and on-time delivery services.",
    },
    {
        img: stategy,
        title: "Access to Financing",
        description:
            "Approved sellers can avail of financing solutions for business growth.",
    },
    {
        img: longPartnership,
        title: "Build Long-Term Partnerships",
        description:
            "Collaborate with trusted partners for consistent business growth.",
    },
    {
        img: programs,
        title: "Exclusive Programs & Benefits",
        description:
            "Gain access to unique programs tailored for sellers' success.",
    },
    {
        img: visibililty,
        title: "Improved Market Visibility",
        description:
            "Expand your presence and reach with enhanced market visibility tools.",
    },
];

const VendorCentralTemplate: React.FC = () => {
    const classes = useStyles();

    return (
        <>
            <div className={`${classes.bannerContainer}`}>
                <div className={`${classes.container} ${classes.bannerSellContainer}`}>
                    <h1 className={`${classes.heading} font-semibold`}>Your Gateway to<br /><span>Smarter, Faster</span> & <br />  <span>Profitable </span>Sales</h1>
                </div>
            </div>
            <div className={`${classes.intoContainer}`}>
                <div className={`${classes.container} flex items-center gap-10 py-14`}>
                    <div className=''>
                        <p className={`${classes.text} font-semibold mb-2`}>Welcome to SB Vendor Central
                        </p>
                        <p className={`${classes.primaryText} font-medium`}>About Steelbazaar<br />Vendor Central</p>
                    </div>
                    <div className={classes.decContainer}>
                        <p className='text-lg mb-[15px]'>SteelBazaar Vendor Central is your gateway to a seamless steel trading experience. This platform enables manufacturers, dealers, and distributors to sell their steel materials directly to SteelBazaar, simplifying operations and boosting visibility.
                        </p>
                        <p className='text-lg mb-[15px]'>With a secure, digital-first approach, vendors can easily manage inventory, orders, and payments. While you focus on delivering quality products, SteelBazaar handles logistics, sales, and customer interactions, ensuring effortless access to a wider market.</p>
                        <p className='text-lg'>By partnering with SteelBazaar, you benefit from a trusted marketplace that drives growth, streamlines processes, and takes the hassle out of reaching your customers.</p>
                    </div>
                </div>
            </div>
            <div className={`${classes.container} pt-[110px]`}>
                <h2 className={`${classes.sectionHeading} font-semibold text-center`}>Start Selling on SB Vendor Central</h2>
                <div className='flex gap-[60px] items-center'>
                    <div className='w-[700px]'>
                        <div className={`${classes.featuresContainer} `}>
                            <p className='text-2xl font-medium'>Step 01:</p>
                            <h4 className='mt-[6px] mb-[15px] text-5xl font-semibold'>Invitation and <span className={`${classes.headingText} text-5xl`}>Registration</span></h4>
                        </div>
                        <div className={`${classes.featureInfo}`}>
                            <h4 className='text-2xl font-medium'>Join Our Exclusive Vendor Community</h4>
                            <p className='text-base'>Seamless Integration into the SteelBazaar Ecosystem</p>
                            <div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Invitation-Only Access</h5>
                                        <p className='text-lg'>SteelBazaar Vendor Central is an invitation-only program. Once invited, register to gain access to the platform.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Simple Registration Process</h5>
                                        <p className='text-lg'>To get started, complete the registration form with your business details.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div><img src={vendorSentral1} alt="" /></div>
                </div>
            </div>
            <div className={`${classes.container} pt-10`}>
                <h2 className={`${classes.platformHeading} font-semibold mb-1 text-center`}>Secure Platform Access</h2>
                <p className={`${classes.platformSubHeading} font-medium text-2xl text-center mb-[60px]`}>Access our secure vendor portal immediately upon registration.</p>
                <div className='flex pt-20'>
                    <div className='flex flex-col items-center'>
                        <div className={`${classes.platformImg}`}>
                            <img src={platform1} alt="" />
                            <div className={`${classes.platformText} text-2xl font-bold`}>
                                Register on <br />Vendor Central
                            </div>
                        </div>
                        <div className={classes.borderDiv}></div>
                        <p className={`${classes.platformSecText} font-medium text-sm text-center`}>Create an account and submit the <br /> necessary business documents.</p>
                    </div>
                    <div className={`${classes.secondaryPlatform} flex flex-col items-center`}>
                        <p className={`${classes.platformSecText} font-medium text-sm text-center`}>Upload relevant business <br /> certifications and bank details for <br /> verification</p>
                        <div className={classes.borderDiv}></div>
                        <div className={`${classes.platformImg}`}>
                            <img src={platform2} alt="" />
                            <div className={`${classes.platformText} text-2xl font-bold`}>
                                Business <br />Verification
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col items-center'>
                        <div className={`${classes.platformImg}`}>
                            <img src={platform3} alt="" />
                            <div className={`${classes.platformText} text-2xl font-bold`}>
                                Inventory <br />Submission
                            </div>
                        </div>
                        <div className={classes.borderDiv}></div>
                        <p className={`${classes.platformSecText} font-medium text-sm text-center`}>Create an account and submit the <br /> necessary business documents.</p>
                    </div>
                    <div className={`${classes.secondaryPlatform} flex flex-col items-center`}>
                        <p className={`${classes.platformSecText} font-medium text-sm text-center`}>Upload relevant business <br /> certifications and bank details for <br /> verification</p>
                        <div className={classes.borderDiv}></div>
                        <div className={`${classes.platformImg}`}>
                            <img src={platform4} alt="" />
                            <div className={`${classes.platformText} text-2xl font-bold`}>
                                Vendor <br />Approval
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col items-center'>
                        <div className={`${classes.platformImg}`}>
                            <img src={platform5} alt="" />
                            <div className={`${classes.platformText} text-2xl font-bold`}>
                                Onboarding
                            </div>
                        </div>
                        <div className={classes.borderDiv}></div>
                        <p className={`${classes.platformSecText} font-medium text-sm text-center`}>Create an account and submit the <br /> necessary business documents.</p>
                    </div>
                </div>
            </div>
            <div className={`${classes.container} pt-[110px]`}>
                <div className='flex gap-[60px] items-center'>
                    <div className='w-[700px]'>
                        <div className={`${classes.featuresContainer} `}>
                            <p className='text-2xl font-medium'>Step 02:</p>
                            <h4 className='mt-[6px] mb-[15px] text-5xl font-semibold'>Showcase <span className={`${classes.headingText} text-5xl`}>Your Products</span></h4>
                        </div>
                        <div className={`${classes.featureInfo}`}>
                            <div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Digital Product Catalogs</h5>
                                        <p className='text-lg'>Submit your product listings and wholesale prices through the Vendor Central portal.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Customisable Storefront</h5>
                                        <p className='text-lg'>Build a branded storefront for product listings</p>
                                    </div>
                                </div>  <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Advanced Analytics</h5>
                                        <p className='text-lg'>Access real-time data to monitor performance and inform strategic decisions.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div><img src={vendorSentral2} alt="" /></div>
                </div>
            </div>
            <div className='py-[110px]'>
                <div className={`${classes.featuresContainer} ${classes.container}`}>
                    <p className='text-2xl font-medium'>Step 03:</p>
                    <h4 className='mt-[6px] text-5xl font-semibold'>Purchase Orders to <span className={`${classes.headingText} text-5xl`}>Retailing</span></h4>
                    <div className={`${classes.featureInfo}`}>
                        <h4 className='text-2xl font-medium'>Streamlined Process from Orders to Customer Delivery</h4>
                    </div>
                </div>
                <div className={`${classes.container} flex flex-col items-center pt-[60px]`}>
                    <div className={`${classes.vendorfeatures} flex items-center `}>
                        <img src={vendorSeller} alt="" />
                        <div className={`${classes.vendorCount} font-medium flex items-center justify-center`}>01</div>
                        <div className={`${classes.venderFeatureText} w-[550px] pl-5`}>
                            <span className='text-2xl font-medium'>Start Selling</span>
                            <p className='text-lg mt-[10px]'>Once approved, you will receive purchase orders from <br /> Steelbazaar based on demand.</p>
                        </div>
                        <div
                            className={`${classes.mehDiagonal}`}
                        ></div>
                    </div>
                    <div className={`${classes.vendorfeatures2} flex items-center`}>
                        <div className={`${classes.venderFeatureText} w-[550px] pr-5 text-right`}>
                            <span className='text-2xl font-medium'>Purchase Orders</span>
                            <p className='text-lg mt-[10px]'>SteelBazaar sends you purchase orders based on demand <br /> forecasts.</p>
                        </div>
                        <div className={`${classes.vendorCount} font-medium flex items-center justify-center`}>02</div>
                        <img src={vendorOrders} alt="" className='ml-5' />
                        <div
                            className={`${classes.mehDiagonal2}`}
                        ></div>
                    </div>
                    <div className={`${classes.vendorfeatures} flex items-center `}>
                        <img src={vendorWearhouse} alt="" className='pr-5' />
                        <div className={`${classes.vendorCount} font-medium flex items-center justify-center`}>03</div>
                        <div className={`${classes.venderFeatureText} w-[550px] pl-5`}>
                            <span className='text-2xl font-medium'>Fulfilment</span>
                            <p className='text-lg mt-[10px]'>Ship the ordered products to SteelBazaar's fulfilment centres.</p>
                        </div>
                        <div
                            className={`${classes.mehDiagonal}`}
                        ></div>
                    </div>
                    <div className={`${classes.vendorfeatures2} flex items-center`}>
                        <div className={`${classes.venderFeatureText} w-[550px] pr-5 text-right`}>
                            <span className='text-2xl font-medium'>Quality Assurance</span>
                            <p className='text-lg mt-[10px]'>Steelbazaar inspects and verifies the quality of received<br />products.</p>
                        </div>
                        <div className={`${classes.vendorCount} font-medium flex items-center justify-center`}>04</div>
                        <img src={vendorQuality} alt="" />
                        <div
                            className={`${classes.mehDiagonal2}`}
                        ></div>
                    </div>
                    <div className={`${classes.vendorfeatures} flex items-center `}>
                        <img src={vendorPayment} alt="" />
                        <div className={`${classes.vendorCount} font-medium flex items-center justify-center`}>05</div>
                        <div className={`${classes.venderFeatureText} w-[550px] pl-5`}>
                            <span className='text-2xl font-medium'>Payment</span>
                            <p className='text-lg mt-[10px]'>Upon successful delivery and quality check, payments are<br />released to your account as per the agreed terms..</p>
                        </div>
                        <div
                            className={`${classes.mehDiagonal}`}
                        ></div>
                    </div>
                    <div className={`${classes.vendorfeatures2} flex items-center`}>
                        <div className={`${classes.venderFeatureText} w-[550px] pr-5 text-right`}>
                            <span className='text-2xl font-medium'>Retailing</span>
                            <p className='text-lg mt-[10px]'>SteelBazaar manages the retailing process, including customer<br />service and returns.</p>
                        </div>
                        <div className={`${classes.vendorCount} font-medium flex items-center justify-center`}>06</div>
                        <img src={vendorRetailing} alt="" />
                        <div
                            className={`${classes.mehDiagonal2}`}
                        ></div>
                    </div>
                    <div className={`${classes.vendorfeatures} flex items-center `}>
                        <img src={vendorRelationship} alt="" />
                        <div className={`${classes.vendorCount} font-medium flex items-center justify-center`}>07</div>
                        <div className={`${classes.venderFeatureText} w-[550px] pl-5`}>
                            <span className='text-2xl font-medium'>Long-Term Relationship</span>
                            <p className='text-lg mt-[10px]'>Continue to receive regular orders from Steelbazaar based on<br />performance, product demand, and supply consistency</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className={classes.cardsContainer}>
                <div className={`${classes.container} pt-[110px] pb-[110px]`}>
                    <div>
                        <h2
                            className={`${classes.sectionHeading} font-semibold text-center`}
                        >
                            Unlock Opportunities with SB Vendor Central
                        </h2>
                    </div>
                    <div className="flex gap-[40px] flex-wrap mt-[50px]">
                        {cardData.map((card, index) => (
                            <div key={index} className={`${classes.card} rounded-lg`}>
                                <div
                                    className={`${classes.cardIconContainer} flex items-center justify-center p-[10px] w-[82px] h-[82px] rounded-2xl`}
                                >
                                    <img src={card.img} alt={card.title} />
                                </div>
                                <h4 className="mt-[10px] mb-[10px] font-medium">{card.title}</h4>
                                <p className="text-base">{card.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className={classes.tradingContainer}>
                <div className={`${classes.container} pt-[130px] pb-[60px]`}>
                    <h2 className={`${classes.tradingHeading} font-semibold`}>Start Selling with Steelbazaar Today</h2>
                    <div className='w-[570px] mb-[30px]'>
                        <p className={`${classes.tradingDes} mt-[10px] text-lg`}>Ready to expand your business with Steelbazaar? Join Vendor <br />Central today and take the first step toward a successful partnership. <br /> Click below to register and start selling to Steelbazaar now</p>
                    </div>
                    <Button className={`${classes.btn3} font-bold text-base flex items-center gap-[10px] px-5 py-[10px]`}> <span className={classes.btnText}>Register Now</span><div className={`${classes.btnArrow2} flex items-center justify-center`}><ArrowForwardIcon /></div></Button>
                </div>
            </div>
        

            <RequestDemoTemplate />
        </>
    );
};

export default VendorCentralTemplate;
