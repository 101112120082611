import React, { useEffect, useMemo, useState } from "react";
import { CART_SECTIONS } from "../../../utils/types";
import CartItemDetail, { ICartItem, IContext } from "./CartItemDetail.template";
import CartPaymentDetailTemplate from "./CartPaymentTerms.template";
import CartReviewTemplate from "./CartDetailsReview.template";
import { PAYMENT_METHODS } from "../../../utils/constant";

interface CartDetailsProps {
	cartItemDetails: ICartItem[] | [];
	handleDeleteCartItem: (cartId: number) => void;
	handleCartItemUpdate: (cartId: number, key: string, value: number | string) => void;
	configuration: IContext;
	handleRfq: () => void;
	onSelectPaymentTerm: (term: string) => void;
	currentSection: string;
	setCurrentSectionTo: (section: CART_SECTIONS) => void;
	paymentMethod: string;
	setCartItemsTo: (cartItem: ICartItem[] | []) => void;
}

const CartTemplate: React.FC<CartDetailsProps> = ({ cartItemDetails, handleDeleteCartItem, handleCartItemUpdate, configuration, handleRfq, onSelectPaymentTerm, currentSection, setCurrentSectionTo, paymentMethod, setCartItemsTo}) => {
	const cartDetailsSectionProps = {
		setCurrentSectionTo: setCurrentSectionTo,
		cartItemDetails,
		handleDeleteCartItem,
		handleCartItemUpdate,
		configuration,
		handleRfq,
		paymentMethod,
		setCartItemsTo
	};
	const [paymentType, selectedPaymentTypeTo] = useState<string>(PAYMENT_METHODS.CASH_PAYMENT);

	useEffect(() => {
		selectedPaymentTypeTo(paymentMethod);
	}, [paymentMethod])

	const handleSelectPaymentTerm = (term: string) => {
		selectedPaymentTypeTo(term);
		onSelectPaymentTerm(term);
	};

	const cartDetailsReviewProps = {
		cartItemDetails,
		handleDeleteCartItem,
		handleCartItemUpdate,
		configuration,
		handleRfq,
		paymentType,
		setCartItemsTo
	};

	const cartSectionView = useMemo(() => {
		switch (currentSection) {
			case CART_SECTIONS.CART_DETAILS:
				return <CartItemDetail {...cartDetailsSectionProps} />;
			case CART_SECTIONS.PAYMENT_SECTION:
				return (
					<CartPaymentDetailTemplate
						onSelectPaymentTerm={handleSelectPaymentTerm}
						setCurrentSectionTo={setCurrentSectionTo}
						cartItemDetails={cartItemDetails}
						configuration={configuration}
						handleRfq={handleRfq}
						paymentMethod={paymentType}
					/>
				);
			case CART_SECTIONS.CART_REVIEW:
				return <CartReviewTemplate {...cartDetailsReviewProps} />;
			default:
				return (
					<CartItemDetail {...cartDetailsSectionProps} />);
		}
	}, [currentSection]);

	return (
		<div>
			{cartSectionView}
		</div>
	);
};

export default CartTemplate;