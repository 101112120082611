import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';
import AboutUsDropdown from './AboutUsDropdown';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PlateformDropdown from './PlateformDropdown';
import ServiceDropDown from './ServiceDropDown';
import TradeDropdown from './TradeDropdown';
import MembershipDropdown from './MembershipDropdown';
import SupplierDropdown from './SupplierDropdown';
import BuyerDropdown from './BuyerDropdown.template';
import ContactUsDropdown from './ContactUsDropdown.template';
import HelpCenterDropdown from './HelpCenterDropdown.template';

const useStyles = createUseStyles((theme: any) => ({
    container: {},
    homeSection: {
        color: theme.palette.text.primary.primary700,
        fontWeight: '500',
    },
    dropdown: {
        color: theme.palette.text.primary.primary700,
        transition: 'max-height 4s ease-in-out',
    },
    navbarOptions: {
        color: theme.palette.text.primary.primary700,
    },
    dropdownContainer: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        gap: '2px',
        padding: '4px',
        borderRadius: '4px',
        '&:hover': {
            backgroundColor: theme.palette.background.primary.primary50,
        },
    },
    rightDropdown: {
        color: theme.palette.text.primary.primary700,
        transition: 'max-height 4s ease-in-out',
        "&:hover": {
            background: theme.palette.background.primary.primary50,
        },
        zIndex:"1000"
    },
    rightDropDownOption: {
        border: `1px solid ${theme.palette.border.primary.primary50}`,
        boxShadow: "0px 4px 24px 0px rgba(29, 54, 121, 0.16)"
    },
    rightDropDownOptionValue: {
        color: theme.palette.text.neutral.neutral400,
    }
}));

const NavbarDropdownTemplate: React.FC = () => {
    const [hoveredDropdown, setHoveredDropdown] = useState<string | null>(null);
    const [hovered, setHovered] = useState<number | null>(null);
    const navigate = useNavigate();

    const classes = useStyles();

    const handleItemClick = (path: string) => {
        navigate(path);
    };

    const renderDropdown = (
        label: string,
        hoveredDropdown: string | null,
        setHoveredDropdown: (label: string | null) => void,
        DropdownComponent: React.FC,
    ) => {
        const isOpen = hoveredDropdown === label;
        return (
            <div
                className={classes.dropdownContainer}
                onMouseEnter={() => setHoveredDropdown(label)}
                onMouseLeave={() => setHoveredDropdown(null)}
                style={{ backgroundColor: isOpen ? '#D6DDF5' : 'transparent' }}
            >
                <div className={`font-medium text-base ${classes.homeSection}`}>{label}</div>
                {isOpen ? (
                    <ExpandLessIcon className={classes.homeSection} />
                ) : (
                    <ExpandMoreIcon className={classes.homeSection} />
                )}
                <div className={`${classes.dropdown}`}>
                    <div className="m-auto max-container">
                        {isOpen && <DropdownComponent />}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="flex justify-between w-full items-center">
            <div className={`${classes.navbarOptions} flex gap-x-[10px] items-center`}>
                {renderDropdown("About Us", hoveredDropdown, setHoveredDropdown, AboutUsDropdown )}
                {renderDropdown("Platform", hoveredDropdown, setHoveredDropdown, PlateformDropdown )}
                {renderDropdown("Services", hoveredDropdown, setHoveredDropdown, ServiceDropDown )}
                {renderDropdown("Membership", hoveredDropdown, setHoveredDropdown, MembershipDropdown,)}
                {renderDropdown("Trade Assurance", hoveredDropdown, setHoveredDropdown, TradeDropdown )}
            </div>

            <div className={`${classes.navbarOptions} flex gap-x-[10px] items-center`}>
                {renderDropdown("Suppliers", hoveredDropdown, setHoveredDropdown, SupplierDropdown )}
                {renderDropdown("Buyer", hoveredDropdown, setHoveredDropdown, BuyerDropdown )}
                {renderDropdown("Contact Us", hoveredDropdown, setHoveredDropdown, ContactUsDropdown )}
                {renderDropdown("Help Center", hoveredDropdown, setHoveredDropdown, HelpCenterDropdown )}
            </div>
        </div>
    );
};

export default NavbarDropdownTemplate;
