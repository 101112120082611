import React, { useRef } from 'react';
import { createUseStyles } from 'react-jss';
import Carousel from "react-multi-carousel";
import CarouselRef from "react-multi-carousel";
import steelBazaarLogo from "../../../assets/images/steelBazaarLogo.svg";
import onboarding1 from '../../../assets/images/onboarding1.svg'
import onboarding2 from '../../../assets/images/onboarding2.svg'
import onboarding3 from '../../../assets/images/onboarding3.svg'

const useStyles = createUseStyles((theme: any) => ({
    carouselContainer: {
        "& .react-multiple-carousel__arrow--right": {
            right: "0 !important"
        },
        "& .react-multiple-carousel__arrow--left": {
            left: "0 !important"
        },
        "& .react-multi-carousel-list": {
            borderRadius: "8px",
        },
        maxWidth: "344px",
    },
    carouselBackground: {
        background: theme.palette.background.neutral.neutral1000,
        maxWidth: "332px",
        minHeight:"600px",
        padding: "70px 38px 140px 38px"
    },
    text: {
        color: theme.palette.text.primary.primary1000,
        lineHeight: '25px',
        marginTop: '28px',
    }
}));

const carouselItems = [
    {
        title: 'Secure Payments',
        image: onboarding1
    },
    {
        title: 'Transparent Process',
        image: onboarding2
    },
    {
        title: '100% Original Products',
        image: onboarding3
    },
];

const OnBoardingCarouselTemplate: React.FC = () => {
    const classes = useStyles()
    const carouselRef = useRef<CarouselRef>(null);
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1280 },
            items: 1,
            partialVisibilityGutter: 10,
        },
        tablet: {
            breakpoint: { max: 1280, min: 980 },
            items: 1,
            partialVisibilityGutter: 10,
        },
        mobile: {
            breakpoint: { max: 980, min: 670 },
            items: 1,
            partialVisibilityGutter: 10,
        },
        "sm-mobile": {
            breakpoint: { max: 670, min: 0 },
            items: 1,
        },
    };

    return (
        <div className={`${classes.carouselBackground}  rounded-2xl  items-center`}>
            <div className=''>
                <div className={`${classes.carouselContainer} rounded-lg mx-auto `}>
                    <Carousel
                        responsive={responsive}
                        infinite={true}
                        partialVisible={false}
                        ref={carouselRef}
                        removeArrowOnDeviceType={["desktop", "tablet"]}
                        autoPlay={true}
                        autoPlaySpeed={2000}
                        slidesToSlide={1}
                        showDots={true}
                    >
                        {carouselItems.map((item) => (
                            <div style={{ height: "200px" }}>
                                <img className='flex justify-center' src={item.image} alt="" />
                            </div>
                        ))}
                    </Carousel>
                </div>
                <div className={`${classes.text} text-center font-semibold text-lg `}>Welcome to SteelBazaar: <br/> Unlock Big Savings in Steel  <br/>  Procurement with Smart  <br/> Digital Solutions, Log in Now</div>
            </div>
        </div>
    );
};

export default OnBoardingCarouselTemplate;