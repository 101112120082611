import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NavbarDropdownTemplate from './NavbarDropdowns/NavbarDropdown.template';
import CategoriesDropdown from './NavbarDropdowns/CategoriesDropdown';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        borderTop: `1px solid ${theme.palette.border.neutral.neutral200}`,
    },
    primaryContainer: {
    },
    homeSection: {
        borderRight: `1px solid ${theme.palette.border.primary.primary700}`,
        cursor: 'pointer',
        color: theme.palette.text.primary.primary700,
    },
    categories: {
        color: theme.palette.text.neutral.neutral600,
    },
    homemenu: {
        position: 'relative',
        color: theme.palette.text.primary.primary700,
        cursor: 'pointer',

        '&:hover $dropDownContent': {
            display: 'block',
        },
    },
    dropDownContent: {
        display: 'none',
        position: 'absolute',
        left: 0,
        backgroundColor: theme.palette.background.paper,
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        zIndex: 1000,
        width: '200px',
        top: '0px', 
    },
}));

const NavbarOptions: React.FC = () => {
    const classes = useStyles();
    return (
        <div className={`${classes.container} w-full`}>
            <div className={`${classes.primaryContainer} flex items-center py-[9px] w-[1300px] mx-auto`}>
                <div className="flex gap-x-2 items-center pr-4">
                    <div className={`${classes.homemenu}`}>
                        <MenuIcon />
                        <div className={`${classes.dropDownContent}`}>
                            <CategoriesDropdown />
                        </div>
                    </div>
                    <div
                        className={`font-medium text-base ${classes.homeSection} pr-3 relative`}
                    >
                        Categories
                    </div>
                </div>
                <NavbarDropdownTemplate />
            </div>
        </div>
    );
};

export default NavbarOptions;