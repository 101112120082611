import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss';
import Tabs, { ITabsSchema } from '../../../molecules/Tabs/Tabs';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Button from '../../../atoms/Button/Button';
import AddNewInvetoryModalTemplate from './AddNewInvetoryModal.template';
import EmptyInventoryStateTemplate from './EmptyInventoryState.template';
import ViewEyeIcon from '../../../../assets/icons/outlineView.svg';
import deleteOutlinedPrimary800 from '../../../../assets/icons/deleteOutlinedPrimary800.svg';
import { FormikProps, useFormik } from 'formik';
import { IAddNewInventoryDetail, IAddVendorInventoryForm } from './AddNewVendorInventory.template';
import { HTTP_STATUS, STATUS } from '../../../../utils/types';
import { useSnackbar } from '../../../../hooks/useSnackBar';
import { capitalizeFirstLetter, convertConstantsToCamelCase, Enum, enumToString } from '../../../../utils/helper';
import * as Yup from 'yup';
import { useAuthenticatedUser } from '../../../../hooks/useAuthenticatedUser';
import ConfirmPrimaryChangeModal from '../../OnBoarding/ConfirmPrimaryChangeModal.templeate';
import SuccessFailurePopup from '../../../molecules/SuccessFailurePopup/SuccessFailurePopup';
import { ColumnType } from '../../../organisms/table';
import { useVendorInventoryService } from '../../../../services/useVendorInventoryService';
import TableV2 from '../../../organisms/TableV2';
import ResourceStatusV2 from '../../../atoms/ResourceStatusV2/ResourceStatusV2';

const useStyles = createUseStyles((theme: any) => ({
    webContainer: {
        display: "grid"
    },
    UnTouchProgressBar: {
        backgroundColor: theme.palette.border.neutral.neutral100
    },
    barHeading: {
        color: theme.palette.text.primary.primary500
    },
    textHeading: {
        color: theme.palette.text.primary.primary900
    },
    selectBox: {
        border: `1px solid ${theme.palette.background.neutral.neutral100}`,
    },
    search: {
        margin: "0",
        background: theme.palette.background.neutral.neutral400,
    },
    searchIcon: {
        color: theme.palette.background.neutral.neutral400
    },
    textHeading2: {
        color: theme.palette.text.primary.primary900
    },
    radioColor: {
        color: theme.palette.text.primary.primary400
    },
    textHeading3: {
        color: theme.palette.background.primary.primary900
    },
    lineColor: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    UsnText: {
        color: theme.palette.text.complementary.complementary600
    },
    locationText: {
        color: theme.palette.text.neutral.neutral700
    }
}));


export enum INVENTORY_WAREHOUSE {
    DELHI = 1,
    MUMBAI = 2,
}
export interface IIVendorInventory {
    id: number,
    usnNumber: string;
    VendorUsnNumber: string;
    GrossWeight: number;
    netWeight: number;
    packingType: string;
    ageingDays: number;
    entryDate: number;
    MillTestCertificateLink: string;
}
interface IAddVendorInventoryFormTemplate {
    formik: FormikProps<IAddVendorInventoryForm>;
    buttonShow?: boolean;
    setWareHouseId?: Dispatch<SetStateAction<number>>;
    editOn?: boolean;
    modalFormikEdit?: FormikProps<IModalFormikForm>;
    existingWarehouseDetails?: IAddNewInventoryDetail[];
}

interface IInventoryAddViewProps {
    warehouseId: number;
}

export interface IModalFormikForm {
    wareHouses: IAddNewInventoryDetail[];
}

const AddInventorysectionTemplate: React.FC<IAddVendorInventoryFormTemplate> = ({ formik, modalFormikEdit, buttonShow, setWareHouseId, editOn, existingWarehouseDetails }) => {
    const classes = useStyles();
    const [searchParams, setSearchParams] = useSearchParams();
    const { user } = useAuthenticatedUser();
    const [activeTab, setActiveTab] = useState(INVENTORY_WAREHOUSE.DELHI);
    const [gstinModalDialogOpen, setGstinModalDialogOpen] = useState<boolean>(false);
    const vendorInventoryService = useVendorInventoryService();
    const [warehouseData, setWarehouseData] = useState<{ id: number, name: string }[]>([{ id: 0, name: '' }]);
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const [availableStock, setAvailabelStockTo] = useState<number | null>(null);
    const [totalUsnNo, setTotalUsnNo] = useState<number | null>(null);
    const [isMillTestSelected, setIsMillTestSelectedTo] = useState<boolean>(false);
    const [selectedFile, setSelectedFileTo] = useState<File | null>(null);

    const initialValues: IModalFormikForm = {
        wareHouses: [{
            warehouseId: 0,
            vendorUsnCode: '',
            grossWeight: 0,
            netWeight: 0,
            packagingType: '',
            isMillTest: false,
            millTestCertificate: null,
        }]
    };

    const validationSchema = Yup.object().shape({
        wareHouses: Yup.array().of(
            Yup.object().shape({
                vendorUsnCode: Yup.string(),
                grossWeight: Yup.number().min(1, 'Gross Weight must be greater than 0').required('Gross Weight is required'),
                netWeight: Yup.number().min(1, 'Net Weight must be greater than 0').required('Net Weight is required'),
                packagingType: Yup.string().required('Packaging Type is required'),
            })
        )
    });

    const modalFormik = useFormik<IModalFormikForm>({
        initialValues,
        validationSchema,
        onSubmit: async () => {
            if (editOn && modalFormikEdit) {
                const updatedWarehouseDetails = [
                    ...modalFormikEdit.values.wareHouses, 
                    ...modalFormik.values.wareHouses.map((warehouse) => ({
                        ...warehouse,
                        millTestCertificate: selectedFile,
                        isMillTest: isMillTestSelected, 
                    }))
                ];
                modalFormikEdit.setFieldValue('wareHouses', updatedWarehouseDetails);
            } else {
                const updatedWarehouseDetails = [
                    ...formik.values.warehouseDetails,
                    ...modalFormik.values.wareHouses.map((warehouse) => ({
                        ...warehouse,
                        millTestCertificate: selectedFile,
                        isMillTest: isMillTestSelected,
                    }))
                ];
                formik.setFieldValue('warehouseDetails', updatedWarehouseDetails);
            }
            modalFormik.setFieldValue('wareHouses', []);
            modalFormik.setTouched({});
            setSelectedFileTo(null);
            setIsMillTestSelectedTo(false)
            setGstinModalDialogOpen(false);
        },
    });
   
    const fetchWarehouse = async () => {
        if (user?.businessId) {
            try {
                const wareHouseResponse = await vendorInventoryService.getAllWarehouseMappedVendor({ vendorId: user?.businessId, status: STATUS.ACTIVE });
                if (wareHouseResponse.status === HTTP_STATUS.OK) {
                    const warehouses = wareHouseResponse?.data?.data?.content;
                    setWarehouseData(warehouses);
                }
            } catch (error) {
                showSnackbar('error', 'Business Profile fetch failed');
            }
        }

    };
    useEffect(() => {
        fetchWarehouse();
    }, [user?.businessId]);

    const InventoryAddView: React.FC<IInventoryAddViewProps> = ({ warehouseId }) => {
        const classes = useStyles();
        const navigate = useNavigate();
        const [vendorInventories, setVendorInventoriesTo] = useState<IAddNewInventoryDetail[]>(formik.values.warehouseDetails);
        const [vendorInventoriesEdit, setVendorInventoriesEditTo] = useState<IAddNewInventoryDetail[]>(modalFormikEdit ? modalFormikEdit.values.wareHouses : []);
        const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
        const [showTable, setShowTable] = useState<boolean>(false);
        const [deleteIndex, setDeleteIndex] = useState<number | null>(null);
        const handleChange = () => {
            setGstinModalDialogOpen(true);
            const newWarehouseDetail = {
                warehouseId: warehouseId,
                vendorUsnCode: '',
                grossWeight: 0,
                netWeight: 0,
                packagingType: '',
                isMillTest: false,
            };
            modalFormik.setFieldValue('wareHouses', [newWarehouseDetail])
        }

        const handleDelete = (index: number) => {
            setIsDeleteModalOpen(true);
            setDeleteIndex(index);
        }

        const handleDialogClose = (event: boolean) => {
            if (event === true && deleteIndex != null) {
                setIsDeleteModalOpen(false);
                const newWarehouseDetails = [...formik.values.warehouseDetails]
                newWarehouseDetails[deleteIndex] = {
                    ...newWarehouseDetails[deleteIndex],
                    status: Enum.ADJUST_OUT
                };
                formik.setFieldValue('warehouseDetails', newWarehouseDetails);
                modalFormikEdit?.setFieldValue('wareHouses',newWarehouseDetails);
                showSnackbar('success', 'Inventory Deleted Successfully');
                calculateTotalSizeOfInventory()
            } else {
                setIsDeleteModalOpen(false);
            }
            if (event === true) {
                setSelectedFileTo?.(null);
            }
        };

        const Action = (id: number) => (
            <div className='flex items-center justify-center gap-x-3' title=''>
                <button onClick={() => handleDelete(id)}><img src={deleteOutlinedPrimary800} alt={deleteOutlinedPrimary800} /></button>
            </div>
        )
        
        useEffect(() => {
            const records = getRecordsEdit();
            if (records && records.length === 0) {
                setShowTable(false);
            } else {
                setShowTable(true);
            }
        }, [warehouseId, vendorInventoriesEdit]);

        const getTableColumns = (edit: boolean) => {
            const columns = [
                { label: "S No.", key: "id", type: "number" as ColumnType, props: { className: '' } },
                { label: "USN Number", key: "vendorUsnNumber", type: "custom" as ColumnType, props: { className: '' } },
                { label: "Vendor USN Number", key: "GrossWeight", type: "custom" as ColumnType, props: { className: '' } },
                { label: "Gross Weight(MT)", key: "thickness", type: "custom" as ColumnType, props: { className: '' } },
                { label: "Net Weight (MT)", key: "netWeight", type: "custom" as ColumnType, props: { className: '' } },
                { label: "Packaging Type", key: "packagingType", type: "custom" as ColumnType, props: { className: '' } },
                { label: "Ageing Days", key: "ageingDays", type: "custom" as ColumnType, props: { className: '' } },
                { label: "Entry Date", key: "entryDate", type: "custom" as ColumnType, props: { className: '' } },
                { label: "Status", key: "status", component: ({ value }: { value: string }) => <ResourceStatusV2 status={value} />, type: "custom" as ColumnType, props: {} },
                { label: "Action", key: "action", type: "custom" as ColumnType, props: { className: '' } }
            ];
            if (edit) {
                return columns.slice(0,-1);
            }
            return columns;
        };
        const getRecords = (): any[][] => {
            let records = [];
            if (existingWarehouseDetails && existingWarehouseDetails.length > 0) {
                records = existingWarehouseDetails 
            }else{
                records = vendorInventories
            }
            return records.filter((vendorInventory: IAddNewInventoryDetail) => {
                return vendorInventory.warehouseId ? vendorInventory.warehouseId === warehouseId : true;
            })
            .map((vendorInventory: IAddNewInventoryDetail, index) => ([
                index + 1,
                vendorInventory.usnCode ?? "NA",
                vendorInventory.vendorUsnCode ?? "NA",
                vendorInventory.grossWeight,
                vendorInventory.netWeight,
                convertConstantsToCamelCase(vendorInventory.packagingType),
                vendorInventory.ageingDays ?? "0",
                vendorInventory.entryDate ?? "NA",
                enumToString(vendorInventory?.status ?? "ACTIVE"),
                vendorInventory?.status === "ACTIVE" ? Action(index) : null
            ]));
        };

        const getRecordsEdit = () => {
            return vendorInventoriesEdit
                .filter((vendorInventory: IAddNewInventoryDetail) => {
                    return vendorInventory.warehouseId && vendorInventory.warehouseId === warehouseId;
                })
                .map((vendorInventory: IAddNewInventoryDetail, index) => ([
                    index + 1,
                    vendorInventory.usnCode ? vendorInventory.usnCode : "NA",
                    vendorInventory.vendorUsnCode ? vendorInventory.vendorUsnCode : "NA",
                    vendorInventory.grossWeight,
                    vendorInventory.netWeight,
                    convertConstantsToCamelCase(vendorInventory.packagingType),
                    vendorInventory.ageingDays ? vendorInventory.ageingDays : "0",
                    vendorInventory.entryDate ? vendorInventory.entryDate : "NA",
                    enumToString(vendorInventory?.status ?? "ACTIVE"),
                    vendorInventory?.status === "ACTIVE" ? Action(index) : null
                ]));
       
        };

        const getSchema = () => ({
            id: "1",
            pagination: {
                total: 0,
                currentPage: 0,
                isVisible: false,
                limit: 1,
                handleChangePage: () => { },
                handleChangeRowsPerPage: () => { }
            },
            columns: getTableColumns(!editOn) ?? []
        })

        const handleAddOtherInventory = () => {
            setGstinModalDialogOpen(true);
            const newWarehouseDetail: IAddNewInventoryDetail = {
                warehouseId: warehouseId,
                vendorUsnCode: '',
                grossWeight: 0,
                netWeight: 0,
                packagingType: '',
                isMillTest: false,
                millTestCertificate: null,
            };
            modalFormik.setFieldValue('wareHouses', [newWarehouseDetail]);
        }
        useEffect(() => {
            setWareHouseId && setWareHouseId(warehouseId);
        }, [modalFormik.values.wareHouses])

        useEffect(() => {
            calculateTotalSizeOfInventory()
        }, [vendorInventories ])

        const calculateTotalSizeOfInventory = () => {
            let totalCalculatedSize: number = 0;
            const hasValidEditEntries = vendorInventoriesEdit.some((vendorInventory: IAddNewInventoryDetail) => 
                vendorInventory?.warehouseId !== 0 
            );
            if (hasValidEditEntries) {
                vendorInventoriesEdit
                    .filter((vendorInventory: IAddNewInventoryDetail) => 
                        vendorInventory.warehouseId ? vendorInventory.warehouseId === warehouseId : true
                    )
                    .forEach((vendorInventory: IAddNewInventoryDetail) => {
                        if (vendorInventory.status != "ADJUST_OUT") {
                            totalCalculatedSize += Number(vendorInventory.netWeight) || 0;
                        }
                    });
                    const hasValidEditEntries3 = vendorInventoriesEdit.some((vendorInventory: IAddNewInventoryDetail) => 
                        vendorInventory?.id 
                    );
                    if(!hasValidEditEntries3){
                        vendorInventories
                        .filter((vendorInventory: IAddNewInventoryDetail) => 
                            vendorInventory.warehouseId ? vendorInventory.warehouseId === warehouseId : true
                        )
                        .forEach((vendorInventory: IAddNewInventoryDetail) => {
                            if (vendorInventory.status != "ADJUST_OUT") {
                                totalCalculatedSize += Number(vendorInventory.netWeight) || 0;
                            }
                        });
                    }
            } else {
                vendorInventories
                    .filter((vendorInventory: IAddNewInventoryDetail) => 
                        vendorInventory.warehouseId ? vendorInventory.warehouseId === warehouseId : true
                    )
                    .forEach((vendorInventory: IAddNewInventoryDetail) => {
                        if (vendorInventory.status != "ADJUST_OUT") {
                            totalCalculatedSize += Number(vendorInventory.netWeight) || 0;
                        }
                    });
            }
            const Totallength = vendorInventories.filter((vendorInventory: IAddNewInventoryDetail) => 
                vendorInventory.warehouseId ? vendorInventory.warehouseId === warehouseId : true
            ).length;
        
            const TotallengthEdit = vendorInventoriesEdit.filter((vendorInventory: IAddNewInventoryDetail) => 
                vendorInventory.warehouseId === warehouseId
            ).length;
            setAvailabelStockTo(totalCalculatedSize);
            setTotalUsnNo(Totallength + TotallengthEdit);
            return totalCalculatedSize;
        };
        
        return (
            <>
                {formik?.values?.warehouseDetails?.length > 0 || modalFormikEdit ?
                    <>
                        {buttonShow && <div className='absolute top-0 right-0'>
                            <Button
                                variant="secondaryContained"
                                label="Add Inventory"
                                onClick={handleAddOtherInventory}
                            />
                        </div>}
                        <div className={` grid`}>
                            <text className={` ${classes.UsnText} text-lg font-medium py-4`}> Total No of USN: {totalUsnNo} </text>
                            { showTable && <TableV2 schema={getSchema()} records={getRecordsEdit()} />}
                            {formik?.values?.warehouseDetails?.length > 0 &&  <TableV2 schema={getSchema()} records={getRecords()} />}
                        </div>
                        {
                            isDeleteModalOpen &&
                            <SuccessFailurePopup
                                variant="Delete"
                                heading="Adjust Inventory?"
                                description="Are you sure you want to adjust inventory for this USN? "
                                setShowPopup={setIsDeleteModalOpen}
                                button1={{
                                    text: 'Yes',
                                    variant: "tertiaryContained",
                                    size: "large",
                                    onClick: () => handleDialogClose(true),
                                }}
                                button2={{
                                    text: 'No',
                                    variant: "secondaryContained",
                                    size: "large",
                                    onClick: () => handleDialogClose(false)
                                }}
                            />
                        }
                    </>
                    :
                    <div className='pt-4'>
                        <EmptyInventoryStateTemplate handleChange={handleChange} />
                    </div>
                }

            </>
        )
    }
    const schema: ITabsSchema[] = warehouseData?.map((warehouse: { id: number, name: string }, index: number) => ({
        label: capitalizeFirstLetter(warehouse?.name),
        component: <InventoryAddView warehouseId={warehouse.id} />,
    }));
    return (
        <div className=' grid gap-3'>
            <text className={`${classes.textHeading3} text-2xl font-medium`}>Inventory Details </text>
            {
                formik.values.warehouseDetails.length > 0 &&
                <>
                    <hr className={`${classes.lineColor} border-t `} />
                    <text className={`font-medium ${classes.locationText}`}>Total number of Location: {warehouseData.length} </text>
                </>

            }
            <div className={`${classes.selectBox} rounded-xl !p-4 grid gap-4 `}>
                <text className={`${classes.textHeading3} text-base font-medium `}> Add USN Details </text>
                <div className='w-full relative'>
                    <Tabs schema={schema} value={(activeTab).toString()} setValue={setActiveTab} />
                </div>
                <text className={`${classes.textHeading3}text-base font-medium`}>Total Inventory Available </text>

                <div className={`${classes.selectBox} rounded-xl grid p-6 w-1/4`}>
                    <text className={` ${classes.radioColor} text-sm font-normal`}>Total Stock Available</text>
                    <text className={` ${classes.radioColor} text-base font-medium`}>{availableStock} MT</text>
                </div>
                <AddNewInvetoryModalTemplate
                    dialogOpen={gstinModalDialogOpen}
                    setDialogOpen={setGstinModalDialogOpen}
                    formik={formik}
                    modalFormik={modalFormik}
                    isMillTestSelected={isMillTestSelected}
                    setIsMillTestSelectedTo={setIsMillTestSelectedTo}
                    setSelectedFileTo={setSelectedFileTo}
                    selectedFile={selectedFile}
                />
            </div>
        </div>
    )
}

export default AddInventorysectionTemplate