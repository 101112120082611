import { FormikProps, useFormik } from 'formik';
import { useEffect, useState } from 'react'
import * as Yup from "yup";
import { VENDOR_CATALOGUE_FORM_STATES, HTTP_STATUS } from '../../../utils/types';
import VendorCatalogueFormTemplate from '../../template/VendorCatalogue/VendorCatalogueForm.template';
import { IVendorCatalogueAddServiceForm, useVendorCatalogueService } from '../../../services/useVendorCatalogueService';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { MODE, REGEX, STATUS, VENDOR_CATALOGUE_ROUTES } from '../../../utils/constant';
import { useVendorInventoryService } from '../../../services/useVendorInventoryService';
import { IVendorCatalogueAddForm } from './VendorCatalogueAdd.page';


interface IProductServicesDetails {
  locationId: number | null;
  machineId: number | null;
}

export interface IVendorCatalogueAddProps {
  formik: FormikProps<IVendorCatalogueAddForm>;
  mode?: string;
}


const VendorCatalogueEditPage = () => {
  const [currentSection, setCurrentSection] = useState<VENDOR_CATALOGUE_FORM_STATES>(VENDOR_CATALOGUE_FORM_STATES.CATALOGUE_TYPE);
  const vendorCatalogueService = useVendorCatalogueService();
  const [vendorCatalgueData, setVendorCatalogueDataTo] = useState<IVendorCatalogueAddForm>();
  const params = useParams();
  const vendorCatalgueId = Number(params.catalogueId);
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [warehouses, setWarehousesTo] = useState<{ id: number, name: string }[]>([{ id: 0, name: '' }]);
  const [initialValuesSet, setInitialValuesSet] = useState(false);
  const vendorInventoryService = useVendorInventoryService();
  const { user } = useAuthenticatedUser();

  const fetchWarehouses = async () => {
    if (user?.businessId) {
      try {
        const warehousesResponse = await vendorInventoryService.getAllWarehouseMappedVendor({ vendorId: user.businessId, status: STATUS.ACTIVE });
        if (warehousesResponse.status === HTTP_STATUS.OK) {
          const warehousesData = warehousesResponse?.data?.data?.content;
          setWarehousesTo(warehousesData);
        }
      } catch (error) {
        showSnackbar('error', 'Warehouses fetch failed');
      }
    }
  };
  const initialValues: IVendorCatalogueAddForm = {
    name: '',
    description: '',
    catalogueType: 'SINGLE_PRODUCT',
    productType: 'STANDARD',
    productId: null,
    paymentTerms: '',
    termsConditions: '',
    status: '',
    catalogueProductDetails: []
  };

  const validationSchema = Yup.object().shape({
    catalogueType: Yup.string().required('Catalogue Type is required'),
    description: Yup.string().required('Description is required'),
    productType: Yup.string().required('Product Type is required'),
    productId: Yup.number().required('Please Select a Product'),
    paymentTerms: Yup.array()
      .of(Yup.string())
      .min(1, 'At least one payment term is required')
      .required('Payment Terms are required'),
    termsConditions: Yup.string().required('Terms and Conditions are required'),
    catalogueProductDetails: Yup.array().of(
      Yup.object().shape({
        maximumRetailPrice: Yup.number()
          .required('Maximum Retail Price is required')
          .min(0, 'Maximum Retail Price must be greater than or equal to zero.').test('is-decimal-precision', 'Max two decimal places.', (value) => {
            return REGEX.TWO_DECIMAL.test(value.toString());
          }),
        offerSalePrice: Yup.number()
          .required('Offer Sale Price is required')
          .min(0, 'Offer Sale Price must be greater than or equal to zero.').test('is-decimal-precision', 'Max two decimal places.', (value) => {
            return REGEX.TWO_DECIMAL.test(value.toString());
          })
          .test('is-less-than-maximumRetailPrice', 'Offer Sale Price must be less than Maximum Retail Price.', function (value) {
            const { maximumRetailPrice } = this.parent;
            return value <= maximumRetailPrice;
        }),
        productDiscountDetails: Yup.array().of(
          Yup.object().shape({
            minQuantity: Yup.number()
              .required('Minimum Quantity is required')
              .min(0, 'Must be greater than or equal to zero.').test('is-decimal-precision', 'Max two decimal places.', (value) => {
                return REGEX.TWO_DECIMAL.test(value.toString());
              })
              .integer('Must be an integer')
              .test('min-quantity-greater-than-prev-max',
                'Must be greater than prev value',
                function (value) {
                  const { path, options } = this;
                  const matches = path.match(/\d+/g);
                  if (!matches || matches.length < 2) return true;
                  const productIndex = parseInt(matches[0], 10);
                  const discountIndex = parseInt(matches[1], 10);
                  if (discountIndex === 0) return true;
                  const previousMax = options.context?.catalogueProductDetails[productIndex]?.productDiscountDetails[discountIndex - 1]?.maxQuantity;
                  return value > previousMax;
                }
              ),
            maxQuantity: Yup.number()
              .required('Maximum Quantity is required')
              .min(0, 'Must be greater than or equal to zero.')
              .test('is-decimal-precision', 'Max two decimal places.', (value) => {
                return REGEX.TWO_DECIMAL.test(value.toString());
              })
              .integer('Must be an integer')
              .test('is-greater-than-min', 'Must exceed Min. Quantity', function (value) {
                const { minQuantity } = this.parent;
                return (value === 0 && minQuantity === 0) || (value > minQuantity);
              }),

            minDiscount: Yup.number()
              .required('Minimum Discount is required')
              .min(0, 'Must be greater than or equal to zero.').test('is-decimal-precision', 'Max two decimal places.', (value) => {
                return REGEX.TWO_DECIMAL.test(value.toString());
              }),
          })
        ),
        // productServicesDetails: Yup.array().of(
        //     Yup.object().shape({
        //         machineId: Yup.number()
        //             .optional()
        //     })
        // ),
        cGst: Yup.number()
          .required('CGST is required')
          .min(0, 'Must be greater than or equal to zero.').test('is-decimal-precision', 'Max two decimal places.', (value) => {
            return REGEX.TWO_DECIMAL.test(value.toString());
          }),
        sGst: Yup.number()
          .required('SGST is required')
          .min(0, 'Must be a positive number').test('is-decimal-precision', 'Max two decimal places.', (value) => {
            return REGEX.TWO_DECIMAL.test(value.toString());
          }),
        iGst: Yup.number()
          .required('IGST is required')
          .min(0, 'Must be a positive number').test('is-decimal-precision', 'Max two decimal places.', (value) => {
            return REGEX.TWO_DECIMAL.test(value.toString());
          }),
        tcs: Yup.number()
          .required('TCS is required')
          .min(0, 'Must be a positive number').test('is-decimal-precision', 'Max two decimal places.', (value) => {
            return REGEX.TWO_DECIMAL.test(value.toString());
          }),
        tax3: Yup.number()
          .required('Tax is required')
          .min(0, 'Must be a positive number').test('is-decimal-precision', 'Max two decimal places.', (value) => {
            return REGEX.TWO_DECIMAL.test(value.toString());
          }),
      })
    )
  });

  const formik = useFormik<IVendorCatalogueAddForm>({
    initialValues,
    validationSchema,
    onSubmit: async () => {
      const filteredCatalogueProductDetails = formik.values.catalogueProductDetails.filter(
        (productDetails) => !(productDetails.maximumRetailPrice === 0 && productDetails.offerSalePrice === 0)
      );
      const body: IVendorCatalogueAddServiceForm = {
        name: formik.values.name,
        description: formik.values.description,
        catalogueType: formik.values.catalogueType,
        productType: formik.values.productType,
        paymentTerms: formik.values.paymentTerms,
        status: formik.values.status,
        termsAndConditions: formik.values.termsConditions,
        catalogueProductDetails: filteredCatalogueProductDetails.map((productDetails) => ({
          vendorProductId: Number(formik.values.productId) ?? 0,
          warehouseId: productDetails.warehouseId ?? 0,
          marketRetailPrice: productDetails.maximumRetailPrice ?? 0,
          offerSalePrice: productDetails.offerSalePrice ?? 0,
          deliveryTerms: productDetails.deliveryTerms,
          packagingTypeEnum: productDetails.packagingType,
          productChargesDetailsRequestBodyDTOs: [
            {
              locationId: productDetails.warehouseId ?? 0,
              chargeType: "TCS",
              rate: productDetails.tcs ?? 0,
              rateType: "PERCENTAGE"
            },
            {
              locationId: productDetails.warehouseId ?? 0,
              chargeType: "CGST",
              rate: productDetails.cGst ?? 0,
              rateType: "PERCENTAGE"
            },
            {
              locationId: productDetails.warehouseId ?? 0,
              chargeType: "SGST",
              rate: productDetails.sGst ?? 0,
              rateType: "PERCENTAGE"
            },
            {
              locationId: productDetails.warehouseId ?? 0,
              chargeType: "IGST",
              rate: productDetails.iGst ?? 0,
              rateType: "PERCENTAGE"
            },
            {
              locationId: productDetails.warehouseId ?? 0,
              chargeType: "TAX_3",
              rate: productDetails.tax3 ?? 0,
              rateType: "PERCENTAGE"
            }
          ],
          productDiscountDetailsRequestBodyDTOs: productDetails.productDiscountDetails.map((discount) => ({
            locationId: discount.locationId ?? 0,
            minQuantity: discount.minQuantity ?? 0,
            maxQuantity: discount.maxQuantity ?? 0,
            discountType: "PERCENTAGE",
            minDiscount: discount.minDiscount ?? 0,
            maxDiscount: discount.minDiscount ?? 0,
          })),
          productServicesDetailsRequestBodyDTOs: productDetails.productServicesDetails.map((machine) => ({
            locationId: machine.locationId ?? 0,
            machineId: machine.machineId ?? 0,
            serviceCategoryId: machine.serviceCategoryId ?? 0,
          }))
        }))
      }
      if (user?.businessId) {
        try {
          const vendorCatalogueUpdateResponse = await vendorCatalogueService.updateVendorCatalogue(user?.businessId, vendorCatalgueId, body);
          if (vendorCatalogueUpdateResponse.status === HTTP_STATUS.OK) {
            navigate(VENDOR_CATALOGUE_ROUTES.MAKE_CATALOGUE);
          }
        } catch (error) {
          showSnackbar("error", `Failed to update Catalogue, Please try again`)
        }
      }
    },
  });

  const getCatalogue = async () => {
    if (user?.businessId) {
      try {
        const vendorCatalogueResponse = await vendorCatalogueService.GetVendorCatalogue(user?.businessId, vendorCatalgueId);

        if (vendorCatalogueResponse.status === HTTP_STATUS.OK) {
          const vendorCatalogueResponseBody = vendorCatalogueResponse.data.data;

          const vendorCatalogue: IVendorCatalogueAddForm = {
            name: vendorCatalogueResponseBody.name,
            description: vendorCatalogueResponseBody.description,
            catalogueType: vendorCatalogueResponseBody.catalogueType,
            productType: vendorCatalogueResponseBody.productType,
            productId: vendorCatalogueResponseBody.catalogueProductDetails[0]?.vendorProductId || "",
            paymentTerms: vendorCatalogueResponseBody.paymentTerms,
            status: vendorCatalogueResponseBody.status,
            termsConditions: vendorCatalogueResponseBody.termsAndConditions,
            catalogueProductDetails: warehouses.map((warehouse) => {
              const productDetail = vendorCatalogueResponseBody.catalogueProductDetails.find(
                (detail: any) => detail.warehouseId === warehouse.id
              );

              if (productDetail) {
                let cGst = null;
                let sGst = null;
                let iGst = null;
                let tcs = null;
                let tax3 = null;

                productDetail.productChargesDetailsRequestBodyDTOs.map((chargeDetail: any) => {
                  switch (chargeDetail.chargeType) {
                    case "CGST":
                      cGst = chargeDetail.rate;
                      break;
                    case "SGST":
                      sGst = chargeDetail.rate;
                      break;
                    case "IGST":
                      iGst = chargeDetail.rate;
                      break;
                    case "TCS":
                      tcs = chargeDetail.rate;
                      break;
                    case "TAX_3":
                      tax3 = chargeDetail.rate;
                      break;
                    default:
                      break;
                  }
                });

                return {
                  productId: productDetail.vendorProductId,
                  warehouseId: productDetail.warehouseId,
                  maximumRetailPrice: productDetail.marketRetailPrice,
                  offerSalePrice: productDetail.offerSalePrice,
                  deliveryTerms: productDetail.deliveryTerms,
                  packagingType: productDetail.packagingTypeEnum,

                  productDiscountDetails: productDetail.productDiscountDetailsRequestBodyDTOs.map((discount: any) => ({
                    locationId: discount.locationId,
                    minQuantity: discount.minQuantity,
                    maxQuantity: discount.maxQuantity,
                    minDiscount: discount.minDiscount,
                  })),

                  productServicesDetails: productDetail.productServicesDetailsRequestBodyDTOs,
                  cGst,
                  sGst,
                  iGst,
                  tcs,
                  tax3,
                };
              } else {
                return {
                  productId: null,
                  warehouseId: warehouse.id,
                  maximumRetailPrice: 0,
                  offerSalePrice: 0,
                  deliveryTerms: '.',
                  packagingType: 'WITHOUT_PACKAGING',
                  productDiscountDetails: [{
                    locationId: null,
                    minQuantity: 0,
                    maxQuantity: 0,
                    minDiscount: 0,
                  }],
                  productServicesDetails: [{
                    locationId: null,
                    machineId: null,
                    serviceCategoryId: null
                  }],
                  cGst: 0,
                  sGst: 0,
                  iGst: 0,
                  tcs: 0,
                  tax3: 0,
                };
              }
            }),
          };
          setVendorCatalogueDataTo(vendorCatalogue);
        }
      } catch (error) {
        showSnackbar("error", `Failed to update Catalogue, Please try again`);
      }
    }
  };



  useEffect(() => {
    getCatalogue();
  }, [warehouses])

  useEffect(() => {
    formik.setValues({
      ...formik.values,
      ...vendorCatalgueData
    })
  }, [vendorCatalgueData])

  useEffect(() => {
    fetchWarehouses();
  }, [user?.businessId]);
  return (
    <VendorCatalogueFormTemplate formik={formik} mode={MODE.EDIT} />
  )
}

export default VendorCatalogueEditPage