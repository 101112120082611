import React from 'react'
import { createUseStyles } from "react-jss";
import banner from "../../../assets/images/rfqFormBanner.png"
import { IRfqRequestBody, useRfqService } from "../../../services/useRfqService";
import { IUserRequestBody, useUserService } from "../../../services/useUserService";
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";
import { useState } from "react";
import { HTTP_STATUS } from "../../../utils/types";
import { isArray } from "lodash";
import { useNavigate } from 'react-router-dom';
import { CUSTOMER_ROUTES } from '../../../utils/constant';
import RequestForQuotationFormTemplate from '../Rfq/RequestForQuotationForm.template';
import SuccessFailurePopup from '../../molecules/SuccessFailurePopup/SuccessFailurePopup';

const useStyles = createUseStyles((theme: any) => ({
    container:{
        maxWidth: "1043px",
        paddingTop: "16px",
        paddingBottom: "108px",
        columnGap: "32px"
    },
    headingContainer:{
        color: theme.palette.text.primary.primary800,
        fontSize: "36px",
    },
    primaryText: {
        fontSize: '40px',
    },
    heading:{

    },
    "@media (max-width: 767px)": {
        container:{
            maxWidth: "767px",
            paddingTop: "none",
            paddingBottom: "none",
            padding: "40px 12px",
            columnGap: "20px"
        },
        headingContainer:{
            fontSize: "28px",
            textAlign: "center",
            lineHeight: "34px"
        },
        heading:{
            textAlign: "center",
            width: "100%",
            margin: 'auto',
            display: "flex",
            justifyContent: "center"
        },


    },
}));


const RequestForQuotationPage = () => {
    const classes = useStyles();
    const rfqService = useRfqService();
    const userService = useUserService();
    const { user } = useAuthenticatedUser();
    const [thanksDialogOpen, setThanksDialogOpen] = useState<boolean>(false);
    const navigate = useNavigate();

    const createRfq = async (requestBody: any) => {
        const payload: IRfqRequestBody = {
            customerId: requestBody.customerId,
            productCategoryId: requestBody.productCategoryId,
            manufacturerId: requestBody.manufacturerId,
            gradeId: requestBody.gradeId,
            standardId: requestBody.standardId,
            shape: requestBody.shape,
            attributes: requestBody.attributes,
            requiredQuantity: requestBody.requiredQuantity,
            expectedDeliveryDate: requestBody.expectedDeliveryDate,
            specificRequirement: requestBody.specificRequirement.join(","),
            paymentTerm: requestBody.paymentTerm,
        };
        if (user !== null) {
            try {
                const enquiryResponse = await rfqService.createRfq(payload);
                if (enquiryResponse?.status === HTTP_STATUS.OK) {
                    setThanksDialogOpen(true)
                } else {
                    throw new Error("Rfq creation failed");
                }
            } catch (error) {
                return false;
            }
        } else {
            const userRequestBody: IUserRequestBody = {
                mobileNumber: requestBody.mobileNumber,
                fullName: requestBody.name,
                email: !!requestBody.email ? requestBody.email : null,
                gstin: !!requestBody.gst ? requestBody.gst : null,
                companyProfile: {
                    address: {
                        city: requestBody.city,
                        email: !!requestBody.email ? requestBody.email : null,
                        state: requestBody.state,
                        pincode: requestBody.pinCode,
                        line1: requestBody.addressLine1,
                        line2: requestBody.addressLine2,
                        mobileNumber: requestBody.mobileNumber,
                        resourceType: "BUSINESS"
                    },
                    business: {
                        name: requestBody.companyName,
                        gstin: !!requestBody.gst ? requestBody.gst : null,
                        statutory: {}
                    }
                }
            }
            const userResponse = await userService.createUser(userRequestBody);
            if (userResponse?.status === HTTP_STATUS.OK) {
                payload.customerId = userResponse.data.data.data.userId
                const enquiryResponse = await rfqService.createRfq({...payload, specificRequirement: (isArray(payload.specificRequirement) ? payload.specificRequirement.join(",") : payload.specificRequirement)});
                if (enquiryResponse?.status === HTTP_STATUS.OK) {
                    setThanksDialogOpen(true)
                }
            } else {
                throw new Error("User creation failed");
            }
        }
    };


    const continueToHome = () => {
        setThanksDialogOpen(false);
    };

    return (
        <>
                <div className={`grid ${classes.container} mx-auto`} >
                    <div className={`${classes.headingContainer} flex justify-between pb-4`}>
                        <div className={`font-semibold ${classes.heading}`}>Tell us About your Customised Requirements</div>
                    </div>
                    <RequestForQuotationFormTemplate onRFQFromSubmit={createRfq} />
                </div>
                {thanksDialogOpen &&
                <SuccessFailurePopup
                    width="688px"
                    variant="Success"
                    heading={<div className="flex">
                        <div className={classes.primaryText}>
                            Thank you for reaching out to us!
                        </div>
                        <span>🌟</span>
                    </div>}
                    description={<div className={`flex flex-col gap-y-3`}>
                        <div className="text-center">Your inquiry means a lot to us, and we're excited to assist you. Our team is dedicated to providing top-notch service and will respond to your query promptly.</div>
                        <div>In the meantime, feel free to explore more of our website to discover our products/services and learn about what makes us stand out. If you have any further questions or need assistance with anything else, don't hesitate to reach out.</div>
                        <div>Once again, thank you for choosing us. We look forward to serving you!</div>
                        <div>
                            <div>Best regards,</div>
                            <div>SteelBazaar Team</div>
                        </div>
                    </div>}
                    setShowPopup={setThanksDialogOpen}
                    button1={{
                        text: 'Continue',
                        variant: "tertiaryContained",
                        size: "large",
                        onClick: continueToHome,
                    }}
                />
            }        </>
    );
};


export default RequestForQuotationPage