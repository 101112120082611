import React, { useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import aboutUsBanner from '../../../assets/images/aboutUsBanner.jpg';
import mission from '../../../assets/images/mission.svg';
import vision from '../../../assets/images/vision.svg';
import { ClassNames } from '@emotion/react';
import aboutSteelBazaar from '../../../assets/images/aboutSteelBazaar.svg'
import AipoweredTemplate from '../../template/Home/AiPowered/Aipowered.template';
import RequestDemoTemplate from '../../template/Home/RequestDemo/RequestDemo.template';
import location from '../../../assets/icons/locationIcon.svg';
import solution from '../../../assets/icons/solutionIcon.svg';
import market from '../../../assets/icons/marketIcon.svg';
import chain from '../../../assets/icons/chainIcon.svg';
import design from '../../../assets/icons/designIcon.svg';
import doc from '../../../assets/icons/docIcon.svg';
import puzzle from '../../../assets/icons/puzzleIcon.svg';
import recycle from '../../../assets/icons/recycleIcon.svg';
import card1 from '../../../assets/images/card1.svg';
import pricing from '../../../assets/images/pricing.svg';
import SBDigiStore from '../../../assets/images/SBDigiStore.svg';
import Lead from '../../../assets/images/Lead.svg';
import bid from '../../../assets/images/bid.svg';
import management from '../../../assets/images/management.svg';
import sbpool from '../../../assets/images/sbpool.svg';
import instafin from '../../../assets/images/instafin.svg';
import insights from '../../../assets/images/insights.svg';
import AboutHeaderImg from '../../../assets/images/AboutHeaderImg.jpg';
import aboutUsDescription from '../../../assets/images/aboutUsDescription.svg';
import { useLocation } from 'react-router-dom';

const useStyles = createUseStyles((theme: any) => ({
    bannerContainer: {
        width: '100%',
        height: '650px',
        backgroundImage: `url(${AboutHeaderImg})`,
        backgroundSize: 'cover',
        position: 'relative',
        marginTop: '0px',
    },
    visionContainer: {
        width: '1297px',    
        margin: '0 auto',
    },
    heading: {
        fontSize: '48px',
        color: theme.palette.text.primary.primary50,
        lineHeight: '66px',
        position: 'relative',
        fontWeight: '700',
        top: '150px',
        "& span": {
            color: '#DBBF32',
        },
    },
    primaryText: {
        color: theme.palette.text.neutral.neutral900,
        fontSize: '40px',
        lineHeight: '15px',
    },
    sectionHeading: {
        color: theme.palette.text.neutral.neutral900,
        fontSize: '54px',
        lineHeight: 'normal',
    },
    secondaryContainer: {
        width: '800px',
    },
    description: {
        "& p": {

        },

    },
    cardDescription: {
        color: '#000',
        lineHeight: '26px',
    },
    container: {
        width: '1297px',
        margin: '0 auto',
    },
    bannerBtn: {

    },
    missionBanner: {
        background: '#F1F6FC',
        padding: '55px 0px',
    },
    misisonHeading: {
        color: theme.palette.text.neutral.neutral900,
        fontSize: '54px',
    },
    visionSecondaryContainer: {
        "& h3": {
            fontSize: '40px',
            color: theme.palette.text.neutral.neutral900,
        },
        "& p": {

        },
    },
    supplyContainer: {
        background: '#F1F6FC',
        padding: '40px 0px',
    },
    cardsHeading: {
        color: theme.palette.text.neutral.neutral900,
        fontSize: '54px',
        lineHeight: '66px',
    },
    supplyCard: {
        boxShadow: '0px 0px 8px 0px rgba(39, 67, 160, 0.25)',
        background: 'white',
        "& p": {
            fontSize: '22px',
            color: theme.palette.text.primary.primary900,
            padding: '0px 12px',
        },
        "& img": {
            width: '84px',
        }
    },
    cardContainer: {
        margin: '105px -16px 0px -16px',
    },
    cardHeadingSecondary: {
        color: '#000',
        fontSize: '28px',
        lineHeight: '34px',
    },
    featureCardsHeading: {
        color: '#333',
        fontSize: '54px',
    },
    featurecardDescription: {
        color: theme.palette.text.neutral.neutral800,
        lineHeight: '26px',
    },
    featureCard: {
        borderRadius: '20px',
        overflow: 'hidden',
        height: '450px',
    },

    card1: {
        background: '#FEC09A',
        width: '540px',
    },
    card2: {
        background: '#FBF3D0',
        width: '330px',
    },
    card3: {
        width: '400px',
        background: '#CEECFD',
    },
    card11: {
        background: theme.palette.text.warning.warning500,
        width: '550px',
    },
    card12: {
        background: theme.palette.text.primary.primary100,
    },
    card13: {
        background: theme.palette.text.success.success100,
    },
    card4: {
        background: '#F8D3D5',
    },
    card5: {
        background: '#FFE0CC',
    },
    card6: {
        background: theme.palette.text.warning.warning100,
    },
    secondaryText: {
        lineHeight: '26px',
        color: '#000',
    },
}));

const AboutUsTemplate: React.FC = () => {

    const classes = useStyles();
    
    return (
        <>
            <div>
                <div className={`${classes.bannerContainer} relative`}>
                    <div className='w-120'>
                        <h1 className={`${classes.heading} ${classes.visionContainer} mt-9 font-semibold`}>Driving <br /> Innovation & <br /> <span>Optimization</span> in <br /> Steel Supply Chains
                        </h1>
                    </div>
                </div>

                <div className={`${classes.visionContainer} flex gap-16 pt-[110px]`}>
                    <div className='w-fit'>
                        <div className='w-full'><img src={aboutUsDescription} alt="" /></div>
                    </div>
                    <div className={`${classes.secondaryContainer}`}>
                        <h2 className={`${classes.sectionHeading} mb-3 font-semibold`}>About Steel Bazaar</h2>
                        <div className={`${classes.description}`}>
                            <p className={`${classes.cardDescription} text-base mb-4`}>SteelBazaar transforms the steel industry by optimising supply chains through global sourcing, digital procurement, and efficient manufacturing driven by sustainability. We streamline steel procurement with transparency and efficiency, leveraging a trusted global network to offer high-quality products at competitive prices. </p>
                            <p className={`${classes.cardDescription} text-base mb-4`}>Our digital platform enables real-time buyer-supplier communication, simplifies transactions, and provides essential market insights. Partnering with top manufacturers, we maintain high-quality standards while promoting responsible sourcing and waste reduction.</p>
                            <p className={`${classes.cardDescription} text-base`}>SteelBazaar also emphasises scrap aggregation, recycling, and a circular manufacturing model, offering real-time market prices to support informed decisions and drive sustainable, efficient industry transformation through advanced technologies and strategies.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${classes.supplyContainer} mt-20`}>
                <div className={classes.container}>
                    <h2 className={`${classes.cardsHeading} text-center font-semibold`}>How Can SteelBazaar's Innovations Optimize Steel Supply Chains?</h2>
                    <div className={`${classes.cardContainer} flex flex-wrap`}>
                        <div className='p-2  w-1/4 mb-10'>
                            <div className={`${classes.supplyCard} px-4 py-5 flex flex-col gap-7 rounded-lg items-center `}>
                                <img src={location} alt="" />
                                <p className='font-semibold text-center'>Global Sourcing Capabilities</p>
                            </div>
                        </div>
                        <div className='p-2  w-1/4'>
                            <div className={`${classes.supplyCard} px-4 py-5 flex flex-col gap-7 rounded-lg items-center `}>
                                <img src={solution} alt="" />
                                <p className='font-semibold text-center'>End-to-End Digital Procurement Solutions</p>
                            </div>
                        </div>
                        <div className='p-2  w-1/4'>
                            <div className={`${classes.supplyCard} px-4 py-5 flex flex-col gap-7 rounded-lg items-center `}>
                                <img src={market} alt="" />
                                <p className='font-semibold text-center'>Real-Time Market Insights</p>
                            </div>
                        </div>
                        <div className='p-2  w-1/4'>
                            <div className={`${classes.supplyCard} px-4 py-5 flex flex-col gap-7 rounded-lg items-center `}>
                                <img src={chain} alt="" />
                                <p className='font-semibold text-center'>Flexible Supply Chain Financing</p>
                            </div>
                        </div>
                        <div className='p-2  w-1/4'>
                            <div className={`${classes.supplyCard} px-4 py-5 flex flex-col gap-7 rounded-lg items-center `}>
                                <img src={design} alt="" />
                                <p className='font-semibold text-center'>Customized Lean Manufacturing Solutions</p>
                            </div>
                        </div>
                        <div className='p-2  w-1/4'>
                            <div className={`${classes.supplyCard} px-4 py-5 flex flex-col gap-7 rounded-lg items-center `}>
                                <img src={doc} alt="" />
                                <p className='font-semibold text-center'>Digital Stream Lined Work Flows</p>
                            </div>
                        </div>
                        <div className='p-2  w-1/4'>
                            <div className={`${classes.supplyCard} px-4 py-5 flex flex-col gap-7 rounded-lg items-center `}>
                                <img src={puzzle} alt="" />
                                <p className='font-semibold text-center'>Integration of Advanced Technologies</p>
                            </div>
                        </div>
                        <div className='p-2  w-1/4'>
                            <div className={`${classes.supplyCard} px-4 py-5 flex flex-col gap-7 rounded-lg items-center `}>
                                <img src={recycle} alt="" />
                                <p className='font-semibold text-center'>Sustainability <br /> Initiatives</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={classes.container}>
                <AipoweredTemplate />
            </div>

            <div className={`${classes.container} flex flex-col gap-10`}>
                <h2 className={`${classes.featureCardsHeading} font-semibold text-center`}>Pioneering Future-Ready Steel Features</h2>
                <div className='flex gap-4'>
                    <div className={`${classes.card1} ${classes.featureCard} flex flex-col justify-between`}>
                        <div className='p-[30px]'>
                            <h4 className={`${classes.cardHeadingSecondary}`}>Comprehensive Product Listings</h4>
                            <p className={`${classes.featurecardDescription} text-base`}>Guaranteed* Save 2-10% , Unlock Big Savings with Our Smart & Automated Procurement Solutions</p>
                        </div>
                        <img src={card1} alt="" className='w-96 mx-auto my-0'/>
                    </div>
                    <div className={`${classes.card2} ${classes.featureCard} flex flex-col justify-between`}>
                        <div className='p-[30px]'>
                            <h4 className={`${classes.cardHeadingSecondary}`}>Digital Catalogue with Real Time Pricing</h4>
                            <p className={`${classes.featurecardDescription} text-base`}>Access Verified Lead to Drive Sales and Business Growth</p>
                        </div>
                        <img src={pricing} alt="" />
                    </div>
                    <div className={`${classes.card3} ${classes.featureCard} flex flex-col justify-between`}>
                        <div className='p-[30px]'>
                            <h4 className={`${classes.cardHeadingSecondary}`}>SB DigiStore</h4>
                            <p className={`${classes.featurecardDescription} text-base`}>Real-Time Live auctions for buying raw materials</p>
                        </div>
                        <img src={SBDigiStore} alt="" className='mb-4'/>
                    </div>
                </div>
                <div className='flex gap-4'>
                    <div className={`${classes.card13} ${classes.featureCard} flex flex-col justify-between`}>
                        <div className='p-[30px]'>
                            <h4 className={`${classes.cardHeadingSecondary}`}>SB Connect Leads Management</h4>
                            <p className={`${classes.featurecardDescription} text-base`}>Access Verified Lead to Drive Sales and Business Growth</p>
                        </div>
                        <img src={Lead} alt="" className='mx-auto my-0'/>
                    </div>
                    <div className={`${classes.card12} ${classes.featureCard} flex flex-col justify-between`}>
                        <div className='p-[30px]'>
                            <h4 className={`${classes.cardHeadingSecondary}`}>SB BidHub</h4>
                            <p className={`${classes.featurecardDescription} text-base`}>Access customized financial services, including invoice financing and trade credit.</p>
                        </div>
                        <img src={bid} alt="" /> 
                    </div>
                    <div className={`${classes.card11} ${classes.featureCard} flex flex-col justify-between`}>
                        <div className='p-[30px]'>
                            <h4 className={`${classes.cardHeadingSecondary}`}>SB Customize Automated RFQ Management </h4>
                            <p className={`${classes.featurecardDescription} text-base`}>Bringing out Hidden Gaps In Procurement and converting them into Real Profits</p>
                        </div>
                        <img src={management} alt="" />
                    </div>
                </div>
                <div className='flex gap-4 mb-[110px]'> 
                    <div className={`${classes.card4} ${classes.featureCard} flex flex-col justify-between`}>
                        <div className='p-[30px]'>
                            <h4 className={`${classes.cardHeadingSecondary}`}>AI-Driven Market Analytics and Insights</h4>
                            <p className={`${classes.featurecardDescription} text-base`}>Guaranteed* Save 2-10% , Unlock Big Savings with Our Smart & Automated Procurement Solutions</p>
                        </div>
                        <img src={insights} alt="" />
                    </div>
                    <div className={`${classes.card5} ${classes.featureCard} flex flex-col justify-between`}>
                        <div className='p-[30px]'>
                            <h4 className={`${classes.cardHeadingSecondary}`}>SB InstaFin</h4>
                            <p className={`${classes.featurecardDescription} text-base`}>Access Verified Lead to Drive Sales and Business Growth</p>
                        </div>
                        <img src={instafin} alt="" className='p-2'/>
                    </div>
                    <div className={`${classes.card6} ${classes.featureCard} flex flex-col justify-between`}>
                        <div className='p-[30px]'>
                            <h4 className={`${classes.cardHeadingSecondary}`}>SB Pool&Save</h4>
                            <p className={`${classes.featurecardDescription} text-base`}>Guaranteed* Save 2-10% , Unlock Big Savings with Our Smart & Automated Procurement Solutions</p>
                        </div>
                        <img src={sbpool} alt=""/>
                    </div>
                </div>
            </div>

            <div className={classes.missionBanner} id='vision'>
                <div className={classes.container}>
                    <h2 className={`${classes.misisonHeading} font-semibold text-center`}>Inspired by Purpose, Driven by Innovation</h2>

                    <div className={`${classes.visionSecondaryContainer} flex gap-10 items-center mt-20`}>
                        <div className='w-full'>
                            <img src={vision} alt="" />
                        </div>
                        <div className='w-full'>
                            <h3 className={`${classes.primaryText} font-semibold mb-10`}>Our Vision</h3>
                            <p className={`${classes.secondaryText} text-lg`}>To lead the Indian steel industry in shaping a robust, sustainable, and globally integrated supply chain that empowers Industry with seamless access to high-quality steel products, advanced manufacturing solutions, and innovative digital transformation.</p>
                        </div>
                    </div>
                    <div className={`${classes.visionSecondaryContainer} flex gap-10 items-center mt-20`}>
                        <div className='w-full'>
                            <h3 className={`${classes.primaryText} font-semibold pb-4 text-end`}>Our Mission</h3>
                            <p className={`${classes.secondaryText} text-lg text-end mt-4`}>To empower businesses in the steel industry by combining comprehensive trade and manufacturing solutions, enabling cost-effective sourcing, efficient production, and innovative digital services that support every step of their growth journey.</p>
                        </div>
                        <div className='w-full'>
                            <img src={mission} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <RequestDemoTemplate />
        </>
    );
};
export default AboutUsTemplate;
