import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import Button from '../../../atoms/Button/Button';
import { Link } from 'react-router-dom';
import { STATIC_PAGES_ROUTES } from '../../../../utils/constant';
import navbarArrowIcon from '../../../../assets/icons/navbarArrowIcon.svg';
import Navbarplatform1 from '../../../../assets/images/Navbarplatform1.jpg';
import Navbarplatform2 from '../../../../assets/images/Navbarplatform2.jpg';
import Navbarplatform3 from '../../../../assets/images/Navbarplatform3.jpg';
import Navbarplatform4 from '../../../../assets/images/Navbarplatform4.jpg';
import Navbarplatform5 from '../../../../assets/images/Navbarplatform5.jpg';
import Navbarplatform6 from '../../../../assets/images/Navbarplatform6.jpg';
import Navbarplatform7 from '../../../../assets/images/Navbarplatform7.jpg';
import Navbarplatform8 from '../../../../assets/images/Navbarplatform8.jpg';
import Navbarplatform9 from '../../../../assets/images/Navbarplatform9.jpg';


const useStyles = createUseStyles((theme: any) => ({
    container: {
        background: "white",
        position: 'absolute',
        width: '100%',
        top: '125px',
        left: '0',
        zIndex: '99',
    },
    primaryContainer: {
        width: '1297px',
        margin: '0 auto',
    },
    dropdownContent: {
        "& h3": {
            color: '#000',
            fontSize: '22px',
            lineHeight: '26px',
        },
        "& h4": {
            lineHeight: '22px',
            color: '#000',
            borderBottom: `1px solid ${theme.palette.border.neutral.neutral200}`
        },
        "& p": {
            color: '#000',
            position: 'relative', 
            paddingLeft: '24px', 
            "&::before": {
                content: '""',
                display: 'inline-block',
                position: 'absolute',
                left: '12px',
                top: '50%',
                transform: 'translateY(-50%)', 
                width: '8px', 
                height: '12px',
                backgroundImage: `url(${navbarArrowIcon})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
            },
        },
    },
    dropdownImgContainer: {
        '& img': {
          width: '100%',
          height: 'auto',
        },
      },
}));

const PlateformDropdown: React.FC = () => {
    const classes = useStyles();

    const [hoveredImage, setHoveredImage] = useState<string>(Navbarplatform3);

    const imagesMap: Record<string, string> = {
        'Navbarplatform1': Navbarplatform1,
        'Navbarplatform2': Navbarplatform2,
        'Navbarplatform3': Navbarplatform3,
        'Navbarplatform4': Navbarplatform4,
        'Navbarplatform5': Navbarplatform5,
        'Navbarplatform6': Navbarplatform6,
        'Navbarplatform7': Navbarplatform7,
        'Navbarplatform8': Navbarplatform8,
        'Navbarplatform9': Navbarplatform9,

      };

    return (
        <div className={`${classes.container} py-10`}>
            <div className={`${classes.primaryContainer} flex justify-between items-center`}>
                <div className='flex w-full gap-[15px]'>
                    <div className='w-1/4 pr-10'>
                        <div className={`${classes.dropdownContent} flex flex-col `}>
                            <h3 className='font-bold mb-[10px]'>Marketplace</h3>
                            <div className='p-[10px]'>
                                <h4 className='font-semibold text-lg py-[5px] px-[10px] mb-1'>SB DigiTrade</h4>
                                <p
                  className="text-base leading-5 py-[5px] px-[10px]"
                  onMouseEnter={() => setHoveredImage(imagesMap['Navbarplatform1'])}
                >
                  <Link to={`${STATIC_PAGES_ROUTES.B2B_MARKETPLACE}`}>B2B Marketplace</Link>
                </p>
                            </div>
                        </div>
                        <div className={`${classes.dropdownContent} flex flex-col `}>
                            <div className='p-[10px]'>
                                <h4 className='font-semibold text-lg py-[5px] px-[10px] mb-1'>SB BidHub</h4>
                                <p className='text-base leading-5 py-[5px] px-[10px]'>Forward Sales Auction</p>
                                <p className='text-base leading-5 py-[5px] px-[10px]'>Reverse Buy Auction</p>
                            </div>
                        </div>
                        <div className={`${classes.dropdownContent} flex flex-col `}>
                            <div className='p-[10px]'>
                                <h4 className='font-semibold text-lg py-[5px] px-[10px] mb-1'>SB LeadX</h4>
                                <p className='text-base leading-5 py-[5px] px-[10px]'>SB Buy Leads</p>
                                <p className='text-base leading-5 py-[5px] px-[10px]'>SB Sell Leads</p>
                            </div>
                        </div>
                    </div>
                    <div className='w-1/4 pr-10'>
                        <div className={`${classes.dropdownContent} flex flex-col `}>
                            <h3 className='font-bold mb-[10px]'>For Sellers/Vendors</h3>
                            <div className='p-[10px]'>
                                <h4 className='font-semibold text-lg py-[5px] px-[10px] mb-1'>SB DigiSell</h4>
                                <p
                                    className="text-base leading-5 py-[5px] px-[10px]"
                                    onMouseEnter={() => setHoveredImage(imagesMap['Navbarplatform1'])}
                                >
                                    <Link to={`${STATIC_PAGES_ROUTES.SELLER_CENTRAL}`}>Seller Central</Link>
                                </p>
                                <p
                                    className="text-base leading-5 py-[5px] px-[10px]"
                                    onMouseEnter={() => setHoveredImage(imagesMap['Navbarplatform1'])}
                                >
                                    <Link to={`${STATIC_PAGES_ROUTES.VENDOR_CENTRAL}`}>Vendor Central</Link>
                                </p>
                            </div>
                        </div>
                        <div className={`${classes.dropdownContent} flex flex-col `}>
                            <div className='p-[10px]'>
                                <h4 className='font-semibold text-lg py-[5px] px-[10px] mb-1'>SB DigiStore</h4>
                                <p className='text-base leading-5 py-[5px] px-[10px]'>Digital Store Front </p>
                                <p className='text-base leading-5 py-[5px] px-[10px]'>Digital Inventory</p>
                                <p className='text-base leading-5 py-[5px] px-[10px]'>Digital Catalogue</p>
                                <p className='text-base leading-5 py-[5px] px-[10px]'>Digital Order Manegment</p>
                            </div>
                        </div>
                    </div>
                    <div className='w-1/4 pr-10'>
                        <div className={`${classes.dropdownContent} flex flex-col `}>
                            <h3 className='font-bold mb-[10px]'>For Buyers</h3>
                            <div className='p-[10px]'>
                                <h4 className='font-semibold text-lg py-[5px] px-[10px] mb-1'>SB Get MySteel</h4>
                                <p className='text-base leading-5 py-[5px] px-[10px]'>Compare Brands</p>
                            </div>
                        </div>
                        <div className={`${classes.dropdownContent} flex flex-col `}>
                            <div className='p-[10px]'>
                                <h4 className='font-semibold text-lg py-[5px] px-[10px] mb-1'>SB Customize</h4>
                                <p className='text-base leading-5 py-[5px] px-[10px]'>Request For Quotation</p>
                            </div>
                        </div>
                        <div className={`${classes.dropdownContent} flex flex-col `}>
                            <div className='p-[10px]'>
                                <h4 className='font-semibold text-lg py-[5px] px-[10px] mb-1'>SB Pool&Save</h4>
                                <p className='text-base leading-5 py-[5px] px-[10px]'>Pool & Save</p>
                            </div>
                        </div>
                        <div className={`${classes.dropdownContent} flex flex-col `}>
                            <div className='p-[10px]'>
                                <h4 className='font-semibold text-lg py-[5px] px-[10px] mb-1'>SB GlobalSource</h4>
                                <p
                                    className="text-base leading-5 py-[5px] px-[10px]"
                                    onMouseEnter={() => setHoveredImage(imagesMap['Navbarplatform1'])}
                                >
                                    <Link to={`${STATIC_PAGES_ROUTES.B2B_MARKETPLACE}/?component=global`}>
                                    Global Sourcing
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={`${classes.dropdownImgContainer} rounded-lg w-1/4 `}>
            {hoveredImage && <img src={hoveredImage} alt="Hovered Image" />}
          </div>
                </div>
            </div>
        </div>
    );
};

export default PlateformDropdown;


