import { createUseStyles } from "react-jss";
import { CUSTOMIZTION_TYPE, SELECT_CUSTOMIZATION } from "../../../../utils/constant";
import Button from "../../../atoms/Button/Button";
import { useEffect, useRef, useState } from "react";
// import { ICatalogue, ICatalogueUpc } from "../../../pages/Catalogue/MultipleUpcCatalogueDetails.page";
import { useConfigurationService } from "../../../../services/useConfigurationService";
import { HTTP_STATUS } from "../../../../utils/types";
import SlittingCustomizationPopup, { ISlitting } from "../../Customization/SlittingCustomizationPopup";
import ShearingCustomizationPopup, { IShearing } from "../../Customization/ShearingCustomizationPopup";
import SlittingCustomizationTableTemplate from "../../Customization/SlittingCustomizationTable.template";
import ShearingCustomizationTableTemplate from "../../Customization/ShearingCustomizationTable.tempate";
import { isNaN } from "lodash";
import { ICatalogue, ICatalogueUpc } from "../../../pages/CatalogueDetail/MultipleUpcCatalogueDetails.page";
import { ISecondaryCatalogue } from "../../../pages/CatalogueDetail/SecondaryCatalogueDetails.page";
// import { ISecondaryCatalogue } from "../../../pages/Catalogue/SecondaryCatalogueDetails.page";

interface SelectCustomizationTemplateProps {
    catalogue: ICatalogue | null;
    secondaryCatalogue: ISecondaryCatalogue | null;
    quantity: number;
    selectedUpc?: ICatalogueUpc | null;
    valueAddedService: IValueAddedService;
    setValueAddedServiceTo: any;
    customizationArray?:any
}

export interface ICustomization {
    uom: string;
    name: string;
    price: number;
    value: string;
    discription: string;
    minLength: number;
    maxLength: number;
    minThickness: number;
    maxThickness: number;
    minWidth: number;
    maxWidth: number;
    description?:string
}

export interface IValueAddedService {
    shearing: IShearing[];
    slitting: ISlitting[];
}

const useStyles = createUseStyles((theme: any) => ({
    mainContainer: {
        gridTemplateColumns: "repeat(4, minmax(0, 1fr))"
    },
    container: {
        padding: "12px",
        border: "1px solid #D2D7E4",
    },
    cardHeading: {
        color: theme.palette.text.primary.primary500,
        fontSize: "18px",
        fontWeight:"600"
    },
    cardDescription: {
        color: theme.palette.text.neutral.neutral600,
        lineHeight: "19px",
        letterSpacing: "0.014px",
        fontWeight:"400",
        height:"auto"
    },
    header: {
        color: theme.palette.text.neutral.neutral900
    },
    headerSubText:{
        color: theme.palette.text.primary.primary500,
    },
    "@media (max-width: 480px)": {
        mainContainer: {
            gridTemplateColumns: "repeat(1, minmax(0, 1fr))"
        },
        cardHeading: {
            fontSize: "16px"
        },
        container: {
            padding: "16px 12px",
        },
    },

}))

const SelectCustomizationReviewFormTemplate: React.FC<SelectCustomizationTemplateProps> = ({ catalogue, secondaryCatalogue, quantity, selectedUpc, valueAddedService, setValueAddedServiceTo, customizationArray }) => {

    const classes = useStyles();
    const configurationService = useConfigurationService();

    const [customization, setCustomizationTo] = useState<ICustomization[]>([]);
    const [shearingCustomization, setShearingCustomization] = useState<ICustomization>(
        {
            uom: "MT",
            name: "Shearing (CTL)",
            price: 0,
            value: "SHEARING",
            discription: "Cutting large sheets of steel into smaller, manageable sizes.",
            minLength: 0,
            maxLength: Infinity,
            minThickness: 0,
            maxThickness: Infinity,
            minWidth: 0,
            maxWidth: Infinity,
        }
    );

    const [slittingCustomization, setSlittingCustomization] = useState<ICustomization>(
        {
            uom: "MT",
            name: "Slitting (CTW)",
            price: 0,
            value: "SLITTING",
            discription: "Longitudinally cutting into narrower strips with uniform width.",
            minLength: 0,
            maxLength: Infinity,
            minThickness: 0,
            maxThickness: Infinity,
            minWidth: 0,
            maxWidth: Infinity,
        }
    );

    const [balanceWeight, setBalanceWeight] = useState<number | null>(null)

    const [slittingDialogOpen, setSlittingDialogOpen] = useState<boolean>(false);
    const [shearingDialogOpen, setShearingDialogOpen] = useState<boolean>(false);

    const handleSelectCustomization = (value: string) => () => {
        if (value === CUSTOMIZTION_TYPE.SHEARING) {
            setShearingDialogOpen(true)
        } else if (value === CUSTOMIZTION_TYPE.SLITTING) {
            setSlittingDialogOpen(true)
        }
    }

    const [editState, setEditState] = useState<boolean>(false)

    const loadConfiguration = (customizationArray: any) => {
        configurationService.getConfiguration1({ contexts: "CUSTOMIZATION" })
            .then(res => {
                if (res?.status === HTTP_STATUS.OK) {
                    const filteredCustomizations = res?.data?.data[0]?.data.filter((option: any) => {
                        return customizationArray.includes(option.value);
                    });
                    setCustomizationTo(filteredCustomizations);
                    setShearingCustomization(filteredCustomizations?.find((attr: any) => attr.value === CUSTOMIZTION_TYPE.SHEARING));
                    setSlittingCustomization(filteredCustomizations?.find((attr: any) => attr.value === CUSTOMIZTION_TYPE.SLITTING));
                }
            })
            .catch((error) => {
                console.error('Error', error);
                setCustomizationTo([]);
            });
    }

    useEffect(() => {
        if (catalogue?.customization) {
            loadConfiguration(catalogue.customization);
        }
    }, [catalogue?.customization]);

    const previousQuantity = useRef<number>(0);

    useEffect(() => {
        if (isNaN(quantity) || quantity === null) return
        const quantityDifference = quantity - (isNaN(previousQuantity.current) ? 0 : previousQuantity.current);
        setBalanceWeight(prevBalanceWeight => (prevBalanceWeight === null ? 0 : prevBalanceWeight) + quantityDifference);
        previousQuantity.current = quantity;
    }, [quantity]);

    const isButtonDisabled = (item: ICustomization) => {
        if (selectedUpc?.attributes?.SPECIFICATION !== undefined) {
            const thicknessSpec = selectedUpc?.attributes.SPECIFICATION.find(attr => attr.name.trim().toLowerCase() === 'thickness')?.attributeOptions;
            const thickness = thicknessSpec && typeof thicknessSpec === 'string' ? parseFloat(thicknessSpec) : NaN;

            const lengthSpec = selectedUpc?.attributes.SPECIFICATION.find(attr => attr.name.trim().toLowerCase() === 'length')?.attributeOptions;
            const length = lengthSpec && typeof lengthSpec === 'string' ? parseFloat(lengthSpec) : NaN;

            const widthSpec = selectedUpc?.attributes.SPECIFICATION.find(attr => attr.name.trim().toLowerCase() === 'width')?.attributeOptions;
            const width = widthSpec && typeof widthSpec === 'string' ? parseFloat(widthSpec) : NaN;
            if (item.value === CUSTOMIZTION_TYPE.SHEARING && shearingCustomization) {
                if (lengthSpec === "COIL") {
                    return thickness === null || length === null || width === null ||
                        thickness < (shearingCustomization?.minThickness) || thickness > (shearingCustomization?.maxThickness) ||
                        width < (shearingCustomization?.minWidth) || width > (shearingCustomization?.maxWidth) || 
                        selectedUpc.warehouseDetails.length == 0 ||
                        isNaN(quantity) || quantity <= 0;
                } else {
                    return thickness === null || length === null || width === null ||
                        thickness < (shearingCustomization?.minThickness) || thickness > (shearingCustomization?.maxThickness) ||
                        length < (shearingCustomization?.minLength) || length > (shearingCustomization?.maxLength) ||
                        width < (shearingCustomization?.minWidth) || width > (shearingCustomization?.maxWidth) ||
                        selectedUpc.warehouseDetails.length == 0 ||
                        isNaN(quantity) || quantity <= 0;
                }
            } else if (item.value === CUSTOMIZTION_TYPE.SLITTING && slittingCustomization) {
                return thickness === null || width === null ||
                    thickness < (slittingCustomization?.minThickness) || thickness > (slittingCustomization?.maxThickness) ||
                    width < (slittingCustomization?.minWidth) || width > (slittingCustomization?.maxWidth) ||
                    selectedUpc.warehouseDetails.length == 0 ||
                    lengthSpec !== "COIL" ||
                    (isNaN(quantity) || quantity <= 0);
            } else if (item.value === CUSTOMIZTION_TYPE.BLANKING) {
                return true;
            } else if (item.value === CUSTOMIZTION_TYPE.METAL_OFFSET_PRINTING) {
                return true;
            }
        } else if (selectedUpc?.attributes?.SPECIFICATION === undefined) {
            return true
        }
        return false;
    }

    return (
        <div className="grid gap-y-6">
            <div className="grid gap-y-3">
                <div className={`${classes.header} text-lg font-bold`}>{SELECT_CUSTOMIZATION.SELECT_CUSTOMIZATION}</div>
                <div className={`${classes.mainContainer} grid gap-3`}>
                    {customizationArray?.map((item: ICustomization) => (
                       item.name &&  <div style={isButtonDisabled(item) ? {backgroundColor:"rgba(10, 18, 41, 0.05)"} : {backgroundColor:"white"}} className={`${classes.container} flex flex-col gap-y-3 rounded-xl w-full`}>
                            <div>
                                <span className={`${classes.cardHeading}`}>{item.name}</span>
                                <p className={`${classes.cardDescription} mb-3 text-sm font-normal`}>{item.description}</p>
                            </div>
                            <div className="mt-auto">
                                <Button
                                    className={`!px-3 leading-none !text-sm`}
                                    variant="tertiaryContained"
                                    label="Select"
                                    size="large"
                                    onClick={handleSelectCustomization(item.value)}
                                    disabled
                                    fullWidth
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {shearingDialogOpen &&
                <ShearingCustomizationPopup
                    dialogOpen={shearingDialogOpen}
                    setDialogOpen={setShearingDialogOpen}
                    selectedUpc={selectedUpc}
                    selectedWeight={quantity}
                    customization={shearingCustomization}
                    valueAddedService={valueAddedService}
                    setValueAddedServiceTo={setValueAddedServiceTo}
                    balanceWeight={balanceWeight}
                    setBalanceWeight={setBalanceWeight}
                />
            }

            {slittingDialogOpen &&
                <SlittingCustomizationPopup
                    dialogOpen={slittingDialogOpen}
                    setDialogOpen={setSlittingDialogOpen}
                    selectedUpc={selectedUpc}
                    selectedWeight={quantity}
                    customization={slittingCustomization}
                    valueAddedService={valueAddedService}
                    setValueAddedServiceTo={setValueAddedServiceTo}
                    balanceWeight={balanceWeight}
                    setBalanceWeight={setBalanceWeight}
                    editState={editState}
                    setEditState={setEditState}
                />
            }

            {(valueAddedService?.shearing?.length > 0 || valueAddedService?.slitting?.length > 0) &&
                <div className="grid gap-y-4">
                    <div className="text-lg font-semibold">{SELECT_CUSTOMIZATION.SELECTED_SERVICES}</div>
                    {valueAddedService?.shearing?.length > 0 &&
                        <ShearingCustomizationTableTemplate valueAddedService={valueAddedService?.shearing} setDialogOpen={setShearingDialogOpen} />}
                    {valueAddedService?.slitting?.length > 0 &&
                        <SlittingCustomizationTableTemplate valueAddedService={valueAddedService?.slitting} setDialogOpen={setSlittingDialogOpen} setEditState={setEditState} />}
                </div>
            }
        </div>
    )
}

export default SelectCustomizationReviewFormTemplate;