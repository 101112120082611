import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';
import { VendorCatalogueSectionProp } from './VendorCatalogueForm.template';
import { VENDOR_CATALOGUE_FORM_STATES, HTTP_STATUS, STATUS, VENDOR_PRODUCT_DETAILS_TYPE } from '../../../utils/types';
import Button from '../../atoms/Button/Button';
import TextFieldV2 from '../../atoms/Input/TextFieldV2';
import CustomRadioGroup from '../../molecules/CustomRadioGroup/CustomRadioGroup';
import TabsV2 from '../../molecules/TabsV2/TabsV2';
import { ITabsSchema } from '../../molecules/Tabs/TabsV2';
import CustomisationDetailsTemplate from './CustomisationDetails.template';
import DeliveryTermsTemplate from './DeliveryTerms.template';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import { useVendorInventoryService } from '../../../services/useVendorInventoryService';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { capitalizeFirstLetter } from '../../../utils/helper';
import { FormikErrors } from 'formik';
import { IVendorCatalogueAddForm, IcatalogueProductDetails } from '../../pages/VendorCatalogue/VendorCatalogueAdd.page';
import { FormHelperText } from '@mui/joy';
import ProductViewTemplate from '../DashBoard/Inventory Management/ProductView.template';
import { IProductData } from '../DashBoard/Inventory Management/SelectProduct.template';
import { catalogueStatusOptions, MODE, VENDOR_CATALOGUE_ROUTES } from '../../../utils/constant';
import { IVendorCatalogueAddServiceForm, useVendorCatalogueService } from '../../../services/useVendorCatalogueService';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';


const useStyles = createUseStyles((theme: any) => ({
    webContainer: {
        display: "grid"
    },
    activatedProgressBar: {
        backgroundColor: theme.palette.background.primary.primary500
    },
    activeProgressBar: {
        backgroundColor: theme.palette.background.primary.primary400
    },
    UnTouchProgressBar: {
        backgroundColor: theme.palette.background.neutral.neutral200
    },
    barHeading: {
        color: theme.palette.text.neutral.neutral700
    },
    textHeading: {
        color: theme.palette.text.primary.primary900
    },
    selectBox: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    radioColor: {
        color: theme.palette.background.primary.primary400,
        width: '23%',
        marginTop: "16px"
    },
    helperText: {
        position: 'absolute',
        bottom: '-20px',
    },
    errorMessage: {
        color: "red",
    },
}));

const paymentTermOptions = [
    { value: "CASH", label: "Cash Down" },
    { value: "CREDIT", label: "Credit" },
]



const CustomisationFormTemplate: React.FC<VendorCatalogueSectionProp> = ({ formik, setCurrentSectionTo, mode }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [activeTab, setActiveTabTo] = useState("1");
    const [deliveryTermsActiveTab, setDeliveryTermsActiveTabTo] = useState("1");
    const [paymentTerm, setPaymentTerm] = useState<string>("");
    const [status, setStatus] = useState<string>("");
    const { user } = useAuthenticatedUser();
    const vendorInventoryService = useVendorInventoryService();
    const [warehouseData, setWarehouseData] = useState<{ id: number, name: string }[]>([{ id: 0, name: '' }]);
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const [productsData, setProductsData] = useState<IProductData | null>(null);
    const vendorCatalogueService = useVendorCatalogueService();

    const fetchWarehouse = async () => {
        if (user?.businessId) {
            try {
                const wareHouseResponse = await vendorInventoryService.getAllWarehouseMappedVendor({ vendorId: user?.businessId, status: STATUS.ACTIVE });
                if (wareHouseResponse.status === HTTP_STATUS.OK) {
                    const warehouses = wareHouseResponse?.data?.data?.content;
                    setWarehouseData(warehouses);
                }
            } catch (error) {
                showSnackbar('error', 'Warehouse fetch failed');
            }
        }
    };

    useEffect(() => {
        fetchWarehouse();
    }, [user?.businessId]);

    const schema: ITabsSchema[] = warehouseData?.map((warehouse: { id: number, name: string }, index: number) => ({
        label: capitalizeFirstLetter(warehouse?.name),
        component: <CustomisationDetailsTemplate name={warehouse?.name} warehouseId={warehouse.id} formik={formik} index={index}/>
    }));

    const schemaDeliveryTerms: ITabsSchema[] = warehouseData?.map((warehouse: { id: number, name: string }, index: number) => ({
        label: capitalizeFirstLetter(warehouse?.name),
        component: <DeliveryTermsTemplate warehouseId={warehouse.id} index={index} formik={formik} setCurrentSectionTo={setCurrentSectionTo} />
    }));

    const handleTabChange = (tab: string | number) => {
        setActiveTabTo(tab.toString());
    };

    const handleDeliveryTermsTabChange = (tab: string | number) => {
        setDeliveryTermsActiveTabTo(tab.toString());
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue("paymentTerms", event.target.value);
        setPaymentTerm(event.target.value);
    };

    const handleChangeStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue("status", event.target.value);
        setStatus(event.target.value);
    };

    const handleOnSaveDraft = async () => {
        const body: IVendorCatalogueAddServiceForm = {
            name: formik.values.name,
            description: formik.values.description,
            catalogueType: formik.values.catalogueType,
            productType: formik.values.productType,
            paymentTerms: formik.values.paymentTerms,
            status: "DRAFT",
            termsAndConditions: formik.values.termsConditions,
            catalogueProductDetails: formik.values.catalogueProductDetails.map((productDetails) => ({
                vendorProductId: Number(formik.values.productId) ?? 0,
                warehouseId: productDetails.warehouseId ?? 0,
                marketRetailPrice: productDetails.maximumRetailPrice ?? 0,
                offerSalePrice: productDetails.offerSalePrice ?? 0,
                paymentType: formik.values.paymentTerms ?? "",
                deliveryTerms: productDetails.deliveryTerms,
                packagingTypeEnum: productDetails.packagingType,
                productChargesDetailsRequestBodyDTOs: [
                    { locationId: productDetails.warehouseId ?? 0, chargeType: "TCS", rate: productDetails.tcs ?? 0, rateType: "PERCENTAGE" },
                    { locationId: productDetails.warehouseId ?? 0, chargeType: "CGST", rate: productDetails.cGst ?? 0, rateType: "PERCENTAGE" },
                    { locationId: productDetails.warehouseId ?? 0, chargeType: "SGST", rate: productDetails.sGst ?? 0, rateType: "PERCENTAGE" },
                    { locationId: productDetails.warehouseId ?? 0, chargeType: "IGST", rate: productDetails.iGst ?? 0, rateType: "PERCENTAGE" },
                    { locationId: productDetails.warehouseId ?? 0, chargeType: "TAX_3", rate: productDetails.tax3 ?? 0, rateType: "PERCENTAGE" },
                ],
                productDiscountDetailsRequestBodyDTOs: productDetails.productDiscountDetails.map((discount) => ({
                    locationId: productDetails.warehouseId ?? 0,
                    minQuantity: discount.minQuantity ?? 0,
                    maxQuantity: discount.maxQuantity ?? 0,
                    discountType: "PERCENTAGE",
                    minDiscount: discount.minDiscount ?? 0,
                    maxDiscount: discount.minDiscount ?? 0,
                })),
                productServicesDetailsRequestBodyDTOs: productDetails.productServicesDetails.map((machine) => ({
                    locationId: productDetails.warehouseId ?? 0,
                    machineId: machine.machineId ?? 0,
                }))
            }))
        };
    
        if (user?.businessId) {
            try {
                const vendorCatalogueCreateResponse = await vendorCatalogueService.createVendorCatalogue(user.businessId, body);
                if (vendorCatalogueCreateResponse.status === HTTP_STATUS.OK) {
                    navigate(VENDOR_CATALOGUE_ROUTES.MAKE_CATALOGUE);
                }
            } catch (error) {
                showSnackbar("error", `Failed to Draft Catalogue. Please try again.`);
            }
        }
    };
    
    const handleCancel = () => {
        navigate(VENDOR_CATALOGUE_ROUTES.MAKE_CATALOGUE);
    };

    const handleBack = () => {
        setCurrentSectionTo(VENDOR_CATALOGUE_FORM_STATES.ADD_PRICING);
    }

    const onNext = (event: React.FormEvent) => {
        if (!formik.values.paymentTerms) {
            showSnackbar('error', "Payment Terms is Required");
            return;
        }
        if (!formik.values.status) {
            showSnackbar('error', "Status is Required");
            return;
        }
        event.preventDefault();
        let fieldError = false;
        const requiredFields = ["paymentTerms","termsConditions","description"];
        for (const key of requiredFields) {
            if (key in formik.errors) {
                formik.setFieldTouched(key, true);
                fieldError = true;
            }
        }

        formik?.errors?.catalogueProductDetails && ( formik?.errors?.catalogueProductDetails as FormikErrors<IcatalogueProductDetails>[]).forEach((catalogueProductErrors: FormikErrors<IcatalogueProductDetails>, index: number) => {
            if (catalogueProductErrors?.deliveryTerms) {
                formik.setFieldTouched(`catalogueProductDetails[${index}].deliveryTerms`, true);
                fieldError = true;
            }
            if (catalogueProductErrors?.packagingType) {
                formik.setFieldTouched(`catalogueProductDetails[${index}].packagingType`, true);
                fieldError = true;
            }
        });
        formik.setValues((prevValues: IVendorCatalogueAddForm) => ({
            ...prevValues,
        }));
        if (fieldError) return;
        setCurrentSectionTo(VENDOR_CATALOGUE_FORM_STATES.REVIEW);

    }
    
    const getVedorProductbyId = async () => {
        if (user?.businessId && formik?.values?.productId) {
            try {
                const productId = formik?.values?.productId;
                const ProductsResponse = await vendorInventoryService.getDetailedProduct(user?.businessId, productId, { vendorProductDetailsType: VENDOR_PRODUCT_DETAILS_TYPE.VENDOR_PRODUCT_USN_DETAILS });
                if (ProductsResponse.status === HTTP_STATUS.OK) {
                    const productData = ProductsResponse?.data?.data;
                    setProductsData({
                        id: productData?.id,
                        productId: productData?.productId,
                        skuCode: productData?.standardProductDetails.upcCode,
                        productName: productData?.productUpcTitle,
                        standardProductDetails: productData?.standardProductDetails,
                        vendorProductStockDetails: productData?.vendorProductStockDetails
                    });
                }
            } catch (error) {
                showSnackbar('error', 'Detailed Product Price fetch failed');
            }
        }
    };

    useEffect(() => {
        getVedorProductbyId();
    }, [formik?.values?.productId]);

    useEffect(() => {
        document.getElementById("main")?.scroll(0, 0); 
    }, []);
    return (
        <div className={`${classes.webContainer} grid gap-y-8 `}>
            <div className={`w-full m-auto flex justify-between gap-x-2 text-var(--black, #4D4D4D) text-center text-sm `}>
                <div className="grid gap-y-3 w-3/5 ">
                    <div className='flex gap-x-2 mx-auto '>
                        <div className={`${classes.activatedProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto `}>Select Catalog Type </div>
                    </div>
                    <div className={`${classes.activatedProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-3/5 ">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.activatedProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto`}>Select Product Type </div>
                    </div>
                    <div className={`${classes.activatedProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/2">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.activatedProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto`}>Select Product </div>
                    </div>
                    <div className={`${classes.activatedProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-3/5">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.activatedProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto`}>Enter Pricing Details</div>
                    </div>
                    <div className={`${classes.activatedProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-3/5">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.activeProgressBar} w-3 h-3 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto font-semibold`}>Select Customisation </div>
                    </div>
                    <div className={`${classes.activeProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/5">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.UnTouchProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto`}>Review</div>
                    </div>
                    <div className={`${classes.UnTouchProgressBar} h-2.5 rounded `}></div>
                </div>
            </div>

            <div className=' grid gap-4'>
                <div className=' grid gap-1/2'>
                    <text className={`${classes.textHeading} text-base font-medium`}> Select Customisation</text>
                    <text className={`${classes.barHeading} text-xs font-normal`}> Please select the Customisation available for the catalog</text>
                </div>
                <div>
                    <ProductViewTemplate
                        product={productsData}
                        showButton={false}
                        showUsnDetails={true}
                    />
                </div>
                <div className={`${classes.selectBox} p-6 rounded-xl grid gap-4 border-3`}>
                    <text className={`${classes.textHeading} text-2xl font-medium`}>Customisation Details</text>
                    <div className='mt-3'>
                        <TabsV2 schema={schema} value={activeTab} setValue={handleTabChange} />
                    </div>
                </div>
                <div className={`${classes.selectBox} p-6 rounded-xl grid gap-4 border-3`}>
                    <text className={`${classes.textHeading} text-2xl font-medium`}>Delivery Terms</text>
                    <div className='mt-3'>
                        <TabsV2 schema={schemaDeliveryTerms} value={deliveryTermsActiveTab} setValue={handleDeliveryTermsTabChange} />
                    </div>
                </div>
                <div className={`${classes.selectBox} p-6 rounded-xl grid gap-4 border-3`}>
                    <text className={`${classes.textHeading} text-2xl font-medium`}>Sales Terms and Conditions</text>
                    <div className='mt-3'>
                        <div>
                            <text className={`${classes.textHeading} text-base font-medium`}>Payment Terms</text>
                            <div className='flex gap-x-4'>
                                {paymentTermOptions.map((option) => (
                                    <FormControlLabel
                                        key={option.value}
                                        className='flex'
                                        control={
                                            <Checkbox
                                                checked={formik.values.paymentTerms.includes(option.value)}
                                                onChange={(event) => {
                                                    const newValue = event.target.checked
                                                        ? [...formik.values.paymentTerms, option.value]
                                                        : formik.values.paymentTerms.filter((item: any) => item !== option.value);

                                                    formik.setFieldValue('paymentTerms', newValue);
                                                }}
                                                name="paymentTerms"
                                            />
                                        }
                                        label={option.label}
                                    />
                                ))}
                            </div>
                            {/* {formik.touched?.paymentTerms && formik.errors?.paymentTerms &&
                                <FormHelperText error className='absolute top-full'>{formik.errors?.paymentTerms}</FormHelperText>
                            } */}
                        </div>
                        <div className='my-6'>
                            <TextFieldV2
                                {...formik.getFieldProps("description")}
                                label="Description"
                                size="small"
                                placeholder="Enter data"
                                multiline
                                rows={4}
                            />
                            {formik.touched && formik.touched?.description && formik.errors?.description && (
                                <div className={classes.errorMessage}>
                                    <small>{formik.errors?.description}</small>
                                </div>
                            )}
                        </div>
                        <div>
                            <TextFieldV2
                                {...formik.getFieldProps("termsConditions")}
                                label={"Sales Terms and Conditions"}
                                size="small"
                                placeholder={"Enter data"}
                                multiline
                                rows={4}
                            />
                            {formik.touched && formik.touched?.termsConditions && formik.errors?.termsConditions && (
                                <div className={classes.errorMessage}>
                                    <small>{formik.errors?.termsConditions}</small>
                                </div>
                            )}
                        </div>
                        <div className='mt-6'>
                            <text className={`${classes.textHeading} text-base font-medium`}>Status</text>
                            <CustomRadioGroup
                                options={catalogueStatusOptions}
                                value={formik.values.status}
                                onChange={handleChangeStatus}
                                className={`${classes.radioColor} `} />
                            {/* {formik.touched?.paymentTerms && formik.errors?.paymentTerms &&
                                <FormHelperText error className='absolute top-full'>{formik.errors?.paymentTerms}</FormHelperText>
                            } */}
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex justify-between items-center'>
                <Button
                    variant="tertiaryContained"
                    label="Cancel"
                    onClick={handleCancel}
                />
                <div className='flex gap-4'>
                    <Button
                        variant="tertiaryContained"
                        label="Back"
                        onClick={handleBack}
                    />
                    {/* {mode === MODE.ADD && (
                        <Button
                            variant="secondaryContained"
                            label="Save as Draft"
                            onClick={handleOnSaveDraft}
                        />
                    )} */}
                    <Button
                        variant="primaryContained"
                        label="Review"
                        onClick={onNext}
                    />
                </div>
            </div>

        </div>
    )
}

export default CustomisationFormTemplate