import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import * as Yup from "yup";
import { useFormik } from "formik";
import TextFieldV2 from '../../../atoms/Input/TextFieldV2';
import { useSnackbar } from '../../../../hooks/useSnackBar';
import { REGEX } from '../../../../utils/constant';
import { HTTP_STATUS, STATUS } from '../../../../utils/types';
import requestDemo from '../../../../assets/images/requestDemo.svg';
import { IEnquiryRequestBody, useEnquiryService } from '../../../../services/useEnquiryService';
import ResponsePopupTemplate from './ResponsePopup.template';
import { useAuthenticatedUser } from '../../../../hooks/useAuthenticatedUser';
import { useBusinessProfileService } from '../../../../services/useBusinessProfileService';
import { IBusinessProfile } from '../../../pages/DashBoard/StoreFrrontAboutUs.page';
import demoBackground from '../../../../assets/images/demoBackground.jpg';
import { Button } from '@mui/joy';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        backgroundImage: `url(${demoBackground})`,
        padding: "99px 0px"
    },
    primaryContainer: {
        width: '1020px',
    },
    secondaryContainer: {
        borderTopRightRadius: "10px",
        borderBottomRightRadius: "10px",
        padding: "30px 40px",
        background: "rgba(255, 255, 255, 0.90)",
        boxShadow: '4px 0px 10px 0px rgba(0, 0, 0, 0.10)',
    },
    mainContainer: {
        backgroundImage: `url(${requestDemo})`,
        backgroundSize: 'cover',
    },
    heading: {
        fontSize: '32px',
        color: "white",
        lineHeight: "37.76px",
        letterSpacing: "-0.64px"
    },
    subHeading: {
        fontSize: '20px',
        color: theme.palette.text.neutral.neutral50,
        lineHeight: '23.6px',
        letterSpacing: "-0.4px"
    },
    subText: {
        color: theme.palette.text.neutral.neutral50,
        letterSpacing: "0.16px",
        lineHeight: '22px',
    },
    errorMessage: {
        color: theme.palette.text.secondary.secondary500,
    },
    requestHeader: {
        "& h4": {
            fontSize: '32px',
            lineHeight: '37px',
        },
        "& p": {
            lineHeight: '22px',
        },
    },
    requestDemoHeading: {
        fontSize: '54px',
        lineHeight: '66px',
    },
    btn: {
        color: "#fff",
        background: theme.palette.background.primary.primary500,
        padding: '10px 20px',
        width: '228px',
    },
    btnArrow: {
        border: '1px solid #fff',
        borderRadius: '100%',
        marginLeft: '10px',
    },
    inputmain: {
        border: '1px solid red !important',
    }

}));

const validationSchema = Yup.object().shape({
    name: Yup.string().matches(REGEX.FULL_NAME, '').required(''),
    email: Yup.string().email("").matches(REGEX.EMAIL, '').required(''),
    phoneNumber: Yup.string().matches(REGEX.PHONE_NUMBER, '').required(""),
    companyName: Yup.string().required(""),
});

const RequestDemoTemplate: React.FC = () => {
    const classes = useStyles();
    const { showSnackbar, SnackBarComponent } = useSnackbar()
    const enquiryService = useEnquiryService();
    const [isImageModalOpen, setIsImageModalOpen] = useState(false);
    const { user } = useAuthenticatedUser();
    const [businessProfile, setBusinessProfileTo] = useState<IBusinessProfile | null>(null);
    const bussinessProfileService = useBusinessProfileService();

    const handleImageModalClose = () => {
        setIsImageModalOpen(false);
    };

    const getBusinessProfile = async () => {
        try {
            const businessProfileResponse = await bussinessProfileService.getBusinessProfile();
            if (businessProfileResponse.status === HTTP_STATUS.OK) {
                const profileData = businessProfileResponse?.data?.data;
                setBusinessProfileTo(profileData);
                return profileData;
            }
        } catch (error) {
            showSnackbar('error', 'Business Profile fetch failed');
        }
    };

    const [initialValues, setInitialValues] = useState<IEnquiryRequestBody>({
        name: '',
        email: '',
        phoneNumber: '',
        category: '',
        description: '',
        quantity: 0,
        type: "DEMO",
        vendorId: null,
        companyName: '',
        section: "HOME_PAGE",
        status: STATUS.ACTIVE
    });

    useEffect(() => {
        const fetchProfile = async () => {
            if (user?.businessId) {
                const profile = await getBusinessProfile();
                setInitialValues((prevValues: any) => ({
                    ...prevValues,
                    email: user.email || '',
                    phoneNumber: user.mobileNumber || 0,
                    companyName: profile?.name || '',
                    vendorId: user.businessId || null,
                }));
            }
        };
        fetchProfile();
    }, []);

    const formik = useFormik<IEnquiryRequestBody>({
        initialValues,
        enableReinitialize: true,
        validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true);
            const body: IEnquiryRequestBody = {
                name: values.name,
                email: values.email,
                phoneNumber: values.phoneNumber,
                category: values.category,
                description: values.description,
                quantity: values.quantity,
                type: values.type,
                vendorId: values.vendorId,
                companyName: values.companyName,
                section: values.section,
                status: values.status
            }
            try {
                const enquiryResponse = await enquiryService.createEnquiry(body);
                if (enquiryResponse.status === HTTP_STATUS.OK) {
                    setIsImageModalOpen(true);
                }
                formik.resetForm();
            }
            catch (error) {
                showSnackbar("error", `Enquiry create failed ${error}`)
            }
            finally {
                setSubmitting(false);
            }
        },
    });

    return (
        <div id='scrollToLabel' className={`${classes.container} w-full `}>
            <h4 className={`${classes.requestDemoHeading} font-semibold mb-10 text-center`}>Request a Demo </h4>
            <div className={`${classes.primaryContainer} grid grid-cols-2 mx-auto`}>
                <div className={`${classes.mainContainer} `}>
                    {/* <img src={requestDemo} alt="" /> */}
                </div>
                <div className={`${classes.secondaryContainer} w-full `}>
                    <form onSubmit={formik.handleSubmit} className='grid gap-y-5 w-full'>
                        <div className={classes.requestHeader}>
                            <p className='text-lg font-normal'>Explore how Steelbazaar can transform your business with a quick personalised demo.</p>
                        </div>
                        <div className='grid gap-y-5'>
                            <div className="grid w-full">
                                <TextFieldV2
                                    id="name"
                                    placeholder="Enter Here"
                                    label="Full Name"
                                    className={formik.touched.name && formik.errors.name ? classes.inputmain : ''}
                                    {...formik.getFieldProps("name")}
                                    onBlur={formik.handleBlur} 
                                    error={
                                        formik?.touched?.name &&
                                        Boolean((formik?.touched?.name))
                                    }
                                />
                                {formik.touched.name && formik.errors.name && (
                                    <div className={classes.errorMessage}>
                                        {formik.errors.name}
                                    </div>
                                )}
                            </div>
                            <div className="grid w-full">
                                <TextFieldV2
                                    id="email"
                                    placeholder="Enter Here"
                                    label="E-Mail ID"
                                    {...formik.getFieldProps("email")}
                                    onBlur={formik.handleBlur} 
                                    error={
                                        formik?.touched?.email &&
                                        Boolean((formik?.touched?.email))
                                    }
                                />
                                {formik.touched.email && formik.errors.email && (
                                    <div className={classes.errorMessage}>
                                    </div>
                                )}
                            </div>
                            <div className="grid w-full">
                                <TextFieldV2
                                    id="phoneNumber"
                                    placeholder="Enter Here"
                                    label="Mobile No."
                                    {...formik.getFieldProps("phoneNumber")}
                                    onBlur={formik.handleBlur} 
                                    error={
                                        formik?.touched?.phoneNumber &&
                                        Boolean((formik?.touched?.phoneNumber))
                                    }
                                />
                                {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                                    <div className={classes.errorMessage}>
                                        <small>{formik.errors.phoneNumber}</small>
                                    </div>
                                )}
                            </div>
                            <div className="grid w-full">
                                <TextFieldV2
                                    id="companyName"
                                    placeholder="Enter Here"
                                    label="Company Name"
                                    {...formik.getFieldProps("companyName")}
                                    onBlur={formik.handleBlur} 
                                    error={
                                        formik?.touched?.companyName &&
                                        Boolean((formik?.touched?.companyName))
                                    }
                                />
                                {formik.touched.companyName && formik.errors.companyName && (
                                    <div className={classes.errorMessage}>
                                        <small>{formik.errors.companyName}</small>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div>
                            <Button type='submit' className={classes.btn} >Schedule a Demo Now <div className={classes.btnArrow}><ArrowForwardIcon /></div></Button>
                        </div>
                        <ResponsePopupTemplate
                            dialogOpen={isImageModalOpen}
                            setDialogOpen={handleImageModalClose}
                        />
                    </form>
                </div>
            </div>
        </div>
    );
};

export default RequestDemoTemplate;