import React, { useMemo, useState } from "react";
import { createUseStyles } from "react-jss";
import Button from "../../atoms/Button/Button";
import { toInr } from "../../../utils/helper";
import { ICatalogueUpc } from "../../pages/CatalogueDetail/CatalogueDetails.page";
// import { IWarehouse } from "./WarehouseLocation.template";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { UNIT } from "../../../utils/constant";
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";
import { ICatalogue } from "../../pages/CatalogueDetail/MultipleUpcCatalogueDetails.page";
import AddToCartIcon from '../../../assets/images/addToCart.svg';
import AddToCartDisabledIcon from '../../../assets/images/addToCartDisabled.svg';
import Whatsapp from "../../atoms/Whatsapp/Whatsapp";
// import SubjectToTermsConditionTemplate from "./SubjectToTermsCondition.template";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { IWarehouse } from "../CatalogueDetail/WarehouseLocation.template";
import SubjectToTermsConditionTemplate from "../CatalogueDetail/SubjectToTermsCondition.template";
const useStyles = createUseStyles((theme: any) => ({
	title: {
		background: '#FFF',
		boxShadow: `0px 0px 16px 0px rgba(0, 0, 0, 0.09)`,
		padding: "20px",
		borderRadius: "16px"
	},
	// priceDetails: {
	// 	color: theme.palette.text._primaryDarkAccent,
	// 	borderBottom: `1px solid ${theme.palette.border.secondaryLight}`
	// },
	priceText: {
		color: theme.palette.text.primary.primary700,
	},
	priceValue: {
		color: theme.palette.text.neutral.neutral700,
		fontSize: "24px"
	},
	quantityText: {
		color: theme.palette.text.neutral.neutral900,
	},
	cartIcon: {
		position: "absolute",
		zIndex: 1,
		padding: "12px 0px 0px 60px",
	},
	cartButton: {
		background: theme.palette.background.neutral.neutral50,
		borderRadius: "10px",
		"&.MuiButton-root": {
			color: theme.palette.background.neutral.neutral50,
		},
		"&:hover": {
			background: theme.palette.background.neutral.neutral50,
		},
		"&:focus": {
			border: "none",
		},
	},
	cartDisabledButton: {
		background: theme.palette.background.neutral.neutral50,
		"&:hover": {
			background: theme.palette.background.neutral.neutral50,
		},
	},
	// priceDetailsBackground: {
	// 	background: theme.palette.background._secondaryLight
	// },
	uom: {
		color: theme.palette.text.neutral.neutral600,
		fontSize: "14px",
	},
	gst: {
		color: theme.palette.text.neutral.neutral600,
		fontSize: "14px",
	},
	// finalPrice: {
	// 	color: theme.palette.text.secondaryDark
	// },
	selectedUpc: {
		color: theme.palette.background.neutral.neutral400,
		border: `1px solid ${theme.palette.border.neutral.neutral200}`,
		background: '#fff'
	},
	incrementDecrement: {
		background: '#fff',
		color: theme.palette.text.primary.primary800,
		border: `1px solid ${theme.palette.text.primary.primary800}`,
		// boxShadow: `0px 4px 8px 3px ${theme.palette.v2.boxShadow.primaryDark}, 0px 1px 3px 0px ${theme.palette.v2.boxShadow.secondaryLight}`,
	},
	// basePrice: {
	// 	textDecoration: "line-through",
	// 	textDecorationColor: theme.palette.v4.background._primaryDarkAccent,
	// 	color: "inherit",
	// },
	// price: {
	// 	color: theme.palette.v4.text.primaryDarkAccent,
	// },
	count: {
		background: 'white',
		color: theme.palette.text.neutral.neutral400,
		border: `1px solid ${theme.palette.border.neutral.neutral200}`,
		fontSize: "16px",
		fontWeight: 500,
	},
	specialPrice: {
		color: theme.palette.text.neutral.neutral800,
	},
	minimumQuantity: {
		color: theme.palette.text.neutral.neutral500,
	},
	finalPriceText: {
		color: theme.palette.text.neutral.neutral600,
	},
	// email: {
	// 	borderRadius: "10px",
	// 	border: `1px solid ${theme.palette.v3.border.primaryLightAccent}`,
	// 	width: "54px",
	// 	height: "48px",
	// },
	// emailIcon: {
	// 	borderRadius: "10px",
	// 	width: "54px",
	// 	height: "48px",
	// 	background: theme.palette.v4.background._primaryDark,
	// },
	arrow: {
		display: "none"
	},
	termAndCondition: {
		display: "flex"
	},
	webContainer: {
		display: "grid"
	},
	mobileContainer: {
		display: "none"
	},
	webButton:{
		display: "flex"
	},
	mobileButton:{
		display: "none"
	},
	"@media (max-width: 480px)": {
		arrow: {
			display: "flex"
		},
		termAndCondition: {
			display: "none"
		},
		// webContainer: {
		// 	display: "none"
		// },
		// mobileContainer: {
		// 	display: "grid"
		// },
		// webButton:{
		// 	display: "none"
		// },
		// mobileButton:{
		// 	display: "flex"
		// },
		// email: {
		// 	width: "49px",
		// 	height: "43px",
		// },
		// emailIcon: {
		// 	width: "49px",
		// 	height: "43px",
		// },
		// gst: {
		// 	fontSize: "12px",
		// },
		// priceValue: {
		// 	fontSize: "18px"
		// },
		// uom: {
		// 	fontSize: "12px",
		// },
		// title: {
		// 	borderRadius: "0px",
		// 	boxShadow: `none`,
		// 	padding: "16px",
		// 	border: `1px solid ${theme.palette.borderV2.tertiary.tertiary100}`,
		// },
	},
}));
export interface PriceDisplayProps {
	price: string | number;
	uom?: string;
	gstText: string;
	classes: {
	  priceValue: string;
	  uom: string;
	  gst: string;
	};
}
export interface PriceDetailsProps {
	minimumPrice: number | null;
	maximumPrice: number | null;
	quantity: string;
	moq: string;
	warehouse: IWarehouse | null;
	gst: number;
	selectedUpc?: ICatalogueUpc | null;
	uom: string;
	handleAddToCart: () => void;
	handleQuantityChange: any;
	catalogueType: String;
	catalogue?: ICatalogue;
	paymentType?: string | null;
	productCombination: boolean | null;
}
const PriceDetailsReviewFormTemplate: React.FC<PriceDetailsProps> = ({ handleAddToCart, paymentType, minimumPrice, handleQuantityChange, maximumPrice, moq, quantity, warehouse, gst, selectedUpc, uom, catalogueType, catalogue, productCombination}) => {
	const classes = useStyles();
	const { user } = useAuthenticatedUser();
	const alterQuantity = (alterQuantityUnit: number) => () => {
		const currentQuantity = (parseFloat(quantity) || 0).toString();
		handleQuantityChange(String(parseFloat(Math.max(parseFloat(currentQuantity) + alterQuantityUnit, parseFloat(moq)).toFixed(2))));
	};
	const increaseQuantity = () => {
		if (warehouse) {
			alterQuantity(1)();
		}
	};
	const decreaseQuantity = () => {
		if (warehouse) {
			alterQuantity(-1)();
		}
	};
	const [dialogOpen, setDialogOpen] = useState<boolean>(false);
	const handleRfqForm = () => {
		setDialogOpen(true);
	}
	const [showAll, setShowAll] = useState(false);
	const handleClick = () => {
		setShowAll(!showAll);
	};
	const PriceDisplay: React.FC<PriceDisplayProps> = ({ price, uom, gstText, classes }) => (
		<div className="flex text-2xl font-semibold tracking-tighter mb-2 whitespace-nowrap">
		  <div className="flex">
			<span className={`${classes.priceValue} font-semibold`}>
			  {price}
			</span>
		  </div>
		  <span className={`${classes.uom} font-bold mt-0 mx-1`}>*</span>
		  <span className={`${classes.uom} font-normal my-auto mr-2`}>
			{`/${uom || "MT"}`}
		  </span>
		  <div className={`${classes.gst} font-medium my-auto`}>
			<span className="mr-1">
			  {gstText}
			</span>
			<span>GST@18%</span>
		  </div>
		</div>
	  );
	  
	  const productPriceDisplay = useMemo(() => {
		const gstText ="exclu.";
		if (productCombination === false) {
		  return (
			<PriceDisplay
			  price="₹ - "
			  uom={uom}
			  gstText={gstText}
			  classes={classes}
			/>
		  );
		}
	  
		const outOfStock = selectedUpc && Object.keys(selectedUpc).length > 1 && !warehouse;
	  
		if (outOfStock) {
		  return (
			<PriceDisplay
			  price="₹ - "
			  uom={uom}
			  gstText={gstText}
			  classes={classes}
			/>
		  );
		}
	  
		if (selectedUpc && Object.keys(selectedUpc).length > 0) {
		  const price = toInr(selectedUpc.upcPrice || 0);
		  return (
			<PriceDisplay
			  price={price}
			  uom={uom}
			  gstText={gstText}
			  classes={classes}
			/>
		  );
		}
	  
		const priceRange = minimumPrice === maximumPrice
		  ? toInr(minimumPrice || 0)
		  : toInr(maximumPrice || 0);
	  
		return (
		  <PriceDisplay
			price={priceRange}
			uom={uom}
			gstText={gstText}
			classes={classes}
		  />
		);
	  }, [selectedUpc, warehouse, minimumPrice, maximumPrice, toInr, uom, classes]);
	  
	  
	  const FinalPriceText = useMemo(() => {
		let message = "For Final Price/Quotation, Click Here.";
		const quantityValid = quantity && parseFloat(quantity) >= parseFloat(moq);
		let rfqFlag = false;
		if (productCombination === false) {
		  message = "Product not available for this selection";
		  rfqFlag = true;
		} else if (!warehouse || !selectedUpc || !quantity || !quantityValid) {
		  if (!warehouse && selectedUpc && Object.keys(selectedUpc).length > 0) {
			message = "Out of Stock";
			rfqFlag = true;
		  } else {
			message = "For Final Price/Quotation, Click Here.";
		  }
		}
	  
		return (
			<>
			  <div className={`${classes.finalPriceText} text-sm font-medium mb-1`}>
				{message}
			   </div>
			   <div className={`${classes.finalPriceText} text-sm font-medium mb-1`}>
					{rfqFlag ? `Please refer to 'Request for Quotation'`: null}
			   </div>
			</>
		);
	  }, [warehouse, selectedUpc, quantity, moq, productCombination, classes.finalPriceText]);
	  
	
	return (
		<div className={`grid ${classes.title} gap-y-3`}>
			{/* TODO: Will uncomment after getting data from backend */}
			<div className={`${classes.webContainer}  gap-y-4 pb-3 border-b`}>
				<div className="flex flex-col border-b pb-4">
					<span className={`${classes.priceText} text-xl font-medium mb-3`}>My Price</span>
					{productPriceDisplay}
					{/* <div className="flex text-2xl font-semibold tracking-tighter mb-2 whitespace-nowrap">
						{selectedUpc && (Object.keys(selectedUpc).length > 0) ?
							<div className="flex">
								<span className={`${classes.priceValue} font-semibold`}>{toInr(selectedUpc.upcPrice || 0)}</span>
							</div>
							: minimumPrice === maximumPrice
								? `${toInr(minimumPrice || 0)}`
								: `${toInr(minimumPrice || 0)}  -  ${toInr(maximumPrice || 0)}`} 
						 <span className={`${classes.uom} font-bold mt-0 mx-1`}>*</span>
						<span className={`${classes.uom} font-normal my-auto mr-2`}>{`/${uom || "MT"}`}</span>
						<div className={`${classes.gst} font-medium my-auto`}>
							<span className="mr-1">{`${selectedUpc && selectedUpc.upcPrice ? "Excluding " : "Exclu."}`}</span>
							<span>GST@18%</span>
						</div>
					</div> */}
					{/* <div className="flex mb-2">
									<span className={`${classes.uom} text-base font-normal`}>Base Price:</span>
									<span className={`${classes.basePrice} text-base font-semibold ml-1`}>Rs75,500</span>
									<span className={`${classes.uom} text-sm font-normal mt-0.5 mr-2`}>{`/${uom || "MT"}`}</span>
									<span className={`${classes.price} text-base font-semibold`}>₹3,000</span>
									<span className={`${classes.uom} text-sm font-normal mt-0.5 mr-2`}>{`/${uom || "MT"}`}</span>
									<span className={`${classes.price} text-base font-medium`}>Off</span>
								</div>
								<div className="flex">
									<span className={`${classes.uom} text-base font-normal`}>Special Deal Discount:</span>
									<span className={`${classes.price} text-base font-semibold ml-1`}>Rs300</span>
									<span className={`${classes.uom} text-sm font-normal mt-0.5 mr-2`}>{`/${uom || "MT"}`}</span>
									<span className={`${classes.price} text-base font-medium`}>Off</span>
								</div> */}
				</div>
				{/* <QuantityDiscountTemplate /> */}
				<div className={`${classes.quantityText} text-xl font-medium`}>Enter Quantity (MT)</div>
				<div className="grid gap-y-2">
					<div className='flex gap-x-4'>
						<div className={`w-8 h-8 my-auto flex rounded justify-center items-center  ${classes.incrementDecrement}`} style={{ cursor: (warehouse && (parseFloat(quantity) > parseFloat(moq))) ? 'pointer' : 'not-allowed' }}><RemoveIcon /></div>
						<input type="text" className={`w-24 h-9 rounded-lg flex text-center justify-center items-center gap-2  ${moq === "" ? classes.selectedUpc : classes.count}`} value={moq} disabled={true} onChange={event => handleQuantityChange(event.target.value)} />
						<div className={`w-8 h-8 my-auto flex rounded justify-center items-center  ${classes.incrementDecrement}`} style={{ cursor: 'not-allowed' }}><AddIcon /></div>
					</div>
					{!(moq === "") && <div className={`font-inter text-xs font-semibold mt-1 mb-1 ${classes.minimumQuantity}`}>Minimum Order Quantity : {moq}{UNIT.UOM} </div>}
				</div>
			</div>
			{showAll &&
				<div className={`${classes.mobileContainer}  gap-y-4 pb-3 border-b`}>
					<div className="flex flex-col border-b pb-4">
						<span className={`${classes.priceText} text-xl font-medium mb-3`}>Avighna Price</span>
						{productPriceDisplay}
						{/* <div className="flex text-2xl font-semibold tracking-tighter mb-2 whitespace-nowrap">
							{selectedUpc && (Object.keys(selectedUpc).length > 0) ?
								<div className="flex">
									<span className={`${classes.priceValue} font-semibold`}>{toInr(selectedUpc.upcPrice || 0)}</span>
								</div>
								: minimumPrice === maximumPrice
									? `${toInr(minimumPrice || 0)}`
									: `${toInr(minimumPrice || 0)}  -  ${toInr(maximumPrice || 0)}`}
							<span className={`${classes.uom} font-bold mt-0 mx-1`}>*</span>
							<span className={`${classes.uom} font-normal my-auto mr-2`}>{`/${uom || "MT"}`}</span>
							<div className={`${classes.gst} font-medium my-auto `}>
								<span className="mr-1">{`${selectedUpc && selectedUpc.upcPrice ? "Excluding" : "Exclu."}`}</span>
								<span>GST@18%</span>
							</div>
						</div> */}
						{/* <div className="flex mb-2">
									<span className={`${classes.uom} text-base font-normal`}>Base Price:</span>
									<span className={`${classes.basePrice} text-base font-semibold ml-1`}>Rs75,500</span>
									<span className={`${classes.uom} text-sm font-normal mt-0.5 mr-2`}>{`/${uom || "MT"}`}</span>
									<span className={`${classes.price} text-base font-semibold`}>₹3,000</span>
									<span className={`${classes.uom} text-sm font-normal mt-0.5 mr-2`}>{`/${uom || "MT"}`}</span>
									<span className={`${classes.price} text-base font-medium`}>Off</span>
								</div>
								<div className="flex">
									<span className={`${classes.uom} text-base font-normal`}>Special Deal Discount:</span>
									<span className={`${classes.price} text-base font-semibold ml-1`}>Rs300</span>
									<span className={`${classes.uom} text-sm font-normal mt-0.5 mr-2`}>{`/${uom || "MT"}`}</span>
									<span className={`${classes.price} text-base font-medium`}>Off</span>
								</div> */}
					</div>
					{/* <QuantityDiscountTemplate /> */}
					<div className={`${classes.quantityText} text-xl font-medium`}>Enter Quantity (MT)</div>
					<div className="grid gap-y-2">
						<div className='flex gap-x-4'>
							<div className={`w-8 h-8 my-auto flex rounded justify-center items-center  ${classes.incrementDecrement}`} onClick={decreaseQuantity} style={{ cursor: (warehouse && (parseFloat(quantity) > parseFloat(moq))) ? 'pointer' : 'not-allowed' }}><RemoveIcon /></div>
							<input type="text" className={`w-24 h-9 rounded-lg flex text-center justify-center items-center gap-2  ${moq === "" ? classes.selectedUpc : classes.count}`} value={moq === "" ? "" : quantity} disabled={moq === ""} onChange={event => handleQuantityChange(event.target.value)} />
							<div className={`w-8 h-8 my-auto flex rounded justify-center items-center  ${classes.incrementDecrement}`} onClick={increaseQuantity} style={{ cursor: warehouse ? 'pointer' : 'not-allowed' }}><AddIcon /></div>
						</div>
						{!(moq === "") && <div className={`font-inter text-xs font-semibold mt-1 mb-1 ${classes.minimumQuantity}`}>Minimum Order Quantity : {moq}{UNIT.UOM} </div>}
					</div>
				</div>
			}
			<div>
				<div className="flex justify-between">
					<div className={`text-sm font-medium mt-1 ${classes.specialPrice}`}>Price (After Discounts)</div>
					<div className={`${classes.arrow} gap-x-2 cursor-pointer`} onClick={handleClick} >
						{showAll ? <ArrowDropUp /> : <ArrowDropDown />}
					</div>
				</div>
				{productPriceDisplay}
				{/* <div className="flex text-2xl font-bold tracking-tighter mb-4 whitespace-nowrap">
					{selectedUpc && (Object.keys(selectedUpc).length > 0) ?
						<div className="flex">
							<span className={`${classes.priceValue} font-bold`}>{toInr(selectedUpc.upcPrice || 0)}</span>
						</div>
						: minimumPrice === maximumPrice
							? `${toInr(minimumPrice || 0)}`
							: `${toInr(minimumPrice || 0)}  -  ${toInr(maximumPrice || 0)}`}
					<span className={`${classes.uom} font-bold mt-0 mx-0.5`}>*</span>
					<span className={`${classes.uom} font-normal my-auto mr-2`}>{`/${uom || "MT"}`}</span>
					<div className={`${classes.gst} font-medium my-auto`}>
						<span className="mr-1">{`${selectedUpc && selectedUpc.upcPrice ? "Excluding" : "Exclu."}`}</span>
						<span>GST@18%</span>
					</div>
				</div> */}
				{FinalPriceText}
				{/* <div className={`${classes.finalPriceText} text-sm font-medium mb-1`}>{(!warehouse || !selectedUpc || !quantity || (parseFloat(quantity) < parseFloat(moq))) ? "Please select specification to add to cart." : productCombination === false ? "No Product Present with this combination" : "For Final Price/Quotation, Click Here."}</div> */}
				{/* <div className={`${classes.finalPriceText} text-sm font-medium mb-2`}>{(!paymentType) ? "Please select payment method to add to cart." : null}</div> */}
				<div className="flex justify-between gap-x-2">
					<div className={`${classes.webButton} flex-1`}>
						<Button variant="primaryContained" disabled={(!warehouse || !selectedUpc || !quantity || (parseFloat(quantity) < parseFloat(moq)) || !paymentType) ?? false} onClick={handleAddToCart} fullWidth label="Order Now" className={`${(!warehouse || !selectedUpc || !quantity || (parseFloat(quantity) < parseFloat(moq))) ? classes.cartDisabledButton : classes.cartButton}`} startIcon={
							(!warehouse || !selectedUpc || !quantity || (parseFloat(quantity) < parseFloat(moq))) ?
								<img src={AddToCartDisabledIcon} alt="icon" /> :
								<img src={AddToCartIcon} alt="icon" />}
						/>
					</div>
					<div className={`${classes.mobileButton} flex-1`}>
						<Button variant="primaryContained" size="small" disabled={(!warehouse || !selectedUpc || !quantity || (parseFloat(quantity) < parseFloat(moq)) || !paymentType) ?? false} onClick={handleAddToCart} fullWidth label="Order Now" className={`${(!warehouse || !selectedUpc || !quantity || (parseFloat(quantity) < parseFloat(moq))) ? classes.cartDisabledButton : classes.cartButton}`} startIcon={
							(!warehouse || !selectedUpc || !quantity || (parseFloat(quantity) < parseFloat(moq))) ?
								<img src={AddToCartDisabledIcon} alt="icon" /> :
								<img src={AddToCartIcon} alt="icon" />}
						/>
					</div>
					<Whatsapp isActive={false}/>
				</div>
			</div>
			{/* {((selectedUpc && Object.keys(selectedUpc).length === 0) || !selectedUpc) ? (
				<div className={`p-2.5 mt-2 ${classes.selectedUpc} text-xs font-normal rounded`}>
					Select the product attributes before adding to the cart
				</div>
			) : !warehouse ? (
				<div className={`p-2.5 mt-2 ${classes.selectedUpc} text-xs font-normal rounded`}>
					Product out of stock, no warehouse found
				</div>
			) : (!quantity || parseFloat(quantity) < parseFloat(moq)) && (
				<div className={`p-2.5 mt-2 ${classes.selectedUpc} text-xs font-normal rounded`}>
					Please order minimum order quantity before adding to the cart
				</div>
			)} */}
			<div className={`${classes.termAndCondition}`}>
				<SubjectToTermsConditionTemplate />
			</div>
		</div>
	);
};
export default PriceDetailsReviewFormTemplate;