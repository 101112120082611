import React, { useRef, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { useLocation } from 'react-router-dom';
import { Button } from '@mui/joy';
import tradeAssurance from '../../../assets/images/tradeAssurance.jpg';
import RequestDemoTemplate from '../../template/Home/RequestDemo/RequestDemo.template';
import website from '../../../assets/images/website.jpg';
import moneyGrowth from '../../../assets/images/moneyGrowth.jpg';
import onlineMoney from '../../../assets/images/onlineMoney.jpg';
import onlineStore from '../../../assets/images/onlineStore.jpg';
import moneyCurrency from '../../../assets/images/moneyCurrency.jpg';
import analyst from '../../../assets/images/analyst.jpg';
import action from '../../../assets/images/action.jpg';
import rupees from '../../../assets/images/rupees.jpg';
import sbInstant from '../../../assets/images/sbInstant.jpg';
import roudTickIcon from '../../../assets/icons/roudTickIcon.jpg';
import purchase from '../../../assets/icons/purchase.jpg';
import sales from '../../../assets/icons/sales.jpg';
import explore from '../../../assets/icons/explore.jpg';
import visibility from '../../../assets/icons/visibility.jpg';
import transaction from '../../../assets/icons/transaction.jpg';
import financing from '../../../assets/icons/financing.jpg';
import card1 from '../../../assets/images/card1.svg';
import pricing from '../../../assets/images/pricing.svg';
import SBDigiStore from '../../../assets/images/SBDigiStore.svg';
import Lead from '../../../assets/images/Lead.svg';
import bid from '../../../assets/images/bid.svg';
import management from '../../../assets/images/management.svg';
import sbpool from '../../../assets/images/sbpool.svg';
import instafin from '../../../assets/images/instafin.svg';
import insights from '../../../assets/images/insights.svg';
import tradingBackground from '../../../assets/images/tradingBackground.jpg';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import sellerBackground from '../../../assets/images/sellerBackground.jpg';
import register from '../../../assets/images/register.jpg';
import sellerProfile from '../../../assets/images/sellerProfile.jpg';
import sellerInverntory from '../../../assets/images/sellerInverntory.jpg';
import sellerSku from '../../../assets/images/sellerSku.jpg';
import sellerStoreFront from '../../../assets/images/sellerStoreFront.jpg';
import sellerCatalog from '../../../assets/images/sellerCatalog.jpg';
import sellerDashboard from '../../../assets/images/sellerDashboard.jpg';

const useStyles = createUseStyles((theme: any) => ({
    bannerContainer: {
        width: '100%',
        height: '650px',
        backgroundImage: `url(${sellerBackground})`,
        backgroundSize: 'cover',
        position: 'relative',
    },
    container: {
        width: '1297px',
        margin: '0 auto',
    },
    bannerSellContainer: {
        padding: '10% 0px'
    },
    heading: {
        fontSize: '56px',
        lineHeight: '66px',
        color: theme.palette.text.primary.primary50,
    },
    primaryHeading: {
        color: '#FD8235',
    },
    secondaryHeading: {
        color: theme.palette.text.warning.warning400,
    },

    bannerDescription: {
        color: theme.palette.text.primary.primary50,
    },
    intoContainer: {
        backgroundColor: theme.palette.background.neutral.neutral50,
    },
    text: {
        fontSize: '32px',
        color: theme.palette.text.primary.primary700,
    },
    primaryText: {
        fontSize: '54px',
        color: '#000',
    },
    decContainer: {
        width: '790px',
        "& p": {
            color: '#000',
            lineHeight: '26px',
            fontWeight: '400',
        },
    },
    sectionHeading: {
        color: "#000",
        fontSize: '54px',
        lineHeight: '66px',
        "& span": {
            color: theme.palette.text.primary.primary500,
        },
        "& p": {
            color: theme.palette.text.success.success500,
        },
    },
    subHeading: {
        color: theme.palette.text.neutral.neutral600,
        lineHeight: '28px',
    },
    futureCard: {
        boxShadow: '0px 0px 8px 0px rgba(39, 67, 160, 0.25)',
        width: '297px',
        "& h5": {
            fontSize: '22px',
            lineHeight: '26px',
            color: theme.palette.text.primary.primary900,
        },
        "& p": {
            color: theme.palette.text.neutral.neutral800,
            lineHeight: '26px',
        },
    },
    rectangleWith: {
        display: 'flex',
        alignItems: 'cenetr',
    },

    rectangle: {
        background: theme.palette.background.primary.primary400,
        width: '150px',
        height: '50px',
        "& h6": {
            color: '#fff',
        },
    },

    /* Triangle with rounded tip */
    triangleRight: {
        width: 0,
        height: 0,
        borderTop: '25px solid transparent',
        borderLeft: `50px solid ${theme.palette.border.primary.primary400}`,
        borderBottom: '25px solid transparent',
        borderTopRightRadius: '10px',
        borderBottomRightRadius: '10px',
        marginLeft: '0px',
    },
    register: {
        width: '213px',
        "& P": {
            color: theme.palette.text.neutral.neutral800,
            lineHeight: '20px',
        },
    },
    rectangle2: {
        background: theme.palette.background.warning.warning400,
    },

    triangleRight2: {
        borderLeft: `50px solid ${theme.palette.border.warning.warning400}`,
    },
    rectangle3: {
        background: theme.palette.background.success.success400,
        width: '223px',
        height: '50px',
        borderRadius: '0px 20px 20px 0px',
        "& h6": {
            color: '#fff',
        },
    },

    triangleRight3: {
        borderLeft: `50px solid ${theme.palette.border.success.success400}`,
        width: 0,
        height: 0,
        marginLeft: '0px',
    },
    featuresContainer: {
        "& p": {
            color: theme.palette.text.neutral.neutral600,
        },
        "& span": {
            fontSize: '40px',
            lineHeight: '48px',
            color: theme.palette.text.primary.primary700,
        },
        "& h4": {
            "& span": {
                color: theme.palette.text.primary.primary500,
                fontWeight: '600',
            },
        },
        "& h6": {
            color: theme.palette.text.neutral.neutral900,
            lineHeight: '28px'
        },
    },
    primaryContainer: {
        borderBottom: `1px solid ${theme.palette.border.neutral.neutral300}`,
    },
    registerDes: {
        color: theme.palette.text.neutral.neutral800,
        lineHeight: '20px',
    },
    featuresText: {
        width: '651px',
        "& h5": {
            color: '#000',
            fontSize: '22px',
            lineHeight: '26px',
        },
        "& p": {
            color: theme.palette.text.neutral.neutral800,
            lineHeight: '26px',
        },
    },
    featureInfo: {
        "& h4": {
            color: theme.palette.text.neutral.neutral900,
        },
        "& p": {
            color: theme.palette.text.neutral.neutral800,
            lineHeight: '20px',
        },
    },
    headingText: {
        fontSize: '48px !important',
    },
    cardsContainer: {
        background: '#F8F8F8',
    },
    card: {
        border: `1px solid ${theme.palette.border.neutral.neutral200}`,
        background: '#FFF',
        padding: '20px 15px',
        width: '406px',
        "& h4": {
            color: '#000',
            fontSize: '22px',
            lineHeight: '26px',
        },
        "& p": {
            lineHeight: '26px',
        },
    },
    cardIconContainer: {
        border: `1px solid ${theme.palette.border.neutral.neutral200}`,
        background: '#F8F8F8',
    },
    cardHeadingSecondary: {
        color: '#000',
        fontSize: '28px',
        lineHeight: '34px',
    },
    featureCardsHeading: {
        color: '#333',
        fontSize: '54px',
    },
    featurecardDescription: {
        color: theme.palette.text.neutral.neutral800,
        lineHeight: '26px',
    },
    featureCard: {
        borderRadius: '20px',
        overflow: 'hidden',
        height: '450px',
    },

    card1: {
        background: '#FEC09A',
        width: '540px',
    },
    card2: {
        background: '#FBF3D0',
        width: '330px',
    },
    card3: {
        width: '400px',
        background: '#CEECFD',
    },
    card11: {
        background: theme.palette.text.warning.warning500,
        width: '550px',
    },
    card12: {
        background: theme.palette.text.primary.primary100,
    },
    card13: {
        background: theme.palette.text.success.success100,
    },
    card4: {
        background: '#F8D3D5',
    },
    card5: {
        background: '#FFE0CC',
    },
    card6: {
        background: theme.palette.text.warning.warning100,
    },
    tradingContainer: {
        width: '100%',
        height: '480px',
        backgroundImage: `url(${tradingBackground})`,
        backgroundSize: 'cover',
        position: 'relative',
    },
    tradingHeading: {
        color: theme.palette.text.primary.primary50,
        fontSize: '32px',
        lineHeight: '38px',
    },
    tradingDes: {
        color: '#fff',
        lineHeight: '26px',
    },
    btn: {
        background: theme.palette.background.primary.primary500,
        color: '#fff', 
        fontSize: '16px', 
        fontWeight: '700',
        padding: '10px 20px',
    },
    btnArrow: {
        border: '1px solid #fff',
        borderRadius: '50%',
    },
    btn2: {
        background: '#0053A9',
        color: '#fff', 
        fontSize: '18px', 
        fontWeight: '500',
        padding: '10px 20px',
        lineHeight: '22px',
        width: 'max-content',
    },
    btnArrow2: {
        border: '1px solid transparent',
        background: theme.palette.background.primary.primary500,
        borderRadius: '50%',
        height: '32px',
        width: '32px',
    },
    btn3: {
        background: '#fff',
        color: '#fff', 
        fontSize: '18px', 
        fontWeight: '500',
        padding: '10px 20px',
        lineHeight: '22px',
        width: 'max-content',
    },
    btnText: {
        color: theme.palette.text.primary.primary500, 
    },
}));

const futureData = [
    {
        imgSrc: website,
        title: 'Digital Inventory Management',
        title2: '',
        description: 'Sell to domestic and international buyers on a trusted platform.'
    },
    {
        imgSrc: onlineMoney,
        title: 'Digital',
        title2: 'Catalogs',
        description: 'Catalogues with comprehensive product details, pricing & offers.'
    },
    {
        imgSrc: onlineStore,
        title: 'Digital ',
        title2: 'Storefronts',
        description: 'Create your personalized store to showcase steel products online.'
    },
    {
        imgSrc: moneyGrowth,
        title: 'Real-Time',
        title2: 'Pricing',
        description: 'Stay competitive with instant updates on market price changes.'
    },
    {
        imgSrc: moneyCurrency,
        title: 'Seamless Digital ',
        title2: 'Transactions',
        description: 'Enjoy smooth order management, secure payments, and logistics.'
    },
    {
        imgSrc: analyst,
        title: 'Analytics ',
        title2: 'Insights',
        description: 'Gain actionable data to optimize sales and boost performance.'
    },
    {
        imgSrc: action,
        title: 'E-Auction',
        title2: 'Digital Tools',
        description: 'Participate in forward and reverse auctions for better deals.'
    },
    {
        imgSrc: rupees,
        title: 'Digital',
        title2: 'Financing Options',
        description: 'Unlock flexible financing to grow your business with ease.'
    }
];

const SellerCentral: React.FC = () => {
    const classes = useStyles();
    const location = useLocation();
    
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const component = params.get('component');

        if (component) {
            const element = document.getElementById(component);
            if (element) {
                element.scrollIntoView();
            }
        }
    }, [location.search]);

    return (
        <>
            <div className={`${classes.bannerContainer}`}>
                <div className={`${classes.container} ${classes.bannerSellContainer}`}>
                    <h1 className={`${classes.heading} mb-4 font-semibold`}>Transformation<br />from <span className={classes.primaryHeading}>Traditional</span> to<br /> <span className={classes.secondaryHeading}>NextGen Digital Trade</span></h1>
                    <p className={`${classes.bannerDescription} text-2xl mb-10 font-semibold`}>Join our global Seller network and unlock<br />new growth opportunities.</p>
                    <Button className={`${classes.btn} font-bold text-base flex items-center gap-[10px] px-5 py-[10px]`}>Start Selling Today <div className={classes.btnArrow}><ArrowForwardIcon /></div></Button>
                </div>
            </div>
            <div className={`${classes.intoContainer}`}>
                <div className={`${classes.container} flex items-center gap-10 py-14`}>
                    <div className=''>
                        <p className={`${classes.text} font-semibold mb-2`}>Welcome to SB Seller Central</p>
                        <p className={`${classes.primaryText} font-medium`}>Sell Smarter,<br />Grow Faster</p>
                    </div>
                    <div className={classes.decContainer}>
                        <p className='text-lg mb-[10px]'>Seller Central by Steelbazaar is a comprehensive platform tailored for steel sellers, enabling them to seamlessly list, manage, & sell their products. From small-scale distributors to large-scale manufacturers, Seller Central equips Sellers with innovative tools to maximize their reach, streamline their operations, & boost sales. Whether you're selling raw materials, semi-finished, or finished steel products, Seller Central ensures a transparent, efficient, & profitable experience.
                        </p>
                        <p className='text-lg'>SteelBazaar is the Go-To Platform for the steel industry. Let us help you grow your business while maximising sales potential.</p>
                    </div>
                </div>
            </div>
            <div className={`${classes.container} pt-[110px] pb-[110px]`}>
                <h2 className={`${classes.sectionHeading} font-semibold text-center`}>The future is Digital. Be Ready Today</h2>
                <p className={`${classes.subHeading} text-center text-2xl font-medium mb-[50px] mt-[10px]`}>Transforming Steel Selling with Advanced Digital Solutions</p>
                <div className='flex gap-[30px] flex-wrap'>
                    {futureData.map((item, index) => (
                        <div key={index} className={`${classes.futureCard} py-5 px-[15px] flex flex-col items-center text-center`}>
                            <img src={item.imgSrc} alt={item.title} />
                            <h5 className='font-semibold mt-[30px]'>{item.title}</h5>
                            <h5 className='font-semibold mb-[10px]'>{item.title2}</h5>
                            <p className='text-lg'>{item.description}</p>
                        </div>
                    ))}
                </div> 
            </div>
            <div className={`${classes.container}`}>
                <h2 className={`${classes.sectionHeading} font-semibold text-center`}>Start selling on Steelbazaar</h2>
                <p className={`${classes.subHeading} text-center text-2xl font-medium mt-2`}>Your Journey Starts Here …</p>
                <div className='mt-12' >
                    <div className='flex gap-[60px] items-center mb-[110px]' >
                        <div><img src={register} alt="" /></div>
                        <section id='registration'>
                        <div className='w-[700px]'>
                            <div className={`${classes.featuresContainer}`}>
                                <p className='text-2xl font-medium'>Step 01:</p>
                                <h4 className='mt-[6px] mb-[15px] text-5xl font-semibold'>Register on<span className={`${classes.headingText} text-5xl`}> SteelBazaar</span></h4>
                                <h6 className='text-2xl font-medium mb-[15px]'>Join our network of trusted vendors in just a few steps.</h6>
                            </div>

                            <div className={`${classes.primaryContainer} flex gap-7 pb-[10px]`}>
                                <div className={`${classes.register} text-center`}>
                                    <div className={classes.rectangleWith}>
                                        <div className={`${classes.rectangle} flex items-center justify-center`}> <h6 className='text-2xl font-semibold '>Register</h6></div>
                                        <div className={classes.triangleRight}></div> 
                                    </div>
                                    <p className='pt-[10px] text-base'>Sign up as a vendor and provide basic business information.</p>
                                </div>
                                <div className={`${classes.register} text-center`}>
                                    <div className={classes.rectangleWith}>
                                        <div className={`${classes.rectangle2} ${classes.rectangle} flex items-center justify-center`}> <h6 className='text-2xl font-semibold '>Verify</h6></div>
                                        <div className={`${classes.triangleRight} ${classes.triangleRight2}`} ></div>
                                    </div>
                                    <p className='pt-[10px] text-base'>Upload necessary documents to Complete the verification process* to ensure a secure marketplace.</p>
                                </div>
                                <div className={`${classes.register} text-center`}>
                                    <div className={classes.rectangleWith}>
                                        <div className={`${classes.rectangle3} flex items-center justify-center`}> <h6 className='text-2xl font-semibold '>Launch</h6></div>
                                    </div>
                                    <p className='pt-[10px] text-base'>Once verified, you’re ready to list your products and start selling!</p>
                                </div>
                            </div>
                            <div className='flex gap-5 items-center'>
                                <p className={`${classes.registerDes} w-[490px] mt-[15px] text-base pr-8`}>*SteelBazaar’s business verification process is specially designed to build buyer confidence by confirming your business's legitimacy and authenticity. Verified sellers gain buyer’s valuable</p>
                                <Button className={`${classes.btn2}  flex items-center gap-[10px]`}>Register Now<div className={classes.btnArrow}><ArrowForwardIcon /></div></Button>
                            </div>
                        </div>
                        </section>
                 
                    </div>

                    <div className='flex gap-[60px] items-center mt-12'>
                        <div className='w-[700px]'>
                            <div className={`${classes.featuresContainer} `}>
                                <p className='text-2xl font-medium'>Step 02:</p>
                                <h4 className='mt-[6px] mb-[15px] text-5xl font-semibold'>Create Your <span className={`${classes.headingText} text-5xl`}>Seller Profile</span></h4>
                            </div>
                            <div className={`${classes.featureInfo}`}>
                                <h4 className='text-2xl font-semibold'>Build Your Brand and Business Values</h4>
                                <p className='text-base'>Build a comprehensive seller profile that showcases your brand, values, and offerings to buyers, helping establish trust and credibility.</p>
                                <div>
                                    <div className='flex gap-[15px] mt-[15px] items-start'>
                                        <img src={roudTickIcon} alt="" />
                                        <div className={classes.featuresText}>
                                            <h5 className='font-medium mb-1'>Business Information</h5>
                                            <p className='text-lg'>Highlight key details like business History Type, location, Product details , contact information and specialities.</p>
                                        </div>
                                    </div>
                                    <div className='flex gap-[15px] mt-[15px] items-start'>
                                        <img src={roudTickIcon} alt="" />
                                        <div className={classes.featuresText}>
                                            <h5 className='font-medium mb-1'>Brand Story</h5>
                                            <p className='text-lg'>Share your brand story to connect with potential buyers.</p>
                                        </div>
                                    </div>
                                    <div className='flex gap-[15px] mt-[15px] items-start'>
                                        <img src={roudTickIcon} alt="" />
                                        <div className={classes.featuresText}>
                                            <h5 className='font-medium mb-1'>Certification & Quality Standards</h5>
                                            <p className='text-lg'>Upload certifications to build trust with buyers.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div><img src={sellerProfile} alt="" /></div>
                    </div>

                    <div className='flex gap-[60px] items-center mt-12'>
                        <div><img src={sellerDashboard} alt="" /></div>
                        <div className='w-[700px]'>
                            <div className={`${classes.featuresContainer} `}>
                                <p className='text-2xl font-medium'>Step 03:</p>
                                <h4 className='mt-[6px] mb-[15px] text-5xl font-semibold'>Access the <br />Comprehensive <span className={`${classes.headingText} text-5xl`}>Dashboard</span></h4>
                            </div>
                            <div className={`${classes.featureInfo}`}>
                                <h4 className='text-2xl font-semibold'>All Your Business Operations in One Place</h4>
                                <p className='text-base'>The SteelBazaar dashboard gives you complete control over your store, orders, inventory, and performance metrics, helping you manage your business effortlessly.</p>
                                <div>
                                    <div className='flex gap-[15px] mt-[15px] items-start'>
                                        <img src={roudTickIcon} alt="" />
                                        <div className={classes.featuresText}>
                                            <h5 className='font-medium mb-1'>Real-Time Analytics</h5>
                                            <p className='text-lg'>Track sales, inventory levels, and buyer trends.</p>
                                        </div>
                                    </div>
                                    <div className='flex gap-[15px] mt-[15px] items-start'>
                                        <img src={roudTickIcon} alt="" />
                                        <div className={classes.featuresText}>
                                            <h5 className='font-medium mb-1'>Order Management</h5>
                                            <p className='text-lg'>View and manage all orders in one location.</p>
                                        </div>
                                    </div>
                                    <div className='flex gap-[15px] mt-[15px] items-start'>
                                        <img src={roudTickIcon} alt="" />
                                        <div className={classes.featuresText}>
                                            <h5 className='font-medium mb-1'>Notifications and Alerts</h5>
                                            <p className='text-lg'>Get notified of important updates, orders, and stock levels.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex gap-[60px] items-center mt-12' id='inventory'>
                        <div className='w-[700px]'>
                            <div className={`${classes.featuresContainer} `}>
                                <p className='text-2xl font-medium'>Step 04:</p>
                                <h4 className='mt-[6px] mb-[15px] text-5xl font-semibold'>Manage Inventory & <br /><span className={`${classes.headingText} text-5xl`}>Orders Digitally</span></h4>
                            </div>
                            <div className={`${classes.featureInfo}`}>
                                <h4 className='text-2xl font-semibold'>Stay in Control of Your Sales</h4>
                                <p className='text-base'>Our platform allows you to manage your inventory seamlessly. Keep your stock up-to-date, handle orders efficiently, and ensure on-time customer deliveries.</p>
                                <div>
                                    <div className='flex gap-[15px] mt-[15px] items-center'>
                                        <img src={roudTickIcon} alt="" />
                                        <div className={classes.featuresText}>
                                            <h5 className='font-medium mb-1'>Inventory management SKU and USN-wise</h5>
                                        </div>
                                    </div>
                                    <div className='flex gap-[15px] mt-[15px] items-center'>
                                        <img src={roudTickIcon} alt="" />
                                        <div className={classes.featuresText}>
                                            <h5 className='font-medium mb-1'>Real-time Inventory and Order tracking</h5>
                                        </div>
                                    </div>
                                    <div className='flex gap-[15px] mt-[15px] items-center'>
                                        <img src={roudTickIcon} alt="" />
                                        <div className={classes.featuresText}>
                                            <h5 className='font-medium mb-1'>Automated stock updates</h5>
                                        </div>
                                    </div>
                                    <div className='flex gap-[15px] mt-[15px] items-center'>
                                        <img src={roudTickIcon} alt="" />
                                        <div className={classes.featuresText}>
                                            <h5 className='font-medium mb-1'>Bar Coding Options</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div><img src={sellerInverntory} alt="" /></div>
                    </div>
                    <div className='flex gap-[60px] items-center mt-12'>
                        <div><img src={sellerSku} alt="" /></div>
                        <div className='w-[710px]'>
                            <div className={`${classes.featuresContainer} `}>
                                <p className='text-2xl font-medium'>Step 05:</p>
                                <h4 className='mt-[6px] mb-[15px] text-5xl font-semibold'>Create Digital Product Catalogs with <span className={`${classes.headingText} text-5xl`}>Predefined SKUs</span></h4>
                            </div>
                            <div className={`${classes.featureInfo}`}>
                                <h4 className='text-2xl font-semibold'>Access Predefined SKUs to Simplify Your Listings</h4>
                                <p className='text-base'>Use SteelBazaar's catalogue builder to create digital catalogues efficiently. Access a library of predefined SKUs to get started quickly and list products accurately.</p>
                                <div>
                                    <div className='flex gap-[15px] mt-[15px] items-start'>
                                        <img src={roudTickIcon} alt="" />
                                        <div className={classes.featuresText}>
                                            <h5 className='font-medium mb-1'>Predefined SKUs</h5>
                                            <p className='text-lg'>Save time by choosing from an extensive list of SKUs tailored to steel products.</p>
                                        </div>
                                    </div>
                                    <div className='flex gap-[15px] mt-[15px] items-start'>
                                        <img src={roudTickIcon} alt="" />
                                        <div className={classes.featuresText}>
                                            <h5 className='font-medium mb-1'>Bulk Upload Options</h5>
                                            <p className='text-lg'>Upload multiple products at once for faster catalogue creation.</p>
                                        </div>
                                    </div>
                                    <div className='flex gap-[15px] mt-[15px] items-start'>
                                        <img src={roudTickIcon} alt="" />
                                        <div className={classes.featuresText}>
                                            <h5 className='font-medium mb-1'>Detailed Descriptions</h5>
                                            <p className='text-lg'>Add details, images, and specifications to showcase each product.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex gap-[60px] items-center mt-12' id='storeFront'>
                        <div className='w-[710px]'>
                            <div className={`${classes.featuresContainer} `}>
                                <p className='text-2xl font-medium'>Step 06:</p>
                                <h4 className='mt-[6px] mb-[15px] text-5xl font-semibold'>Build Your <br /><span className={`${classes.headingText} text-5xl`}>Personalized Storefront</span></h4>
                            </div>
                            <div className={`${classes.featureInfo}`}>
                                <h4 className='text-2xl font-semibold'>Attract Buyers with a Professional Online Store</h4>
                                <p className='text-base'>Build your storefront with a detailed Profile, Listings of your Products and services, and Auction listings with your brand and a professional look that attracts buyers and encourages repeat visits. SteelBazaar’s intuitive tools make setup simple.</p>
                                <div>
                                    <div className='flex gap-[15px] mt-[15px] items-start'>
                                        <img src={roudTickIcon} alt="" />
                                        <div className={classes.featuresText}>
                                            <h5 className='font-medium mb-1'>Product Listings</h5>
                                            <p className='text-lg'>Add detailed descriptions, specifications, and pricing for each product.</p>
                                        </div>
                                    </div>
                                    <div className='flex gap-[15px] mt-[15px] items-start'>
                                        <img src={roudTickIcon} alt="" />
                                        <div className={classes.featuresText}>
                                            <h5 className='font-medium mb-1'>Service Offerings</h5>
                                            <p className='text-lg'>Highlight any additional services like custom manufacturing or packaging.</p>
                                        </div>
                                    </div>
                                    <div className='flex gap-[15px] mt-[15px] items-start'>
                                        <img src={roudTickIcon} alt="" />
                                        <div className={classes.featuresText}>
                                            <h5 className='font-medium mb-1'>Featured Products</h5>
                                            <p className='text-lg'>Promote best-sellers and discounted items.</p>
                                        </div>
                                    </div>
                                    <div className='flex gap-[15px] mt-[15px] items-start'>
                                        <img src={roudTickIcon} alt="" />
                                        <div className={classes.featuresText}>
                                            <h5 className='font-medium mb-1'>Sales Offers</h5>
                                            <p className='text-lg'>Promote best-sellers and discounted items.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div><img src={sellerStoreFront} alt="" /></div>
                    </div>
                    <div className='flex gap-[60px] items-center mt-12' id='publish'>
                        <div><img src={sellerCatalog} alt="" /></div>
                        <div className='w-[710px]'>
                            <div className={`${classes.featuresContainer} `}>
                                <p className='text-2xl font-medium'>Step 07:</p>
                                <h4 className='mt-[6px] mb-[15px] text-5xl font-semibold'>Publish Your <br /> <span className={`${classes.headingText} text-5xl`}>Catalog on SteelBazaar</span></h4>
                            </div>
                            <div className={`${classes.featureInfo}`}>
                                <h4 className='text-2xl font-semibold'>Showcase Your Products to a Global Audience</h4>
                                <p className='text-base'>Once your catalogue and storefront are ready, publish them on the SteelBazaar marketplace to reach buyers worldwide and start accepting orders.</p>
                                <div>
                                    <div className='flex gap-[15px] mt-[15px] items-start'>
                                        <img src={roudTickIcon} alt="" />
                                        <div className={classes.featuresText}>
                                            <h5 className='font-medium mb-1'>Instant Publishing</h5>
                                            <p className='text-lg'>Go live with just one click once everything is ready.</p>
                                        </div>
                                    </div>
                                    <div className='flex gap-[15px] mt-[15px] items-start'>
                                        <img src={roudTickIcon} alt="" />
                                        <div className={classes.featuresText}>
                                            <h5 className='font-medium mb-1'>Market-Wide Visibility</h5>
                                            <p className='text-lg'>Your products become visible to a broad audience of steel buyers.</p>
                                        </div>
                                    </div>
                                    <div className='flex gap-[15px] mt-[15px] items-start'>
                                        <img src={roudTickIcon} alt="" />
                                        <div className={classes.featuresText}>
                                            <h5 className='font-medium mb-1'>Ongoing Support</h5>
                                            <p className='text-lg'>SteelBazaar’s team provides support as you begin selling on the platform</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${classes.container} flex flex-col gap-10 pt-[110px]`} id='sellSmart'>
                <h2 className={`${classes.sectionHeading} font-semibold text-center`}>Sell Smarter, Faster with Advanced Tools</h2>
                <div className='flex gap-4'>
                    <div className={`${classes.card1} ${classes.featureCard} flex flex-col justify-between`}>
                        <div className='p-[30px]'>
                            <h4 className={`${classes.cardHeadingSecondary}`}>Feature Listings</h4>
                            <p className={`${classes.featurecardDescription} text-base`}>Highlight your brand and sales offers to increase visibility and attract global buyers</p>
                        </div>
                        <img src={card1} alt="" className='w-96 mx-auto my-0' />
                    </div>
                    <div className={`${classes.card2} ${classes.featureCard} flex flex-col justify-between`}>
                        <div className='p-[30px]'>
                            <h4 className={`${classes.cardHeadingSecondary}`}>Digital Catalogue with Real Time Pricing</h4>
                            <p className={`${classes.featurecardDescription} text-base`}>Access Verified Lead to Drive Sales and Business Growth</p>
                        </div>
                        <img src={pricing} alt="" />
                    </div>
                    <div className={`${classes.card3} ${classes.featureCard} flex flex-col justify-between`}>
                        <div className='p-[30px]'>
                            <h4 className={`${classes.cardHeadingSecondary}`}>SB DigiStore</h4>
                            <p className={`${classes.featurecardDescription} text-base`}>Real-Time Live auctions for buying raw materials</p>
                        </div>
                        <img src={SBDigiStore} alt="" className='mb-4' />
                    </div>
                </div>
                <div className='flex gap-4'>
                    <div className={`${classes.card13} ${classes.featureCard} flex flex-col justify-between`}>
                        <div className='p-[30px]'>
                            <h4 className={`${classes.cardHeadingSecondary}`}>SB Connect Leads Management</h4>
                            <p className={`${classes.featurecardDescription} text-base`}>Access exclusive curated buyer leads to generate strong sales opportunities.</p>
                        </div>
                        <img src={Lead} alt="" className='mx-auto my-0' />
                    </div>
                    <div className={`${classes.card12} ${classes.featureCard} flex flex-col justify-between`}>
                        <div className='p-[30px]'>
                            <h4 className={`${classes.cardHeadingSecondary}`}>SB BidHub</h4>
                            <p className={`${classes.featurecardDescription} text-base`}>Quickly sell old, aged, or excess stock through competitive auctions.</p>
                        </div>
                        <img src={bid} alt="" />
                    </div>
                    <div className={`${classes.card11} ${classes.featureCard} flex flex-col justify-between`}>
                        <div className='p-[30px]'>
                            <h4 className={`${classes.cardHeadingSecondary}`}>SB Inventim</h4>
                            <p className={`${classes.featurecardDescription} text-base`}>Track, Organize, and Manage Inventory for accurate, real-time stock tracking.</p>
                        </div>
                        <img src={management} alt="" />
                    </div>
                </div>
                <div className='flex gap-4 mb-[110px]'>
                    <div className={`${classes.card4} ${classes.featureCard} flex flex-col justify-between`}>
                        <div className='p-[30px]'>
                            <h4 className={`${classes.cardHeadingSecondary}`}>AI-Driven Market Analytics and Insights</h4>
                            <p className={`${classes.featurecardDescription} text-base`}>Gain Actionable Insights into market trends and buyer behaviors.</p>
                        </div>
                        <img src={insights} alt="" />
                    </div>
                    <div className={`${classes.card5} ${classes.featureCard} flex flex-col justify-between`}>
                        <div className='p-[30px]'>
                            <h4 className={`${classes.cardHeadingSecondary}`}>SB InstaFin</h4>
                            <p className={`${classes.featurecardDescription} text-base`}>Help buyers purchase more with convenient and easy financing solutions facilitated by SteelBazaar</p>
                        </div>
                        <img src={instafin} alt="" className='p-2' />
                    </div>
                    <div className={`${classes.card6} ${classes.featureCard} flex flex-col justify-between`}>
                        <div className='p-[30px]'>
                            <h4 className={`${classes.cardHeadingSecondary}`}>SB Branding</h4>
                            <p className={`${classes.featurecardDescription} text-base`}>Access digital marketing tools to enhance brand reach and engagement.</p>
                        </div>
                        <img src={sbpool} alt="" />
                    </div>
                </div>
            </div>
            <div className={classes.cardsContainer}>
                <div className={`${classes.container} pt-[110px] pb-[110px]`}>
                    <div >
                        <h2 className={`${classes.sectionHeading} font-semibold text-center`}>Unlock Limitless Opportunities</h2>
                        <p className={`${classes.subHeading} text-center text-2xl font-medium`}>Joining Seller Central offers unparalleled advantages</p>
                    </div>
                    <div className='flex gap-[30px] flex-wrap mt-[50px]'>
                        <div className={`${classes.card} rounded-lg`}>
                            <div className={`${classes.cardIconContainer} flex items-center justify-center p-[10px] w-[82px] h-[82px] rounded-2xl`}><img src={explore} alt="" /></div>
                            <h4 className='mt-[10px] mb-[10px] font-medium'>Explore New Markets & Customers</h4>
                            <p className='text-base'>Connect with a vast network of buyers across domestic & international markets.</p>
                        </div>
                        <div className={`${classes.card} rounded-lg`}>
                            <div className={`${classes.cardIconContainer} flex items-center justify-center p-[10px] w-[82px] h-[82px] rounded-2xl`}><img src={sales} alt="" /></div>
                            <h4 className='mt-[10px] mb-[10px] font-medium'>Maximize Sales with Lead Generation</h4>
                            <p className='text-base'>Get regular customer inquiries through our integrated lead management.</p>
                        </div>
                        <div className={`${classes.card} rounded-lg`}>
                            <div className={`${classes.cardIconContainer} flex items-center justify-center p-[10px] w-[82px] h-[82px] rounded-2xl`}><img src={purchase} alt="" /></div>
                            <h4 className='mt-[10px] mb-[10px] font-medium'>Cost-Effective Purchases</h4>
                            <p className='text-base'>Negotiate best deals and explore long-term contracts and partnerships</p>
                        </div>
                        <div className={`${classes.card} rounded-lg`}>
                            <div className={`${classes.cardIconContainer} flex items-center justify-center p-[10px] w-[82px] h-[82px] rounded-2xl`}><img src={visibility} alt="" /></div>
                            <h4 className='mt-[10px] mb-[10px] font-medium'>Enhanced Visibility</h4>
                            <p className='text-base'>Showcase your products to a targeted audience with marketing support.</p>
                        </div>
                        <div className={`${classes.card} rounded-lg`}>
                            <div className={`${classes.cardIconContainer} flex items-center justify-center p-[10px] w-[82px] h-[82px] rounded-2xl`}><img src={transaction} alt="" /></div>
                            <h4 className='mt-[10px] mb-[10px] font-medium'>Ease of Transactions</h4>
                            <p className='text-base'>Enjoy smooth order management, secure payments, and on-time delivery services.</p>
                        </div>
                        <div className={`${classes.card} rounded-lg`}>
                            <div className={`${classes.cardIconContainer} flex items-center justify-center p-[10px] w-[82px] h-[82px] rounded-2xl`}><img src={financing} alt="" /></div>
                            <h4 className='mt-[10px] mb-[10px] font-medium'>Access to Financing</h4>
                            <p className='text-base'>Approved sellers can avail of financing solutions for business growth.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.tradingContainer}>
                <div className={`${classes.container} pt-[60px] pb-[60px]`}>
                    <h2 className={`${classes.tradingHeading} font-semibold`}>Your Trusted Partner in Steel Trading <br />
                        Built for Sellers, <br />
                        Powered by Innovation</h2>
                    <div className='w-[570px] mb-[30px]'>
                        <p className={`${classes.tradingDes} mt-[10px] text-lg`}>Steelbazaar’s Seller Central combines industry expertise with technological innovation, ensuring that steel sellers thrive in an ever-evolving market. With unmatched features, dedicated support, & access to a thriving ecosystem of buyers, Seller Central is your ultimate partner for sustainable growth in steel trading.</p>
                    </div>
                    <Button className={`${classes.btn3} font-bold text-base flex items-center gap-[10px] px-5 py-[10px]`}> <span className={classes.btnText}>Register Now</span><div className={`${classes.btnArrow2} flex items-center justify-center`}><ArrowForwardIcon /></div></Button>
                </div>
            </div>
            <RequestDemoTemplate />
        </>
    );
};
export default SellerCentral;
