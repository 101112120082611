import { Button } from '@mui/joy';
import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import procurmentBanner from '../../../assets/images/procurmentBanner.jpg';
import timeIcon from '../../../assets/icons/timeIcon.jpg';
import roundTickIcon from '../../../assets/icons/roundTickIcon.jpg';
import currency_rupee from '../../../assets/icons/currency_rupee.jpg';
import continuityIcon from '../../../assets/icons/continuityIcon.jpg';
import downTime from '../../../assets/icons/downTime.jpg';
import bussinessIcon from '../../../assets/icons/downTime.jpg';
import pCardIcon1 from '../../../assets/icons/pCardIcon1.jpg';
import pCardIcon2 from '../../../assets/icons/pCardIcon2.jpg';
import pCardIcon3 from '../../../assets/icons/pCardIcon3.jpg';
import pCardIcon4 from '../../../assets/icons/pCardIcon4.jpg';
import pCardIcon5 from '../../../assets/icons/pCardIcon5.jpg';
import pCardIcon6 from '../../../assets/icons/pCardIcon6.jpg';
import pCardIcon7 from '../../../assets/icons/pCardIcon7.jpg';
import pCardIcon8 from '../../../assets/icons/pCardIcon8.jpg';
import RequestDemoTemplate from '../../template/Home/RequestDemo/RequestDemo.template';
import meter from '../../../assets/images/meter.png';
import iProcure from '../../../assets/images/iProcure.jpg';
import sbMaxCard from '../../../assets/images/sbMaxCard.jpg';
import procurePlus from '../../../assets/images/procurePlus.jpg';
import primaryBlueTick from '../../../assets/icons/primaryBlueTick.jpg';
import orangeTick from '../../../assets/icons/orangeTick.jpg';
import blueTick from '../../../assets/icons/blueTick.jpg';
import AccordionExpandDefault from './accordian';
import accordianImg1 from '../../../assets/images/accordianImg1.jpg';
import accordianImg2 from '../../../assets/images/accordianImg2.jpg';
import accordianImg3 from '../../../assets/images/accordianImg3.jpg';
import accordianImg4 from '../../../assets/images/accordianImg4.jpg';
import accordianImg5 from '../../../assets/images/accordianImg5.jpg';
import accordianImg6 from '../../../assets/images/accordianImg6.jpg';
import accordianImg7 from '../../../assets/images/accordianImg7.jpg';
import accordianImg8 from '../../../assets/images/accordianImg8.jpg';
import accordianImg9 from '../../../assets/images/accordianImg9.jpg';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        width: '1297px',
        margin: '0 auto',
    },
    bannerContainer: {
        width: '100%',
        height: '650px',
        backgroundImage: `url(${procurmentBanner})`,
        backgroundSize: 'cover',
        position: 'relative',
    },
    bannerBtn: {
        background: theme.palette.background.primary.primary500,
        padding: '10px 20px',
        width: '228px',
        color: '#fff',

    },
    btnArrow: {
        border: '1px solid #fff',
        borderRadius: '100%',
    },
    bannerSellContainer: {
        padding: '10% 0px',
    },
    heading: {
        fontSize: '56px',
        lineHeight: '66px',
        color: theme.palette.text.primary.primary50,
        "& span": {
            color: theme.palette.text.warning.warning400,
        },
    },
    bannerDescription: {
        color: theme.palette.text.primary.primary50,
        lineHeight: '28px',
        "& span": {
            color: theme.palette.text.warning.warning400,
        },
    },
    warningText: {
        color: `${theme.palette.text.secondary.secondary600} !important`,
    },
    intoContainer: {
        backgroundColor: theme.palette.background.neutral.neutral50,
    },
    primaryText: {
        fontSize: '56px',
        color: theme.palette.text.complementary.complementary400,
        lineHeight: '42px',
        "& span": {
            fontSize: '36px',
            color: '#F8F8F8',
        }
    },
    instaDescription: {
        color: '#F8F8F8',
        lineHeight: '22px',
        letterSpacing: '-0.045px',
        fontSize: '18px',
        fontWeight: '400',
        "& span": {
            color: '#F8F8F8 !important',
            fontWeight: '600',
        },
    },
    ratingContainer: {
        "& p": {
            color: '#F8F8F8',
            fontSize: '28px',
        },
    },
    score: {
        marginTop: '-90px',
        fontSize: '60px',
        color: '#fff',
    },
    btn1: {
        color: '#fff',
        border: '1px solid #fff',
        borderRadius: '6px',
    },
    btn2: {
        background: '#fff',
        color: theme.palette.text.neutral.neutral800,
        borderRadius: '6px',
    },
    text: {
        fontSize: '54px',
        color: "#000",
        lineHeight: '66px',
    },
    decContainer: {
        width: '715px',
        "& p": {
            color: "#000",
            lineHeight: '26px',
        },
    },
    cardsContainer: {
    },
    card: {
        border: `1px solid ${theme.palette.border.neutral.neutral200}`,
        background: '#F8F8F8',
        padding: '20px 15px',
        width: '406px',
        "& h4": {
            color: '#000',
            fontSize: '22px',
            lineHeight: '26px',
        },
        "& p": {
            lineHeight: '20px',
            color: theme.palette.text.neutral.neutral800,
        },
    },

    cardIconContainer: {
        border: `1px solid ${theme.palette.border.neutral.neutral200}`,
        background: '#FFF',
    },
    cardHeadingSecondary: {
        color: '#000',
        fontSize: '28px',
        lineHeight: '34px',
    },
    sectionHeading: {
        color: "#000",
        fontSize: '54px',
        lineHeight: '66px',
        "& span": {
            color: theme.palette.text.primary.primary500,
        },
        "& p": {
            color: theme.palette.text.success.success500,
        },
    },
    subHeading: {
        color: theme.palette.text.neutral.neutral600,
        lineHeight: '28px',
    },
    procurementcard: {
        border: `1px solid ${theme.palette.border.neutral.neutral200}`,
        background: '#FFF',
        padding: '20px 15px',
        width: '24%',
        "& h4": {
            color: '#000',
            fontSize: '22px',
            lineHeight: '26px',
        },
        "& p": {
            lineHeight: '26px',
            color: theme.palette.text.neutral.neutral800,
        },
    },
    pCardIconContainer: {
        border: `1px solid ${theme.palette.border.neutral.neutral200}`,
        background: '#FFF',
    },
    pCardsContainer: {
        background: '#F8F8F8',
    },
    accordianContainer: {
        background: theme.palette.background.primary.primary500,
        "& h2": {
            color: "#fff",
            fontSize: '54px',
            lineHeight: '66px',
        },
        "& p": {
            color: '#F8F8F8',
            "& > span": {
                color: theme.palette.text.success.success400,
            },
        },
    },
    accordionCard: {
        border: "1px solid #ddd",
        borderRadius: "5px",
        marginBottom: "10px",
        overflow: "hidden",
    },
    accordionToggle: {
        display: "flex",
        alignItems: "flex-start",
        cursor: "pointer",
        justifyContent: "space-between",
        backgroundColor: "#ebedf0",
        padding: "15px",
        transition: "0.3s",
        "&.active": {
            backgroundColor: "#5a8dee",
        },
        "&.active $accordionIcon": {
            transform: "rotate(180deg)",
            color: "#fff",
        },
        "&.active $accordionTitle": {
            color: "#fff",
        },
    },
    accordionTitle: {
        fontWeight: 500,
        fontSize: "14px",
        marginBottom: 0,
        color: "#475F7B",
        transition: "0.3s",
    },
    accordionIcon: {
        fontSize: "12px",
        transition: "0.35s",
        color: "#475F7B",
    },
    accordionBody: {
        padding: "15px",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "24px",
        color: "#727E8C",
    },
    collapse: {
        height: 0,
        overflow: "hidden",
        transition: "height 0.35s ease",
        "&.show": {
            height: "auto",
        },
    },
    ProcurementSectionHeading: {
        fontSize: '54px',
        lineHeight: '66px',
        color: '#000',
        "& span": {
            color: theme.palette.text.primary.primary500,
        },
    },
    procurementSubHeading: {
        color: theme.palette.text.neutral.neutral600,
        lineHeight: '28px',

    },
    curve: {
        borderRadius: '74px 0px 0px 74px',
        borderTop: '12px solid #1387F6',
        borderLeft: '12px solid #1387F6',
        borderBottom: '12px solid #1387F6',
        marginLeft: '-43px',
        marginBottom: '-13px',
        marginTop: '-12px',
    },
    curve2: {
        borderRadius: '0px 74px 74px 0px',
        borderTop: '12px solid #1387F6',
        borderRight: '12px solid #1387F6',
        borderBottom: '12px solid #1387F6',
        marginRight: '-78px',
    },
    serialNumber: {
        background: theme.palette.background.complementary.complementary800,
        borderRadius: '74px',
        border: '1px solid #fff',
        color: '#fff',
        fontSize: '54px',
        fontWeight: '500',
    },
    serialNumber2: {
        background: '#FFBB1F',
        borderRadius: '74px',
        border: '1px solid #fff',
        color: '#000',
        fontSize: '54px',
        fontWeight: '500',
    },
    secondaryContianerMain: {
        position: 'absolute',
        width: '511px',
        left: '143px',
        "& h5": {
            color: '#000',
            fontSize: '28px',
            lineHeight: '34px',
        },
        "& p": {
            color: theme.palette.text.neutral.neutral800,
            lineHeight: '26px',
        },
    },
    secondaryContianerMain2: {
        position: 'absolute',
        width: '511px',
        right: '142px',
        "& h5": {
            color: '#000',
            fontSize: '28px',
            lineHeight: '34px',
        },
        "& p": {
            color: theme.palette.text.neutral.neutral800,
            lineHeight: '26px',
        },
    },
    cardTop1: {
        background: '#FD8235',
        color: '#fff',
        borderRadius: '15px 15px 0px 0px',
        margin: '0 auto',
    },
    cardTop2: {
        background: theme.palette.background.complementary.complementary400,
        color: '#fff',
        borderRadius: '15px 15px 0px 0px',
        margin: '0 auto',
    },
    cardTop3: {
        background: theme.palette.background.primary.primary500,
        color: '#fff',
        borderRadius: '15px 15px 0px 0px',
        margin: '0 auto',
    },
    bussinessCard: {
        boxShadow: '2px 2px 15px 0px rgba(0, 0, 0, 0.12)',
        overflow: 'hidden',
        "& h5": {
            fontSize: '40px',
        },
        "& p": {

        }
    },
    cardBottom1: {
        borderRadius: '0px !important',
    },
    procureCardHeaidng1: {
        "& span": {
            color: theme.palette.text.complementary.complementary400,
        },
    },
    procureCardHeaidng2: {
        "& span": {
            color: theme.palette.text.primary.primary500,
        },
    },
    procureCardHeaidng3: {
        "& span": {
            color: '#FD8235',
        },
    },


    // <------------------------------------------->
    featureHeading: {
        color: '#000',
        fontSize: '54px',
        fontWeight: '600',
        textAlign: 'center',
        marginBottom: '48px',
        "& span": {
            color: '#2743A0',
        },
    },
    accordMainHeading: {
        color: theme.palette.text.neutral.neutral800,
        lineHeight: '58px',
        "& span": {
            color: theme.palette.text.primary.primary500,
            borderBottom: '4px solid #EECF44',
        },
    },
    accordSecondaryHeading: {
        color: theme.palette.text.neutral.neutral900,
        fontSize: '24px',
        lineHeight: '28px',
    },
    accordion: {
        borderRadius: "0.5rem",
        overflow: "hidden",
        margin: "1rem 0",
    },
    tab: {
        position: "relative",
        borderBottom: '1px solid #CACCCE',
        "& input": {
            position: "absolute",
            opacity: 0,
            zIndex: -1,
        },
    },
    accodText: {
        fontSize: '25px',
        color: '#000',
        paddingLeft: '10px',
    },
    tabLabel: {
        display: "flex",
        alignItems: "center",
        padding: "8px 5px",
        color: "black",
        cursor: "pointer",
        "&::before": {
            content: '"+"',
            fontSize: "30px",
            fontWeight: '500',
            transition: "transform 0.5s, content 0.5s",
            color: theme.palette.text.primary.primary500,
        },
        "& input:checked + &::after": {
            content: '"−"',
        },
    },
    tabLabelExpanded: {
        "&::before": {
            content: '"−"',
        },
    },
    tabContent: {
        maxHeight: 0,
        overflow: "hidden",
        transition: "max-height 0.35s ease-in-out",
    },
    tabContentExpanded: {
        maxHeight: "500px",
    },
    contentInner: {
        padding: "10px 0px",
        fontSize: '18px',
    },

}));

const cardData = [
    {
        icon: pCardIcon1,
        title: "Cost Savings",
        description:
            "Lower costs and boost profits by analysing spending, consolidating suppliers & negotiating effectively.",
    },
    {
        icon: pCardIcon2,
        title: "Improved Quality",
        description:
            "Choose suppliers that provide the best overall value, including quality, ensuring high-quality goods & services for organisations.",
    },
    {
        icon: pCardIcon3,
        title: "Risk Mitigation",
        description:
            "Identifying and mitigating supply chain risks, like supplier instability or disruptions, through supplier evaluation and risk assessment.",
    },
    {
        icon: pCardIcon4,
        title: "Increased Efficiency",
        description:
            "Streamlined procurement, automation, and strategic sourcing can enhance efficiency and reduce lead times.",
    },
    {
        icon: pCardIcon5,
        title: "Enhanced Supplier Relationships",
        description:
            "Building long-term, collaborative relationships with key suppliers to foster trust and communication for better outcomes.",
    },
    {
        icon: pCardIcon6,
        title: "Innovation & Continuous Improvement",
        description:
            "Partnering with strategic suppliers leverages innovation and process improvement expertise.",
    },
    {
        icon: pCardIcon7,
        title: "Better Alignment with Business Objectives",
        description:
            "Strategic sourcing aligns procurement with organisational goals, fostering long-term success and competitiveness.",
    },
    {
        icon: pCardIcon8,
        title: "Enhanced Visibility and Control",
        description:
            "Strategic sourcing aligns procurement with organisational goals, fostering long-term success and competitiveness.",
    },
];


const faqs = [
    {
        id: 1,
        header: "What is Lorem Ipsum?",
        text: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.`,
    },
    {
        id: 2,
        header: "Where does it come from?",
        text: `It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.`,
    },
    {
        id: 3,
        header: "Why do we use it?",
        text: `Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in classical Latin literature from 45 BC.`,
    },
    {
        id: 4,
        header: "Where can I get some?",
        text: `There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.`,
    },
];



const ProcurementTemplate: React.FC = () => {
    const classes = useStyles();

    type TabKeys = "tab1" | "tab2" | "tab3" | "tab4" | "tab5" | "tab6" | "tab7" | "tab8" | "tab9" | "tab10" | "tab11" | "tab12" | "tab13" | "tab14" | "tab15" | "tab16" | "tab17" | "tab18" | "tab19" | "tab20" | "tab21" | "tab22" | "tab23" | "tab24" | "tab25" | "tab26" | "tab27" | "tab28" | "tab29" | "tab30" | "tab31" | "tab32" | "tab33" | "tab34" | "tab35" | "tab36";

    const [expanded, setExpanded] = useState<TabKeys | null>(null);

    const toggleAccordion = (tab: TabKeys) => {
        setExpanded((prev) => (prev === tab ? null : tab));
    };

    return (
        <>
            <div className={`${classes.bannerContainer}`}>
                <div className={`${classes.container} ${classes.bannerSellContainer}`}>
                    <h1 className={`${classes.heading} font-semibold`}>Unlock Every<br />Hidden Savings<br />with Our Smart Steel<br /> <span>Procurement Solutions</span></h1>
                    <p className={`${classes.bannerDescription} text-2xl font-medium mt-4 mb-4`}>Our procurement process delivers precision & cost<br />efficiency, providing businesses with exactly what<br />they need. <span>Here's how it works</span></p>
                    <a href="#" className={`${classes.bannerBtn} flex gap-[10px] rounded-lg font-bold items-center justify-center text-base`}>Get Started Today <div className={classes.btnArrow}><ArrowForwardIcon /></div></a>
                </div>
            </div>

            <div className={`${classes.intoContainer} `}>
                <div className={`${classes.container} flex items-center py-14 justify-between`}>
                    <div className=''>
                        <p className={`${classes.text} font-semibold mb-2`}>Optimize Costs<br />Maximize Efficiency </p>
                    </div>
                    <div className={classes.decContainer}>
                        <p className='text-lg mb-4'>SteelBazaar transforms steel procurement through a digital platform that integrates strategic sourcing. Our approach continually enhances purchasing activities for efficient & cost-effective acquisition of quality steel products. By analyzing spending patterns & market conditions while fostering supplier relationships, we elevate procurement from a transactional function to a strategic business activity.
                        </p>
                        <p className='text-lg'>This allows businesses to optimize supply chains, reduce costs, & improve quality. Our commitment to transparency, sustainability, and excellence helps organizations of all sizes achieve their procurement goals.</p>
                    </div>
                </div>
            </div>
            <div className={classes.cardsContainer}>
                <div className={`${classes.container} pt-[110px] pb-[110px]`}>
                    <div >
                        <h2 className={`${classes.sectionHeading} font-semibold text-center`}>Are Steel Procurement Issues <span className={`${classes.warningText}`}>Blocking</span><br />
                            Your Enterprise <span>Growth?</span></h2>
                        <p className={`${classes.subHeading} text-center text-2xl font-medium mt-1`}>Discover Solutions to Overcome These Common Obstacles</p>
                    </div>
                    <div className='flex gap-[30px] flex-wrap mt-[50px]'>
                        <div className={`${classes.card} rounded-lg`}>
                            <div className={`${classes.cardIconContainer} flex items-center justify-center p-[10px] w-[82px] h-[82px] rounded-2xl`}><img src={currency_rupee} alt="" /></div>
                            <h4 className='mt-[10px] mb-[10px] font-semibold'>Unpredictable Costs</h4>
                            <p className='text-base'>Rising material prices and lack of transparency in sourcing.</p>
                        </div>
                        <div className={`${classes.card} rounded-lg`}>
                            <div className={`${classes.cardIconContainer} flex items-center justify-center p-[10px] w-[82px] h-[82px] rounded-2xl`}><img src={roundTickIcon} alt="" /></div>
                            <h4 className='mt-[10px] mb-[10px] font-semibold'>Quality Inconsistencies</h4>
                            <p className='text-base'>Difficulty ensuring reliable and consistent material quality.</p>
                        </div>
                        <div className={`${classes.card} rounded-lg`}>
                            <div className={`${classes.cardIconContainer} flex items-center justify-center p-[10px] w-[82px] h-[82px] rounded-2xl`}><img src={timeIcon} alt="" /></div>
                            <h4 className='mt-[10px] mb-[10px] font-semibold'>Delayed Deliveries</h4>
                            <p className='text-base'>Supply chain disruptions impacting timely availability.</p>
                        </div>
                        <div className={`${classes.card} rounded-lg`}>
                            <div className={`${classes.cardIconContainer} flex items-center justify-center p-[10px] w-[82px] h-[82px] rounded-2xl`}><img src={bussinessIcon} alt="" /></div>
                            <h4 className='mt-[10px] mb-[10px] font-semibold'>Vendor Management Complexity</h4>
                            <p className='text-base'>Managing multiple vendors with varying performance levels.</p>
                        </div>
                        <div className={`${classes.card} rounded-lg`}>
                            <div className={`${classes.cardIconContainer} flex items-center justify-center p-[10px] w-[82px] h-[82px] rounded-2xl`}><img src={downTime} alt="" /></div>
                            <h4 className='mt-[10px] mb-[10px] font-semibold'>Inefficient Processes</h4>
                            <p className='text-base'>Manual procurement workflows causing delays and errors. </p>
                        </div>
                        <div className={`${classes.card} rounded-lg`}>
                            <div className={`${classes.cardIconContainer} flex items-center justify-center p-[10px] w-[82px] h-[82px] rounded-2xl`}><img src={continuityIcon} alt="" /></div>
                            <h4 className='mt-[10px] mb-[10px] font-semibold'>Cash Flow Strains</h4>
                            <p className='text-base'>Lack of flexible financing options for large-scale procurement.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`${classes.accordianContainer} pt-14`}>
                <h2 className='font-semibold mb-2 text-center'>Smart Sourcing for Smarter Steel Supply</h2>
                <p className='mb-16 text-2xl font-semibold text-center'>Assured Quality, Huge Cost <span>Savings</span></p>
                <AccordionExpandDefault />
                <div className={`${classes.container} py-[64px] flex gap-20`}>
                    <div>
                        <h3 className={`${classes.primaryText} mb-6 font-bold`}>Check Instant<br /><span className=''>Are You Buying Costly Steel?</span></h3>
                        <h4 className={`${classes.instaDescription} mt-6`}>Know how much more Efficient , Cost-Savings and Customer-Centric ,Your Existing Supply <br />Chain can be? Drive Profitability with Steelbazaar Performance Scores. Steel Bazaar helps in <br />optimising and bringing huge real potential value to you. <br /> <br />
                            <span>Discover the Power of Performance Metrics.</span></h4>

                        <div className='flex gap-4 items-center mt-6'>
                            <a href="" className={`${classes.btn1} flex items-center justify-center p-3`}>Learn More</a>
                            <a href="" className={`${classes.btn2} flex items-center justify-center p-3 w-max`}>Contact us now</a>
                        </div>
                    </div>
                    <div className={`${classes.ratingContainer} flex flex-col items-center`}><p className='font-bold mb-6 text-center'>Evaluate Your Supply <br />Chain Score Now</p><img src={meter} alt="" /> <span className={`${classes.score} font-bold`}>8/10</span></div>
                </div>

            </div>
            <div className={`${classes.container} pt-[110px] pb-[110px]`}>
                <h2 className={`${classes.ProcurementSectionHeading} font-semibold text-center`}>Our Streamlined Procurement Process
                    from <span>Requisition to Delivery</span></h2>
                <p className={`${classes.procurementSubHeading} text-2xl font-semibold text-center`}>Designed for precision and cost-efficiency procurement, Here's how it works.</p>
                <div className='flex items-center justify-center'>
                    <div className='mt-20 flex flex-col items-center w-max' >
                        <div className='flex items-center'>
                            <div className={`${classes.curve} h-[146px] w-[146px] flex items-center justify-center relative`}>
                                <div className={`${classes.serialNumber} h-[110px] w-[110px] flex items-center justify-center `}>01</div>
                                <div className={`${classes.secondaryContianerMain} flex gap-[30px] w-full w-[700px]`}>
                                    <div>
                                        <h5 className='font-semibold mb-1'>Identify Steel Requirements</h5>
                                        <p className='text-lg'>Determine specific steel needs, including type, quantity, <br />
                                            quality standards, delivery timelines, and other <br />
                                            attributes to meet project specifications.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex items-center'>
                            <div className={`${classes.curve2} h-[146px] w-[146px] flex items-center justify-center relative`}>
                                <div className={`${classes.serialNumber2} h-[110px] w-[110px] flex items-center justify-center `}>02</div>
                                <div className={`${classes.secondaryContianerMain2} flex gap-[30px] w-full w-[700px]`}>
                                    <div className='text-right'>
                                        <h5 className='font-semibold mb-1'>Supplier Selection</h5>
                                        <p className='text-lg'>Evaluate and choose from a global & domestic network of vetted suppliers offering competitive pricing, quality, compliance & reliable delivery.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex items-center'>
                            <div className={`${classes.curve} h-[146px] w-[146px] flex items-center justify-center relative`}>
                                <div className={`${classes.serialNumber} h-[110px] w-[110px] flex items-center justify-center `}>03</div>
                                <div className={`${classes.secondaryContianerMain} flex gap-[30px] w-full w-[700px]`}>
                                    <div>
                                        <h5 className='font-semibold mb-1'>Request for Quotation (RFQ)</h5>
                                        <p className='text-lg'>Submit detailed RFQs to selected suppliers to obtain accurate pricing and delivery timelines.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex items-center'>
                            <div className={`${classes.curve2} h-[146px] w-[146px] flex items-center justify-center relative`}>
                                <div className={`${classes.serialNumber2} h-[110px] w-[110px] flex items-center justify-center `}>04</div>
                                <div className={`${classes.secondaryContianerMain2} flex gap-[30px] w-full w-[700px]`}>
                                    <div className='text-right'>
                                        <h5 className='font-semibold mb-1'>Quotation Evaluation</h5>
                                        <p className='text-lg'>Assess received quotations based on cost, quality, supplier reliability & alignment with project timelines.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex items-center'>
                            <div className={`${classes.curve} h-[146px] w-[146px] flex items-center justify-center relative`}>
                                <div className={`${classes.serialNumber} h-[110px] w-[110px] flex items-center justify-center `}>05</div>
                                <div className={`${classes.secondaryContianerMain} flex gap-[30px] w-full w-[700px]`}>
                                    <div>
                                        <h5 className='font-semibold mb-1'>Negotiation and Contracting</h5>
                                        <p className='text-lg'>Negotiate to finalize terms, ensuring favourable conditions before formalizing agreements.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex items-center'>
                            <div className={`${classes.curve2} h-[146px] w-[146px] flex items-center justify-center relative`}>
                                <div className={`${classes.serialNumber2} h-[110px] w-[110px] flex items-center justify-center `}>06</div>
                                <div className={`${classes.secondaryContianerMain2} flex gap-[30px] w-full w-[700px]`}>
                                    <div className='text-right'>
                                        <h5 className='font-semibold mb-1'>Order Placement</h5>
                                        <p className='text-lg'>Issue purchase orders to chosen suppliers detailing product specifications, quantities and delivery schedules.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex items-center'>
                            <div className={`${classes.curve} h-[146px] w-[146px] flex items-center justify-center relative`}>
                                <div className={`${classes.serialNumber} h-[110px] w-[110px] flex items-center justify-center `}>07</div>
                                <div className={`${classes.secondaryContianerMain} flex gap-[30px] w-full w-[700px]`}>
                                    <div>
                                        <h5 className='font-semibold mb-1'>Production Monitoring</h5>
                                        <p className='text-lg'>Oversee manufacturing progress to ensure adherence to quality standards and agreed timelines.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex items-center'>
                            <div className={`${classes.curve2} h-[146px] w-[146px] flex items-center justify-center relative`}>
                                <div className={`${classes.serialNumber2} h-[110px] w-[110px] flex items-center justify-center `}>08</div>
                                <div className={`${classes.secondaryContianerMain2} flex gap-[30px] w-full w-[700px]`}>
                                    <div className='text-right'>
                                        <h5 className='font-semibold mb-1'>Quality Inspection</h5>
                                        <p className='text-lg'>Conduct thorough inspections upon delivery to verify compliance with specified quality and standards.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex items-center'>
                            <div className={`${classes.curve} h-[146px] w-[146px] flex items-center justify-center relative`}>
                                <div className={`${classes.serialNumber} h-[110px] w-[110px] flex items-center justify-center `}>09</div>
                                <div className={`${classes.secondaryContianerMain} flex gap-[30px] w-full w-[700px]`}>
                                    <div>
                                        <h5 className='font-semibold mb-1'>Logistics and Delivery</h5>
                                        <p className='text-lg'>Coordinate transportation and logistics to ensure timely and safe delivery of steel products.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex items-center'>
                            <div className={`${classes.curve2} h-[146px] w-[146px] flex items-center justify-center relative`}>
                                <div className={`${classes.serialNumber2} h-[110px] w-[110px] flex items-center justify-center `}>10</div>
                                <div className={`${classes.secondaryContianerMain2} flex gap-[30px] w-full w-[700px]`}>
                                    <div className='text-right'>
                                        <h5 className='font-semibold mb-1'>Payment and Documentation</h5>
                                        <p className='text-lg'>Process payments as per agreed terms and maintain comprehensive records for future reference</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <h2 className={`${classes.featureHeading}`}>Our Unique Features of <span>Procurement Solutions</span></h2>
            <div className={`${classes.container} flex items-center gap-[60px]`}>
                <div><img src={accordianImg1} alt="" /></div>
                <section>
                    <h2 className={`${classes.accordMainHeading} text-5xl font-semibold`}>Comprehensive Material <br /> <span>Sourcing Solutions</span> </h2>
                    <h3 className={`${classes.accordSecondaryHeading} font-medium mt-[15px]`}>Optimize Costs with Divers e Sourcing Strategies</h3>
                    <div className={classes.accordion}>
                        <div className={classes.tab}>
                            <input
                                type="checkbox"
                                id="cb1"
                                checked={expanded === "tab1"}
                                onChange={() => toggleAccordion("tab1")}
                            />
                            <label
                                htmlFor="cb1"
                                className={`${classes.tabLabel} ${expanded === "tab1" ? classes.tabLabelExpanded : ""
                                    }`}
                            >
                                <span className={`${classes.accodText} font-medium`}>Real-Time Priced Catalogs</span>
                            </label>
                            <div
                                className={`${classes.tabContent} ${expanded === "tab1" ? classes.tabContentExpanded : ""
                                    }`}
                            >
                                <div className={classes.contentInner}>
                                    <p>Access up-to-date pricing to make informed purchasing decisions.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={classes.accordion}>
                        <div className={classes.tab}>
                            <input
                                type="checkbox"
                                id="cb2"
                                checked={expanded === "tab2"}
                                onChange={() => toggleAccordion("tab2")}
                            />
                            <label
                                htmlFor="cb2"
                                className={`${classes.tabLabel} ${expanded === "tab2" ? classes.tabLabelExpanded : ""
                                    }`}
                            >
                                <span className={`${classes.accodText} font-medium`}>Brand Comparison Tools</span>
                            </label>
                            <div
                                className={`${classes.tabContent} ${expanded === "tab2" ? classes.tabContentExpanded : ""
                                    }`}
                            >
                                <div className={classes.contentInner}>
                                    <p>
                                        Evaluate products based on real-time market prices and quality to select the best fit.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.accordion}>
                        <div className={classes.tab}>
                            <input
                                type="checkbox"
                                id="cb3"
                                checked={expanded === "tab3"}
                                onChange={() => toggleAccordion("tab3")}
                            />
                            <label
                                htmlFor="cb3"
                                className={`${classes.tabLabel} ${expanded === "tab3" ? classes.tabLabelExpanded : ""
                                    }`}
                            >
                                <span className={`${classes.accodText} font-medium`}>Pool and Save Programs</span>
                            </label>
                            <div
                                className={`${classes.tabContent} ${expanded === "tab3" ? classes.tabContentExpanded : ""
                                    }`}
                            >
                                <div className={classes.contentInner}>
                                    <p>
                                        Collaborate with other buyers to leverage collective purchasing power for discounts.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.accordion}>
                        <div className={classes.tab}>
                            <input
                                type="checkbox"
                                id="cb4"
                                checked={expanded === "tab4"}
                                onChange={() => toggleAccordion("tab4")}
                            />
                            <label
                                htmlFor="cb4"
                                className={`${classes.tabLabel} ${expanded === "tab4" ? classes.tabLabelExpanded : ""
                                    }`}
                            >
                                <span className={`${classes.accodText} font-medium`}>Pool and Save Programs</span>
                            </label>
                            <div
                                className={`${classes.tabContent} ${expanded === "tab4" ? classes.tabContentExpanded : ""
                                    }`}
                            >
                                <div className={classes.contentInner}>
                                    <p>
                                        Benefit from reduced prices by ordering larger quantities.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <div className={`${classes.container} flex items-center gap-[60px] pt-12`}>
                <section>
                    <h2 className={`${classes.accordMainHeading} text-5xl font-semibold`}><span>Global Sourcing</span> for Superior <br /> Quality & Pricing </h2>
                    <div className={classes.accordion}>
                        <div className={classes.tab}>
                            <input
                                type="checkbox"
                                id="cb5"
                                checked={expanded === "tab5"}
                                onChange={() => toggleAccordion("tab5")}
                            />
                            <label
                                htmlFor="cb5"
                                className={`${classes.tabLabel} ${expanded === "tab5" ? classes.tabLabelExpanded : ""
                                    }`}
                            >
                                <span className={`${classes.accodText} font-medium`}>Partnerships with Suppliers</span>
                            </label>
                            <div
                                className={`${classes.tabContent} ${expanded === "tab5" ? classes.tabContentExpanded : ""
                                    }`}
                            >
                                <div className={classes.contentInner}>
                                    <p>Forming alliances with reputed international & Domestic Suppliers to secure cost-effective materials.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={classes.accordion}>
                        <div className={classes.tab}>
                            <input
                                type="checkbox"
                                id="cb6"
                                checked={expanded === "tab6"}
                                onChange={() => toggleAccordion("tab6")}
                            />
                            <label
                                htmlFor="cb6"
                                className={`${classes.tabLabel} ${expanded === "tab6" ? classes.tabLabelExpanded : ""
                                    }`}
                            >
                                <span className={`${classes.accodText} font-medium`}>Cross-Border Solutions</span>
                            </label>
                            <div
                                className={`${classes.tabContent} ${expanded === "tab6" ? classes.tabContentExpanded : ""
                                    }`}
                            >
                                <div className={classes.contentInner}>
                                    <p>
                                        Implementing cross-border procurement solutions to leverage global supply chains.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.accordion}>
                        <div className={classes.tab}>
                            <input
                                type="checkbox"
                                id="cb7"
                                checked={expanded === "tab7"}
                                onChange={() => toggleAccordion("tab7")}
                            />
                            <label
                                htmlFor="cb7"
                                className={`${classes.tabLabel} ${expanded === "tab7" ? classes.tabLabelExpanded : ""
                                    }`}
                            >
                                <span className={`${classes.accodText} font-medium`}>Compliance and Quality Control</span>
                            </label>
                            <div
                                className={`${classes.tabContent} ${expanded === "tab7" ? classes.tabContentExpanded : ""
                                    }`}
                            >
                                <div className={classes.contentInner}>
                                    <p>
                                        Ensuring compliance and quality control to meet international product standards
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.accordion}>
                        <div className={classes.tab}>
                            <input
                                type="checkbox"
                                id="cb8"
                                checked={expanded === "tab8"}
                                onChange={() => toggleAccordion("tab8")}
                            />
                            <label
                                htmlFor="cb8"
                                className={`${classes.tabLabel} ${expanded === "tab8" ? classes.tabLabelExpanded : ""
                                    }`}
                            >
                                <span className={`${classes.accodText} font-medium`}>Access to Diverse Steel Grades</span>
                            </label>
                            <div
                                className={`${classes.tabContent} ${expanded === "tab8" ? classes.tabContentExpanded : ""
                                    }`}
                            >
                                <div className={classes.contentInner}>
                                    <p>
                                        Providing access to a variety of steel grades and products that are not available locally.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div><img src={accordianImg2} alt="" /></div>
            </div>

            <div className={`${classes.container} flex items-center gap-[60px] pt-12`}>
                <div><img src={accordianImg3} alt="" /></div>
                <section>
                    <h2 className={`${classes.accordMainHeading} text-5xl font-semibold`}>End-to-end <br /> <span>Digital Procurement</span> </h2>
                    <div className={classes.accordion}>
                        <div className={classes.tab}>
                            <input
                                type="checkbox"
                                id="cb9"
                                checked={expanded === "tab9"}
                                onChange={() => toggleAccordion("tab9")}
                            />
                            <label
                                htmlFor="cb9"
                                className={`${classes.tabLabel} ${expanded === "tab9" ? classes.tabLabelExpanded : ""
                                    }`}
                            >
                                <span className={`${classes.accodText} font-medium`}>Advanced Digital Platform</span>
                            </label>
                            <div
                                className={`${classes.tabContent} ${expanded === "tab9" ? classes.tabContentExpanded : ""
                                    }`}
                            >
                                <div className={classes.contentInner}>
                                    <p>Utilize a user-friendly digital interface to streamline procurement processes and enhance operational efficiency.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={classes.accordion}>
                        <div className={classes.tab}>
                            <input
                                type="checkbox"
                                id="cb10"
                                checked={expanded === "tab10"}
                                onChange={() => toggleAccordion("tab10")}
                            />
                            <label
                                htmlFor="cb10"
                                className={`${classes.tabLabel} ${expanded === "tab10" ? classes.tabLabelExpanded : ""
                                    }`}
                            >
                                <span className={`${classes.accodText} font-medium`}>Real-Time Market Insights</span>
                            </label>
                            <div
                                className={`${classes.tabContent} ${expanded === "tab10" ? classes.tabContentExpanded : ""
                                    }`}
                            >
                                <div className={classes.contentInner}>
                                    <p>
                                        Stay informed with up-to-date market data, enabling strategic decision-making and timely procurement.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.accordion}>
                        <div className={classes.tab}>
                            <input
                                type="checkbox"
                                id="cb11"
                                checked={expanded === "tab11"}
                                onChange={() => toggleAccordion("tab11")}
                            />
                            <label
                                htmlFor="cb11"
                                className={`${classes.tabLabel} ${expanded === "tab11" ? classes.tabLabelExpanded : ""
                                    }`}
                            >
                                <span className={`${classes.accodText} font-medium`}>Transparent Transactions</span>
                            </label>
                            <div
                                className={`${classes.tabContent} ${expanded === "tab11" ? classes.tabContentExpanded : ""
                                    }`}
                            >
                                <div className={classes.contentInner}>
                                    <p>
                                        Ensure clarity and trust in all dealings, minimising risks and fostering strong supplier relationships
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.accordion}>
                        <div className={classes.tab}>
                            <input
                                type="checkbox"
                                id="cb12"
                                checked={expanded === "tab12"}
                                onChange={() => toggleAccordion("tab12")}
                            />
                            <label
                                htmlFor="cb12"
                                className={`${classes.tabLabel} ${expanded === "tab12" ? classes.tabLabelExpanded : ""
                                    }`}
                            >
                                <span className={`${classes.accodText} font-medium`}>Dedicated Customer Support</span>
                            </label>
                            <div
                                className={`${classes.tabContent} ${expanded === "tab12" ? classes.tabContentExpanded : ""
                                    }`}
                            >
                                <div className={classes.contentInner}>
                                    <p>
                                        Receive continuous assistance from our expert team, ensuring a seamless procurement experience.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div className={`${classes.container} flex items-center gap-[60px] pt-12`}>
                <section>
                    <h2 className={`${classes.accordMainHeading} text-5xl font-semibold`}>Enhanced <span>Vendor Management</span> <br /> for Optimal Results   </h2>
                    <div className={classes.accordion}>
                        <div className={classes.tab}>
                            <input
                                type="checkbox"
                                id="cb13"
                                checked={expanded === "tab13"}
                                onChange={() => toggleAccordion("tab13")}
                            />
                            <label
                                htmlFor="cb13"
                                className={`${classes.tabLabel} ${expanded === "tab13" ? classes.tabLabelExpanded : ""
                                    }`}
                            >
                                <span className={`${classes.accodText} font-medium`}>Vendor Selection</span>
                            </label>
                            <div
                                className={`${classes.tabContent} ${expanded === "tab13" ? classes.tabContentExpanded : ""
                                    }`}
                            >
                                <div className={classes.contentInner}>
                                    <p>Evaluate vendors based on performance metrics to ensure quality partnerships.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={classes.accordion}>
                        <div className={classes.tab}>
                            <input
                                type="checkbox"
                                id="cb14"
                                checked={expanded === "tab14"}
                                onChange={() => toggleAccordion("tab14")}
                            />
                            <label
                                htmlFor="cb14"
                                className={`${classes.tabLabel} ${expanded === "tab14" ? classes.tabLabelExpanded : ""
                                    }`}
                            >
                                <span className={`${classes.accodText} font-medium`}>Regular Audits</span>
                            </label>
                            <div
                                className={`${classes.tabContent} ${expanded === "tab14" ? classes.tabContentExpanded : ""
                                    }`}
                            >
                                <div className={classes.contentInner}>
                                    <p>
                                        Conduct vendor audits consistently to maintain quality and compliance standards.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.accordion}>
                        <div className={classes.tab}>
                            <input
                                type="checkbox"
                                id="cb15"
                                checked={expanded === "tab15"}
                                onChange={() => toggleAccordion("tab15")}
                            />
                            <label
                                htmlFor="cb15"
                                className={`${classes.tabLabel} ${expanded === "tab15" ? classes.tabLabelExpanded : ""
                                    }`}
                            >
                                <span className={`${classes.accodText} font-medium`}>Performance</span>
                            </label>
                            <div
                                className={`${classes.tabContent} ${expanded === "tab15" ? classes.tabContentExpanded : ""
                                    }`}
                            >
                                <div className={classes.contentInner}>
                                    <p>
                                        Tracking: Utilize real-time analytics for effective supplier performance tracking.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.accordion}>
                        <div className={classes.tab}>
                            <input
                                type="checkbox"
                                id="cb16"
                                checked={expanded === "tab16"}
                                onChange={() => toggleAccordion("tab16")}
                            />
                            <label
                                htmlFor="cb16"
                                className={`${classes.tabLabel} ${expanded === "tab16" ? classes.tabLabelExpanded : ""
                                    }`}
                            >
                                <span className={`${classes.accodText} font-medium`}>Vendor</span>
                            </label>
                            <div
                                className={`${classes.tabContent} ${expanded === "tab16" ? classes.tabContentExpanded : ""
                                    }`}
                            >
                                <div className={classes.contentInner}>
                                    <p>
                                        Development Implement vendor development programs aimed at enhancing supplier capabilities.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div><img src={accordianImg4} alt="" /></div>
            </div>
            <div className={`${classes.container} flex items-center gap-[60px] pt-12`}>
                <div><img src={accordianImg5} alt="" /></div>
                <section>
                    <h2 className={`${classes.accordMainHeading} text-5xl font-semibold`}>Innovative <span>Reverse Auction</span> <br /> Procurement Drive Down Costs </h2>
                    <div className={classes.accordion}>
                        <div className={classes.tab}>
                            <input
                                type="checkbox"
                                id="cb17"
                                checked={expanded === "tab17"}
                                onChange={() => toggleAccordion("tab17")}
                            />
                            <label
                                htmlFor="cb17"
                                className={`${classes.tabLabel} ${expanded === "tab17" ? classes.tabLabelExpanded : ""
                                    }`}
                            >
                                <span className={`${classes.accodText} font-medium`}>Competitive Bids</span>
                            </label>
                            <div
                                className={`${classes.tabContent} ${expanded === "tab17" ? classes.tabContentExpanded : ""
                                    }`}
                            >
                                <div className={classes.contentInner}>
                                    <p>Reverse auction platform enabling buyers to receive competitive bids from suppliers.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={classes.accordion}>
                        <div className={classes.tab}>
                            <input
                                type="checkbox"
                                id="cb18"
                                checked={expanded === "tab18"}
                                onChange={() => toggleAccordion("tab18")}
                            />
                            <label
                                htmlFor="cb18"
                                className={`${classes.tabLabel} ${expanded === "tab18" ? classes.tabLabelExpanded : ""
                                    }`}
                            >
                                <span className={`${classes.accodText} font-medium`}>Cost Reduction</span>
                            </label>
                            <div
                                className={`${classes.tabContent} ${expanded === "tab18" ? classes.tabContentExpanded : ""
                                    }`}
                            >
                                <div className={classes.contentInner}>
                                    <p>
                                        Cost reduction through dynamic bidding from pre-qualified vendors.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.accordion}>
                        <div className={classes.tab}>
                            <input
                                type="checkbox"
                                id="cb19"
                                checked={expanded === "tab19"}
                                onChange={() => toggleAccordion("tab19")}
                            />
                            <label
                                htmlFor="cb19"
                                className={`${classes.tabLabel} ${expanded === "tab19" ? classes.tabLabelExpanded : ""
                                    }`}
                            >
                                <span className={`${classes.accodText} font-medium`}>Transparency and Competition</span>
                            </label>
                            <div
                                className={`${classes.tabContent} ${expanded === "tab19" ? classes.tabContentExpanded : ""
                                    }`}
                            >
                                <div className={classes.contentInner}>
                                    <p>
                                        Enhanced transparency and competition among suppliers.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.accordion}>
                        <div className={classes.tab}>
                            <input
                                type="checkbox"
                                id="cb20"
                                checked={expanded === "tab20"}
                                onChange={() => toggleAccordion("tab20")}
                            />
                            <label
                                htmlFor="cb20"
                                className={`${classes.tabLabel} ${expanded === "tab20" ? classes.tabLabelExpanded : ""
                                    }`}
                            >
                                <span className={`${classes.accodText} font-medium`}>Streamlined Procurement</span>
                            </label>
                            <div
                                className={`${classes.tabContent} ${expanded === "tab20" ? classes.tabContentExpanded : ""
                                    }`}
                            >
                                <div className={classes.contentInner}>
                                    <p>
                                        Streamlined procurement process with real-time pricing during auctions.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div className={`${classes.container} flex items-center gap-[60px] pt-12`}>
                <section>
                    <h2 className={`${classes.accordMainHeading} text-5xl font-semibold`}>Get Products <span>Customized</span> <br /> to Your Specifications  <br /></h2>
                    <div className={classes.accordion}>
                        <div className={classes.tab}>
                            <input
                                type="checkbox"
                                id="cb21"
                                checked={expanded === "tab21"}
                                onChange={() => toggleAccordion("tab21")}
                            />
                            <label
                                htmlFor="cb21"
                                className={`${classes.tabLabel} ${expanded === "tab21" ? classes.tabLabelExpanded : ""
                                    }`}
                            >
                                <span className={`${classes.accodText} font-medium`}>Customised Steel Products</span>
                            </label>
                            <div
                                className={`${classes.tabContent} ${expanded === "tab21" ? classes.tabContentExpanded : ""
                                    }`}
                            >
                                <div className={classes.contentInner}>
                                    <p>Steel products are tailored to meet industry-specific needs.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={classes.accordion}>
                        <div className={classes.tab}>
                            <input
                                type="checkbox"
                                id="cb22"
                                checked={expanded === "tab22"}
                                onChange={() => toggleAccordion("tab22")}
                            />
                            <label
                                htmlFor="cb22"
                                className={`${classes.tabLabel} ${expanded === "tab22" ? classes.tabLabelExpanded : ""
                                    }`}
                            >
                                <span className={`${classes.accodText} font-medium`}>Tailor-Made Specifications</span>
                            </label>
                            <div
                                className={`${classes.tabContent} ${expanded === "tab22" ? classes.tabContentExpanded : ""
                                    }`}
                            >
                                <div className={classes.contentInner}>
                                    <p>
                                        Specifications designed for specialised manufacturing requirements.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.accordion}>
                        <div className={classes.tab}>
                            <input
                                type="checkbox"
                                id="cb23"
                                checked={expanded === "tab23"}
                                onChange={() => toggleAccordion("tab23")}
                            />
                            <label
                                htmlFor="cb23"
                                className={`${classes.tabLabel} ${expanded === "tab23" ? classes.tabLabelExpanded : ""
                                    }`}
                            >
                                <span className={`${classes.accodText} font-medium`}>Advanced Manufacturing</span>
                            </label>
                            <div
                                className={`${classes.tabContent} ${expanded === "tab23" ? classes.tabContentExpanded : ""
                                    }`}
                            >
                                <div className={classes.contentInner}>
                                    <p>
                                        Services Access to cutting-edge manufacturing services through Steelbazaar’s network.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.accordion}>
                        <div className={classes.tab}>
                            <input
                                type="checkbox"
                                id="cb24"
                                checked={expanded === "tab24"}
                                onChange={() => toggleAccordion("tab24")}
                            />
                            <label
                                htmlFor="cb24"
                                className={`${classes.tabLabel} ${expanded === "tab24" ? classes.tabLabelExpanded : ""
                                    }`}
                            >
                                <span className={`${classes.accodText} font-medium`}>Prototype and Trial</span>
                            </label>
                            <div
                                className={`${classes.tabContent} ${expanded === "tab24" ? classes.tabContentExpanded : ""
                                    }`}
                            >
                                <div className={classes.contentInner}>
                                    <p>
                                        Production Opportunity for prototype and trial production before placing a final bulk order.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div><img src={accordianImg6} alt="" /></div>
            </div>
            <div className={`${classes.container} flex items-center gap-[60px] pt-12`}>
                <div><img src={accordianImg7} alt="" /></div>
                <section>
                    <h2 className={`${classes.accordMainHeading} text-5xl font-semibold`}>Unlocking <span>Financial Flexibility</span> <br /> For Your Enterprises</h2>
                    <div className={classes.accordion}>
                        <div className={classes.tab}>
                            <input
                                type="checkbox"
                                id="cb25"
                                checked={expanded === "tab25"}
                                onChange={() => toggleAccordion("tab25")}
                            />
                            <label
                                htmlFor="cb25"
                                className={`${classes.tabLabel} ${expanded === "tab25" ? classes.tabLabelExpanded : ""
                                    }`}
                            >
                                <span className={`${classes.accodText} font-medium`}>Tailored Credit</span>
                            </label>
                            <div
                                className={`${classes.tabContent} ${expanded === "tab25" ? classes.tabContentExpanded : ""
                                    }`}
                            >
                                <div className={classes.contentInner}>
                                    <p>Credit solutions are specifically designed for corporate clients engaged in large procurement activities.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={classes.accordion}>
                        <div className={classes.tab}>
                            <input
                                type="checkbox"
                                id="cb26"
                                checked={expanded === "tab26"}
                                onChange={() => toggleAccordion("tab26")}
                            />
                            <label
                                htmlFor="cb26"
                                className={`${classes.tabLabel} ${expanded === "tab26" ? classes.tabLabelExpanded : ""
                                    }`}
                            >
                                <span className={`${classes.accodText} font-medium`}>Deferred Payment</span>
                            </label>
                            <div
                                className={`${classes.tabContent} ${expanded === "tab26" ? classes.tabContentExpanded : ""
                                    }`}
                            >
                                <div className={classes.contentInner}>
                                    <p>
                                        Terms Flexible payment terms that allow clients to manage their cash flow more effectively.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.accordion}>
                        <div className={classes.tab}>
                            <input
                                type="checkbox"
                                id="cb27"
                                checked={expanded === "tab27"}
                                onChange={() => toggleAccordion("tab27")}
                            />
                            <label
                                htmlFor="cb27"
                                className={`${classes.tabLabel} ${expanded === "tab27" ? classes.tabLabelExpanded : ""
                                    }`}
                            >
                                <span className={`${classes.accodText} font-medium`}>Supply Chain Finance</span>
                            </label>
                            <div
                                className={`${classes.tabContent} ${expanded === "tab27" ? classes.tabContentExpanded : ""
                                    }`}
                            >
                                <div className={classes.contentInner}>
                                    <p>
                                        Options Financial solutions aimed at optimising working capital within the supply chain.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.accordion}>
                        <div className={classes.tab}>
                            <input
                                type="checkbox"
                                id="cb28"
                                checked={expanded === "tab28"}
                                onChange={() => toggleAccordion("tab28")}
                            />
                            <label
                                htmlFor="cb28"
                                className={`${classes.tabLabel} ${expanded === "tab28" ? classes.tabLabelExpanded : ""
                                    }`}
                            >
                                <span className={`${classes.accodText} font-medium`}>Access to Trade</span>
                            </label>
                            <div
                                className={`${classes.tabContent} ${expanded === "tab28" ? classes.tabContentExpanded : ""
                                    }`}
                            >
                                <div className={classes.contentInner}>
                                    <p>
                                        Financing Opportunities for trade financing to support international procurement needs.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div className={`${classes.container} flex items-center gap-[60px] pt-12`}>
                <section>
                    <h2 className={`${classes.accordMainHeading} text-5xl font-semibold`}><span>Assured Quality</span> <br />
                        in Every Procurement</h2>
                    <div className={classes.accordion}>
                        <div className={classes.tab}>
                            <input
                                type="checkbox"
                                id="cb29"
                                checked={expanded === "tab29"}
                                onChange={() => toggleAccordion("tab29")}
                            />
                            <label
                                htmlFor="cb29"
                                className={`${classes.tabLabel} ${expanded === "tab29" ? classes.tabLabelExpanded : ""
                                    }`}
                            >
                                <span className={`${classes.accodText} font-medium`}>Strict Quality Control</span>
                            </label>
                            <div
                                className={`${classes.tabContent} ${expanded === "tab29" ? classes.tabContentExpanded : ""
                                    }`}
                            >
                                <div className={classes.contentInner}>
                                    <p>Implementing stringent quality control measures at every stage of the procurement process.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={classes.accordion}>
                        <div className={classes.tab}>
                            <input
                                type="checkbox"
                                id="cb30"
                                checked={expanded === "tab30"}
                                onChange={() => toggleAccordion("tab30")}
                            />
                            <label
                                htmlFor="cb30"
                                className={`${classes.tabLabel} ${expanded === "tab30" ? classes.tabLabelExpanded : ""
                                    }`}
                            >
                                <span className={`${classes.accodText} font-medium`}>Third-Party Audits</span>
                            </label>
                            <div
                                className={`${classes.tabContent} ${expanded === "tab30" ? classes.tabContentExpanded : ""
                                    }`}
                            >
                                <div className={classes.contentInner}>
                                    <p>
                                        Utilising third-party quality audits for thorough material verification.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.accordion}>
                        <div className={classes.tab}>
                            <input
                                type="checkbox"
                                id="cb27"
                                checked={expanded === "tab27"}
                                onChange={() => toggleAccordion("tab27")}
                            />
                            <label
                                htmlFor="cb27"
                                className={`${classes.tabLabel} ${expanded === "tab27" ? classes.tabLabelExpanded : ""
                                    }`}
                            >
                                <span className={`${classes.accodText} font-medium`}>Real-Time Reporting</span>
                            </label>
                            <div
                                className={`${classes.tabContent} ${expanded === "tab27" ? classes.tabContentExpanded : ""
                                    }`}
                            >
                                <div className={classes.contentInner}>
                                    <p>
                                        Providing real-time quality reports accessible through the SB Maxx portal.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.accordion}>
                        <div className={classes.tab}>
                            <input
                                type="checkbox"
                                id="cb28"
                                checked={expanded === "tab28"}
                                onChange={() => toggleAccordion("tab28")}
                            />
                            <label
                                htmlFor="cb28"
                                className={`${classes.tabLabel} ${expanded === "tab28" ? classes.tabLabelExpanded : ""
                                    }`}
                            >
                                <span className={`${classes.accodText} font-medium`}>Adherence to Standards</span>
                            </label>
                            <div
                                className={`${classes.tabContent} ${expanded === "tab28" ? classes.tabContentExpanded : ""
                                    }`}
                            >
                                <div className={classes.contentInner}>
                                    <p>
                                        Consistently adhering to international standards and specifications.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div><img src={accordianImg8} alt="" /></div>
            </div>
            <div className={`${classes.container} flex items-center gap-[60px] pt-12`}>
                <div><img src={accordianImg9} alt="" /></div>
                <section>
                    <h2 className={`${classes.accordMainHeading} text-5xl font-semibold`}><span>On-Time Deliveries
                    </span> <br />
                        Keeping Your Business Moving</h2>
                    <div className={classes.accordion}>
                        <div className={classes.tab}>
                            <input
                                type="checkbox"
                                id="cb29"
                                checked={expanded === "tab29"}
                                onChange={() => toggleAccordion("tab29")}
                            />
                            <label
                                htmlFor="cb29"
                                className={`${classes.tabLabel} ${expanded === "tab29" ? classes.tabLabelExpanded : ""
                                    }`}
                            >
                                <span className={`${classes.accodText} font-medium`}>Real-time Order Tracking</span>
                            </label>
                            <div
                                className={`${classes.tabContent} ${expanded === "tab29" ? classes.tabContentExpanded : ""
                                    }`}
                            >
                                <div className={classes.contentInner}>
                                    <p>Provides customers with real-time order tracking and status updates.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={classes.accordion}>
                        <div className={classes.tab}>
                            <input
                                type="checkbox"
                                id="cb30"
                                checked={expanded === "tab30"}
                                onChange={() => toggleAccordion("tab30")}
                            />
                            <label
                                htmlFor="cb30"
                                className={`${classes.tabLabel} ${expanded === "tab30" ? classes.tabLabelExpanded : ""
                                    }`}
                            >
                                <span className={`${classes.accodText} font-medium`}>Guaranteed Delivery Timelines</span>
                            </label>
                            <div
                                className={`${classes.tabContent} ${expanded === "tab30" ? classes.tabContentExpanded : ""
                                    }`}
                            >
                                <div className={classes.contentInner}>
                                    <p>
                                        Offers guaranteed delivery timelines with penalty clauses for late delivery.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.accordion}>
                        <div className={classes.tab}>
                            <input
                                type="checkbox"
                                id="cb31"
                                checked={expanded === "tab31"}
                                onChange={() => toggleAccordion("tab31")}
                            />
                            <label
                                htmlFor="cb31"
                                className={`${classes.tabLabel} ${expanded === "tab31" ? classes.tabLabelExpanded : ""
                                    }`}
                            >
                                <span className={`${classes.accodText} font-medium`}>Efficient Supply Chain Management</span>
                            </label>
                            <div
                                className={`${classes.tabContent} ${expanded === "tab31" ? classes.tabContentExpanded : ""
                                    }`}
                            >
                                <div className={classes.contentInner}>
                                    <p>
                                        Leverages Steelbazaar’s logistics network for efficient supply chain management.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.accordion}>
                        <div className={classes.tab}>
                            <input
                                type="checkbox"
                                id="cb32"
                                checked={expanded === "tab32"}
                                onChange={() => toggleAccordion("tab32")}
                            />
                            <label
                                htmlFor="cb32"
                                className={`${classes.tabLabel} ${expanded === "tab32" ? classes.tabLabelExpanded : ""
                                    }`}
                            >
                                <span className={`${classes.accodText} font-medium`}>Reduced Lead Times</span>
                            </label>
                            <div
                                className={`${classes.tabContent} ${expanded === "tab32" ? classes.tabContentExpanded : ""
                                    }`}
                            >
                                <div className={classes.contentInner}>
                                    <p>
                                        Achieves reduced lead times through strategic warehousing and local sourcing.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
            <div className={`${classes.container} py-[110px]`}>
                <h2 className={`${classes.ProcurementSectionHeading} font-semibold text-center`}>
                    Tailored Procurement Solutions <br /><span>for Every Business</span></h2>
                <p className={`${classes.procurementSubHeading} text-2xl font-semibold text-center mt-1`}>Smarter, Faster, Seamless</p>
                <div className='mt-[50px] flex gap-[30px]'>
                    <div>
                        <div className={`${classes.cardTop1} py-[10px] w-[325px] text-center font-semibold text-2xl `}>SMEs & Micro</div>
                        <div className={`${classes.bussinessCard} h-[682px] rounded-2xl relative`}>
                            <img src={iProcure} alt="" />
                            <div className='px-[15px] '>
                                <h5 className={`${classes.procureCardHeaidng3} my-3 font-semibold`}><span>i</span>Procure</h5>
                                <p className='flex items-center gap-3 mb-5'><img src={orangeTick} alt="" /> RM Sourcings: eBidding Based</p>
                                <p className='flex items-center gap-3'><img src={orangeTick} alt="" /> Inventory Management</p>
                            </div>
                            <div className={`${classes.cardTop1} ${classes.cardBottom1} py-[10px] w-full text-center font-semibold text-lg absolute bottom-0 flex gap-[10px] items-center justify-center`}>Explore More <div className={classes.btnArrow}><ArrowForwardIcon /></div></div>

                        </div>
                    </div>
                    <div>
                        <div className={`${classes.cardTop2} py-[10px] w-[325px] text-center font-semibold text-2xl `}>Mid Corporate & MSMEs</div>
                        <div className={`${classes.bussinessCard} h-[682px] rounded-2xl relative`}>
                            <img src={procurePlus} alt="" />
                            <div className='px-[15px] '>
                                <h5 className={`${classes.procureCardHeaidng1} my-3 font-semibold`}>iProcure <span>Plus</span></h5>
                                <div className='flex flex-col gap-5'>
                                    <p className='flex items-center gap-3'><img src={blueTick} alt="" />RM Sourcings: eBidding Based</p>
                                    <p className='flex items-center gap-3'><img src={blueTick} alt="" /> Inventory Management</p>
                                    <p className='flex items-center gap-3'><img src={blueTick} alt="" /> Supply Chain Finance</p>
                                    <p className='flex items-center gap-3'><img src={blueTick} alt="" /> Logistics</p>
                                    <p className='flex items-center gap-3'><img src={blueTick} alt="" /> Performance Metrics based Dashboard</p>
                                </div>
                            </div>
                            <div className={`${classes.cardTop2} ${classes.cardBottom1} py-[10px] w-full text-center font-semibold text-lg absolute bottom-0 flex gap-[10px] items-center justify-center`}>Explore More <div className={classes.btnArrow}><ArrowForwardIcon /></div></div>

                        </div>
                    </div>
                    <div>
                        <div className={`${classes.cardTop3} py-[10px] w-[325px] text-center font-semibold text-2xl `}>Enterprises</div>
                        <div className={`${classes.bussinessCard} h-[682px] rounded-2xl relative`}>
                            <img src={sbMaxCard} alt="" />
                            <div className='px-[15px] '>
                                <h5 className={`${classes.procureCardHeaidng2} my-3 font-semibold`}>SB<span> Maxx</span> </h5>
                                <div className='flex flex-col gap-5'>
                                    <p className='flex items-center gap-3'><img src={primaryBlueTick} alt="" /> Supply Chain Planning</p>
                                    <p className='flex items-center gap-3'><img src={primaryBlueTick} alt="" /> Demand and Forecasting</p>
                                    <p className='flex items-center gap-3'><img src={primaryBlueTick} alt="" /> RM Sourcings: eBidding Based</p>
                                    <p className='flex items-center gap-3'><img src={primaryBlueTick} alt="" /> Supply Chain Finance</p>
                                    <p className='flex items-center gap-3'><img src={primaryBlueTick} alt="" /> Inventory Management</p>
                                    <p className='flex items-center gap-3'><img src={primaryBlueTick} alt="" /> Logistics</p>
                                    <p className='flex items-center gap-3'><img src={primaryBlueTick} alt="" /> Performance Metrics based Dashboard</p>
                                </div>

                            </div>
                            <div className={`${classes.cardTop3} ${classes.cardBottom1} py-[10px] w-full text-center font-semibold text-lg absolute bottom-0 flex gap-[10px] items-center justify-center`}>Explore More <div className={classes.btnArrow}><ArrowForwardIcon /></div></div>

                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.pCardsContainer}>
                <div className={`${classes.container} pt-[110px] pb-[110px]`}>
                    <div >
                        <h2 className={`${classes.sectionHeading} font-semibold text-center`}>Benefits of Steelbazaar Procurement</h2>
                    </div>
                    <div className="flex gap-[15px] flex-wrap mt-[30px]">
                        {cardData.map((card, index) => (
                            <div
                                key={index}
                                className={`${classes.procurementcard} rounded-lg flex flex-col items-center`}
                            >
                                <div
                                    className={`${classes.pCardIconContainer} flex items-center justify-center p-[10px] w-[82px] h-[82px] rounded-2xl`}
                                >
                                    <img src={card.icon} alt={card.title} />
                                </div>
                                <h4 className="mt-[10px] mb-[10px] font-semibold text-center">{card.title}</h4>
                                <p className="text-base text-center">{card.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <RequestDemoTemplate />
        </>
    );
};

export default ProcurementTemplate;
