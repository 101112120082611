import React, { createContext, useState, useMemo, FC } from 'react';

interface AuthLoginType {
    children: React.ReactNode;
}

export interface AuthPopupType {
    isAuthTemplateActive: boolean;
    toggleAuthTemplate: (value?: boolean) => void;
}

export const AuthPopupContext = createContext<AuthPopupType>({
    isAuthTemplateActive: false,
    toggleAuthTemplate: () => {},
});

export const AuthPopupProvider: FC<AuthLoginType> = ({ children }) => {
    const [isAuthTemplateActive, setAuthTemplateActive] = useState(false);

    const toggleAuthTemplate = (value?: boolean) => {
        setAuthTemplateActive(value ?? !isAuthTemplateActive);
    };

    const providerValue = useMemo(() => ({
        isAuthTemplateActive,
        toggleAuthTemplate,
    }), [isAuthTemplateActive]);

    return (
        <AuthPopupContext.Provider value={providerValue}>
            {children}
        </AuthPopupContext.Provider>
    );
};
