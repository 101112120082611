import React from 'react';
import { createUseStyles } from 'react-jss';
import navbarArrowIcon from '../../../../assets/icons/navbarArrowIcon.svg';
import { STATIC_PAGES_ROUTES } from '../../../../utils/constant';
import { Link } from 'react-router-dom';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        width: '100%',
        background: '#fff',
        position: 'absolute',
        top: '125px',
        left: '0',
        zIndex: '99',
        margin: '0 auto',
    },
       primaryContainer: {
        width: '1038px',
        margin: '0 auto',
        position: 'relative',
    },
    mainContainer: {
        position: 'absolute',
        top: '0px',
        background: '#fff',
        width: '200px',
        borderRadius: '0px 0px 8px 8px',
        "& p": {
            color: '#000',
            borderBottom: `1px solid ${theme.palette.border.neutral.neutral200}`,
            position: 'relative',
            "&::before": {
                content: '""',
                display: 'inline-block',
                position: 'absolute',
                left: '-4px',
                top: '50%',
                transform: 'translateY(-50%)',
                width: '8px', 
                height: '12px',
                backgroundImage: `url(${navbarArrowIcon})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
            },
        },
    },
}));



const AboutUsDropdown: React.FC = () => {
    const classes = useStyles();

    return (
        <>
        <div className={`${classes.container}`}>
            <div className={`${classes.primaryContainer}`}>
                <div className={`${classes.mainContainer} px-[23px] pt-5 pb-10`}>
                <p className='p-[10px]'>  <Link to={`${STATIC_PAGES_ROUTES.ABOUT_US}`}>About Steelbazaar</Link></p>
                <p className='p-[10px]'>  <Link to={`${STATIC_PAGES_ROUTES.ABOUT_US}`}>Vision and Mission</Link></p>
                </div>
            </div>
        </div>
        </>
    );
};

export default AboutUsDropdown;
 